
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.70313rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #000;
  --bs-progress-bar-bg: #2a3b19;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius); }
  @media (min-width: 1201px) {
    .progress,
    .progress-stacked {
      --bs-progress-font-size: 0.70312rem; } }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition); }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height); }

.progress-stacked > .progress {
  overflow: visible; }

.progress-stacked > .progress > .progress-bar {
  width: 100%; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }
      
.progress-bx {
  overflow: hidden;
  position: relative; }
  .progress-bx .progress {
    overflow: unset; }
    .progress-bx .progress .progress-bar {
      position: relative;
      overflow: unset; }
  .progress-bx.style-1 .progress-label {
    font-weight: 500;
    letter-spacing: 0.02em;
    margin-bottom: 15px; }
    .progress-bx.style-1 .progress-label .pull-end {
      position: absolute;
      right: 0; }
  .progress-bx.style-1 .progress {
    height: 3px;
    background: #D0D0D0;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 30px;
    overflow: visible; }
    .progress-bx.style-1 .progress .progress-bar {
      box-shadow: none;
      -webkit-animation: myanimation 2s;
      animation: myanimation 2s; }
    .progress-bx.style-1 .progress.primary .progress-bar {
      background: var(--primary); }

.scroltop {
  box-shadow: -4px 4px 24px -10px #791313 !important;
  background: var(--primary);
  border-color: var(--primary);
  border-style: solid;
  border-width: 0;
  bottom: 15px;
  color: #000;
  cursor: pointer;
  display: none;
  height: 50px;
  line-height: 50px;
  margin: 0;
  position: fixed;
  right: 15px;
  text-align: center;
  width: 50px;
  z-index: 999;
  border-radius: 6px;
  box-shadow: -4px 4px 24px -10px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  @media only screen and (max-width: 575px) {
    .scroltop {
      height: 35px;
      width: 35px;
      line-height: 35px; } }

.scroltop-progress {
  position: fixed;
  cursor: pointer;
  bottom: 15px;
  right: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: block;
  opacity: 0;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
  z-index: 999;
  visibility: hidden;
  transform: translateY(20px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear; }
  .scroltop-progress::after {
    content: "\f062";
    font-size: 18px;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    position: absolute;
    left: 0;
    top: 0;
    height: 50px;
    cursor: pointer;
    width: 50px;
    line-height: 50px;
    color: var(--dark);
    text-align: center;
    transition: all 200ms linear; }
  .scroltop-progress svg path {
    fill: none;
    stroke-width: 4;
    box-sizing: border-box;
    stroke: var(--dark);
    transition: all 200ms linear; }
  .scroltop-progress.active-progress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0); }
  .scroltop-progress.scroltop-primary::after {
    color: var(--primary); }
  .scroltop-progress.scroltop-primary svg path {
    stroke: var(--primary); }
  .scroltop-progress.scroltop-secondary::after {
    color: #791313; }
  .scroltop-progress.scroltop-secondary svg path {
    stroke: #791313; }
  .scroltop-progress:hover::after {
    color: var(--primary); }
  .scroltop-progress:hover svg path {
    stroke: var(--primary); }

.video {
  z-index: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 87px;
  height: 87px;
  line-height: 87px;
  background-color: var(--primary);
  color: #000;
  border-radius: 50%;
  text-align: center;
  font-size: 24px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }
  .video:hover {
    transform: translate(-50%, -50%) scale(1.2); }
  .video i {
    color: #000; }
  @media only screen and (max-width: 1280px) {
    .video {
      width: 70px;
      height: 70px;
      line-height: 70px;
      font-size: 18px; } }
  @media only screen and (max-width: 767px) {
    .video {
      width: 55px;
      height: 55px;
      line-height: 55px;
      font-size: 16px; } }
  @media only screen and (max-width: 575px) {
    .video svg {
      width: 50px;
      height: 50px; } }
  .video.style-1 {
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 18px; }

@keyframes animationSignal1 {
  /*Video Popup*/
  0% {
    opacity: 0.8;
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    -webkit-transform: scale(0.9); }
  100% {
    transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    opacity: 0; } }

@-webkit-keyframes animationSignal1 {
  /*Video Popup*/
  0% {
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    -webkit-transform: scale(0.9); }
  100% {
    transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    opacity: 0; } }

@keyframes animationSignal2 {
  /*Video Popup*/
  0% {
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9); }
  100% {
    transform: scale(1.9);
    -moz-transform: scale(1.9);
    -webkit-transform: scale(1.9);
    -ms-transform: scale(1.9);
    -o-transform: scale(1.9);
    opacity: 0; } }

@-webkit-keyframes animationSignal2 {
  /*Video Popup*/
  0% {
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9); }
  100% {
    transform: scale(1.9);
    -moz-transform: scale(1.9);
    -webkit-transform: scale(1.9);
    -ms-transform: scale(1.9);
    -o-transform: scale(1.9);
    opacity: 0; } }
