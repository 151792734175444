
.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #000;
  --bs-list-group-active-bg: #2a3b19;
  --bs-list-group-active-border-color: #2a3b19;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius); }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > .list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg); }
  .list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg); }

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg); }
  .list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color); }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: calc(-1 * var(--bs-list-group-border-width));
      border-top-width: var(--bs-list-group-border-width); }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: calc(-1 * var(--bs-list-group-border-width));
      border-left-width: var(--bs-list-group-border-width); }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1170px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1440) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width); }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle); }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle); }
  .list-group-item-primary.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-primary-text);
    --bs-list-group-active-border-color: var(--bs-primary-text); }

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle); }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle); }
  .list-group-item-secondary.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-secondary-text);
    --bs-list-group-active-border-color: var(--bs-secondary-text); }

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle); }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-success-border-subtle); }
  .list-group-item-success.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-success-text);
    --bs-list-group-active-border-color: var(--bs-success-text); }

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle); }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-info-border-subtle); }
  .list-group-item-info.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-info-text);
    --bs-list-group-active-border-color: var(--bs-info-text); }

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle); }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle); }
  .list-group-item-warning.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-warning-text);
    --bs-list-group-active-border-color: var(--bs-warning-text); }

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle); }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle); }
  .list-group-item-danger.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-danger-text);
    --bs-list-group-active-border-color: var(--bs-danger-text); }

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle); }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-light-border-subtle); }
  .list-group-item-light.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-light-text);
    --bs-list-group-active-border-color: var(--bs-light-text); }

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle); }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle); }
  .list-group-item-dark.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-dark-text);
    --bs-list-group-active-border-color: var(--bs-dark-text); }
    
.list-circle li,
.list-angle-double li,
.list-square li,
.list-square-check li {
  position: relative;
  padding: 6px 5px 6px 22px; }
  .list-circle li::before,
  .list-angle-double li::before,
  .list-square li::before,
  .list-square-check li::before {
    position: absolute;
    left: 0;
    top: 7px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900; }

.list-circle.primary li::before,
.list-angle-double.primary li::before,
.list-square.primary li::before,
.list-square-check.primary li::before {
  color: #791313; }

.list-check li {
  position: relative;
  padding: 6px 5px 6px 30px; }
  .list-check li::before {
    font-size: 20px;
    content: "\e83f";
    font-family: 'feather' !important;
    font-style: normal;
    position: absolute;
    left: 0;
    top: 5px; }

.list-check.primary li::before {
  color: #791313; }

.avatar-list.avatar-list-stacked .avatar {
  margin-right: -17px;
  display: inline-block; }
  .avatar-list.avatar-list-stacked .avatar:last-child {
    margin-right: 0; }
  .avatar-list.avatar-list-stacked .avatar span {
    background-color: #791313;
    color: #000;
    height: 2.375rem;
    line-height: 2.375rem;
    width: 2.375rem;
    border-radius: 100%;
    font-size: 12px;
    display: block;
    text-align: center; }
  .avatar-list.avatar-list-stacked .avatar img {
    object-fit: cover;
    border-radius: 100%;
    border: 2px solid #fff;
    height: 2.375rem;
    width: 2.375rem; }
  .avatar-list.avatar-list-stacked .avatar:hover img {
    z-index: 1; }

/* Light Gallery */
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-sub-html,
.lg-toolbar {
  background-color: rgba(30, 30, 30, 0.6); }

.lg-outer {
  z-index: 999999; }
  .lg-outer .lg-toogle-thumb,
  .lg-outer .lg-thumb-outer,
  .lg-outer .lg-img-wrap,
  .lg-outer .lg-item {
    background-color: transparent; }
  .lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
    border-color: #791313; }

.lg-backdrop {
  z-index: 99999;
  background-color: rgba(30, 30, 30, 0.9); }

.lg-outer .lg-toogle-thumb,
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-toolbar .lg-icon,
#lg-counter {
  color: #000; }

.lg-item {
  cursor: pointer;
  display: block; }

.lg-progress-bar .lg-progress {
  background-color: #791313; }