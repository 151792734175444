@media only screen and (max-width: 575px) {
    .mobile_popup{
        z-index: 9999 !important;
        position: fixed;
        left: 0px;
        right: 0px;
        bottom: 0px;
        max-height: 90%;
        opacity: 1;
        color: rgb(40, 44, 63);
        overflow-y: auto;
        will-change: transform;
        backface-visibility: hidden;
        background: rgb(255, 255, 255);
        border-radius: 24px 24px 0px 0px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px -4px 16px;
        animation: 0.25s ease 0s 1 normal none running mobileAnimation;
        transform: translate3d(0px, 0px, 0px);
    }
    .mobile_poup_header  {
        border-bottom: 1px solid rgb(240, 240, 245);
        white-space: nowrap;
        padding: 24px 12px 12px;
    }
    .mobile_item_poup_header{
        border-bottom: 1px dashed rgb(190, 190, 193);
        white-space: nowrap;
        padding: 24px 12px 12px;
    }
    .mobile_popup_content{
        height: 70vh;
    }
    .mobile_popup_inner_content{
        height: 100%;
        overflow: hidden scroll;
        width: 100%;
        position: relative;
    }
    .mobile_filtre_popup_list{
        display: flex;
    flex-direction: column;
    align-content: flex-start;
    height: 100%;
    overflow: scroll;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    margin: 0px;
    padding: 0px;
    border-right: 1px solid rgb(240, 240, 245);
    background: rgb(255, 255, 255);
    width: 45%;
    scrollbar-width: none;
    }
    .mobile_filtre_popup_list.active::before{
        content: "";
        position: absolute;
        transition: transform 300ms ease-out 0s;
        top: 0px;
        transform: translateY(calc(0px));
        left: 0px;
        width: 6px;
        height: 56px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        background: var(--primary)
    }
    .filter_inner_list .active::before{
        content: "";
        position: absolute;
        transition: transform 300ms ease-out 0s;
        top: 0px;
        transform: translateY(calc(0px));
        left: 0px;
        width: 6px;
        height: 56px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        background: var(--primary)
    }
    .mobile_filter_popup_content{
        margin-left: 42%;
    padding-left: 24px;
    padding-right: 12px;
    height: 100%;
    width: 100%;
    position: relative;
    }
    .mobile_popup_footer{
        display: flex;
        box-shadow: rgba(40, 44, 63, 0.1) 0px -2.63677px 5.27354px;
        width: 100%;
        height: 58px;
        bottom: 52px;
        position: absolute;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: end;
        justify-content: flex-end;
        padding: 0px 16px;
        z-index: 1;
        background: rgb(255, 255, 255);
    }
    .mobile_popup_clear_filter{
        width: 140px;
        margin: 0px 16px;
        padding: 0px;
    }
    .mobile_popup_text{
        -webkit-font-smoothing: antialiased;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.3px;
        color: var(--primary);
        overflow: hidden;
        width: 100%;
        display: -webkit-box;
        overflow-wrap: break-word;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    .mobile_popup_btn{
        background-color: unset;
        box-shadow: unset;
        position: relative;
        overflow: hidden;
        transition: transform 0.1s ease-in 0s;
        padding: 8px;
        border: 0px;
        outline: none;
        appearance: none;
    }
    .mobile_apply_btn{
        width: 96px;
        margin: auto;
        position: relative;
        overflow: hidden;
        background-color: var(--primary);
        width: 100%;
        transition: transform 0.1s ease-in 0s;
        min-width: 96px;
        padding-left: 16px;
        padding-right: 16px;
        height: 44px;
        border: 0px;
        border-radius: 12px;
    }
    .mobile_add_item{
        border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    background-color: rgb(255, 255, 255);
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    min-height: 100px;
    max-height: 90%;
    overflow-y: hidden;
    z-index: 400;
    box-shadow: rgba(27, 30, 36, 0.1) 0px 12px 28px 0px;
    will-change: transform;
    animation: 250ms ease 0s 1 normal none running mobileAnimation;
    transform: translate3d(0px, 0px, 0px);
    bottom: 0px;
    }
    .mobile_add_item_wrapper{
        background-color: #f0f0f5;
    }
    .mobile_item_popup{
        padding: 20px 20px;
    }
    .img-line{
        width: 20% !important;
    }
   .addon_mobile_inner_footer{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center
   }
   .mobile_popup_item_total{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-right: 4px;
   }
   .mobile_popup_sub_inner{
    margin-right: 4px;
   }
   .mobile_item_add_cart_wrapper{
    width: 55%;
   }
   .jfNwiD {
    position: relative;
    overflow: hidden;
    background-color: var(--primary);
    width: 100%;
    transition: transform 0.1s ease-in 0s;
    min-width: 96px;
    padding-left: 16px;
    padding-right: 16px;
    height: 44px;
    border: 0px;
    border-radius: 12px;
}
.fCyAnV {
    text-align: center;
}
.eRVJEZ {
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: rgba(255, 255, 255, 0.92);
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    overflow-wrap: break-word;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.addon_item_footer{
    padding: 16px 16px 20px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px -3px 20px 0px;
    background-color: rgb(255, 255, 255);
    position: fixed;
    bottom: 40px;
    width: 100%;
}
// .MenuStickyBottom_container{
//     position: relative !important;
//     bottom: 0px !important;
// }
.modal-dialog{
    margin: 0px !important;
}
    }

@keyframes mobileAnimation {
    0% {
        transform: translate3d(0px, 100%, 0px);
    }
    
    100% {
        transform: translate3d(0px, 0px, 0px);
    }
}