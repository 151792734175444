.category_pg_container {
  // background-image: url('../../../images/common_black_bg.png');

  .category_products_container {
    display: flex;

    .categories_section {
      padding-bottom: 9rem;
      overscroll-behavior: contain;
      position: sticky;
      z-index: 10;
      display: block;
      height: 100vh;
      width: 14rem;
      top: 90px;
      overflow: auto;
      border-right: 0.1px solid #c8c7c7;

      .category_active {
        border-left-width: 4px;
        border-left-style: solid;
        --tw-border-opacity: 1;
        border-left-color: var(--primary);
        background: linear-gradient(270deg, #ebd5d5, 73.74%, #f5caca);

        .category_name {
          color: var(--primary) !important;
        }
      }

      .category_inactive_border {
        border-left-width: 4px;
        border-left-color: #fff;
        border-left-style: solid;
      }

      .category {
        margin-bottom: 0.5rem;
        display: flex;
        cursor: pointer;
        align-items: center;
        width: 100%;
        padding: 0.5rem 1rem;

        &:hover .category_name {
          color: #791313;
          // background: linear-gradient(270deg, #efed9e 73.74%, #f8f572);
        }

        .category_img_container {
          height: 48px;
          width: 48px;

          .category_img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .category_name {
          color: var(--theme-text-color);
          font-weight: 700;
          margin-top: 0.25rem;
          font-size: 16px !important;
          display: block;
          padding-left: 15px;
        }
      }
    }

    .categories_section::-webkit-scrollbar {
      width: 0px !important;
      background-color: none !important;
    }
  }
}

.categories_products {
  width: calc(100% - 20rem);

  .categories_products_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;

    h1 {
      margin: 0;
      font-size: 1.5rem;
      font-weight: 800;
      font-family: inherit;
      color: #000;
    }
  }

  .categories_products_list {
    padding-left: 1rem;

    .category_pr_container {
      row-gap: 30px;
    }
  }
}

.product_block {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  border: 1px solid #e5e7eb;
  // box-shadow: 2px 2px 10px #fff;

  .product_transition {
    transition: all 0.35s ease-in-out;
    position: relative;
    overflow: hidden;

    // &::before {
    //     content: "";
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     width: 100%;
    //     height: calc(45%);
    //     border-radius: 24px 24px 0 0;
    //     background-color: #e0e0e0;
    //     transition: all .35s ease-in-out;
    // }

    .product_image {
      object-fit: cover;
      height: 190px;
      width: 100%;
    }
  }

  .product_caption {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 15px 15px 20px;
    background-color: #ffffff;

    .product_caption_top {
      display: flex;
      margin-bottom: auto;
      align-items: flex-start;
      justify-content: space-between;

      .product_title {
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: #000;
        letter-spacing: 0.1px;
        max-width: 80%;
        // font-family: 'Cinzel';
      }
    }

    .product_description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      letter-spacing: 0.5px;
      font-weight: 400;
      font-family: "Mulish", sans-serif;
      margin-bottom: 12px;
      color: #000;
    }

    .product_caption_bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 20px;
      flex-wrap: wrap;

      @media screen and (max-width: 425px) {
        display: block;
        padding-top: 5px;
      }
      .pr_stp {
        font-size: 12px;
        font-weight: 400;
        font-family: "Mulish";
        text-decoration: line-through;
        color: #2b1e3566;
      }

      .pr_price {
        font-size: 16px;
        font-weight: 700;
        font-family: "Mulish";
        margin-right: 7px;
        color: #791313;
      }

      .pr_with_customizable {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .pr_add_cart_btn {
        width: 80px;
        height: 30px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        letter-spacing: 0.3px;
        font-family: inherit;
        position: relative;
        text-align: center;
        background-color: #791313;

        @media screen and (max-width: 425px) {
          width: 100%;
          border: 1px solid #791313;
          background-color: #fff;
          margin-top: 8px;
        }
        .pr_add_title {
          text-align: center;
          width: 100%;
          line-height: 30px;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0.3px;
          font-family: inherit;
          cursor: pointer;
          color: var(--white);

          @media screen and (max-width: 425px) {
            color: var(--primary);
          }
        }

        .pr_add_inside_btns {
          width: 35%;
          font-size: 14px;
          line-height: 28px;
          font-family: inherit;
          cursor: pointer;
          color: var(--white);

          @media screen and (max-width: 425px) {
            color: var(--primary);
          }
        }

        .pr_add_qty {
          font-size: 14px;
          font-weight: 700;
          line-height: 30px;
          font-family: inherit;
          color: var(--white);

          @media screen and (max-width: 425px) {
            color: var(--primary);
          }
        }
      }
    }
  }
}

// MOBILE LAYOUT

.mobile_category_pg_container {
  .category_products_container {
    display: block;

    .categories_section {
      padding: 0px 20px;
      overscroll-behavior: contain;
      position: sticky;
      z-index: 10;
      display: flex;
      width: 100%;
      top: 39px;
      overflow: auto;
      background-color: #ffffff;
      border-bottom: 0.1px solid #5c5858;

      .category_active::after {
        content: "";
        border-bottom: 1.5px solid var(--primary);
        transition: border-bottom 0.3s ease, transform 0.3s ease;
        position: absolute;
        bottom: 0;
        height: 1px;
        width: 90%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      .category {
        display: flex;
        cursor: pointer;
        align-items: center;
        width: 100%;
        padding: 1rem 10px 1rem 0px;
        position: relative;

        &:hover .category_name {
          color: #791313;
        }

        .category_img_container {
          height: 50px;
          width: 50px;

          .category_img {
            width: 100%;
            height: 100%;
            max-width: inherit;
            object-fit: cover;
            border-radius: 5px;
          }
        }

        .category_name {
          color: var(--theme-text-color);
          font-weight: 700;
          font-size: 12px;
          display: block;
          font-family: "Mulish", sans-serif;
          width: 5rem;
          margin-left: 15px;
        }
      }
    }

    .mobile_categories_products {
      width: 100%;

      .categories_products_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 1rem;
        margin-top: 1rem;
        margin-bottom: 1.5rem;

        h1 {
          margin: 0;
          font-size: 1.5rem;
          font-weight: 700;
          font-family: inherit;
          color: #9a9a9a;
          letter-spacing: 1px;
        }
      }

      .categories_products_list {
        padding: 15px 15px 60px 15px;

        .mobile_product_block {
          overflow: hidden;
          height: 100%;
          display: flex;
          flex-direction: row;
          position: relative;
          border-radius: 8px;
          margin-bottom: 15px;
          background-color: #ffffff;
          padding: 2px;
          border: 1px solid rgba(2, 6, 12, 0.15);

          .product_transition {
            transition: all 0.35s ease-in-out;
            position: relative;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 10px;

            .product_image {
              width: 120px;
              height: 110px;
              border-radius: 5px;
              object-fit: cover;
            }
          }

          .product_caption {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding: 10px 10px 10px 10px;

            .product_caption_top {
              .product_title {
                font-size: 14px;
                font-weight: 800;
                text-transform: uppercase;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                line-height: 24px;
                font-family: "Mulish";
                color: #000;
                max-width: 100%;
                letter-spacing: 0.5px;
              }
            }

            .product_description {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              letter-spacing: 0.2px;
              font-weight: 400;
              font-family: "Mulish", sans-serif;
              margin-top: 8px;
              font-size: 12px;
            }

            .product_caption_bottom {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-top: 15px;

              .pr_stp {
                font-size: 15px;
                font-weight: 500;
                font-family: inherit;
                letter-spacing: 0.6px;
                text-decoration: line-through;
              }

              .pr_price {
                font-size: 18px;
                font-weight: 600;
                letter-spacing: 0.1px;
                font-family: inherit;
                margin-right: 7px;
                color: #791313;
              }
            }
          }
        }
      }
    }
  }
}

.categories_section::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.mobile_pr_add_cart_btn {
  width: 100px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  letter-spacing: 0.3px;
  position: relative;
  align-items: center;
  text-align: center;
  color: #ffffff;
  background-color: #791313;
  margin-top: 15px;

  .pr_add_title {
    text-align: center;
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    font-family: "Mulish", sans-serif;
    cursor: pointer;
    letter-spacing: 0.3px;
    color: var(--white);
  }

  .pr_add_inside_btns {
    width: 35%;
    font-size: 14px;
    font-weight: 800;
    line-height: 30px;
    cursor: pointer;
    font-family: "Mulish";
    color: var(--white);
  }

  .pr_add_qty {
    font-size: 14px;
    font-weight: 700;
    font-family: "Mulish";
    color: var(--white);
  }
}
