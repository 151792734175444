/* .collapse{
	border-radius:$radius;
} */
.publish-drop ul:hover {
  background-color: unset;
}

input.form-control {
  position: relative;
}

input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.right-sidebar-sticky {
  position: sticky;
  top: 7.5rem;
}

.content-title.box-primary,
.content-title.box-secondary,
.content-title.box-success,
.content-title.box-danger,
.content-title.box-warning,
.content-title.box-info,
.content-title.box-dark,
.content-title.box-light {
  background-color: #f25521;
}
.content-title.box-primary .cpa,
.content-title.box-primary i,
.content-title.box-secondary .cpa,
.content-title.box-secondary i,
.content-title.box-success .cpa,
.content-title.box-success i,
.content-title.box-danger .cpa,
.content-title.box-danger i,
.content-title.box-warning .cpa,
.content-title.box-warning i,
.content-title.box-info .cpa,
.content-title.box-info i,
.content-title.box-dark .cpa,
.content-title.box-dark i,
.content-title.box-light .cpa,
.content-title.box-light i {
  color: #000 !important;
}

.content-title.box-secondary {
  background-color: #791313;
}

.content-title.box-success {
  background-color: #10ca93;
}

.content-title.box-danger {
  background-color: #f25767;
}

.content-title.box-warning {
  background-color: #ff9f00;
}

.content-title.box-info {
  background-color: #00afef;
}

.content-title.box-dark {
  background-color: #181c32;
}

.content-title.box-light {
  background-color: #ebeef6;
}
.view-bottom {
  flex-direction: column;
  justify-content: flex-end;
  height: 88%;
  display: flex;
}

/* add-email */
.publish-content ul {
  padding: 0.625rem;
}
.publish-content ul:hover {
  background-color: #ecf2ff;
  border-radius: 0.375rem;
}

.dd .accordion-button:not(.collapsed) {
  box-shadow: unset;
}

.dd .accordion-body {
  border: 1px solid #eeeeee;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top: 0;
  margin-top: -5px;
}

@media only screen and (max-width: 575px) {
  .dd .dd-list {
    padding-left: 0;
  }
}
