.page-titles {
  padding: 0.9375rem 1.25rem;
  background: #fff;
  margin-bottom: 30px;
  border-radius: 5px;
}
[data-theme-version='dark'] .page-titles {
  background: #1e2746;
}
@media only screen and (max-width: 767px) {
  .page-titles {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 15px;
    padding: 15px 0;
  }
}
.page-titles .justify-content-sm-end {
  align-items: center;
}
.page-titles h4,
.page-titles .h4 {
  margin-bottom: 0;
  margin-top: 0;
  color: #f25521;
  font-size: 1.25rem;
}
.page-titles h4 span,
.page-titles .h4 span {
  font-size: 0.875rem;
  font-weight: 400;
}
.page-titles .breadcrumb {
  margin-bottom: 0;
  padding: 0;
  background: transparent;
  /*  .breadcrumb-item + .breadcrumb-item {
              &:before {
                  content: "\e606";
                  font-family: 'simple-line-icons';
                  font-size: 10px;
              }
          } */
}
.page-titles .breadcrumb li {
  margin-top: 0;
  margin-bottom: 0;
}
.page-titles .breadcrumb li a {
  color: #828690;
}
.page-titles .breadcrumb li.active {
  color: #555555;
}
.page-titles .breadcrumb-datepicker {
  font-size: 0.75rem;
  color: #89879f;
}
.page-titles .breadcrumb-datepicker__icon {
  font-size: 0.875rem;
}
.page-titles .breadcrumb-widget .border-dark {
  border-color: #dee2e6 !important;
}
.page-titles .breadcrumb-widget h4,
.page-titles .breadcrumb-widget .h4 {
  color: #646c9a;
  font-weight: 600;
}
@media only screen and (max-width: 575px) {
  .page-titles .breadcrumb-widget {
    text-align: left !important;
    margin-bottom: 0.9375rem;
  }
}
