.card {
  border: 0px solid #E1E1F0;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04);
  background: #fff; }
  
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E1E1F0;
    background: #fff;
    padding: 20px 30px; }
  .card-title {
    font-size: 24px;
    margin-bottom: 0px; }
  .card .card-body {
    padding: 20px 30px;
    background: #fff;
    border-radius: 15px; }
