.btn,
.wp-block-button__link {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.9375rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.6;
  --bs-btn-color: #666666;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.25rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

  .btn,
  .wp-block-button__link {
    transition: none;
  }
}

.btn:hover,
.wp-block-button__link:hover {
  color: #791313;
  background-color: var(--white);
  border-color: #791313;
}

.btn-check+.btn:hover,
.btn-check+.wp-block-button__link:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.btn:focus-visible,
.wp-block-button__link:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:focus-visible+.btn,
.btn-check:focus-visible+.wp-block-button__link {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:checked+.btn,
.btn-check:checked+.wp-block-button__link,
:not(.btn-check)+.btn:active,
:not(.btn-check)+.wp-block-button__link:active,
.btn:first-child:active,
.wp-block-button__link:first-child:active,
.btn.active,
.active.wp-block-button__link,
.btn.show,
.show.wp-block-button__link {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-check:checked+.btn:focus-visible,
.btn-check:checked+.wp-block-button__link:focus-visible,
:not(.btn-check)+.btn:active:focus-visible,
:not(.btn-check)+.wp-block-button__link:active:focus-visible,
.btn:first-child:active:focus-visible,
.wp-block-button__link:first-child:active:focus-visible,
.btn.active:focus-visible,
.active.wp-block-button__link:focus-visible,
.btn.show:focus-visible,
.show.wp-block-button__link:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled,
.wp-block-button__link:disabled,
.btn.disabled,
.disabled.wp-block-button__link,
fieldset:disabled .btn,
fieldset:disabled .wp-block-button__link {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary,
.wp-block-button__link {
  --bs-btn-color: #000;
  --bs-btn-bg: #2a3b19;
  --bs-btn-border-color: #2a3b19;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #91b35d;
  --bs-btn-hover-border-color: #8aaf53;
  --bs-btn-focus-shadow-rgb: 106, 141, 54;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #97b866;
  --bs-btn-active-border-color: #8aaf53;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #2a3b19;
  --bs-btn-disabled-border-color: #2a3b19;
}

.btn-secondary {
  --bs-btn-color: #000;
  --bs-btn-bg: #FE9F10;
  --bs-btn-border-color: #FE9F10;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fead34;
  --bs-btn-hover-border-color: #fea928;
  --bs-btn-focus-shadow-rgb: 216, 135, 14;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #feb240;
  --bs-btn-active-border-color: #fea928;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FE9F10;
  --bs-btn-disabled-border-color: #FE9F10;
}

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #13c24d;
  --bs-btn-border-color: #13c24d;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #36cb68;
  --bs-btn-hover-border-color: #2bc85f;
  --bs-btn-focus-shadow-rgb: 16, 165, 65;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #42ce71;
  --bs-btn-active-border-color: #2bc85f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #13c24d;
  --bs-btn-disabled-border-color: #13c24d;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #00aeff;
  --bs-btn-border-color: #00aeff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #26baff;
  --bs-btn-hover-border-color: #1ab6ff;
  --bs-btn-focus-shadow-rgb: 0, 148, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #33beff;
  --bs-btn-active-border-color: #1ab6ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #00aeff;
  --bs-btn-disabled-border-color: #00aeff;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #FFBD13;
  --bs-btn-border-color: #FFBD13;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc736;
  --bs-btn-hover-border-color: #ffc42b;
  --bs-btn-focus-shadow-rgb: 217, 161, 16;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffca42;
  --bs-btn-active-border-color: #ffc42b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FFBD13;
  --bs-btn-disabled-border-color: #FFBD13;
}

.btn-danger {
  --bs-btn-color: #000;
  --bs-btn-bg: #ff0003;
  --bs-btn-border-color: #ff0003;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ff2629;
  --bs-btn-hover-border-color: #ff1a1c;
  --bs-btn-focus-shadow-rgb: 217, 0, 3;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff3335;
  --bs-btn-active-border-color: #ff1a1c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ff0003;
  --bs-btn-disabled-border-color: #ff0003;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #faf8f2;
  --bs-btn-border-color: #faf8f2;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d5d3ce;
  --bs-btn-hover-border-color: #c8c6c2;
  --bs-btn-focus-shadow-rgb: 213, 211, 206;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c8c6c2;
  --bs-btn-active-border-color: #bcbab6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #faf8f2;
  --bs-btn-disabled-border-color: #faf8f2;
}

.btn-dark {
  --bs-btn-color: #000;
  --bs-btn-bg: #222222;
  --bs-btn-border-color: #222222;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #434343;
  --bs-btn-hover-border-color: #383838;
  --bs-btn-focus-shadow-rgb: 67, 67, 67;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #4e4e4e;
  --bs-btn-active-border-color: #383838;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #222222;
  --bs-btn-disabled-border-color: #222222;
}

.btn-outline-primary,
.is-style-outline .wp-block-button__link {
  --bs-btn-color: #2a3b19;
  --bs-btn-border-color: #2a3b19;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #2a3b19;
  --bs-btn-hover-border-color: #2a3b19;
  --bs-btn-focus-shadow-rgb: 125, 166, 64;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #2a3b19;
  --bs-btn-active-border-color: #2a3b19;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2a3b19;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2a3b19;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #FE9F10;
  --bs-btn-border-color: #FE9F10;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FE9F10;
  --bs-btn-hover-border-color: #FE9F10;
  --bs-btn-focus-shadow-rgb: 254, 159, 16;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FE9F10;
  --bs-btn-active-border-color: #FE9F10;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FE9F10;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FE9F10;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #13c24d;
  --bs-btn-border-color: #13c24d;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #13c24d;
  --bs-btn-hover-border-color: #13c24d;
  --bs-btn-focus-shadow-rgb: 19, 194, 77;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #13c24d;
  --bs-btn-active-border-color: #13c24d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #13c24d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #13c24d;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #00aeff;
  --bs-btn-border-color: #00aeff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #00aeff;
  --bs-btn-hover-border-color: #00aeff;
  --bs-btn-focus-shadow-rgb: 0, 174, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #00aeff;
  --bs-btn-active-border-color: #00aeff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00aeff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00aeff;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #FFBD13;
  --bs-btn-border-color: #FFBD13;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FFBD13;
  --bs-btn-hover-border-color: #FFBD13;
  --bs-btn-focus-shadow-rgb: 255, 189, 19;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FFBD13;
  --bs-btn-active-border-color: #FFBD13;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFBD13;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFBD13;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #ff0003;
  --bs-btn-border-color: #ff0003;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ff0003;
  --bs-btn-hover-border-color: #ff0003;
  --bs-btn-focus-shadow-rgb: 255, 0, 3;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff0003;
  --bs-btn-active-border-color: #ff0003;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ff0003;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff0003;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #faf8f2;
  --bs-btn-border-color: #faf8f2;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #faf8f2;
  --bs-btn-hover-border-color: #faf8f2;
  --bs-btn-focus-shadow-rgb: 250, 248, 242;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #faf8f2;
  --bs-btn-active-border-color: #faf8f2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #faf8f2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #faf8f2;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #222222;
  --bs-btn-border-color: #222222;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #222222;
  --bs-btn-hover-border-color: #222222;
  --bs-btn-focus-shadow-rgb: 34, 34, 34;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #222222;
  --bs-btn-active-border-color: #222222;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #222222;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #222222;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 106, 141, 54;
  text-decoration: underline;
}

.btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg,
.btn-group-lg>.btn,
.btn-group-lg>.wp-block-button__link {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.17188rem;
  --bs-btn-border-radius: 1rem;
}

.btn-sm,
.btn-group-sm>.btn,
.btn-group-sm>.wp-block-button__link {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.82031rem;
  --bs-btn-border-radius: 0.75rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
  display: none;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}


.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group>.btn,
.btn-group>.wp-block-button__link,
.btn-group-vertical>.btn,
.btn-group-vertical>.wp-block-button__link {
  position: relative;
  flex: 1 1 auto;
}

.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:checked+.wp-block-button__link,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn-check:focus+.wp-block-button__link,
.btn-group>.btn:hover,
.btn-group>.wp-block-button__link:hover,
.btn-group>.btn:focus,
.btn-group>.wp-block-button__link:focus,
.btn-group>.btn:active,
.btn-group>.wp-block-button__link:active,
.btn-group>.btn.active,
.btn-group>.active.wp-block-button__link,
.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:checked+.wp-block-button__link,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn-check:focus+.wp-block-button__link,
.btn-group-vertical>.btn:hover,
.btn-group-vertical>.wp-block-button__link:hover,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.wp-block-button__link:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.wp-block-button__link:active,
.btn-group-vertical>.btn.active,
.btn-group-vertical>.active.wp-block-button__link {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.25rem;
}


.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.wp-block-button__link:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn.dropdown-toggle-split:first-child,
.btn-group>.dropdown-toggle-split.wp-block-button__link:first-child,
.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn-group:not(:last-child)>.wp-block-button__link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:nth-child(n + 3),
.btn-group>.wp-block-button__link:nth-child(n + 3),
.btn-group> :not(.btn-check)+.btn,
.btn-group> :not(.btn-check)+.wp-block-button__link,
.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn-group:not(:first-child)>.wp-block-button__link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-group-sm>.wp-block-button__link+.dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-group-lg>.wp-block-button__link+.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.wp-block-button__link,
.btn-group-vertical>.btn-group {
  width: 100%;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.wp-block-button__link:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn-group:not(:last-child)>.wp-block-button__link {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn~.btn,
.btn-group-vertical>.wp-block-button__link~.btn,
.btn-group-vertical>.btn~.wp-block-button__link,
.btn-group-vertical>.wp-block-button__link~.wp-block-button__link,
.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn-group:not(:first-child)>.wp-block-button__link {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-close {
  --bs-btn-close-color:#FFFFFF;
  --bs-btn-close-opacity: 0.5;  
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0rem rgba(125, 166, 64, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: var(--bs-btn-close-opacity);
}

.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}

.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}

.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

button:hover,
button:active,
button:focus {
  outline: 0;
}

.btn,
.wp-block-button__link {
  padding: 8px 24px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  line-height: 1.5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  color: var(--white);
}

@media only screen and (max-width: 1200px) {

  .btn,
  .wp-block-button__link {
    padding: 9px 20px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 575px) {

  .btn,
  .wp-block-button__link {
    font-size: 14px;
    padding: 6px 15px;
  }
}

.btn.btn-md,
.btn-md.wp-block-button__link {
  padding: 15px 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
}

@media only screen and (max-width: 991px) {

  .btn.btn-md,
  .btn-md.wp-block-button__link {
    padding: 15px 20px;
  }
}

@media only screen and (max-width: 767px) {

  .btn.btn-md,
  .btn-md.wp-block-button__link {
    padding: 10px 20px;
    font-size: 14px;
  }
}

.btn.btn-lg,
.btn-group-lg>.btn,
.btn-group-lg>.wp-block-button__link,
.btn-lg.wp-block-button__link {
  padding: 18px 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

@media only screen and (max-width: 991px) {

  .btn.btn-lg,
  .btn-group-lg>.btn,
  .btn-group-lg>.wp-block-button__link,
  .btn-lg.wp-block-button__link {
    padding: 15px 20px;
  }
}

@media only screen and (max-width: 767px) {

  .btn.btn-lg,
  .btn-group-lg>.btn,
  .btn-group-lg>.wp-block-button__link,
  .btn-lg.wp-block-button__link {
    padding: 10px 20px;
    font-size: 14px;
  }
}

.btn:hover,
.wp-block-button__link:hover {
  color: var(--white);
}

.btn.btn-secondary,
.btn-secondary.wp-block-button__link {
  color: #000;
}

.btn.btn-secondary:hover,
.btn-secondary.wp-block-button__link:hover {
  color: #000;
}

.btn i,
.wp-block-button__link i {
  font-size: 20px;
  line-height: 1;
}

.google-btn {
  color: #000000;
  border: 1px solid #B1B1B1;
}

.google-btn i {
  margin-right: 15px;
}

.google-btn svg {
  margin-right: 15px;
}

.google-btn:hover {
  color: #000;
  border: 1px solid #B1B1B1;
}

.btn-block {
  display: block;
  width: 100%;
}

.shadow-primary {
  box-shadow: 0px 5px 30px 0px var(--rgba-primary-2);
}

.btn-rounded {
  border-radius: 50px;
}

.btn-info {
  color: #000;
}

.btn-info:hover {
  color: #000;
}

.btn-info .btn-icon-left {
  color: #00aeff;
}

.btn-white {
  background-color: #000;
  color: #222;
}

.btn-white:hover {
  color: #222 !important;
  background-color: #000 !important;
}

.btn-success {
  color: #000;
}

.btn-success .btn-icon-left {
  color: #13c24d;
}

.btn-success:hover {
  color: #000;
}

.btn-primary,
.wp-block-button__link {
  border-color: #791313;
  background-color: #791313;

  

}





.btn-primary.disabled,
.disabled.wp-block-button__link,
.btn-primary:disabled,
.wp-block-button__link:disabled,
.btn-primary:not(:disabled):not(.disabled).active,
.wp-block-button__link:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.wp-block-button__link:not(:disabled):not(.disabled):active,
.btn-primary:active,
.wp-block-button__link:active,
.btn-primary:focus,
.wp-block-button__link:focus,
.btn-primary:hover,
.wp-block-button__link:hover {
  border-color: #791313;
  background-color: #791313;
  color: var(--white);
}

.btn-primary .btn-icon-left,
.wp-block-button__link .btn-icon-left {
  color: #791313;
}

.btn-secondary {
  border-color: #791313;
  background-color: #791313;
  color: #000;
}

.btn-secondary .btn-icon-left {
  color: #791313;
}

.btn-link {
  color: #791313;
  text-decoration: none;
}

.btn-link:hover {
  color: var(--primary-hover);
}

.btn-underline {
  font-size: 1rem;
  color: #791313;
  font-weight: 400;
  border-bottom: 2px solid #791313;
}

.btn-dark {
  color: #000 !important;
}

.btn-dark:hover {
  color: #000;
}

.btn-dark .btn-icon-left {
  color: #222222;
}

.btn-dark.light {
  background-color: #7d95ce;
  border-color: #7d95ce;
  color: #151F37 !important;
}

.btn-dark.light:hover {
  color: #000 !important;
}

.btn-outline-primary,
.is-style-outline .wp-block-button__link {
  color: #791313;
  border-color: #791313;
}

.btn-outline-primary.disabled,
.is-style-outline .disabled.wp-block-button__link,
.btn-outline-primary:disabled,
.is-style-outline .wp-block-button__link:disabled,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.is-style-outline .wp-block-button__link:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.is-style-outline .wp-block-button__link:not(:disabled):not(.disabled):active,
.btn-outline-primary:active,
.is-style-outline .wp-block-button__link:active,
.btn-outline-primary:focus,
.is-style-outline .wp-block-button__link:focus,
.btn-outline-primary:hover,
.is-style-outline .wp-block-button__link:hover {
  border-color: #791313;
  background-color: #791313;
  color: #fff;
}

.btn-outline-secondary {
  color: #791313;
  border-color: #791313;
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:active,
.btn-outline-secondary:focus,
.btn-outline-secondary:hover {
  border-color: #791313;
  background-color: #791313;
  color: #000;
}

.btn-outline-danger {
  color: #ff0003;
}

.btn-outline-danger:hover {
  color: #000;
}

.btn-outline-info {
  color: #00aeff;
}

.btn-outline-info:hover {
  color: #000;
}

.btn-outline-success {
  color: #13c24d;
}

.btn-outline-success:hover {
  color: #000;
}

.btn-outline-warning {
  color: #FFBD13;
}

.btn-outline-warning:hover {
  color: #000;
}

.btn-outline-dark {
  color: #222222;
}

.btn-outline-dark:hover {
  color: #000;
}

.btn-check:checked+.btn,
.btn-check:checked+.wp-block-button__link {
  color: #000;
}

.btn-xl {
  font-size: 22px;
  padding: 25px 35px;
}

.btn-sm,
.btn-group-sm>.btn,
.btn-group-sm>.wp-block-button__link {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: var(--border-radius-base);
}

.btn-link-lg {
  font-weight: 700;
  font-size: 18px;
}

.btn-link-lg:hover {
  text-decoration: unset;
}

.btn-light {
  background-color: #000;
}

.btn-light:hover {
  background-color: #791313;
  color: #000 !important;
  border-color: transparent;
}

.btn-light.text-primary:hover {
  color: #000 !important;
}

.light.btn-success {
  color: #13c24d;
  border: 1px solid #c2f9d4;
  background-color: #f0fef4;
}

.light.btn-success:hover {
  background-color: #13c24d;
  border-color: #13c24d;
  color: #000;
}

.light.btn-info {
  color: #00aeff;
  border: 1px solid #c7edff;
  background-color: #e6f7ff;
}

.light.btn-info:hover {
  background-color: #00aeff;
  border-color: #00aeff;
  color: #000;
}

.light.btn-primary,
.light.wp-block-button__link {
  color: #791313;
  border: 1px solid var(--rgba-primary-3);
  background-color: var(--rgba-primary-1);
}

.light.btn-primary:hover,
.light.wp-block-button__link:hover {
  background-color: #791313;
  border-color: #791313;
  color: #000;
}

.light.btn-secondary {
  color: #FE9F10;
  border: 1px solid #ffe0b3;
  background-color: #ffedd1;
}

.light.btn-secondary:hover {
  background-color: #FE9F10;
  border-color: #FE9F10;
  color: #000;
}

.light.btn-warning {
  color: #FFBD13;
  border: 1px solid #ffebb6;
  background-color: #0003d5;
}

.light.btn-warning:hover {
  background-color: #FFBD13;
  border-color: #FFBD13;
  color: #000;
}

.light.btn-danger {
  color: #ff0003;
  border: 1px solid #ffd6d7;
  background-color: #ffe6e6;
}

.light.btn-danger:hover {
  background-color: #ff0003;
  border-color: #ff0003;
  color: #000;
}

.light.btn-dark {
  color: #222222;
  border: 1px solid #d5d5d5;
  background-color: #e1e1e1;
}

.light.btn-dark:hover {
  background-color: #222222;
  border-color: #222222;
  color: #000;
}

.btn-danger {
  color: #000;
}

.btn-icon-right {
  color: #000;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  display: inline-block;
  margin: -15px -10px -15px 20px;
  padding: 15px 10px;
}

.btn-icon-left {
  background: #fff;
  border-radius: 50px;
  display: inline-block;
  float: left;
  color: inherit;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: -8px 15px -8px -20px;
}

.wp-block-button {
  margin-bottom: 0.3125rem;
}

.is-style-squared .wp-block-button__link {
  border-radius: 0;
}

.wp-block-button__link {
  border-radius: 60px;
}

.wp-block-button__link::after {
  content: none;
}

@media only screen and (max-width: 575px) {

  .btn-group .btn,
  .btn-group .wp-block-button__link {
    padding-left: 15px;
    padding-right: 15px;
  }

}

@media only screen and (max-width: 991px) {

  .link-dark {
    float: right;
  }
}

.fb-btn {
  background: #4867AA !important;
}

.tw-btn {
  background: #81B2DF !important;
}

.in-btn {
  background: #D74141 !important;
}

.lin-btn {
  background: #3A9DF9 !important;
}

.btn.btn-cart,
.btn-cart.wp-block-button__link {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 4px;
}

.btn.btn-cart i,
.btn-cart.wp-block-button__link i {
  font-size: 22px;
}

.btn.btn-cart.btn-white,
.btn-cart.btn-white.wp-block-button__link {
  background-color: #000;
}

.btn.btn-cart.btn-square,
.btn-cart.btn-square.wp-block-button__link {
  border-radius: 6px;
}

.btn.btn-cart.btn-circle,
.btn-cart.btn-circle.wp-block-button__link {
  border-radius: 20px;
}

.btn.btn-cart.btn-primary,
.btn-cart.wp-block-button__link {
  background: #791313;
}

.btn-gray {
  background-color: #F3F3F3;
  color: var(--title);
  transition: 0.5s !important;
}

.btn-gray:hover {
  background: #791313;
  color: #000;
}

.filter-btn i {
  font-family: 'feather' !important;
  font-style: normal;
  text-transform: none;
  vertical-align: middle;
}

@keyframes animationPlayBtn1 {
  0% {
    opacity: 0.8;
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }

  100% {
    transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    opacity: 0;
  }
}

@-webkit-keyframes animationPlayBtn1 {
  0% {
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }

  100% {
    transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    opacity: 0;
  }
}

.btn-hover-1 {
  overflow: hidden;
  z-index: 1;
}

.btn-hover-1::before,
.btn-hover-1::after {
  background: #fff;
  content: '';
  position: absolute;
}

.btn-hover-1::before {
  width: 120%;
  height: 0;
  padding-bottom: 120%;
  top: -110%;
  left: -10%;
  border-radius: 50%;
  transform: translate3d(0, 68%, 0) scale3d(0, 0, 0);
}

.btn-hover-1::after {
  width: 90%;
  top: 0;
  left: 0;
  transform: translate3d(0, -100%, 0);
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}

.btn-hover-1 span {
  display: block;
  position: relative;
  z-index: 2;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.btn-hover-1.btn-white::before {
  background: #791313;
}

.btn-hover-1.btn-white::after {
  background: #791313;
}

.btn-hover-1:focus::before,
.btn-hover-1:active::before,
.btn-hover-1:hover::before {
  transform: translate3d(0, 0, 0) scale3d(1.2, 1, 1);
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}

.btn-hover-1:focus::after,
.btn-hover-1:active::after,
.btn-hover-1:hover::after {
  transform: translate3d(0, 0, 0);
  transition-duration: 0.05s;
  transition-delay: 0.4s;
  transition-timing-function: linear;
}

.btn-hover-1:focus span,
.btn-hover-1:active span,
.btn-hover-1:hover span {
  animation: MoveScaleUpInitial 0.3s forwards, MoveScaleUpEnd 0.3s forwards 0.3s;
  color: #791313;
}

.btn-hover-1:focus.btn-primary span,
.btn-hover-1.wp-block-button__link:focus span,
.btn-hover-1:focus.btn-outline-primary span,
.is-style-outline .btn-hover-1.wp-block-button__link:focus span,
.btn-hover-1:active.btn-primary span,
.btn-hover-1.wp-block-button__link:active span,
.btn-hover-1:active.btn-outline-primary span,
.is-style-outline .btn-hover-1.wp-block-button__link:active span,
.btn-hover-1:hover.btn-primary span,
.btn-hover-1.wp-block-button__link:hover span,
.btn-hover-1:hover.btn-outline-primary span,
.is-style-outline .btn-hover-1.wp-block-button__link:hover span {
  color: #791313;
}

.btn-hover-1:focus.btn-secondary span,
.btn-hover-1:focus.btn-outline-secondary span,
.btn-hover-1:active.btn-secondary span,
.btn-hover-1:active.btn-outline-secondary span,
.btn-hover-1:hover.btn-secondary span,
.btn-hover-1:hover.btn-outline-secondary span {
  color: #791313;
}

.btn-hover-1:focus.btn-dark span,
.btn-hover-1:focus.btn-outline-dark span,
.btn-hover-1:active.btn-dark span,
.btn-hover-1:active.btn-outline-dark span,
.btn-hover-1:hover.btn-dark span,
.btn-hover-1:hover.btn-outline-dark span {
  color: #222222;
}

.btn-hover-1:focus.btn-white span,
.btn-hover-1:active.btn-white span,
.btn-hover-1:hover.btn-white span {
  color: #000;
}

@keyframes MoveScaleUpInitial {
  to {
    transform: translate3d(0, -105%, 0) scale3d(1, 2, 1);
    opacity: 0;
  }
}

@keyframes MoveScaleUpEnd {
  from {
    transform: translate3d(0, 100%, 0) scale3d(1, 2, 1);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.btn-hover-2 {
  color: rgba(0, 0, 0, 0) !important;
  transition: all .3s ease !important;
  text-shadow: 0 0 0 #fff, 500px 0 0 #fff;
  overflow: hidden;
  font-family: 'Mulish', sans-serif;
}

.btn-hover-2.btn-white {
  text-shadow: 0 0 0 #000, 500px 0 0 #000;
}

.btn-hover-2.btn-gray {
  text-shadow: 0 0 0 var(--title), 500px 0 0 var(--title);
}

// .btn-hover-2:hover {
//   text-shadow: -600px 0 0 #fff, 0 0 0 #fff; }
.btn-hover-2:hover.btn-white {
  text-shadow: -600px 0 0 #000, 0 0 0 #000;
}

.btn-hover-2:hover.btn-gray {
  text-shadow: -600px 0 0 #fff, 0 0 0 #fff;
}

.btn-shadow {
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
}

.btn.btn-square,
.btn-square.wp-block-button__link {
  padding: 0;
  position: relative;
  border-radius: var(--border-radius-base);
  outline: none;
  border: 0;
}

.btn.btn-square.btn-white i,
.btn-square.btn-white.wp-block-button__link i {
  color: #791313;
}

// .btn.btn-square i, .btn-square.wp-block-button__link i {
//   font-size: 24px; }
.btn.btn-square .badge,
.btn-square.wp-block-button__link .badge {
  position: absolute;
  top: -16px;
  right: -11px;
  padding: 0;
  height: 20px;
  width: 20px;
  font-weight: 700;
  font-size: 12px;
  line-height: 21px;
  background: #791313;
  color: #FFFFFF;
  border-radius: 10px;
}

.is-fixed .other_header_title {
  color: #000;
}

.btn.btn-hover-3,
.btn-hover-3.wp-block-button__link {
  overflow: hidden;
}

.btn.btn-hover-3 .btn-text,
.btn-hover-3.wp-block-button__link .btn-text {
  transition: opacity .50s, transform .80s;
  transition-timing-function: cubic-bezier(0.12, 0.8, 0.3, 1);
}

.btn.btn-hover-3 .btn-text::before,
.btn-hover-3.wp-block-button__link .btn-text::before {
  content: attr(data-text);
  position: absolute;
  white-space: nowrap;
  display: inline-block;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, 100%);
  transition: opacity .5s, transform .8s;
  transition-timing-function: cubic-bezier(0.12, 0.8, 0.3, 1);
}

.btn.btn-hover-3:hover .btn-text,
.btn-hover-3.wp-block-button__link:hover .btn-text {
  transform: translateY(-200%);
}

.btn.btn-hover-3:hover .btn-text::before,
.btn-hover-3.wp-block-button__link:hover .btn-text::before {
  transform: translate(-50%, 150%);
  opacity: 1;
}