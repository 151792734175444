.menu-left-part {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #faefef;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 999;
    top: 0;
    padding-top: 100px;
    padding-left: 0px;
    left: calc(-100% - 100px);
    transition: 0.4s left cubic-bezier(0.22, 0.61, 0.36, 1);
}

.menu-part-opened {
    left: 0;
}

#header-main-menu {
    padding-left: 50px;
    padding-right: 10px;
    vertical-align: top;
}

.mobile-main-menu {
    background-color: transparent;
    max-width: 90%;
}

.mobile-main-menu li a {
    font-size: 29px;
    color: #000;
    padding: 10px 0;
    position: relative;
    font-weight: 700;
    font-family: 'Mulish', sans-serif;
}
.current {
    color: var(--primary) !important;
}

.mobile-main-menu li a::after {
    height: 0px;
    left: 10px;
    top: 33px;
    content: "";
    display: block;
    background-color: var(--primary);
    transition: width 0.3s;
    position: absolute;
    z-index: -1;
    width: 0;
    border-radius: 2px;
}
.mobile-main-menu li a.current::after {
    width: 100%;
}

.menu-right-text {
    margin-top: 40px;
    display: block;
    width: 75%;
    margin-left: 50px;

}

.menu-text-title {
    color: var(--primary);
    font-size: 13px;
    letter-spacing: 5px;
    font-weight: 700;
    padding-bottom: 30px;
    margin-bottom: 0;
}

.menu-text {
    font-size: 24px;
    color: #000;
    line-height: 40px;
    font-weight: 600;
    font-family: 'Mulish', sans-serif;
}
.menu_primary_text {
    font-size: 34px;
    font-weight: 700;
    color: var(--primary);
    font-family: 'Mulish', sans-serif;
}