// .fade {
//     background-color: rgba(0, 0, 0, 0.7);
// }

.show {
    display: block;
}

.modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    z-index: 999999999;
}

.fade {
    transition: opacity .15s linear
}

.shadow {
    box-shadow: 0 0.125rem 0.25rem #d5d0d07a !important
}

.delivery_location svg {
    width: 1.5rem;
    height: 1.5rem;
    color: var(--primary);
}

.map_gps {
    align-items: center;
}

.map_model_title {
    font-size: 16px;
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    color: #000;
}


.google_map {
    height: 100%;
    width: 100%;
}

@media (min-width: 1200px) {
    // .map_location_form {
    //     display: flex;
    // }
    // .gmap_container {
    //     width: 60%;
    //     height: 70vh;
    // }
    .gmap_address {
        width: 100%;
        padding: 20px
    }
}
.gmap_container  {
    width: 100%;
    height: 70vh;
}
@media screen and (max-width: 1200px) {
    .map_location_form {
        display: block;
    }
    // .gmap_container {
    //     width: 100%;
    //     height: 50vh;
    // }
    .gmap_address {
        width: 100%;
        padding: 15px
    }
}

.address_type {
    display: flex;
    align-items: center;
    gap: 2px;
}

.no-address-found {
    font-size: 14px;
    color: #7e808c;
    // margin: 25px 0 0;
    // margin-left: 56px;
    font-weight: 600;
    font-family: "Mulish", sans-serif;
}

.no-address-found-popup {
    height: 100px;
    line-height: 100px;
    font-size: 14px;
    color: #7e808c;
    font-weight: 400;
    padding: 0px 50px;
    font-family: inherit;
}


.address_type p {
    margin: 0px;
    font-size: 14px;
    color: #000;
}

.address_type {
    cursor: pointer;
}

.get_location_header {
    display: inline-block !important;

}

.delivery_location {
    font-size: 16px;
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    color: #000;

}

.delivery_location img{
  
  border-radius: 2px;
  width: 20px;
  height: 20px;
    

}

.map_geolocation_wrapper {
    margin-bottom: 20px;
    width: 100%;
    min-height: 28rem;
}

.map_geolocation_inner_container {
    display: flex;
    padding: 15px 25px;
    border: 1px solid #dddbdb
}


.map_location {
    text-align: left;
    font-size: 18px;
    width: 32px;
    padding-top: 0;
    padding-left: 1px;
    color: #535665;
}

.map_geolocation_list {
    display: table-cell;
    cursor: pointer;
}

.map_geolocation_list_title {
    font-family: "Mulish", sans-serif;
    color: #791313;
    font-weight: 700;
    font-size: 15px;
}

.map_geolocation_list_subtitle {
    font-size: 13px;
    color:#000;
    line-height: 1.3;
    margin: 0px;
    font-family: "Mulish", sans-serif;
}

.map_location svg {
    color: #791313 !important;
    margin-right: 5px;
}

.geo_location_map_list {
    padding: 15px 20px 15px 25px;
    border: 1px solid #d4d5d9;
    margin: 10px 0px;
}

.saved_address_title {
    font-size: 15px;
    color: #7e808c;
    text-transform: capitalize;
    color: #791313;
    font-weight: 700;
    font-family: 'Mulish';
    ;
}

.saved_address_individual {
    overflow: hidden;
    cursor: pointer;
}

.saved_address_inner_wrapper {
    display: flex;
    padding: 22px 0px;
}

.address_type {
    display: table-cell;
    text-align: left;
    font-size: 18px;
    padding-top: 0;
    padding-left: 1px;
    color: #535665;
}

.saved_address_content {
    padding-left: 10px;
}

.saved_address_inner_title {
    font-size: 16px;
    font-weight: 700;
    color: #282c3f;
    font-family: 'Mulish';
}

.saved_address_subtitle {
    font-size: 13px;
    color: #93959f;
    line-height: 1.3;
    padding-top: 5px;
    font-family: 'Mulish';
}

.saved_address_individual::after {
    content: "";
    display: block;
    border-top: 1px dashed #a9abb2;
    position: relative;
}

.saved_address_individual:last-child::after {
    border-width: 0px;
}

.saved_address_individual:first-child::after {
    border-width: 1px;
}

.load_mre_btn_Wrapper {
    color: #791313;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 13px;
    cursor: pointer;
    padding: 10px 0px;
    text-align: right;
    font-family: 'Mulish';
}

// .serviceable_map_container {
//     padding: 15px 10px;
// }
// not servicable location
.not_serviceable_map_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px;

    .sit_tight {
        font-family: inherit;
        font-size: 1rem;
        line-height: 1.75rem;
        font-weight: 700;
        font-family: "Mulish", sans-serif;
        color: #000;
    }

    .not_serviceable_group_text {
        display: flex;
        align-items: center;
        margin-top: 10px;
        cursor: pointer;
        font-family: "Mulish", sans-serif;
        color: #000;
    }
    .take_me_to {
        font-family: inherit;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        margin-right: 5px;
    }
}

.address_types {
    display: flex;
    column-gap: 10px;
}
.address_type_btn {
    padding: 5px 20px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    transition: all 0.2s;
    color: var(--primary);
    border: 1px solid var(--primary);
    cursor: pointer;
}
.selected_address_type_btn {
    background-color: var(--primary);
    color: #fff;
}