
.team-detail {
    position: relative;
    display: flex;
    overflow: hidden;
    align-items: center;
    margin-bottom: 55px; }
    .team-detail .dz-media {
      width: 500px;
      min-width: 500px; }
    .team-detail .dz-info {
      width: 100%;
      margin-left: 50px; }
      .team-detail .dz-info .head {
        padding-bottom: 18px;
        margin-bottom: 15px;
        border-bottom: 2px solid #C6C6C6; }
        .team-detail .dz-info .head .title {
          font-size: 50px;
          line-height: 1.1; }
        .team-detail .dz-info .head .sub-title {
          font-weight: 400;
          font-size: 18px; }
      .team-detail .dz-info p {
        font-size: 18px;
        width: 580px;
        margin-bottom: 20px; }
      .team-detail .dz-info .team-info {
        margin-bottom: 18px; }
        .team-detail .dz-info .team-info li {
          font-weight: 400;
          font-size: 18px;
          margin-bottom: 10px; }
          .team-detail .dz-info .team-info li strong {
            font-weight: 400;
            color: var(--title);
            margin-right: 15px; }
      .team-detail .dz-info .dz-social-icon ul {
        margin-right: -20px; }
        .team-detail .dz-info .dz-social-icon ul li {
          display: inline-block;
          padding: 0 5px;
          margin-right: 20px; }
          .team-detail .dz-info .dz-social-icon ul li a {
            font-size: 20px; }
            .team-detail .dz-info .dz-social-icon ul li a i {
              color: #666666; }
    @media only screen and (max-width: 1280px) {
      .team-detail {
        margin-bottom: 40px; }
        .team-detail .dz-info .head .title {
          font-size: 35px; }
        .team-detail .dz-info p {
          font-size: 16px;
          width: 100%;
          margin-bottom: 15px; }
        .team-detail .dz-info .team-info li {
          font-size: 16px; } }
    @media only screen and (max-width: 991px) {
      .team-detail .dz-media {
        width: 250px;
        min-width: 250px;
        height: 350px; }
        .team-detail .dz-media img {
          height: 100%;
          object-fit: cover; }
      .team-detail .dz-info {
        margin-left: 30px; }
        .team-detail .dz-info .head {
          padding-bottom: 10px;
          margin-bottom: 10px; }
          .team-detail .dz-info .head .title {
            font-size: 25px; }
        .team-detail .dz-info p {
          font-size: 14px; }
        .team-detail .dz-info .dz-social-icon ul li a {
          font-size: 16px; } }
    @media only screen and (max-width: 767px) {
      .team-detail {
        display: block;
        margin-bottom: 20px; }
        .team-detail .dz-media {
          width: 100%;
          min-width: 100%;
          height: 100%; }
        .team-detail .dz-info {
          margin-left: 0;
          margin-top: 30px; }
          .team-detail .dz-info .head .title {
            margin-bottom: 0; } }
  
  .team-bio {
    margin-bottom: 55px; }
    @media only screen and (max-width: 1280px) {
      .team-bio {
        margin-bottom: 30px; } }
    @media only screen and (max-width: 767px) {
      .team-bio {
        margin-bottom: 20px; } }
  
  .dish-gallery {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px; }
    .dish-gallery li {
      margin-bottom: 20px;
      z-index: 1;
      padding-left: 10px;
      padding-right: 10px; }
      .dish-gallery li img {
        width: 100%;
        min-width: 100%;
        height: 150px; }
    @media only screen and (max-width: 767px) {
      .dish-gallery {
        margin-bottom: 10px; }
        .dish-gallery li img {
          height: 120px; } }
    .dish-gallery.gallery-grid-2 li {
      width: 50%;
      padding-left: 10px;
      padding-right: 10px; }
  
  .team-skills p {
    width: 480px; }
    @media only screen and (max-width: 1280px) {
      .team-skills p {
        width: 100%; } }
  
  .dz-card {
    position: relative;
    background-color: #000; }
    .dz-card .dz-info {
      padding: 30px;
      position: relative; }
    .dz-card .dz-title {
      margin-bottom: 15px;
      word-break: break-word; }
      @media only screen and (max-width: 575px) {
        .dz-card .dz-title {
          margin-bottom: 15px; } }
    .dz-card.blog-half {
      display: flex; }
      @media only screen and (max-width: 575px) {
        .dz-card.blog-half {
          display: block; } }
      .dz-card.blog-half .dz-title {
        margin-bottom: 10px; }
      .dz-card.blog-half .dz-info {
        padding: 30px;
        position: relative;
        flex: 1; }
      .dz-card.blog-half .dz-media {
        margin-bottom: 0; }
        .dz-card.blog-half .dz-media a {
          display: block;
          height: 100%; }
        .dz-card.blog-half .dz-media img {
          height: 100%;
          object-fit: cover; }
          @media only screen and (max-width: 575px) {
            .dz-card.blog-half .dz-media img {
              min-height: auto; } }
      .dz-card.blog-half.post-video .post-video-icon {
        width: 60px;
        height: 60px;
        font-size: 20px; }
      .dz-card.blog-half .swiper-container {
        height: 100%; }
  
  .dz-media.height-sm {
    height: 220px; }
    .dz-media.height-sm img {
      height: 100%;
      object-fit: cover; }
  
  .dz-media.height-md {
    height: 265px; }
    .dz-media.height-md img {
      height: 100%;
      object-fit: cover; }
    @media only screen and (max-width: 1200px) {
      .dz-media.height-md {
        height: 220px; } }
    @media only screen and (max-width: 767px) {
      .dz-media.height-md {
        height: 200px; } }
    .dz-media.height-md .post-swiper {
      height: 100%; }
  
  .dz-media.height-lg {
    height: 350px; }
    @media only screen and (max-width: 1200px) {
      .dz-media.height-lg {
        height: 300px; } }
    @media only screen and (max-width: 767px) {
      .dz-media.height-lg {
        height: 220px; }
        .dz-media.height-lg img {
          height: 100%;
          object-fit: cover; } }
  
  .dz-meta {
    margin-bottom: 10px; }
    .dz-meta ul {
      margin: 0;
      padding: 0; }
      .dz-meta ul li {
        display: inline-block;
        position: relative;
        font-size: 14px;
        font-weight: 400;
        margin-right: 12px; }
        .dz-meta ul li i {
          font-size: 10px;
          margin-right: 5px;
          color: #791313;
          transform: scale(1.2);
          position: relative;
          top: 0px; }
        .dz-meta ul li a {
          color: inherit; }
        @media only screen and (max-width: 575px) {
          .dz-meta ul li {
            margin-right: 10px;
            padding-right: 10px; } }
  
  .dz-badge {
    background-color: #791313;
    color: #000;
    border-radius: 3px;
    padding: 6px 15px;
    font-size: 15px; }
    .dz-badge:hover {
      color: #000; }
  
  .post-video .post-video-icon {
    width: 100px;
    height: 100px;
    line-height: 100px;
    border-radius: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #791313;
    font-size: 30px;
    margin: 0 auto;
    background-color: #000;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -webkit-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
    box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.5);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px; }
    @media only screen and (max-width: 575px) {
      .post-video .post-video-icon {
        height: 50px;
        width: 50px;
        line-height: 50px;
        font-size: 14px; } }
    .post-video .post-video-icon::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      border: 1px solid #fff;
      -webkit-border-radius: 50%;
      -khtml-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      border-radius: 100%;
      animation: animationSignal1;
      animation-iteration-count: infinite;
      animation-duration: 3s;
      -webkit-animation: animationSignal1;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-duration: 3s;
      z-index: -1;
      transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      -moz-transition: all 0.5;
      -o-transition: all 0.5;
      -webkit-transition: all 0.5;
      -ms-transition: all 0.5;
      transition: all 0.5; }
    .post-video .post-video-icon::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      border: 1px solid #fff;
      -webkit-border-radius: 100%;
      -khtml-border-radius: 100%;
      -moz-border-radius: 100%;
      -ms-border-radius: 100%;
      -o-border-radius: 100%;
      border-radius: 100%;
      animation: animationSignal2;
      animation-iteration-count: infinite;
      animation-duration: 3s;
      -webkit-animation: animationSignal2;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-duration: 3s;
      z-index: -1;
      transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      -moz-transition: all 0.5;
      -o-transition: all 0.5;
      -webkit-transition: all 0.5;
      -ms-transition: all 0.5;
      transition: all 0.5; }
  
  .dz-card.blog-half.sticky {
    position: relative;
    border: 0.375rem solid #791313;
    overflow: hidden; }
    .dz-card.blog-half.sticky .sticky-icon {
      background: #791313;
      width: 3.125rem;
      height: 3.125rem;
      position: absolute;
      transform: rotate(45deg);
      top: -1.563rem;
      right: -1.563rem;
      z-index: 1;
      color: #000; }
      .dz-card.blog-half.sticky .sticky-icon i {
        position: absolute;
        bottom: 0.1875rem;
        right: 1.125rem;
        transform: rotate(5deg);
        color: #000;
        font-size: 1rem; }
  
  .swiper-grid-wrapper.swiper {
    width: 100%;
    height: 690px;
    margin-left: auto;
    margin-right: auto; }
  
  .swiper-grid-wrapper .dz-card.style-1 {
    height: 100%; }
    .swiper-grid-wrapper .dz-card.style-1 .dz-media {
      height: 100% !important; }
  
  .swiper-grid-wrapper .swiper-slide {
    height: calc((100% - 30px) / 2) !important;
    transition: all 0.5s;
    display: flex;
    justify-content: center;
    align-items: center; }
  
  .dz-card.style-1 {
    position: relative;
    border-radius: 10px;
    box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
    overflow: hidden;
    background-color: #000; }
    .dz-card.style-1 .dz-media {
      width: 100%;
      height: 100%; }
      .dz-card.style-1 .dz-media img {
        height: 100%;
        object-fit: cover;
        width: 100%; }
    .dz-card.style-1 .dz-info .dz-meta ul li {
      font-weight: 500; }
      .dz-card.style-1 .dz-info .dz-meta ul li i {
        font-size: 16px;
        transform: translateY(-2px) scale(1.15); }
    .dz-card.style-1 .dz-info .dz-title {
      margin-bottom: 8px; }
    @media only screen and (max-width: 1280px) {
      .dz-card.style-1 .dz-info {
        padding: 20px; } }
    .dz-card.style-1.blog-half .dz-media {
      min-width: 240px;
      width: 240px;
      height: auto; }
    .dz-card.style-1.blog-half .dz-info {
      padding: 25px 30px;
      display: flex;
      flex-direction: column; }
      .dz-card.style-1.blog-half .dz-info p {
        font-size: 14px;
        line-height: 22px; }
      .dz-card.style-1.blog-half .dz-info .read-btn {
        margin-top: auto; }
    @media only screen and (max-width: 767px) {
      .dz-card.style-1.blog-half .dz-info {
        padding: 20px; }
        .dz-card.style-1.blog-half .dz-info .dz-title {
          font-size: 18px; } }
    @media only screen and (max-width: 575px) {
      .dz-card.style-1.blog-half .dz-media {
        min-width: 100%;
        width: 100%;
        height: 250px; } }
  
  .dz-card.style-2 {
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.8s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    background: rgba(34, 34, 34, 0.05);
    box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15); }
    @media only screen and (max-width: 575px) {
      .dz-card.style-2 .dz-info {
        padding: 22px; } }
    .dz-card.style-2 .dz-meta {
      margin-bottom: 10px; }
      .dz-card.style-2 .dz-meta ul li {
        font-size: 15px;
        font-weight: 500;
        position: relative;
        margin-right: 35px; }
        .dz-card.style-2 .dz-meta ul li i {
          color: #791313;
          font-size: 12px; }
        .dz-card.style-2 .dz-meta ul li.dz-user {
          color: var(--title); }
          .dz-card.style-2 .dz-meta ul li.dz-user span {
            color: #791313; }
        .dz-card.style-2 .dz-meta ul li.dz-comment {
          color: var(--title); }
        .dz-card.style-2 .dz-meta ul li:last-child {
          margin-right: 0; }
        .dz-card.style-2 .dz-meta ul li:not(:last-child)::after {
          content: "";
          height: 6px;
          width: 6px;
          position: absolute;
          right: -20px;
          top: 50%;
          border-radius: 50%;
          background-color: #791313;
          transform: translate(0%, -50%); }
      @media only screen and (max-width: 991px) {
        .dz-card.style-2 .dz-meta ul li {
          margin-right: 15px; }
          .dz-card.style-2 .dz-meta ul li:not(:last-child)::after {
            display: none; } }
      @media only screen and (max-width: 575px) {
        .dz-card.style-2 .dz-meta ul li {
          margin-right: 9px; }
          .dz-card.style-2 .dz-meta ul li:not(:last-child)::after {
            display: none; } }
    .dz-card.style-2.blog-half {
      box-shadow: none; }
      .dz-card.style-2.blog-half .dz-date {
        position: absolute;
        top: 15px;
        left: 15px;
        z-index: 1;
        background-color: #791313;
        color: var(--theme-text-color);
        border-radius: var(--border-radius-base);
        font-size: 14px;
        font-weight: 500;
        padding: 6px 15px;
        margin-right: 0;
        text-transform: uppercase;
        display: block;
        -webkit-transition: all 0.8s;
        -ms-transition: all 0.8s;
        transition: all 0.8s; }
      .dz-card.style-2.blog-half .dz-info {
        padding: 25px 20px 25px 25px;
        flex: 1;
        display: flex;
        flex-direction: column; }
        @media only screen and (max-width: 575px) {
          .dz-card.style-2.blog-half .dz-info {
            padding: 25px 20px; } }
        .dz-card.style-2.blog-half .dz-info p {
          margin-bottom: 0;
          font-size: 15px;
          line-height: 24px; }
        .dz-card.style-2.blog-half .dz-info .btn-wrapper {
          margin-top: auto; }
      .dz-card.style-2.blog-half .dz-meta {
        margin-bottom: 15px; }
        .dz-card.style-2.blog-half .dz-meta ul li {
          font-size: 15px;
          font-weight: 500;
          position: relative;
          margin-right: 35px; }
          .dz-card.style-2.blog-half .dz-meta ul li i {
            color: #791313;
            font-size: 12px; }
          .dz-card.style-2.blog-half .dz-meta ul li.dz-user {
            color: var(--title); }
            .dz-card.style-2.blog-half .dz-meta ul li.dz-user span {
              color: #791313; }
          .dz-card.style-2.blog-half .dz-meta ul li.dz-comment {
            color: var(--title); }
          .dz-card.style-2.blog-half .dz-meta ul li:last-child {
            margin-right: 0; }
          .dz-card.style-2.blog-half .dz-meta ul li:not(:last-child)::after {
            content: "";
            height: 6px;
            width: 6px;
            position: absolute;
            right: -20px;
            top: 50%;
            border-radius: 50%;
            background-color: #791313;
            transform: translate(0%, -50%); }
        @media only screen and (max-width: 575px) {
          .dz-card.style-2.blog-half .dz-meta ul li {
            margin-right: 9px; }
            .dz-card.style-2.blog-half .dz-meta ul li:not(:last-child)::after {
              display: none; } }
      .dz-card.style-2.blog-half .dz-media {
        overflow: hidden;
        position: relative;
        border-radius: unset;
        width: 270px;
        min-width: 270px; }
      @media only screen and (max-width: 767px) {
        .dz-card.style-2.blog-half {
          display: block; }
          .dz-card.style-2.blog-half .dz-media {
            max-width: 100%;
            min-width: 100%;
            width: 100%;
            height: 250px; } }
      .dz-card.style-2.blog-half:hover .dz-meta ul li.dz-date {
        background-color: rgba(255, 255, 255, 0.2);
        color: #000; }
  
  .dz-card.style-3 {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    height: 100%;
    min-height: 570px;
    background-size: cover;
    object-fit: cover;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    background-position: center; }
    .dz-card.style-3::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;
      background: linear-gradient(359.53deg, #222222 0.4%, rgba(34, 34, 34, 0) 99.59%); }
    .dz-card.style-3 video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .dz-card.style-3 .dz-media {
      height: 100%;
      position: relative; }
      .dz-card.style-3 .dz-media img {
        filter: grayscale(0.1);
        height: 100%;
        object-fit: cover;
        -webkit-transition: all 0.8s;
        -ms-transition: all 0.8s;
        transition: all 0.8s; }
    .dz-card.style-3 .dz-info {
      padding: 30px;
      position: unset;
      bottom: 0;
      left: 0;
      z-index: 1;
      margin-top: auto; }
      .dz-card.style-3 .dz-info .dz-meta {
        margin-bottom: 0; }
        .dz-card.style-3 .dz-info .dz-meta ul li {
          position: relative; }
          .dz-card.style-3 .dz-info .dz-meta ul li i {
            color: #791313;
            font-size: 12px; }
          .dz-card.style-3 .dz-info .dz-meta ul li.dz-date {
            position: absolute;
            top: 30px;
            left: 30px;
            z-index: 1;
            background-color: #791313;
            color: var(--theme-text-color);
            font-weight: 500;
            border-radius: var(--border-radius-base);
            font-size: 15px;
            text-transform: uppercase;
            display: block;
            padding: 10px 25px;
            -webkit-transition: all 0.8s;
            -ms-transition: all 0.8s;
            transition: all 0.8s; }
            .dz-card.style-3 .dz-info .dz-meta ul li.dz-date:hover {
              background-color: #000;
              color: #791313; }
            .dz-card.style-3 .dz-info .dz-meta ul li.dz-date::after {
              display: none; }
          .dz-card.style-3 .dz-info .dz-meta ul li.dz-user {
            color: var(--title);
            font-weight: 500;
            color: #000;
            font-size: 15px;
            margin-right: 35px; }
            .dz-card.style-3 .dz-info .dz-meta ul li.dz-user span {
              color: #791313; }
            .dz-card.style-3 .dz-info .dz-meta ul li.dz-user i {
              color: #791313; }
          .dz-card.style-3 .dz-info .dz-meta ul li.dz-comment {
            color: var(--title);
            font-weight: 500;
            color: #000;
            font-size: 15px; }
            .dz-card.style-3 .dz-info .dz-meta ul li.dz-comment i {
              color: #791313; }
          .dz-card.style-3 .dz-info .dz-meta ul li:not(:last-child)::after {
            content: "";
            height: 6px;
            width: 6px;
            position: absolute;
            right: -17%;
            top: 50%;
            border-radius: 50%;
            background-color: #791313;
            transform: translate(0%, -50%); }
        @media only screen and (max-width: 767px) {
          .dz-card.style-3 .dz-info .dz-meta ul li.dz-date {
            left: 30px;
            top: 30px; } }
        @media only screen and (max-width: 575px) {
          .dz-card.style-3 .dz-info .dz-meta ul li.dz-date {
            left: 20px;
            top: 20px; }
          .dz-card.style-3 .dz-info .dz-meta ul li.dz-user {
            margin-right: 0px; }
          .dz-card.style-3 .dz-info .dz-meta ul li:not(:last-child)::after {
            display: none; } }
    .dz-card.style-3:hover .dz-media img {
      transform: rotate(5deg) scale(1.2); }
    .dz-card.style-3.dz-card-large .dz-info {
      padding: 35px; }
      .dz-card.style-3.dz-card-large .dz-info .dz-meta ul li.dz-date {
        top: 35px;
        left: 35px; }
      @media only screen and (max-width: 767px) {
        .dz-card.style-3.dz-card-large .dz-info .dz-meta ul li.dz-date {
          left: 30px;
          top: 30px; } }
      @media only screen and (max-width: 575px) {
        .dz-card.style-3.dz-card-large .dz-info .dz-meta ul li.dz-date {
          left: 20px;
          top: 20px; } }
    @media only screen and (max-width: 767px) {
      .dz-card.style-3 .dz-info {
        padding: 30px; } }
    @media only screen and (max-width: 575px) {
      .dz-card.style-3 {
        min-height: 450px; }
        .dz-card.style-3 .dz-info {
          padding: 20px; }
          .dz-card.style-3 .dz-info .dz-text {
            margin-bottom: 0; }
        .dz-card.style-3 .dz-category {
          margin-bottom: 20px; } }
    .dz-card.style-3.overlay {
      background-color: #ffffff;
      opacity: 0.9;
      z-index: 1; }
      .dz-card.style-3.overlay .dz-meta li .post-date {
        color: rgba(255, 255, 255, 0.8); }
  
  /*=== Pages ===*/
  .clients-logo {
    height: 50px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    .clients-logo img {
      width: 135px; }
    .clients-logo:hover img {
      -webkit-animation: logoUpToDown 0.4s forwards;
      -moz-animation: logoUpToDown 0.4s forwards;
      animation: logoUpToDown 0.4s forwards; }
    @media only screen and (max-width: 575px) {
      .clients-logo {
        margin: 0 25px; } }
  
  @keyframes logoUpToDown {
    49% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%); }
    50% {
      opacity: 0;
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%); }
    51% {
      opacity: 1; } }
  
  .dropdown-item.active, .dropdown-item:active {
    background-color: #791313; }
  
  .service-tags a {
    padding: 10px 25px;
    border: 1px solid lightgray;
    background-color: #000;
    color: #000;
    display: inline-block;
    margin: 9px;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
    .service-tags a:hover {
      background-color: #791313;
      border-color: #791313;
      color: #000;
      box-shadow: 0px 5px 15px -5px #791313; }
  
  .dzFormMsg,
  .dzSubscribeMsg {
    position: fixed;
    right: 5%;
    bottom: 20px;
    z-index: 9999999; }
    .dzFormMsg .alert,
    .dzSubscribeMsg .alert {
      margin: 0;
      position: relative;
      box-shadow: 0 0 50px rgba(0, 0, 0, 0.1); }
    @media only screen and (max-width: 1200px) {
      .dzFormMsg,
      .dzSubscribeMsg {
        right: 9%;
        bottom: 17px; } }
    @media only screen and (max-width: 767px) {
      .dzFormMsg,
      .dzSubscribeMsg {
        right: 5%;
        bottom: 18%;
        width: 90%; } }
    @media only screen and (max-width: 575px) {
      .dzFormMsg,
      .dzSubscribeMsg {
        bottom: 10%; } }
  
  .calculator-listing .dzFormBmi .dzFormInner {
    position: static;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.05);
    text-align: center;
    box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
    border-radius: 10px;
    margin: 0; }
    @media only screen and (max-width: 768px) {
      .calculator-listing .dzFormBmi .dzFormInner {
        padding: 20px 25px; } }
  
//   .video-box-sec::after {
//     content: "";
//     background-image: url(../images/background/bg-pattern1.html);
//     position: absolute;
//     left: 10px;
//     width: 95px;
//     bottom: -110px;
//     animation: move4 5s infinite;
//     height: 230px; }
  
//   .video-box-sec::before {
//     content: "";
//     background-image: url(../images/background/bg-pattern2.html);
//     position: absolute;
//     right: 100px;
//     width: 43px;
//     animation: move4 3.5s infinite;
//     bottom: -35px;
//     height: 160px; }
  
//   .schedule-section::after {
//     content: "";
//     background-image: url(../images/background/bg-pattern2.html);
//     position: absolute;
//     left: 50px;
//     width: 43px;
//     animation: move4 5s infinite;
//     top: 85px;
//     height: 160px; }
//     @media only screen and (max-width: 1680px) {
//       .schedule-section::after {
//         left: 10px; } }
//     @media only screen and (max-width: 1480px) {
//       .schedule-section::after {
//         display: none; } }
  
  .schedule-section .section-head .title {
    line-height: 1.6 !important; }
    @media only screen and (max-width: 1280px) {
      .schedule-section .section-head .title {
        line-height: 1.3 !important; } }