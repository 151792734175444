.overlay-black-light,
.overlay-black-middle,
.overlay-black-dark,
.overlay-gradient-light,
.overlay-gradient-middle,
.overlay-gradient-dark,
.overlay-white-light,
.overlay-white-middle,
.overlay-white-dark,
.overlay-primary-light,
.overlay-primary-middle,
.overlay-primary-dark,
.overlay-primary {
  position: relative;
}

.overlay-black-light::before,
.overlay-black-middle::before,
.overlay-black-dark::before,
.overlay-gradient-light::before,
.overlay-gradient-middle::before,
.overlay-gradient-dark::before,
.overlay-white-light::before,
.overlay-white-middle::before,
.overlay-white-dark::before,
.overlay-primary-light::before,
.overlay-primary-middle::before,
.overlay-primary-dark::before,
.overlay-primary::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.overlay-black-light .container,
.overlay-black-light .container-fluid,
.overlay-black-light .container-sm,
.overlay-black-light .container-md,
.overlay-black-light .container-lg,
.overlay-black-light .container-xl,
.overlay-black-middle .container,
.overlay-black-middle .container-fluid,
.overlay-black-middle .container-sm,
.overlay-black-middle .container-md,
.overlay-black-middle .container-lg,
.overlay-black-middle .container-xl,
.overlay-black-dark .container,
.overlay-black-dark .container-fluid,
.overlay-black-dark .container-sm,
.overlay-black-dark .container-md,
.overlay-black-dark .container-lg,
.overlay-black-dark .container-xl,
.overlay-gradient-light .container,
.overlay-gradient-light .container-fluid,
.overlay-gradient-light .container-sm,
.overlay-gradient-light .container-md,
.overlay-gradient-light .container-lg,
.overlay-gradient-light .container-xl,
.overlay-gradient-middle .container,
.overlay-gradient-middle .container-fluid,
.overlay-gradient-middle .container-sm,
.overlay-gradient-middle .container-md,
.overlay-gradient-middle .container-lg,
.overlay-gradient-middle .container-xl,
.overlay-gradient-dark .container,
.overlay-gradient-dark .container-fluid,
.overlay-gradient-dark .container-sm,
.overlay-gradient-dark .container-md,
.overlay-gradient-dark .container-lg,
.overlay-gradient-dark .container-xl,
.overlay-white-light .container,
.overlay-white-light .container-fluid,
.overlay-white-light .container-sm,
.overlay-white-light .container-md,
.overlay-white-light .container-lg,
.overlay-white-light .container-xl,
.overlay-white-middle .container,
.overlay-white-middle .container-fluid,
.overlay-white-middle .container-sm,
.overlay-white-middle .container-md,
.overlay-white-middle .container-lg,
.overlay-white-middle .container-xl,
.overlay-white-dark .container,
.overlay-white-dark .container-fluid,
.overlay-white-dark .container-sm,
.overlay-white-dark .container-md,
.overlay-white-dark .container-lg,
.overlay-white-dark .container-xl,
.overlay-primary-light .container,
.overlay-primary-light .container-fluid,
.overlay-primary-light .container-sm,
.overlay-primary-light .container-md,
.overlay-primary-light .container-lg,
.overlay-primary-light .container-xl,
.overlay-primary-middle .container,
.overlay-primary-middle .container-fluid,
.overlay-primary-middle .container-sm,
.overlay-primary-middle .container-md,
.overlay-primary-middle .container-lg,
.overlay-primary-middle .container-xl,
.overlay-primary-dark .container,
.overlay-primary-dark .container-fluid,
.overlay-primary-dark .container-sm,
.overlay-primary-dark .container-md,
.overlay-primary-dark .container-lg,
.overlay-primary-dark .container-xl,
.overlay-primary .container,
.overlay-primary .container-fluid,
.overlay-primary .container-sm,
.overlay-primary .container-md,
.overlay-primary .container-lg,
.overlay-primary .container-xl {
  position: relative;
  z-index: 1;
}

.overlay-black-light::before,
.overlay-black-middle::before,
.overlay-black-dark::before {
  background: #050d36;
}

.overlay-gradient-light::before,
.overlay-gradient-middle::before,
.overlay-gradient-dark::before {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
}

.overlay-white-light::before,
.overlay-white-middle::before,
.overlay-white-dark::before {
  background: #FFF;
}

.overlay-primary-light::before,
.overlay-primary-middle::before,
.overlay-primary-dark::before,
.overlay-primary::before {
  background: #791313;
}

.overlay-primary-light::before,
.overlay-white-light::before,
.overlay-gradient-light::before,
.overlay-black-light::before {
  opacity: 0.2;
}

.overlay-primary-middle::before,
.overlay-white-middle::before,
.overlay-gradient-middle::before,
.overlay-black-middle::before {
  opacity: 0.5;
}

.overlay-primary-dark::before,
.overlay-white-dark::before,
.overlay-gradient-dark::before,
.overlay-black-dark::before {
  opacity: 0.9;
}

.overlay-bx {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.overlay-bx .align-b {
  height: auto;
  left: 0;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: auto;
  bottom: 0;
  width: 100%;
  transform: translate(0);
  -o-transform: translate(0);
  -moz-transform: translate(0);
  -webkit-transform: translate(0);
}

.overlay-bx .align-m {
  height: auto;
  left: 50%;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 10px;
}

.overlay-icon {
  height: auto;
  left: 50%;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 10px;
}

.overlay-icon a {
  display: inline-block;
  padding: 0;
  margin: 0 2px;
  background-color: #000;
}

.overlay-icon a i {
  font-size: 18px;
}

.overlay-bx:hover a>i,
.dz-media:hover .overlay-bx a>i,
.dz-box-bx:hover .overlay-bx a>i {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.overlay-bx:hover,
.dz-media:hover .overlay-bx,
.dz-box:hover .overlay-bx,
.ow-img:hover .overlay-bx,
.ow-portfolio-img:hover .overlay-bx {
  opacity: 1;
  visibility: visible;
}

.gallery-box-1 {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.gallery-box-1::after {
  content: "";
  position: absolute;
  z-index: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.gallery-box-1 span {
  position: absolute;
  bottom: 5px;
  left: 12px;
  color: #000;
  font-weight: 500;
  font-size: 18px;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .gallery-box-1 span {
    font-size: 16px;
    left: 8px;
  }
}

.overlay-shine .dz-media {
  position: relative;
  overflow: hidden;
}

.overlay-shine .dz-media::before {
  position: absolute;
  top: 0;
  left: -85%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.overlay-shine .dz-media img {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.overlay-shine:hover .dz-media::before {
  -webkit-animation: shine .75s;
  animation: shine .75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.dz-img-effect {
  position: relative;
  overflow: hidden;
  display: block;
  vertical-align: middle;
}

.dz-img-effect img {
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.dz-img-effect.opacity img:hover {
  opacity: 0.8;
}

.dz-img-effect.zoom-slow img {
  transition: all 10s;
  -moz-transition: all 10s;
  -webkit-transition: all 10s;
  -o-transition: all 10s;
}

.dz-img-effect.zoom-slow:hover img {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.dz-img-effect.zoom:hover img {
  transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.dz-img-effect.shrink:hover img {
  transform: scale(0.8);
  -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
}

.dz-img-effect.side-pan:hover img {
  margin-left: -11%;
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.dz-img-effect.vertical-pan:hover img {
  margin-top: -10%;
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.dz-img-effect.fade-in {
  background: #ffffff;
}

.dz-img-effect.fade-in img {
  opacity: 0.65;
}

.dz-img-effect.fade-in:hover img {
  opacity: 1;
}

.dz-img-effect.fade-out {
  background: #ffffff;
}

.dz-img-effect.fade-out:hover img {
  opacity: 0.7;
}

.dz-img-effect.rotate:hover img {
  -moz-transform: scale(1.5) rotate(-20deg);
  -webkit-transform: scale(1.5) rotate(-20deg);
  -o-transform: scale(1.5) rotate(-20deg);
  -ms-transform: scale(1.5) rotate(-20deg);
  transform: scale(1.5) rotate(-20deg);
}

.dz-img-effect.image-sepia img {
  -webkit-filter: sepia(100%);
  filter: sepia(100%);
}

.dz-img-effect.blurr img {
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.dz-img-effect.blurr:hover img {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
}

.dz-img-effect.blurr-invert img {
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
}

.dz-img-effect.blurr-invert:hover img {
  filter: blur(0px);
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
}

.dz-img-effect.off-color img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  -ms-filter: grayscale(0);
}

.dz-img-effect.off-color:hover img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
}

.dz-img-effect.on-color img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
}

.dz-img-effect.on-color:hover img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  -ms-filter: grayscale(0);
}

.dz-box:hover .dz-img-effect.rotate img {
  -moz-transform: scale(1.5) rotate(-20deg);
  -webkit-transform: scale(1.5) rotate(-20deg);
  -o-transform: scale(1.5) rotate(-20deg);
  -ms-transform: scale(1.5) rotate(-20deg);
  transform: scale(1.5) rotate(-20deg);
}

.dz-accordion {
  margin: 0px 0px 15px 0px;
}


.dz-accordion .accordion-item {
  background: #fff;
  border: 1px solid #B1B1B1;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  border-radius: 0;
}

.dz-accordion {
  border-bottom: 1px solid #ececec;
}

.dz-accordion .accordion-body {
  padding: 16px 0px;
  border-radius: 0;
  margin-top: -9px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-accordion .accordion-body p {
  font-size: 15px;
  max-width: 515px;
  line-height: 25px;
  letter-spacing: 0.02em;
}

@media only screen and (max-width: 991px) {
  .dz-accordion .accordion-body {
    padding: 16px 0px;
  }

  .dz-accordion .accordion-body p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-accordion .accordion-body p {
    font-size: 13px;
    line-height: 20px;
  }
}

.dz-accordion .accordion-collapse {
  border: 0;
  border: 0;
  border-radius: 0 !important;
  border-top: 1px dashed #d4d5d9;
}

.dz-accordion .accordion-header {
  background-color: #000;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-accordion .accordion-header .accordion-button {
  font-size: 15px;
  border: 0;
  padding: 16px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  color: #282c3f;
  font-family: 'Mulish', sans-serif;}
.dz-accordion .accordion-header .accordion-button p{
  color: #7e808c;
  font-size: 12px;
  font-family: 'Mulish', sans-serif;  font-weight: 400;
  margin: 0px;
}

.dz-accordion .accordion-header .accordion-button::after {
  content: none;
}

.dz-accordion .accordion-header .accordion-button .toggle-close {
  background-color: transparent;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  top:13px;
  line-height: 28px;
  text-align: center;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 1;
  overflow: hidden;
}

.dz-accordion .accordion-header .accordion-button .toggle-close::before {
  content: "\f107";
  color: var(--title);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  font-size: 18px;
  font-family: 'Font Awesome 5 Free';
  z-index: 99;
  opacity: 1;
  transition: all 0.5s;
  display: block;
  font-weight: 900;
}

.dz-accordion .accordion-header .accordion-button.collapsed {
  background: #ffffff;
  color: inherit;
}

.dz-accordion .accordion-header .accordion-button.collapsed::after {
  opacity: 0;
}

.dz-accordion .accordion-header .accordion-button.collapsed .toggle-close {
  content: "";
}

.dz-accordion .accordion-header .accordion-button.collapsed .toggle-close::after {
  opacity: 1;
}

.dz-accordion .accordion-header .accordion-button.collapsed .toggle-close::before {
  opacity: 1;
}

.dz-accordion .accordion-header .accordion-button:not(.collapsed) {
  color: var(--title);
  background-color: #ffffff;
  box-shadow: none;
  padding: 16px 0px;
}

.dz-accordion .accordion-header .accordion-button:not(.collapsed) .toggle-close::before {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  content: "\f106";
}

@media only screen and (max-width: 991px) {
  .dz-accordion .accordion-header .accordion-button {
    padding: 15px 0px;
    font-size: 16px;
  }

  .dz-accordion .accordion-header .accordion-button:not(.collapsed) {
    padding: 16px 0px;
  }

  .dz-accordion .accordion-header .accordion-button .toggle-close {
    width: 22px;
    height: 22px;
    line-height: 22px;
  }

  .dz-accordion .accordion-header .accordion-button .toggle-close::before {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {

  .dz-accordion .accordion-header .accordion-button:not(.collapsed) {
    padding: 16px 0px;
  }
  .dz-accordion .accordion-header .accordion-button p{
    margin: 0px;
  }
}

.widget.dz-widget_services .form-check {
  margin-bottom: 15px;
}

.widget.dz-widget_services .form-check .form-check-input {
  border: 1px solid rgba(34, 34, 34, 0.3);
  border-radius: 4px;
  width: 20px;
  height: 20px;
}

.widget.dz-widget_services .form-check .form-check-input:checked {
  background-color: #791313;
  border: 1px solid #791313;
}

.widget.dz-widget_services .form-check .form-check-label {
  font-family: var(--font-family-title);
  margin-left: 10px;
  font-size: 15px;
}

@media only screen and (max-width: 991px) {
  .faq-media {
    width: 450px;
    height: 450px;
    margin: auto;
  }
}

@media only screen and (max-width: 767px) {
  .faq-media {
    width: 300px;
    height: 300px;
  }
}

.faq-info .title {
  font-size: 50px;
}

.faq-info input {
  background: #F8F8F8;
  border: 1px solid #B9B9B9;
}

@media only screen and (max-width: 991px) {
  .faq-info .title {
    font-size: 35px;
  }
}

.btn-social {
  color: #000;
  height: 40px;
  width: 40px;
  min-width: 40px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  border-radius: 4px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  display: block;
  padding: 0;
}

.btn-social.btn-transparent {
  background-color: rgba(255, 255, 255, 0.2);
}

.btn-social.btn-transparent:hover {
  background-color: #791313;
  color: #000;
}

.btn-social.btn-primary:hover,
.btn-social.wp-block-button__link:hover {
  background-color: #791313;
  color: #000;
}

.btn-social.btn-secondary:hover {
  background-color: #791313;
  color: #000;
}

.btn-social.btn-circle {
  border: 0;
  border-radius: 50%;
}

.btn-social.btn-sm,
.btn-group-sm>.btn-social.btn,
.btn-group-sm>.btn-social.wp-block-button__link {
  height: 30px;
  width: 30px;
  min-width: 30px;
  line-height: 30px;
  font-size: 14px;
}

.btn-social.btn-lg,
.btn-group-lg>.btn-social.btn,
.btn-group-lg>.btn-social.wp-block-button__link {
  height: 50px;
  width: 50px;
  min-width: 50px;
  line-height: 50px;
  font-size: 18px;
}

.dz-social-icon ul {
  margin-left: -5px;
  margin-right: -5px;
}

.dz-social-icon ul li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

.dz-social-icon.icon-gap-10 ul {
  margin-left: -10px;
  margin-right: -10px;
}

.dz-social-icon.icon-gap-10 ul li {
  padding-left: 10px;
  padding-right: 10px;
}

.dz-social-icon.icon-gap-5 ul {
  margin-left: -5px;
  margin-right: -5px;
}

.dz-social-icon.icon-gap-5 ul li {
  padding-left: 5px;
  padding-right: 5px;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus,
.ps__rail-x.ps--clicking,
.ps__rail-y.ps--clicking {
  background-color: transparent;
  opacity: 0.9;
}

.ps__rail-y:hover>.ps__thumb-y,
.ps__rail-y:focus>.ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #DAE2F3;
  width: 4px;
}

.ps__thumb-y {
  background-color: #DAE2F3;
  width: 4px;
}

.dz-img-box {
  position: relative;
  z-index: 1;
  overflow: hidden;
}


.dz-img-box.style-1 {
  border-radius: 10px;
}

.dz-img-box.style-1::after {
  position: absolute;
  content: "";
  z-index: 1;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0.39deg, #791313 0.33%, rgba(125, 166, 64, 0) 101%);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-img-box.style-1 .dz-tag {
  position: absolute;
  top: -30px;
  background: #791313;
  left: 0;
  color: #000;
  border-radius: 9px 0px 10px;
  padding: 4px 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-img-box.style-1 .dz-content {
  display: flex;
  justify-content: space-between;
  opacity: 0;
  align-items: center;
  margin-bottom: -90px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 2;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  padding: 20px 30px;
}

.dz-img-box.style-1 .dz-content .dz-info .dz-title a {
  color: #000;
}

.dz-img-box.style-1 .dz-content .dz-info .dz-price {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #791313;
}

.dz-img-box.style-1:hover::after {
  opacity: 1;
}

.dz-img-box.style-1:hover .dz-tag {
  top: 0px;
}

.dz-img-box.style-1:hover .dz-content {
  opacity: 1;
  width: 100%;
  margin-bottom: 0;
}

.dz-img-box.style-2 {
  text-align: center;
  padding: 40px 20px 30px;
  border: 1px solid #F5F5F5;
  background: #fff;
  border-radius: var(--border-radius-base);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dz-img-box.style-2::before,
.dz-img-box.style-2::after {
  position: absolute;
  content: "";
  z-index: -1;
  top: -190px;
  left: 50%;
  transform: translateX(-50%);
  background: #F5F5F5;
  width: 335px;
  height: 335px;
  border-radius: 50%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-img-box.style-2::before {
  top: -100%;
  background-color: #791313;
  z-index: 0;
}

.dz-img-box.style-2 .dz-media {
  width: 150px;
  min-width: 150px;
  margin: 0 auto 10px;
  border-radius: 50%;
  border: 9px solid #fff;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-img-box.style-2 .dz-content {
  margin-top: auto;
}

.dz-img-box.style-2 .dz-content p {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 8px;
}

.dz-img-box.style-2 .dz-content .btn,
.dz-img-box.style-2 .dz-content .wp-block-button__link {
  margin-top: 18px;
}

.dz-img-box.style-2:hover,
.dz-img-box.style-2.active {
  border-color: #791313;
}

.dz-img-box.style-2:hover::after,
.dz-img-box.style-2.active::after {
  top: 0;
  border-radius: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
}

.dz-img-box.style-2:hover::before,
.dz-img-box.style-2.active::before {
  top: -190px;
}

.dz-img-box.style-2:hover .dz-media,
.dz-img-box.style-2.active .dz-media {
  animation: dzRotate360 10s linear infinite;
}

@media only screen and (max-width: 991px) {
  .dz-img-box.style-2 {
    padding: 40px 20px 30px;
  }
}

.dz-img-box.style-3 {
  border-radius: 10px;
  // box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  width: 100%;
  max-width: 273px;
  height: 186px;
}

.dz-img-box.style-3::after {
  position: absolute;
  content: "";
  z-index: 1;
  opacity: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0.39deg, #222222 0.33%, rgba(34, 34, 34, 0) 101%);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-img-box.style-3 .dz-media {
  width: 100%;
  width: 100%;
  max-width: 273px;
  height: 182px;
}

.dz-img-box.style-3 .dz-tag {
  position: absolute;
  top: 0px;
  background: #791313;
  left: 0;
  color: #000;
  border-radius: 9px 0px 10px;
  padding: 4px 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.dz-img-box.style-3 .dz-content {
  background: #fff;
  text-align: center;
  padding: 23px 15px;
  opacity: 1;
  display: block;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  width: 100%;
  position: absolute;
  bottom: 0px;
  margin-bottom: 0px;
}

.dz-img-box.style-3 .dz-content p {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 2px;
}

.dz-img-box.style-3 .dz-hover-content {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  position: absolute;
  bottom: 0;
  opacity: 0;
  z-index: 2;
  width: 100%;
  align-items: center;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  margin-bottom: -100px;
}

.dz-img-box.style-3 .dz-hover-content .dz-info .dz-title a {
  color: #000;
}

.dz-img-box.style-3 .dz-hover-content .dz-info .dz-price {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #791313;
}

.dz-img-box.style-3:hover::after,
.dz-img-box.style-3.active::after {
  opacity: 1;
}

.dz-img-box.style-3:hover .dz-content,
.dz-img-box.style-3.active .dz-content {
  bottom: -150px;
  opacity: 0;
  display: none;
}

.dz-img-box.style-3:hover .dz-media,
.dz-img-box.style-3.active .dz-media {
  width: 100%;
  min-width: 100%;
  height: 100%;
}

.dz-img-box.style-3:hover .dz-hover-content,
.dz-img-box.style-3.active .dz-hover-content {
  opacity: 1;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .dz-img-box.style-3 .dz-content {
    padding: 20px;
  }
}

.dz-img-box.style-4 {
  background: #fff;
  box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
  border-radius: 10px;
  padding: 18px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  height: 160px;
}

.dz-img-box.style-4::before {
  content: "";
  height: 0;
  width: 0;
  background-color: #222222;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  border-radius: 100%;
}

.dz-img-box.style-4 .menu-detail {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.dz-img-box.style-4 .menu-detail .dz-media {
  margin-right: 20px;
  width: 60px;
  height: 60px;
  min-width: 60px;
}

.dz-img-box.style-4 .menu-detail .dz-content .title {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  margin-bottom: 3px;
}

.dz-img-box.style-4 .menu-detail .dz-content .title a {
  color: var(--title);
}

.dz-img-box.style-4 .menu-detail .dz-content p {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 0;
}

.dz-img-box.style-4 .menu-footer {
  max-width: 100px;
  margin-top: auto;
}

.dz-img-box.style-4 .menu-footer span {
  font-weight: 500;
  font-size: 13px;
  line-height: 30px;
}

.dz-img-box.style-4 .menu-footer .price {
  color: #791313;
  margin-bottom: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  font-size: 18px;
  display: block;
  font-weight: 600;
  line-height: 1.2;
}

.dz-img-box.style-4 .detail-btn {
  background-color: #791313;
  width: 48px;
  height: 48px;
  line-height: 48px;
  color: #000;
  text-align: center;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 10px 0px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-img-box.style-4.active::before,
.dz-img-box.style-4:hover::before {
  height: 120px;
  width: 120px;
  transform: scale(6);
  right: 40%;
}

.dz-img-box.style-4.active .menu-detail .dz-content .title a,
.dz-img-box.style-4:hover .menu-detail .dz-content .title a {
  color: #000;
}

.dz-img-box.style-4.active .menu-footer .price,
.dz-img-box.style-4:hover .menu-footer .price {
  color: #000;
}

.dz-img-box.style-4.active .detail-btn,
.dz-img-box.style-4:hover .detail-btn {
  background: #fff;
  color: #791313;
}

.dz-img-box.style-4.active .detail-btn i,
.dz-img-box.style-4:hover .detail-btn i {
  animation: dzRotate360 10s linear infinite;
}

.dz-img-box.style-5 {
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 30px);
}

.dz-img-box.style-5 .dz-content {
  margin-bottom: 15px;
}

.dz-img-box.style-5 .dz-content .weight,
.dz-img-box.style-5 .dz-content .price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dz-img-box.style-5 .dz-content .weight {
  margin-bottom: 5px;
}

.dz-img-box.style-5 .dz-content .weight span {
  font-size: 12px;
}

.dz-img-box.style-5 .dz-content .price h6,
.dz-img-box.style-5 .dz-content .price .h6 {
  margin-bottom: 0;
}

.dz-img-box.style-5 .dz-media {
  border-radius: 10px;
  margin-top: auto;
  width: 100%;
  height: 100%;
}

.dz-img-box.style-5 .dz-media::after {
  background: linear-gradient(0deg, rgba(34, 34, 34, 0.8), rgba(34, 34, 34, 0.8));
  content: "";
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-img-box.style-5 .dz-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dz-img-box.style-5 .dz-media .detail-btn {
  width: 48px;
  height: 48px;
  line-height: 48px;
  display: block;
  text-align: center;
  position: absolute;
  top: -50px;
  transform: translateX(-50%);
  left: 50%;
  color: #000;
  background-color: #791313;
  border-radius: 10px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 2;
  opacity: 0;
}

.dz-img-box.style-5:hover .dz-media::after {
  opacity: 1;
}

.dz-img-box.style-5:hover .dz-media .detail-btn {
  opacity: 1;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

@media only screen and (max-width: 1280px) {
  .dz-img-box.style-5 {
    padding: 10px;
  }
}

.dz-img-box.style-6 {
  display: flex;
  align-items: center;
  padding: 20px 25px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 10px;
}

.dz-img-box.style-6 .dz-media {
  margin-right: 12px;
  min-width: 80px;
  width: 80px;
}

.dz-img-box.style-6 .dz-content {
  width: 100%;
}

.dz-img-box.style-6 .dz-content .dz-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dz-img-box.style-6 .dz-content .dz-head .header-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}

.dz-img-box.style-6 .dz-content .dz-head .header-text a {
  color: var(--title);
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.dz-img-box.style-6 .dz-content .dz-head .header-text a:hover {
  color: #791313;
}

.dz-img-box.style-6 .dz-content .dz-head .img-line {
  width: 100%;
  height: 1px;
  margin: 0 15px;
  border-bottom: 1px dashed #7D7D7D;
}

.dz-img-box.style-6 .dz-content .dz-head .header-price {
  color: #791313;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.dz-img-box.style-6 .dz-content .dz-body {
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  margin-bottom: 0;
}

.dz-img-box.style-6:hover {
  box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
}

@media only screen and (max-width: 1191px) {
  .dz-img-box.style-6 {
    padding: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .dz-img-box.style-6 {
    display: block;
    text-align: center;
  }

  .dz-img-box.style-6 .dz-media {
    min-width: 80px;
    width: 80px;
    margin: 0 auto 20px;
  }

  .dz-img-box.style-6 .dz-content {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .dz-img-box.style-6 {
    display: flex;
    align-items: center;
    text-align: left;
  }

  .dz-img-box.style-6 .dz-media {
    margin: 0 10px 0 0;
  }

  .dz-img-box.style-6 .dz-content {
    text-align: left;
  }
}

@media only screen and (max-width: 575px) {
  .dz-img-box.style-6 {
    margin-bottom: 10px;
  }

  .dz-img-box.style-6 .dz-content .dz-head .header-text a {
    font-size: 16px;
  }

  .dz-img-box.style-6 .dz-content .dz-head .img-line,
  .dz-img-box.style-6 .dz-content .dz-head .header-price {
    display: none;
  }

  .dz-img-box.style-6 .dz-content .dz-body {
    font-size: 14px;
  }
}

.dz-img-box.style-7 {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
  text-align: center;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  position: relative;
  height: 100%;
}

.dz-img-box.style-7 .dz-media img {
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.dz-img-box.style-7 .dz-meta {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.dz-img-box.style-7 .dz-meta ul li.seller {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  background-color: #FE9F10;
  color: var(--theme-text-color);
  border-radius: 9px 0px 10px;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 10px;
  margin-right: 0;
  text-transform: uppercase;
  display: block;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.dz-img-box.style-7 .dz-meta ul li.rating {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  background-color: #000;
  color: var(--title);
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 10px;
  margin-right: 0;
  text-transform: uppercase;
  display: block;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  align-items: center;
}

.dz-img-box.style-7 .dz-meta ul li.rating i {
  font-size: 12px;
  top: -2px;
  color: #FE9F10;
}

.dz-img-box.style-7 .dz-content {
  padding: 20px 15px;
  display: block;
  text-decoration: none;
  cursor: pointer;
}

.dz-img-box.style-7 .dz-content p {
  font-size: 14px;
  margin-bottom: 10px;
}

.dz-img-box.style-7 .dz-content .price {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.1;
  color: #791313;
}

.dz-img-box.style-7:hover {
  // transform: translateY(-10px);
}

.dz-img-box.style-7:hover .dz-media img {
  transform: scale(1.1);
}

@media only screen and (max-width: 1280px) {
  .dz-img-box.style-7 .dz-meta ul li.rating {
    bottom: 15px;
    right: 15px;
  }

  .dz-img-box.style-7 .dz-content {
    padding: 20px 15px;
  }
}

.dz-img-box.style-8 {
  overflow: unset;
  margin-bottom: 45px;
  display: flex;
  flex-direction: column;
}

.dz-img-box.style-8 .dz-media {
  border-radius: 5px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.dz-img-box.style-8 .dz-media img {
  object-fit: cover;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-img-box.style-8 .dz-content {
  padding: 15px 0 0;
  position: relative;
}

.dz-img-box.style-8 .dz-content .dz-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.dz-img-box.style-8 .dz-content .dz-head .heart.heart-blast {
  background-position: -1680px 0 !important;
  transition: background 1s steps(28);
}

.dz-img-box.style-8 .dz-content .category {
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.dz-img-box.style-8:hover .dz-media img {
  transform: scale(1.1);
}

@media only screen and (max-width: 1280px) {
  .dz-img-box.style-8 {
    margin-bottom: 30px;
  }

  .dz-img-box.style-8 .dz-media {
    width: 100%;
    min-width: 100%;
    height: 100%;
  }
}

@keyframes dzRotate360 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.dz-divider {
  height: 1px;
  position: relative;
  margin: 30px 0;
}

.dz-divider.tb20 {
  margin: 20px 0;
}

.dz-divider.tb15 {
  margin: 15px 0;
}

.dz-divider.tb10 {
  margin: 10px 0;
}

.dz-divider.tb0 {
  margin: 0;
}

.dz-divider.divider-2px {
  height: 2px;
}

.dz-divider.divider-3px {
  height: 3px;
}

.dz-divider.divider-4px {
  height: 4px;
}

.dz-divider i {
  position: absolute;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  display: block;
  border-radius: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.dz-divider.icon-left {
  margin-left: 40px;
}

.dz-divider.icon-left i {
  left: -40px;
}

.dz-divider.icon-right {
  margin-right: 40px;
}

.dz-divider.icon-right i {
  left: auto;
  right: -40px;
}

.dz-divider.icon-center i {
  left: 50%;
  margin-left: -5px;
}

.bg-gray-dark {
  background-color: #d3d3d3;
}
.dz-contnet-innre-box{
  display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 1.4rem;
    line-height: 1.7rem;
    align-items: center;
}