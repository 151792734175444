footer {
  position: relative;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../../..//images//footer/footerBackground.webp');
  background-size:cover;
  background-repeat: no-repeat;
  padding-top: 30px;
  padding-bottom: 50px;
  background-position: bottom;
}
.footer_col {
  margin-bottom: 20px;
}
.fssai {
  padding-top: 20px;
    font-size: 14px;
    font-family: 'Mulish';
    color: #cccccc;
}
.partner_with_us {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    border: 1px solid #791313;
    color: #aca4a4;
    max-width: 200px;
    cursor: pointer;
    text-align: center;
    padding: 5px 10px; 
    border-radius: 4px;
    background-color: transparent;

    img {
      width: 16px;
    }
    p {
      font-family: "Mulish", sans-serif;
      font-size: 16px;
      font-weight: 600;
      padding-left: 5px;
    }
}
footer .widget {
  margin-bottom: 30px;

  @media (max-width: 768px) {
    margin: 15px 0px;
  }
}

.powered_by {
  color: #fff;
}

footer strong {
  color: #fff;
}

footer .footer-logo {
  margin-bottom: 15px;
}

footer .footer-logo img {
  max-width: 100%;
}

footer .widget-logo ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

footer .widget-logo ul li {
  display: inline-block;
  flex: 50%;
  padding: 0 15px 15px 0px;
}

footer .widget_about p {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1200px) {
  footer .widget_about {
    padding: 0;
  }
}

@media only screen and (max-width: 1191px) {
  footer .widget_about {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

footer .footer-title {
  margin: 0;
  margin-bottom: 12px;
  position: relative;
}

.footer_sm_txts{
  font-size: 12px !important;
  cursor: pointer;
}
footer .footer-title a {
  color: inherit;
}

footer .footer-title .title {
  color: var(--title);
}

footer .footer-title .dz-separator {
  position: absolute;
  bottom: 0;
  left: 0;
}

footer .footer-top {
  padding-top: 65px;
  padding-bottom: 40px;
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 1280px) {
  footer .footer-top {
    padding-top: 80px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 991px) {
  footer .footer-top {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  footer .footer-top {
    padding-top: 30px;
    padding-bottom: 0;
  }
}

footer .footer-bottom {
  padding: 40px 0 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

@media only screen and (max-width: 991px) {
  footer .footer-bottom {
    padding: 18px 0;
    text-align: center !important;
  }

  footer .footer-bottom .text-left,
  footer .footer-bottom .text-right {
    text-align: center !important;
  }

  footer .footer-bottom .text-right {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  footer .footer-bottom {
    padding: 22px 0;
  }
}

footer .widget-link li {
  display: inline-block;
  text-transform: uppercase;
  margin-left: 20px;
}

@media only screen and (max-width: 767px) {
  footer .widget-link li {
    margin-left: 8px;
    margin-right: 7px;
  }
}

footer .widget-link a {
  color: #fff;
}

footer .widget_services ul li a {
  color: inherit;
  display: block;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

footer .wp-block-latest-posts li,
footer .wp-block-categories-list li,
footer .wp-block-archives-list li,
footer .widget_categories ul li,
footer .widget_archive ul li,
footer .widget_meta ul li,
footer .widget_pages ul li,
footer .widget_recent_comments ul li,
footer .widget_nav_menu li,
footer .widget_recent_entries ul li,
footer .widget_services ul li {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

footer .widget_rss ul li {
  --title: #fff;
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
}

footer .wp-block-calendar table caption,
footer .wp-block-calendar table tbody {
  color: #fff;
}

footer .wp-block-tag-cloud a,
footer .widget_tag_cloud .tagcloud a {
  border-color: rgba(255, 255, 255, 0.1);
}

footer .wp-block-search,
footer .wp-block-archives,
footer .wp-block-latest-posts,
footer .wp-block-latest-comments,
footer .wp-block-categories,
footer .wp-block-calendar {
  margin-bottom: 0;
}

footer .dz-meta ul li span,
footer .dz-meta ul li a {
  color: #791313;
}

.contact-ft {
  text-align: center;
  margin-bottom: 40px;
}

.contact-ft i {
  color: #791313;
  font-size: 50px;
  line-height: 1;
  margin-bottom: 15px;
  display: inline-block;
}

.contact-ft h4,
.contact-ft .h4,
.contact-ft .wp-block-search .wp-block-search__label,
.wp-block-search .contact-ft .wp-block-search__label {
  color: #fff;
  text-decoration: underline;
}

.contact-ft h5,
.contact-ft .h5 {
  color: #fff;
  font-weight: 400;
}

.list-column ul {
  display: flex;
  flex-wrap: wrap;
}

.list-column ul li {
  flex: 0 0 50%;
  max-width: 50%;
}

.fb-link {
  list-style: none;
  margin: 0;
  padding: 0;
}

.fb-link li {
  display: inline-block;
}

.fb-link li a {
  color: #fff;
  position: relative;
}

.fb-link li a::after {
  content: "";
  background: #791313;
  width: 5px;
  height: 5px;
}

.footer-link {
  margin: 0px -15px;
  text-align: end;
}

@media only screen and (max-width: 991px) {
  .footer-link {
    text-align: center;
  }
}

.footer-link li {
  display: inline-block;
  position: relative;
  padding: 0px 15px;
}

.footer-link li a {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 400;
}

.site-footer .bg1,
.site-footer .bg2 {
  position: absolute;
}

.site-footer .bg1 {
  left: 0;
  bottom: 10px;
}

.site-footer .bg2 {
  right: 10px;
  top: 15px;
}

@media only screen and (max-width: 1480px) {

  .site-footer .bg1,
  .site-footer .bg2 {
    display: none;
  }
}

.site-footer .footer-bottom {
  position: relative;
  z-index: 1;
}

.site-footer .footer-bottom p {
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  font-family: 'Mulish', sans-serif;
  color: #999999;
  margin-bottom: 0;
}

.site-footer .footer-bottom .copyright-text {
  color: #999999;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.1;
}

.site-footer .footer-bottom .copyright-text a {
  color: #791313;
}

.site-footer .widget_services ul li {
  padding: 0px 0 20px 0px;
}

.site-footer .widget_services ul li a {
  color: #b2b2b2;
  font-weight: 300;
  font-family: 'Mulish', sans-serif;
  display: flex;
}

.footer_outlet_txt {
  padding-left: 5px;
  text-transform: none;
  word-wrap: break-word;
  overflow: hidden;
}
.site-footer .widget_services ul li a::before {
  display: none;
}

.site-footer .footer-top .footer-title {
  color: #fff;
}


@media only screen and (max-width: 1280px) {
  .site-footer .footer-top .footer-title {
    font-size: 20px;
    margin-bottom: 20px !important;
  }

  .site-footer .footer-bottom p {
    font-size: 14px;
  }

  .site-footer .footer-bottom .copyright-text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .site-footer .footer-top .footer-title {
    font-size: 14px;
    margin-bottom: 10px !important;
  }
  .site-footer .footer-bottom .copyright-text {
    display: block;
    margin-top: 15px;
  }
}

.footer-title {
  margin-bottom: 20px !important;
  font-weight: 600;
  font-size: 16px;
  font-family: 'Mulish', sans-serif;
  line-height: 1.1;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

.site-footer.style-2 {
  background-color: #fff;
}

.site-footer.style-2 .footer-bg-wrapper {
  margin-left: 100px;
  margin-right: 100px;
  z-index: 1;
  position: relative;
  border-radius: 30px;
  background-color: #222222;
}

.site-footer.style-2 .footer-bg-wrapper .particles-js-canvas-el {
  position: absolute;
  bottom: 0;
  left: 0;
}

.site-footer.style-2 .footer-top {
  padding-top: 45px;
  padding-bottom: 30px;
}

.site-footer.style-2 .footer-top .footer-title {
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.1;
  color: #fff;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

.site-footer.style-2 .footer-bottom .copyright-text {
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1;
}

.site-footer.style-2 .footer-bottom .footer-link a {
  color: #666666;
  letter-spacing: 0.01em;
}

@media only screen and (max-width: 1480px) {
  .site-footer.style-2 .footer-bg-wrapper {
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 0;
  }
}

@media only screen and (max-width: 1280px) {
  .site-footer.style-2 .footer-top .footer-title {
    font-size: 20px;
    line-height: 1;
  }
}

@media only screen and (max-width: 575px) {
  .site-footer.style-2 .footer-bottom .footer-link li {
    padding: 0px 5px;
  }

  .site-footer.style-2 .footer-bottom .footer-link li a {
    font-size: 13px;
  }
}

.footer-subscribe-wrapper {
  margin-bottom: 60px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 40px;
}

.footer-subscribe-wrapper .wrapper-inner .title {
  font-size: 24px;
  letter-spacing: 0.01em;
}

@media only screen and (max-width: 991px) {
  .footer-subscribe-wrapper {
    margin-bottom: 40px;
  }

  .footer-subscribe-wrapper .wrapper-inner .title {
    font-size: 22px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-subscribe-wrapper {
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
}

.site-footer.style-3 {
  background-color: rgba(34, 34, 34, 0.05);
  z-index: 0;
}

.site-footer.style-3 .footer-subscribe-wrapper,
.site-footer.style-3 .footer-top {
  border-bottom: 1px solid rgba(34, 34, 34, 0.1);
}

.site-footer.style-3 .footer-subscribe-wrapper {
  margin-bottom: 55px;
}

.site-footer.style-3 .footer-title {
  margin-bottom: 20px !important;
}

@media only screen and (max-width: 991px) {
  .site-footer.style-3 .footer-title {
    font-size: 20px;
    margin-bottom: 15px !important;
  }
}

.site-footer.style-3 .footer-top {
  padding-top: 40px;
  padding-bottom: 50px;
}

@media only screen and (max-width: 991px) {
  .site-footer.style-3 .footer-top {
    padding-top: 20px;
  }
}

.site-footer.style-3 .footer-menu ul {
  margin: 0 -15px;
}

.site-footer.style-3 .footer-menu ul li {
  margin: 0 15px;
  display: inline-block;
}

.site-footer.style-3 .footer-menu ul li a {
  letter-spacing: 0.01em;
  color: #666666;
  font-size: 14px;
  line-height: 1.1;
}

@media only screen and (max-width: 1280px) {
  .site-footer.style-3 .footer-menu ul {
    margin: 0 -8px;
  }

  .site-footer.style-3 .footer-menu ul li {
    margin: 0 8px;
  }
}

@media only screen and (max-width: 767px) {
  .site-footer.style-3 .footer-menu ul {
    margin: 0;
  }

  .site-footer.style-3 .footer-menu ul li {
    display: block;
    margin: 0;
    padding: 5px 0;
  }
}

@media only screen and (max-width: 767px) {
  .site-footer.style-3 .footer-menu {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 575px) {
  .site-footer.style-3 .footer-menu {
    margin-bottom: 22px;
  }
}

.site-footer.style-3 .footer-bottom p {
  color: #666666;
}

.site-footer.style-3 .footer-bottom .copyright-text {
  color: #666666;
}

@media only screen and (max-width: 1280px) {
  .site-footer.style-3 .footer-bottom p {
    font-size: 14px;
  }

  .site-footer.style-3 .footer-bottom .copyright-text {
    font-size: 14px;
  }
}

.data-typography-1 {
  --font-family-base: 'Mulish', sans-serif;
  --font-family-title: "Noto Sans", sans-serif;
}

#bg {
  background-attachment: fixed;
  background-size: cover;
}

@media only screen and (min-width: 1281px) {

  /* boxed */
  .boxed .page-wraper {
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  }

  .boxed .main-bnr-one .banner-inner .banner-content h1,
  .boxed .main-bnr-one .banner-inner .banner-content .h1 {
    font-size: 70px;
    line-height: 80px;
  }

  .boxed .main-bnr-one .banner-inner .garlic {
    right: -135px;
  }

  .boxed .main-bnr-one .main-swiper1-pagination {
    left: 0;
  }

  .boxed .swiper-btn-lr .btn-prev-long,
  .boxed .swiper-btn-lr .btn-prev {
    left: -30px;
  }

  .boxed .swiper-btn-lr .btn-next-long,
  .boxed .swiper-btn-lr .btn-next {
    right: -30px;
  }

  .boxed .section-wrapper-2 .bg1 {
    width: 22vw;
  }

  .boxed .section-wrapper-2 .bg2 {
    top: 20px;
    right: 0px;
  }

  .boxed .section-wrapper-2.right .bg1 {
    width: 22vw;
    right: -306px;
  }

  .boxed .main-bnr-two .banner-inner .banner-content h1,
  .boxed .main-bnr-two .banner-inner .banner-content .h1 {
    font-size: 70px;
    line-height: 80px;
  }

  .boxed .section-wrapper-5,
  .boxed .section-wrapper-4,
  .boxed .section-wrapper-8 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .boxed .site-footer .bg1 {
    left: -114px;
    bottom: -36px;
    width: 10vw;
  }

  .boxed .site-footer .bg2 {
    right: -18px;
  }

  .boxed .site-footer.style-2 .footer-bg-wrapper {
    margin-left: 20px;
    margin-right: 20px;
  }

  .boxed .site-footer.style-3 .bg1 {
    left: -115px;
    bottom: 110px;
  }

  .boxed .site-footer.style-3 .bg2 {
    top: -100px;
    right: 0;
  }

  .boxed .under-construction .construction-media::after {
    left: 50px;
  }

  .boxed .under-construction .construction-media img {
    left: 40px;
  }

  .boxed .section-wrapper-5 .bg1 {
    top: 0;
    left: -190px;
  }

  .boxed .section-wrapper-5 .bg2 {
    width: 30vw;
    right: 80px;
  }

  .boxed .section-wrapper-7 .bg1 {
    top: 9px;
    width: 10vw;
    left: -106px;
  }

  .boxed .section-wrapper-7 .bg2 {
    width: 30vw;
    right: -80px;
  }

  .boxed .section-wrapper-7::after {
    width: 550px;
    height: 550px;
    right: -12%;
  }

  .boxed .icon-bx-wraper.style-5 .icon-content {
    padding: 90px 15px 40px;
  }

  /* Frame */
  .frame {
    padding: 30px;
  }

  .frame .page-wraper {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    height: auto !important;
  }


  .frame button.scroltop {
    right: 50px;
    bottom: 50px;
  }
}

@media only screen and (max-width: 1281px) {
  .frame {
    padding: 0 !important;
  }

  .frame button.scroltop {
    right: 15px;
    bottom: 15px;
  }
}

.theme-sharped {
  --border-radius-base: 0;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}

.theme-rounded {
  --border-radius-base: 10px;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}

.theme-bg-color {
  background-color: var(--theme-bg-color);
}

.color_1 {
  background-color: #7da640;
}

.color_2 {
  background-color: #fe9f10;
}

[data-body-bg="color_3"] {
  background-color: #ee2737;
}

[data-body-bg="color_4"] {
  background-color: #a75d5d;
}

[data-body-bg="color_5"] {
  background-color: #ea5c2b;
}

.color_6 {
  background-color: #a9907e;
}

[data-body-bg="color_7"] {
  background-color: #87805e;
}

.footer_logo {
  max-width: 140px;

  @media screen and (max-width: 425px) {
      width: 100%;
      max-width: 100%;
  }
}