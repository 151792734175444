.service_menu_nav {
  background: #fff;
  padding: 30px 30px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.service_menu_nav li a {
  background-color: #fff;
  display: block;
  border-radius: 6px;
  padding: 14px 25px;
  margin-bottom: 10px;
  color: #000;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background-size: 200%;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.service_menu_nav li a::before {
  content: "\f30b";
  font-weight: 900;
  font-family: "Line Awesome Free";
  position: absolute;
  right: 50px;
  top: 3px;
  font-size: 30px;
  opacity: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.service_menu_nav li a::after {
  background-color: #791313;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  content: "";
  background-size: 200%;
  opacity: 0;
  z-index: -1;
}

.service_menu_nav li:hover a,
.service_menu_nav li.active a {
  padding-left: 35px;
  border-color: transparent;
  color: #fff;
}

.service_menu_nav li:hover a::before,
.service_menu_nav li.active a::before {
  opacity: 1;
  right: 25px;
  color: #fff;
}

.service_menu_nav li:hover a::after,
.service_menu_nav li.active a::after {
  opacity: 1;
}

.wp-block-categories-dropdown,
.wp-block-archives-dropdown {
  margin-bottom: 20px;
}

.wp-block-categories-dropdown select::after,
.wp-block-archives-dropdown select::after {
  content: "\f078";
  font-weight: 900;
  font-family: "Line Awesome Free";
  position: absolute;
  right: 50px;
  left: auto;
  top: 50%;
}

.post-tags strong {
  font-weight: 700;
  color: #212529;
  margin-right: 10px;
}

.post-tags a {
  margin-right: 10px;
  display: inline-block;
}

.widget_profile .dz-media {
  width: 100%;
  min-width: 100%;
}

.widget_profile .dz-media img {
  border-radius: var(--border-radius-base);
}

.widget_profile p {
  font-size: 16px;
}

.widget-newslatter .news-box {
  background-color: var(--rgba-primary-1);
  padding: 20px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.widget-newslatter .news-box form::before {
  font-family: themify;
  content: "\e75a";
  font-size: 50px;
  font-weight: 700;
  opacity: 0.05;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  opacity: 0.05;
}

.widget-newslatter .news-box form::after {
  color: #791313;
  content: "Newsletter";
  font-size: 50px;
  font-weight: 700;
  opacity: 0.3;
  position: absolute;
  bottom: 100px;
  left: 15px;
  z-index: -1;
}

.widget-newslatter .form-control,
.widget-newslatter .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .widget-newslatter select,
.widget-newslatter .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .widget-newslatter select {
  background-color: #fff;
  border-radius: var(--border-radius-base);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.widget-newslatter p {
  font-size: 15px;
  margin-bottom: 30px;
}

.widget-newslatter .input-group {
  margin-bottom: 10px;
}

#masonry.row {
  width: auto !important;
}

.shop-widget,
.widget.style-1 {
  box-shadow: 0 0 60px 20px rgba(0, 0, 0, 0.1);
  padding: 35px;
  border-bottom: 4px solid;
  border-color: #791313;
}

.shop-widget .search-bx,
.widget.style-1 .search-bx {
  margin-bottom: 0;
}

.shop-widget .search-bx .form-control,
.shop-widget .search-bx .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .shop-widget .search-bx select,
.shop-widget .search-bx .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .shop-widget .search-bx select,
.widget.style-1 .search-bx .form-control,
.widget.style-1 .search-bx .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .widget.style-1 .search-bx select,
.widget.style-1 .search-bx .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .widget.style-1 .search-bx select {
  background-color: #f3f4f6;
  box-shadow: unset;
}

.shop-widget .search-bx .input-group,
.widget.style-1 .search-bx .input-group {
  margin-bottom: 0;
}

@media only screen and (max-width: 1280px) {
  .shop-widget,
  .widget.style-1 {
    padding: 25px;
  }
}

.side-bar h1,
.side-bar .h1 {
  font-size: 2rem;
}

.side-bar h2,
.side-bar .h2 {
  font-size: 1.625rem;
}

.side-bar h3,
.side-bar .h3 {
  font-size: 1.5rem;
}

.side-bar h4,
.side-bar .h4,
.side-bar .wp-block-search .wp-block-search__label,
.wp-block-search .side-bar .wp-block-search__label {
  font-size: 1.25rem;
}

.side-bar h5,
.side-bar .h5 {
  font-size: 1rem;
}

.sidebar-widget .wp-block-woocommerce-attribute-filter > h1,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h1,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h2,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h2,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h3,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h3,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h4,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h4,
.sidebar-widget
  .wp-block-search
  .wp-block-woocommerce-attribute-filter
  > .wp-block-search__label,
.wp-block-search
  .sidebar-widget
  .wp-block-woocommerce-attribute-filter
  > .wp-block-search__label,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h5,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h5,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h6,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h6,
.sidebar-widget .wp-block-group__inner-container > h1,
.sidebar-widget .wp-block-group__inner-container > .h1,
.sidebar-widget .wp-block-group__inner-container > h2,
.sidebar-widget .wp-block-group__inner-container > .h2,
.sidebar-widget .wp-block-group__inner-container > h3,
.sidebar-widget .wp-block-group__inner-container > .h3,
.sidebar-widget .wp-block-group__inner-container > h4,
.sidebar-widget .wp-block-group__inner-container > .h4,
.sidebar-widget
  .wp-block-search
  .wp-block-group__inner-container
  > .wp-block-search__label,
.wp-block-search
  .sidebar-widget
  .wp-block-group__inner-container
  > .wp-block-search__label,
.sidebar-widget .wp-block-group__inner-container > h5,
.sidebar-widget .wp-block-group__inner-container > .h5,
.sidebar-widget .wp-block-group__inner-container > h6,
.sidebar-widget .wp-block-group__inner-container > .h6,
.shop-widget .wp-block-woocommerce-attribute-filter > h1,
.shop-widget .wp-block-woocommerce-attribute-filter > .h1,
.shop-widget .wp-block-woocommerce-attribute-filter > h2,
.shop-widget .wp-block-woocommerce-attribute-filter > .h2,
.shop-widget .wp-block-woocommerce-attribute-filter > h3,
.shop-widget .wp-block-woocommerce-attribute-filter > .h3,
.shop-widget .wp-block-woocommerce-attribute-filter > h4,
.shop-widget .wp-block-woocommerce-attribute-filter > .h4,
.shop-widget
  .wp-block-search
  .wp-block-woocommerce-attribute-filter
  > .wp-block-search__label,
.wp-block-search
  .shop-widget
  .wp-block-woocommerce-attribute-filter
  > .wp-block-search__label,
.shop-widget .wp-block-woocommerce-attribute-filter > h5,
.shop-widget .wp-block-woocommerce-attribute-filter > .h5,
.shop-widget .wp-block-woocommerce-attribute-filter > h6,
.shop-widget .wp-block-woocommerce-attribute-filter > .h6,
.shop-widget .wp-block-group__inner-container > h1,
.shop-widget .wp-block-group__inner-container > .h1,
.shop-widget .wp-block-group__inner-container > h2,
.shop-widget .wp-block-group__inner-container > .h2,
.shop-widget .wp-block-group__inner-container > h3,
.shop-widget .wp-block-group__inner-container > .h3,
.shop-widget .wp-block-group__inner-container > h4,
.shop-widget .wp-block-group__inner-container > .h4,
.shop-widget
  .wp-block-search
  .wp-block-group__inner-container
  > .wp-block-search__label,
.wp-block-search
  .shop-widget
  .wp-block-group__inner-container
  > .wp-block-search__label,
.shop-widget .wp-block-group__inner-container > h5,
.shop-widget .wp-block-group__inner-container > .h5,
.shop-widget .wp-block-group__inner-container > h6,
.shop-widget .wp-block-group__inner-container > .h6 {
  position: relative;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.wp-block-search .wp-block-search__label {
  color: var(--title);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.wp-block-group:where(.has-background) {
  padding: 1.25em 2.375em;
}

.widget_working ul li {
  display: block;
}

.widget_working ul li .time a {
  font-weight: 600;
  color: #791313;
  display: block;
}

.widget_getintuch ul li {
  margin-bottom: 10px;
}

.widget_getintuch .quick_connect {
  display: flex;
  align-items: baseline;
}

.widget_getintuch .quick_connect span {
  margin-left: 5px;
  font-family: "Mulish", sans-serif;
  color: #b2b2b2;
  word-wrap: break-word;
  overflow: hidden;
}
@media only screen and (max-width: 1200px) {
  .widget_getintuch ul li {
    margin-bottom: 15px;
  }
}

.widget_getintuch ul li h5,
.widget_getintuch ul li .h5 {
  font-size: 20px;
  line-height: 1.3;
  margin-bottom: 5px;
}

.widget_getintuch ul li i {
  font-size: 12px;
  color: var(--bs-light);
}

.footer_outlet_ic {
  color: var(--primary);
  width: 25px;
  margin-top: 5px;
}
.ft_address {
  p {
    font-size: 14px !important;
    font-weight: 300;
    line-height: 24px;
    width: 100%;
  }
}
.widget_getintuch ul li p span {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  cursor: pointer;
  letter-spacing: 0.02em;
  font-family: "Mulish", sans-serif;
  color: #b2b2b2;
}

.widget.bg-white {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-base);
}

.widget_calendar table {
  border-collapse: separate;
  border-spacing: 2px;
  width: 100%;
}

.widget_calendar table thead {
  background-color: #999999;
}

.widget_calendar table thead tr th {
  font-size: 11px;
  padding: 5px;
  text-align: center;
  border: none;
  color: #fff;
}

.widget_calendar table tbody td {
  font-size: 13px;
  padding: 6px 5px;
  text-align: center;
  background-color: var(--rgba-primary-2);
  border: none;
  color: #444;
}

.widget_calendar table tbody td#today {
  background-color: #791313;
  color: #fff;
}

.widget_calendar table tbody td#today a {
  color: #fff;
}

.widget_calendar table tfoot tr td {
  border: none;
  padding: 0px;
}

.widget_calendar table tfoot tr td a {
  background-color: #fff;
  padding: 4px 10px;
}

.searchform {
  position: relative;
}

.searchform input[type="text"] {
  width: 100%;
  height: 40px;
  padding: 10px 90px 10px 15px;
  border: 1px solid #cccccc;
}

.searchform input[type="submit"] {
  height: 40px;
  padding: 5px 15px;
  background-color: var(--color-primary);
  position: absolute;
  right: 0;
  bottom: 0;
  color: #ffffff;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none;
}

.searchform input[type="submit"]:hover,
.searchform input[type="submit"]:focus,
.searchform input[type="submit"]:active {
  background-color: var(--color-hover);
  color: #ffffff !important;
  border-bottom-color: #5a9e2f;
}

.widget_services li a span {
  position: relative;
}

.widget_services li a span:after {
  content: "";
  position: absolute;
  height: 1px;
  background-color: #791313;
  bottom: 0;
  right: 0;
  width: 0;
  transform-origin: left;
  -moz-transform-origin: left;
  -ms-transform-origin: left;
  -webkit-transform-origin: left;
  -o-transform-origin: left;
  transition: all 0.8s;
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
}

.widget_services li a:hover span:after {
  width: 100%;
  left: 0;
  right: auto;
  transform-origin: right;
  -moz-transform-origin: right;
  -ms-transform-origin: right;
  -webkit-transform-origin: right;
  -o-transform-origin: right;
}

@media only screen and (max-width: 991px) {
  .modal-dialog {
    max-width: 750px !important;
    padding: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .modal-dialog {
    padding: 0px;
  }
}

.dzmove1 {
  animation: dzMove1 5s linear infinite;
}

@keyframes dzMove1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  50% {
    -webkit-transform: translate(25px, 25px);
    transform: translate(25px, 25px);
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

.dzmove2 {
  -webkit-animation: move1 5s infinite;
  animation: move1 5s infinite;
}

@-webkit-keyframes dzmove2 {
  0% {
    -webkit-transform: rotate(-25deg) translate(-50px, -50px);
    transform: rotate(-25deg) translate(-50px, -50px);
  }

  50% {
    -webkit-transform: rotate(25deg) translate(50px, 50px);
    transform: rotate(25deg) translate(50px, 50px);
  }

  100% {
    -webkit-transform: rotate(-25deg) translate(-50px, -50px);
    transform: rotate(-25deg) translate(-50px, -50px);
  }
}

.dzheart {
  animation: dzHeart 1s linear infinite;
}

@keyframes dzHeart {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(0.9);
  }
}

.range-slider.style-1 {
  padding: 45px 0 0;
}

.range-slider.style-1 .noUi-horizontal {
  height: 8px;
}

.range-slider.style-1 .noUi-horizontal .noUi-handle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #791313;
  background-color: #fff;
  box-shadow: none;
  top: -5px;
}

.range-slider.style-1 .noUi-horizontal .noUi-handle::before,
.range-slider.style-1 .noUi-horizontal .noUi-handle::after {
  content: none;
}

.range-slider.style-1 .noUi-horizontal .noUi-handle .noUi-tooltip {
  border: 0px;
  background: #791313;
  color: white;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 600;
  padding: 2px 5px;
  bottom: 30px;
}

.range-slider.style-1 .noUi-horizontal .noUi-handle .noUi-tooltip::after {
  content: "";
  position: absolute;
  background-color: inherit;
  width: 10px;
  height: 10px;
  bottom: -11px;
  margin-left: -4px;
  left: 50%;
  transform: rotate(45deg) translate(-50%, -50%);
}

.range-slider.style-1
  .noUi-horizontal
  .noUi-handle.noUi-handle-lower
  .noUi-tooltip {
  background: var(--title);
}

.range-slider.style-1
  .noUi-horizontal
  .noUi-handle.noUi-handle-lower
  .noUi-tooltip.after {
  background-color: var(--title);
}

.range-slider.style-1 .noUi-target {
  background: #d3d3d3;
  border-radius: 4px;
  border: 0;
  box-shadow: none;
}

.range-slider.style-1 .noUi-target .noUi-connect {
  background: #791313;
}

.adv-box {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  height: 100%;
}

.adv-box .adv-media {
  position: relative;
  overflow: hidden;
}

.adv-box.style-1 {
  overflow: unset;
  background-color: #054f48;
  text-align: center;
}

.adv-box.style-1 .adv-content {
  padding: 30px 20px 0px;
}

.adv-box.style-1 .adv-content span {
  font-weight: 700;
  font-size: 20px;
  color: #fff;
}

.adv-box.style-1 .adv-content .title {
  color: #fecc48;
  font-weight: 800;
  font-size: 43px;
  font-family: var(--font-family-title);
  line-height: 64px;
}

.adv-box.style-1 .adv-media {
  position: relative;
  overflow: unset;
  width: 400px;
  left: -31px;
  top: 0px;
}

.adv-box.style-1 .adv-media .item-media {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

@media only screen and (max-width: 1280px) {
  .adv-box.style-1 .adv-content {
    margin-bottom: 30px;
  }

  .adv-box.style-1 .adv-media {
    width: 370px;
    left: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .adv-box.style-1 .adv-media {
    width: 100%;
    left: 0;
    text-align: right;
  }

  .adv-box.style-1 .adv-content {
    margin-bottom: 45px;
  }

  .adv-box.style-1 .adv-content .title {
    font-size: 33px;
    line-height: 44px;
  }
}

@media only screen and (max-width: 991px) {
  .adv-box.style-1 .adv-media .main-media {
    width: 500px;
    height: 300px;
    object-fit: cover;
  }
}

@media only screen and (max-width: 767px) {
  .adv-box.style-1 .adv-media .main-media {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.adv-box.style-2 {
  background-color: #791313;
  display: flex;
  align-items: center;
  overflow: unset;
}

.adv-box.style-2 .adv-content {
  padding: 10px;
  margin-left: -10px;
}

.adv-box.style-2 .adv-content .price {
  font-weight: 800;
  font-size: 24px;
  line-height: 36px;
  color: #f9fe10;
  display: block;
  margin-bottom: 8px;
}

.adv-box.style-2 .adv-content .special {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

.adv-box.style-2 .adv-content .title {
  font-weight: 700;
  font-size: 50px;
  line-height: 55px;
}

.adv-box.style-2 .adv-media {
  position: relative;
  overflow: unset;
  height: 220px;
  min-height: 220px;
  width: 200px;
}

.adv-box.style-2 .adv-media img {
  position: absolute;
  bottom: -1px;
  object-fit: cover;
}

@media only screen and (max-width: 1280px) {
  .adv-box.style-2 .adv-media {
    width: 180px;
  }

  .adv-box.style-2 .adv-content {
    padding: 0;
    margin-left: -15px;
  }

  .adv-box.style-2 .adv-content .special {
    font-size: 16px;
    line-height: 20px;
  }

  .adv-box.style-2 .adv-content .title {
    font-size: 40px;
    line-height: 40px;
  }

  .adv-box.style-2 .adv-content .price {
    font-size: 18px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 1024px) {
  .adv-box.style-2 .adv-media {
    width: 145px;
    height: 185px;
    min-height: 185px;
  }
}

@media only screen and (max-width: 767px) {
  .adv-box.style-2 .adv-media {
    width: 120px;
    margin-right: 30px;
  }
}

.adv-box.style-3 .adv-content {
  padding: 40px 15px 40px 190px;
}

.adv-box.style-3 .adv-content .special {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.adv-box.style-3 .adv-content .title {
  font-weight: 800;
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 20px;
  text-shadow: 4px 8px 4px rgba(0, 0, 0, 0.25);
  color: #fecc48;
}

@media only screen and (max-width: 1024px) {
  .adv-box.style-3 .adv-content {
    padding: 30px 14px 30px 131px;
  }

  .adv-box.style-3 .adv-content .title {
    font-size: 30px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .adv-box.style-3 .adv-content {
    padding: 30px 14px 30px 160px;
  }
}

@media only screen and (max-width: 767px) {
  .adv-box.style-3 .adv-content {
    padding: 30px 14px 30px 265px;
  }
}

@media only screen and (max-width: 575px) {
  .adv-box.style-3 .adv-content {
    padding: 25px 14px 25px 162px;
  }
}

.adv-box.style-4 {
  overflow: unset;
  background-color: #138919;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 30px 0 30px;
}

.adv-box.style-4 .adv-media {
  position: relative;
  overflow: unset;
  width: 250px;
  min-width: 250px;
  height: 150px;
}

.adv-box.style-4 .adv-media img {
  position: absolute;
  top: -55px;
  object-fit: cover;
  left: -90px;
}

.adv-box.style-4 .adv-content .special {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}

.adv-box.style-4 .adv-content .price {
  font-weight: 800;
  font-size: 54px;
  line-height: 81px;
  color: #fff;
}

@media only screen and (max-width: 1024px) {
  .adv-box.style-4 {
    padding: 0px 20px 0 0px;
  }

  .adv-box.style-4 .adv-content .price {
    font-size: 40px;
    line-height: 50px;
  }

  .adv-box.style-4 .adv-media {
    width: 200px;
    min-width: 200px;
  }

  .adv-box.style-4 .adv-media img {
    top: -20px;
    left: -70px;
  }
}

@media only screen and (max-width: 767px) {
  .adv-box.style-4 .adv-content .price {
    font-size: 40px;
    line-height: 40px;
  }

  .adv-box.style-4 .adv-content .special {
    font-size: 14px;
    line-height: 24px;
  }

  .adv-box.style-4 .adv-media {
    width: 150px;
    min-width: 150px;
  }

  .adv-box.style-4 .adv-media img {
    top: 0;
    left: 0;
  }
}

@media only screen and (max-width: 575px) {
  .adv-box.style-4 {
    display: block;
    text-align: center;
    padding: 20px;
  }

  .adv-box.style-4 .adv-media img {
    left: 50px;
    margin: auto;
  }
}

.offer-label {
  width: 100px;
  height: 100px;
}

.offer-label img {
  animation: dzRotate360 10s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
}

.offer-label span {
  color: var(--title);
  font-size: 22px;
  font-weight: 700;
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  line-height: 1.2;
}

.tempus-dominus-widget {
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.tempus-dominus-widget .date-container-days .day {
  border-radius: 0.475rem !important;
}

.tempus-dominus-widget .picker-switch {
  font-weight: 600;
}

.tempus-dominus-widget .date-container-days div:not(.no-highlight) {
  font-size: 15px;
}

.tempus-dominus-widget button {
  border-radius: 0.475rem !important;
}

.tempus-dominus-widget .toggleMeridiem {
  border: 0;
  padding: 8px !important;
}

.tempus-dominus-widget.light
  .date-container-days
  div:not(.no-highlight).active {
  background-color: #791313;
}

.tempus-dominus-widget.light
  .date-container-days
  div:not(.no-highlight).today::before {
  border-bottom-color: #791313;
}

.tempus-dominus-widget.light .date-container-days .dow {
  font-size: 13px;
  color: #222222;
}

.tempus-dominus-widget.light button {
  background-color: #791313;
  border-color: #791313;
}

.tempus-dominus-widget.light
  .date-container-days
  div.range-end:not(.no-highlight),
.tempus-dominus-widget.light
  .date-container-days
  div.range-in:not(.no-highlight),
.tempus-dominus-widget.light
  .date-container-days
  div.range-start:not(.no-highlight),
.tempus-dominus-widget.light .date-container-days div:not(.no-highlight).active,
.tempus-dominus-widget.light
  .date-container-decades
  div:not(.no-highlight).active,
.tempus-dominus-widget.light
  .date-container-months
  div:not(.no-highlight).active,
.tempus-dominus-widget.light
  .date-container-years
  div:not(.no-highlight).active,
.tempus-dominus-widget.light
  .time-container-clock
  div:not(.no-highlight).active,
.tempus-dominus-widget.light .time-container-hour div:not(.no-highlight).active,
.tempus-dominus-widget.light
  .time-container-minute
  div:not(.no-highlight).active,
.tempus-dominus-widget.light
  .time-container-second
  div:not(.no-highlight).active {
  background-color: #791313;
}

.separator.no-highlight {
  background: transparent;
}

.tabs-style-1 {
  border-bottom: 2px solid #eaeaea;
  margin: 0 0 30px;
}

.tabs-style-1 li {
  margin: 0 3px -1px 0;
}

.tabs-style-1 .nav-link {
  border: 2px solid rgba(255, 255, 255, 0);
  border-width: 2px 2px 0;
  color: var(--title);
}

.tabs-style-1 .nav-link.active,
.tabs-style-1 .nav-link:hover,
.tabs-style-1 .nav-link:active,
.tabs-style-1 .nav-link:focus {
  border: 2px solid #eaeaea;
  background-color: #eaeaea;
  color: var(--title);
}
