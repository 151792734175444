.deznav {
  width: 13rem;
  padding-bottom: 0;
  // height: calc(100% - 80px);
  height: 100%;
  position: fixed;
  top: 3rem;
  padding-top: 0;
  z-index: 5;
  background-color: #000;
  transition: all 0.2s ease;
  box-shadow: 0px 40px 40px 0px rgba(82, 63, 105, 0.1);
}

.deznav {
  right: 0px;
  top: -10px;
}

.menu-toggle .deznav {
  right: -100px;
}

.deznav {
  background-color: #000;
  position: fixed;
}

@media only screen and (max-width: 767px) {
  .deznav {
    background-color: #000;
    height: calc(100% - 60px);
    position: fixed;
  }
}

.deznav .deznav-scroll {
  position: relative;
  // height: calc(100% - 80px);
  // overflow-y: scroll;
  height: calc(100% - 60px);
}

@media only screen and (max-width: 767px) {
  .deznav .deznav-scroll {
    height: calc(100% - 60px);
  }

}

@media only screen and (min-width: 1201px) and (max-width: 1350px) {
  .deznav {
    width: 25rem;
  }
}

.deznav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.deznav .metismenu {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
}

.deznav .metismenu.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
}

.deznav .metismenu>li {
  display: flex;
  flex-direction: column;
}

.deznav .metismenu>li button>i {
  font-size: 1.125rem;
  display: inline-block;
  vertical-align: middle;
  padding: 0 0.4375rem 0 0;
  font-weight: 700;
  position: relative;
  top: -2px;
}

[data-sidebar-style='compact'] .deznav .metismenu>li a>i {
  display: block;
  padding: 0;
}

.deznav .metismenu>li>button {
  font-weight: 400;
  display: inline-block;
  font-size: 15px;
  text-align: justify;
}

.nav-text {
  margin-left: 10px;
}

.deznav .metismenu>li>button svg {
  max-width: 24px;
  max-height: 24px;
  height: 100%;
  margin-right: 5px;
  margin-top: -3px;
  color: #7da640;
  font-size: 18px;
}

.deznav .metismenu>li>button g [fill] {
  fill: #8088a1;
}

.deznav .metismenu>li:hover>button,
.deznav .metismenu>li:focus>button {
  color: #7da640;
}

.deznav .metismenu>li:hover>button g [fill],
.deznav .metismenu>li:focus>button g [fill] {
  fill: #7da640;
}

.deznav .metismenu>li.mm-active>button {
  background-color: transparent;
  border: none;
  color: #7da640;
}

.deznav .metismenu>li.mm-active>button g [fill] {
  fill: #7da640;
}

.deznav .metismenu>li.mm-active.active-no-child {
  padding: 0;
}

.deznav .metismenu li {
  position: relative;
}

.deznav .metismenu ul {
  transition: all 0.2s ease-in-out;
  position: relative;
  z-index: 1;
  padding-left: 30px;
}

.deznav .metismenu ul button {
  font-weight: 300;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: relative;
  font-size: 18px;
}

.deznav .metismenu ul button:before {
  content: '';
  position: absolute;
  width: 3px;
  height: 3px;
  background: #89879f;
  position: absolute;
  left: 15px;
  top: 17px;
  border-radius: 4px;
}

.deznav .metismenu ul button:hover,
.deznav .metismenu ul button:focus,
.deznav .metismenu ul button.mm-active {
  text-decoration: none;
  color: #7da640;
}

.deznav .metismenu ul ul {
  padding-left: 15px;
}

.deznav .metismenu button {
  position: relative;
  display: block;
  padding: 0.625rem 0.625rem;
  outline-width: 0;
  color: #89879f;
  text-decoration: none;
  background-color: transparent;
  border: none;
}

.deznav .metismenu .has-arrow:after {
  width: 0.5rem;
  height: 0.5rem;
  right: 1.875rem;
  top: 48%;
  border-color: inherit;
  -webkit-transform: rotate(-225deg) translateY(-50%);
  transform: rotate(-225deg) translateY(-50%);
}

.deznav .metismenu .has-arrow[aria-expanded='true']:after,
.deznav .metismenu .mm-active>.has-arrow:after {
  -webkit-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(-135deg) translateY(-50%);
}

@media (max-width: 767px) {
  .nav-header {
    width: 3.75rem;
    z-index: 10;
  }

  .nav-header .brand-title {
    display: none;
  }

  .footer {
    padding-left: 0;
  }

  .deznav {
    left: 0px;
    top: 3.75rem;
  }

  .menu-toggle .deznav {
    left: -100px;
  }
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu>li.mega-menu>ul.collapse:not(.in),
[data-sidebar-style='mini'][data-layout='vertical'] .deznav .metismenu>li.mega-menu>ul.collapse:not(.in) {
  height: 252px !important;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu>li.mega-menu:hover>a,
[data-sidebar-style='mini'][data-layout='vertical'] .deznav .metismenu>li.mega-menu:hover>a {
  width: calc(70vw + 3.75rem);
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu>li.mega-menu:hover>ul,
[data-sidebar-style='mini'][data-layout='vertical'] .deznav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 13.75rem;
  width: 70vw;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu>li.mega-menu:hover>ul ul a,
[data-sidebar-style='mini'][data-layout='vertical'] .deznav .metismenu>li.mega-menu:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu>li.mega-menu-xl:hover>a,
[data-sidebar-style='mini'][data-layout='vertical'] .deznav .metismenu>li.mega-menu-xl:hover>a {
  width: calc(70vw + 3rem);
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu>li.mega-menu-xl:hover>ul,
[data-sidebar-style='mini'][data-layout='vertical'] .deznav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 200px;
  width: 70vw;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu>li.mega-menu-xl:hover>ul ul a,
[data-sidebar-style='mini'][data-layout='vertical'] .deznav .metismenu>li.mega-menu-xl:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu>li.mega-menu-lg:hover>a,
[data-sidebar-style='mini'][data-layout='vertical'] .deznav .metismenu>li.mega-menu-lg:hover>a {
  width: calc(55vw + 3rem);
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu>li.mega-menu-lg:hover>ul,
[data-sidebar-style='mini'][data-layout='vertical'] .deznav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 200px;
  width: 55vw;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu>li.mega-menu-lg:hover>ul ul a,
[data-sidebar-style='mini'][data-layout='vertical'] .deznav .metismenu>li.mega-menu-lg:hover>ul ul a {
  width: 101%;
}


[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu>li.mega-menu-md:hover>ul,
[data-sidebar-style='mini'][data-layout='vertical'] .deznav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 18.75rem;
  width: 45vw;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu>li.mega-menu-md:hover>ul ul a,
[data-sidebar-style='mini'][data-layout='vertical'] .deznav .metismenu>li.mega-menu-md:hover>ul ul a {
  width: 101%;
}


[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu>li.mega-menu-sm:hover>ul,
[data-sidebar-style='mini'][data-layout='vertical'] .deznav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 18.125rem;
  width: 30vw;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu>li.mega-menu-sm:hover>ul ul a,
[data-sidebar-style='mini'][data-layout='vertical'] .deznav .metismenu>li.mega-menu-sm:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style='mini'][data-layout='vertical'][data-container='boxed'] .deznav .metismenu>li.mega-menu:hover>a {
  width: calc(60vw + 3.75rem);
}

[data-sidebar-style='mini'][data-layout='vertical'][data-container='boxed'] .deznav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 25rem;
  width: 60vw;
}

[data-sidebar-style='mini'][data-layout='vertical'][data-container='boxed'] .deznav .metismenu>li.mega-menu:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style='mini'][data-layout='vertical'][data-container='boxed'] .deznav .metismenu>li.mega-menu-xl:hover>a {
  width: calc(60vw + 3.75rem);
}

[data-sidebar-style='mini'][data-layout='vertical'][data-container='boxed'] .deznav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 25.625rem;
  width: 60vw;
}

[data-sidebar-style='mini'][data-layout='vertical'][data-container='boxed'] .deznav .metismenu>li.mega-menu-lg:hover>a {
  width: calc(50vw + 3.75rem);
}

[data-sidebar-style='mini'][data-layout='vertical'][data-container='boxed'] .deznav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 16.25rem;
  width: 50vw;
}

[data-sidebar-style='mini'][data-layout='vertical'][data-container='boxed'] .deznav .metismenu>li.mega-menu-md:hover>a {
  width: calc(40vw + 3.75rem);
}

[data-sidebar-style='mini'][data-layout='vertical'][data-container='boxed'] .deznav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 18.75rem;
  width: 40vw;
}

[data-sidebar-style='mini'][data-layout='vertical'][data-container='boxed'] .deznav .metismenu>li.mega-menu-sm:hover>a {
  width: calc(22vw + 3.75rem);
}

[data-sidebar-style='mini'][data-layout='vertical'][data-container='boxed'] .deznav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 18.125rem;
  width: 22vw;
}

[data-layout='horizontal'] .deznav .metismenu>li.mega-menu:not(:last-child) {
  position: static;
}

[data-layout='horizontal'] .deznav .metismenu>li.mega-menu ul {
  left: 0;
  right: 0;
}

[data-theme-version='dark'][data-layout='horizontal'] .deznav .metismenu>li.mega-menu ul li {
  border-color: #333a54;
}

[data-sibebarbg='color_2'][data-layout='horizontal'] .deznav .metismenu>li.mega-menu ul li {
  border-color: #03090f;
}

[data-sibebarbg='color_3'][data-layout='horizontal'] .deznav .metismenu>li.mega-menu ul li {
  border-color: #0145d1;
}

[data-sibebarbg='color_4'][data-layout='horizontal'] .deznav .metismenu>li.mega-menu ul li {
  border-color: #22134e;
}

[data-sibebarbg='color_5'][data-layout='horizontal'] .deznav .metismenu>li.mega-menu ul li {
  border-color: #711717;
}

[data-sibebarbg='color_6'][data-layout='horizontal'] .deznav .metismenu>li.mega-menu ul li {
  border-color: #121843;
}

[data-sibebarbg='color_7'][data-layout='horizontal'] .deznav .metismenu>li.mega-menu ul li {
  border-color: #3511d0;
}

[data-sibebarbg='color_8'][data-layout='horizontal'] .deznav .metismenu>li.mega-menu ul li {
  border-color: #1161aa;
}

[data-sibebarbg='color_9'][data-layout='horizontal'] .deznav .metismenu>li.mega-menu ul li {
  border-color: #002629;
}

[data-sibebarbg='color_10'][data-layout='horizontal'] .deznav .metismenu>li.mega-menu ul li {
  border-color: #af5b00;
}

[data-layout='horizontal'] .deznav .metismenu>li.mega-menu ul li a {
  transition: all 0.4s ease-in-out;
}

[data-layout='horizontal'] .deznav .metismenu>li.mega-menu ul li a:hover {
  border-radius: 0.25rem;
}

[data-layout='horizontal'] .deznav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 13.75rem;
  width: 70vw;
  z-index: 99;
}

[data-layout='horizontal'] .deznav .metismenu>li.mega-menu:hover>ul::after {
  content: '';
  height: 100%;
  width: 1px;
  position: absolute;
  background-color: #000;
  right: 2.8125rem;
  top: 0;
}

[data-theme-version='dark'][data-layout='horizontal'] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #1d2544;
}

[data-sibebarbg='color_2'][data-layout='horizontal'] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #0c223a;
}

[data-sibebarbg='color_3'][data-layout='horizontal'] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #0758fe;
}

[data-sibebarbg='color_4'][data-layout='horizontal'] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #331d77;
}

[data-sibebarbg='color_5'][data-layout='horizontal'] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #9c1f1f;
}

[data-sibebarbg='color_6'][data-layout='horizontal'] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #1d276b;
}

[data-sibebarbg='color_7'][data-layout='horizontal'] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #4c27ed;
}

[data-sibebarbg='color_8'][data-layout='horizontal'] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #167cd8;
}

[data-sibebarbg='color_9'][data-layout='horizontal'] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #00545c;
}

[data-sibebarbg='color_10'][data-layout='horizontal'] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #e27500;
}

[data-layout='horizontal'] .deznav .metismenu>li.mega-menu:hover>ul ul a {
  width: 101%;
}

[data-layout='horizontal'] .deznav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 210px;
  width: 70vw;
}

[data-layout='horizontal'] .deznav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 210px;
  width: 700px;
  height: 210px !important;
}

@media only screen and (min-width: 1201px) and (max-width: 1500px) {
  [data-layout='horizontal'] .deznav .metismenu>li.mega-menu-lg:hover>ul {
    width: 700px;
  }
}

[data-layout='horizontal'] .deznav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 20rem;
  width: 54vw;
}

@media only screen and (min-width: 1201px) and (max-width: 1500px) {
  [data-layout='horizontal'] .deznav .metismenu>li.mega-menu-md:hover>ul {
    width: 60vw;
  }
}

[data-layout='horizontal'] .deznav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 20rem;
  width: 25vw;
}

@media only screen and (min-width: 1201px) and (max-width: 1500px) {
  [data-layout='horizontal'] .deznav .metismenu>li.mega-menu-sm:hover>ul {
    width: 35vw;
  }
}

[data-layout='horizontal'][data-container='boxed'] .deznav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

[data-layout='horizontal'][data-container='boxed'] .deznav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 21.875rem;
  width: 100%;
}

[data-layout='horizontal'][data-container='boxed'] .deznav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 21.875rem;
  width: 55vw;
}

[data-layout='horizontal'][data-container='boxed'] .deznav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 18.75rem;
  width: 45vw;
}

[data-layout='horizontal'][data-container='boxed'] .deznav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 18.125rem;
  width: 50vw;
}

/*$primary  : #7356f1 !default;*/
/*
  0 - 600: Phone
  600 - 900: Tablet portrait
  900 - 1200: Tablet landscape
  1200 - 1800: Normal styles
  1800+ : Big Desktop
  1em = 16px
  The smaller device rules always should write below the bigger device rules
  Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
  */
[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .nav-header {
  width: 5rem;
  z-index: 999;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .nav-header .brand-logo {
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #7da640 !important;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .header {
  padding-left: 5rem;
  width: 100%;
}

[direction='rtl'][data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .header {
  padding: 0 0.9375rem;
  padding-right: 5rem;
}

.menu-toggle .deznav {
  width: 5rem;
  overflow: visible;
  position: fixed;
}

.menu-toggle .deznav .nav-text {
  display: none;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .slimScrollDiv,
[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .deznav-scroll {
  overflow: visible !important;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu li {
  position: relative;
}

.menu-toggle .deznav .metismenu li button {
  padding: 0.8125rem 0.9375rem;
}

.menu-toggle .deznav .metismenu li button svg {
  max-width: 24px;
  max-height: 24px;
  margin-right: 0;
}

.menu-toggle .deznav .metismenu li button:before {
  content: none;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu li>ul {
  position: absolute;
  left: 5rem;
  top: 0;
  width: 12rem;
  z-index: 1001;
  display: none;
  padding-left: 1px;
  height: auto !important;
  box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
  border-radius: 6px;
  margin-left: 0;
  border: 0;
  background: #fff;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu li>ul:after {
  content: '';
  position: absolute;
  background: inherit;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  left: -5px;
  top: 20px;
}

[direction='rtl'][data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu li>ul {
  left: auto;
  right: 3rem;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu li>ul li:hover ul {
  left: 11.8125rem;
  top: 0;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu li>ul li:hover ul:after {
  content: none;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu li:hover>ul {
  display: block;
  height: auto;
  overflow: visible;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu>li {
  transition: all 0.4s ease-in-out;
  padding: 0 13px;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu>li>a.has-arrow:after {
  display: none;
}

.menu-toggle .deznav .metismenu>li.mm-active>button {
  background: rgba(255, 172, 90, 0.5);
  border-radius: 6px;
  border: none;
}

.menu-toggle .deznav .metismenu>li:hover:nth-last-child(-n + 1)>ul {
  bottom: 0;
  top: auto;
}

.menu-toggle .deznav .metismenu>li:hover>button {
  border-radius: 6px;
  background: rgba(125, 166, 64, .08);
}

[data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu>li:hover>a {
  background: #1e2746;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu>li:hover>ul {
  height: auto !important;
  padding: 10px 0;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu>li:hover>ul a {
  padding: 6px 20px 6px 20px;
  margin-left: -0.1rem;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu>li:hover>ul ul {
  padding: 10px 0;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu>li:hover>ul ul a {
  padding: 6px 20px 6px 20px;
  margin-left: -0.1rem;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu .nav-label,
[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav .metismenu .nav-badge {
  display: none;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .content-body {
  margin-left: 5rem;
}

[direction='rtl'][data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .content-body {
  margin-right: 5rem;
  margin-left: auto;
  border: 0;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle+.footer {
  padding-left: 5rem;
}

[direction='rtl'][data-sidebar-style='full'][data-layout='vertical'] .menu-toggle+.footer {
  padding-left: 0;
  padding-right: 5rem;
}

[data-sidebar-style='full'][data-layout='horizontal'] .deznav .metismenu {
  padding: 0 30px;
}

[data-sidebar-style='full'][data-layout='horizontal'] .header .header-content {
  padding-left: 1.875rem;
}


.breadcrumb-row ul {
  // background: #fff;
  // box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  margin: 0;
  // border-radius: 10px;
  display: inline-block;
  align-items: center;
  // padding: 13px 30px;
}

@media only screen and (max-width: 991px) {
  .breadcrumb-row ul {
    padding: 6px 25px;
  }
}

.breadcrumb-row ul li {
  padding: 0;
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  color: #222222;
}

.breadcrumb-row ul li.active {
  color: #791313;
}

.breadcrumb-row ul li a {
  color: #9A9A9A;
}

.breadcrumb-row ul .breadcrumb-item+.breadcrumb-item::before {
  content: "\f105";
  color: #9A9A9A;
  font-weight: 700;
  font-family: 'Line Awesome Free';
  font-size: 15px;
  padding-right: 10px;
  top: 0px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-row ul {
    padding: 5px 20px;
  }

  .breadcrumb-row ul li {
    font-size: 14px;
  }
}


.page-title {
  padding: 30px 0;
}

.page-title.dashboard {
  background: transparent;
  margin-left: 75px;
  padding: 20px 0 0px;
}

.page-title .page-title-content p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}

.page-title .page-title-content p span {
  font-weight: 500;
  color: var(--primary);
}

.breadcrumbs {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 575px) {
  .breadcrumbs {
    justify-content: flex-start;
  }
}

.breadcrumbs li a {
  color: #AEAED5;
  display: inline-block;
  margin-left: 15px;
  font-size: 14px;
}

.breadcrumbs li:first-child a {
  margin-left: 0px;
}

.breadcrumbs li.active a {
  color: var(--primary);
}

.bottom {
  background: #1b2a4e;
}

@media only screen and (max-width: 575px) {
  .bottom {
    padding-bottom: 0;
  }
}

.bottom .bottom-logo p {
  line-height: 30px;
  font-weight: 400;
  color: #999999;
}

@media only screen and (max-width: 991px) {
  .bottom .bottom-widget {
    margin-bottom: 30px;
  }
}

.bottom .bottom-widget .widget-title {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.bottom .bottom-widget ul li a {
  color: #999999;
  font-weight: 400;
  margin-bottom: 10px;
  display: inline-block;
}


.dz-form-card {
  padding: 40px 40px 50px;
  border-radius: 10px;
  margin-top: -415px;
}

.dz-form-card .section-head {
  margin-bottom: 30px;
}

.dz-form-card .section-head .title {
  font-weight: 400;
  font-size: 45px;
  line-height: 56px;
  color: #000;
}

.dz-form-card .section-head p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  opacity: 0.8;
}

.dz-form-card .input-group {
  margin-bottom: 30px;
}

.dz-form-card .input-line {
  position: relative;
  border: 0;
}

.dz-form-card .input-line::before,
.dz-form-card .input-line::after {
  content: "";
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 25px;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.dz-form-card .input-line::after {
  width: 100%;
  background-color: #000;
  opacity: 0.25;
  box-shadow: 10px 10px 20px 0 rgba(0, 7, 66, 0.2);
}

.dz-form-card .input-line::before {
  width: 0;
  background-color: #000;
  transform: translateX(-50%);
  left: 50%;
}

.dz-form-card .input-line:hover::before,
.dz-form-card .input-line:focus::before,
.dz-form-card .input-line:active::before,
.dz-form-card .input-line.focused::before {
  width: 100%;
}

.dz-form-card .input-line textarea {
  height: 120px !important;
}

.dz-form-card textarea::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  opacity: 0.6;
}

.dz-form-card .form-control::placeholder,
.dz-form-card .wp-block-categories-dropdown select::placeholder,
.wp-block-categories-dropdown .dz-form-card select::placeholder,
.dz-form-card .wp-block-archives-dropdown select::placeholder,
.wp-block-archives-dropdown .dz-form-card select::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  opacity: 0.6;
}

@media only screen and (max-width: 1280px) {
  .dz-form-card {
    padding: 30px;
    margin-top: -390px;
  }
}

@media only screen and (max-width: 991px) {
  .dz-form-card {
    margin-bottom: 40px;
    margin-top: 0;
  }
}

.dz-separator {
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 8px;
  position: relative;
}

.dz-separator.m-b0 {
  margin-bottom: 0;
}

.dz-separator.style-icon {
  width: 30px;
  height: auto;
  text-align: center;
  font-size: 20px;
  color: var(--primary);
}

.dz-separator.style-icon::after,
.dz-separator.style-icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 40px;
  width: 70px;
  height: 2px;
  background: #fff;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 12px;
}

.dz-separator.style-icon::before {
  left: auto;
  right: 40px;
}

.dz-separator-outer {
  overflow: hidden;
}

.dz-separator.style-1 {
  height: 3px;
  width: 60px;
  background-color: var(--primary);
  border-radius: 8px;
}

.site-filters {
  margin-bottom: 30px;
}

.site-filters ul {
  margin: 0;
  list-style: none;
}

.site-filters li {
  display: inline-block;
  padding: 0;
  margin-bottom: 3px;
}

.site-filters li.btn,
.site-filters li.wp-block-button__link {
  box-shadow: none;
  -webkit-box-shadow: none;
  outline: none !important;
}

.site-filters li input {
  display: none;
}

.site-filters li a {
  margin: 0 5px 5px 0;
  display: block;
}

.site-filters.center {
  text-align: center;
}

.site-filters.center ul {
  display: inline-block;
  margin: auto;
}

.site-filters.center [class*="btn"] {
  display: inline-block;
  margin: 0 5px 10px;
  text-transform: uppercase;
}

@media only screen and (max-width: 575px) {
  .site-filters.center [class*="btn"] {
    margin: 0 1px 5px;
    padding: 5px 10px;
    font-size: 12px;
  }
}

.site-filters.style-1 {
  margin-bottom: 40px;
}

.site-filters.style-1 ul {
  margin-right: -10px;
}

.site-filters.style-1 ul li {
  padding: 8px 15px;
  border: none;
  margin-right: 10px;
}

.site-filters.style-1 ul li a {
  color: #222222;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  margin: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.site-filters.style-1 ul li a i {
  font-size: 25px;
  margin-right: 10px;
}

.site-filters.style-1 ul li:hover,
.site-filters.style-1 ul li.active {
  background: var(--bg-light);
}

.site-filters.style-1 ul li:hover a,
.site-filters.style-1 ul li.active a {
  color: var(--primary);
}

.site-filters.style-1 ul li:hover a i,
.site-filters.style-1 ul li.active a i {
  -webkit-animation: upToDown 0.5s forwards;
  -moz-animation: upToDown 0.5s forwards;
  animation: upToDown 0.5s forwards;
}

@media only screen and (max-width: 1280px) {
  .site-filters.style-1 ul {
    margin-right: -5px;
  }

  .site-filters.style-1 ul li {
    padding: 8px;
    margin-right: 5px;
  }

  .site-filters.style-1 ul li a {
    font-size: 13px;
  }

  .site-filters.style-1 ul li a i {
    margin-right: 5px;
  }
}

@media only screen and (max-width: 991px) {
  .site-filters.style-1 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .site-filters.style-1 {
    text-align: center;
  }
}

.site-filters.style-2 {
  margin-bottom: 20px;
}

.site-filters.style-2 ul li {
  text-align: center;
  border: none;
  padding: 0px 30px;
}

.site-filters.style-2 ul li a {
  color: #222222;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  margin: 0;
}

.site-filters.style-2 ul li a span {
  display: block;
  margin-bottom: 10px;
}

.site-filters.style-2 ul li a span i {
  font-size: 40px;
}

.site-filters.style-2 ul li:hover a,
.site-filters.style-2 ul li.active a {
  color: var(--primary);
}

.site-filters.style-2 ul li:hover a i,
.site-filters.style-2 ul li.active a i {
  animation: shake 1s;
}

@media only screen and (max-width: 991px) {
  .site-filters.style-2 ul li {
    padding: 0px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .site-filters.style-2 ul li {
    margin-bottom: 20px;
    padding: 0px 10px;
  }

  .site-filters.style-2 ul li a {
    font-size: 14px;
  }

  .site-filters.style-2 ul li a span i {
    font-size: 30px;
  }
}

@-webkit-keyframes upToDown {
  49% {
    -webkit-transform: translateY(100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }

  51% {
    opacity: 1;
  }
}

@-moz-keyframes upToDown {
  49% {
    -moz-transform: translateY(100%);
  }

  50% {
    opacity: 0;
    -moz-transform: translateY(-100%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes upToDown {
  49% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.dz-img-overlay1,
.dz-img-overlay2,
.dz-img-overlay3,
.dz-img-overlay4,
.dz-img-overlay5,
.dz-img-overlay6,
.dz-img-overlay7,
.dz-img-overlay8,
.dz-img-overlay9,
.dz-img-overlay11,
.dz-img-overlay12 {
  position: relative;
  overflow: hidden;
}

.dz-img-overlay1::before,
.dz-img-overlay1::after,
.dz-img-overlay2::before,
.dz-img-overlay2::after,
.dz-img-overlay3::before,
.dz-img-overlay3::after,
.dz-img-overlay4::before,
.dz-img-overlay4::after,
.dz-img-overlay5::before,
.dz-img-overlay5::after,
.dz-img-overlay6::before,
.dz-img-overlay6::after,
.dz-img-overlay7::before,
.dz-img-overlay7::after,
.dz-img-overlay8::before,
.dz-img-overlay8::after,
.dz-img-overlay9::before,
.dz-img-overlay9::after,
.dz-img-overlay11::before,
.dz-img-overlay11::after,
.dz-img-overlay12::before,
.dz-img-overlay12::after {
  content: "";
  background: #282d32;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0.4;
  z-index: 1;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-img-overlay1.overlay-primary,
.dz-img-overlay2.overlay-primary,
.dz-img-overlay3.overlay-primary,
.dz-img-overlay4.overlay-primary,
.dz-img-overlay5.overlay-primary,
.dz-img-overlay6.overlay-primary,
.dz-img-overlay7.overlay-primary,
.dz-img-overlay8.overlay-primary,
.dz-img-overlay9.overlay-primary,
.dz-img-overlay11.overlay-primary,
.dz-img-overlay12.overlay-primary {
  background: var(--primary);
}

.dz-box:hover .dz-img-overlay1::before {
  opacity: 0.7;
}

.dz-box:hover .dz-img-overlay2::before {
  opacity: 0.9;
}

.dz-box:hover .dz-img-overlay3::before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dz-box:hover .dz-img-overlay4::before {
  width: 100%;
  left: 0;
}

.dz-box:hover .dz-img-overlay5::before {
  height: 100%;
  top: 0;
}

.dz-box:hover .dz-img-overlay6::before {
  height: 100%;
}

.dz-box:hover .dz-img-overlay7::before,
.dz-box:hover .dz-img-overlay7::after {
  width: 50%;
}

.dz-box:hover .dz-img-overlay8::before,
.dz-box:hover .dz-img-overlay8::after {
  width: 100%;
  opacity: 0.3;
}

.dz-box:hover .dz-img-overlay9::before,
.dz-box:hover .dz-img-overlay9::after {
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

.dz-img-overlay1::before {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dz-img-overlay1:hover::before {
  opacity: 0.5;
}

.dz-img-overlay2::before {
  width: 100%;
  height: 100%;
  opacity: 0;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
}

.dz-img-overlay2::before:hover::before {
  opacity: 0.9;
}

.dz-img-overlay3::before {
  left: 50%;
  top: 50%;
}

.dz-img-overlay3:hover::before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dz-img-overlay4::before {
  left: 50%;
  top: 0;
  height: 100%;
}

.dz-img-overlay4:hover::before {
  width: 100%;
  left: 0;
}

.dz-img-overlay5::before {
  left: 0;
  top: 50%;
  width: 100%;
}

.dz-img-overlay5:hover::before {
  height: 100%;
  top: 0;
}

.dz-img-overlay6::before {
  left: 0;
  top: auto;
  bottom: 0;
  width: 100%;
}

.dz-img-overlay6:hover::before {
  height: 100%;
}

.dz-img-overlay7::before,
.dz-img-overlay7::after {
  height: 100%;
}

.dz-img-overlay7::after {
  left: auto;
  right: 0;
}

.dz-img-overlay7:hover::before,
.dz-img-overlay7:hover::after {
  width: 50%;
}

.dz-img-overlay8::before,
.dz-img-overlay8::after {
  height: 100%;
}

.dz-img-overlay8::after {
  left: auto;
  right: 0;
}

.dz-img-overlay8:hover::before,
.dz-img-overlay8:hover::after {
  width: 100%;
  opacity: 0.3;
}

.dz-img-overlay9::before::before,
.dz-img-overlay9::before::after,
.dz-img-overlay9::after::before,
.dz-img-overlay9::after::after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}

.dz-img-overlay9::before:hover::before,
.dz-img-overlay9::before:hover::after,
.dz-img-overlay9::after:hover::before,
.dz-img-overlay9::after:hover::after {
  width: 100%;
  height: 100%;
  opacity: 0.3;
}