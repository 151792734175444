
.MenuStickyBottom_container{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    pointer-events: none;
}
@media only screen and (min-width:640px){
    .MenuStickyBottom_container{
        width: 800px;
        margin: 0 auto;
        display: none;
    }
}

.MenuStickyBottom_fabButtonWithCart{
    transform: translateZ(0);
}

.MenuStickyBottom_fabButton {
    height: 84px;
    z-index: 1;
    transition: transform .25s ease;
    will-change: transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    contain: content;
    position: relative;
    // transform: translate3d(0, 132px, 0);
}

.MenuFabButton_btnContainer {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 150px;
    height: 40px;
    pointer-events: auto;
}

.MenuFabButton_btn {
    height: 40px;
}

.FabButton_container {
    width: 150px;
    height: 40px;
    border-radius: 30px;
    background: #5d8ed5;
    color: #000;
    text-align: center;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.3), 0 2px 1px 0 rgba(93,141,213,.2);
    padding: 14px;
    font-weight: 600;
    font-size: .93rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    will-change: transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: scaleX(1);
    transition: transform .25s ease-in-out;
}
.MenuFabButton_btnIcon {
    margin-right: 6px;
    font-size: .86rem;
}
.MenuFabButton_btnIcon {
    margin-right: 6px;
    font-size: .86rem;
}
.MenuStickyBottom_viewCartVisible {
    transform: translateZ(0);
}

.MenuStickyBottom_viewCart {
    height: 48px;
    overflow: hidden;
    z-index: 3;
    transition: transform .25s ease;
    will-change: transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: translate3d(0, 100%, 0);
    contain: content;
    position: relative;
}
.styles_container {
    height: 48px;
    background: #791313;
    color: #000;
    z-index: 2;
    position: relative;
    contain: content;
    pointer-events: auto;
    width: 100%;
}
.styles_container:focus{
    background-color: #791313 !important;
}
.styles_content{
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
}

.styles_main {
    flex: 1;
    flex-direction: column;
    line-height: 1.2;
}
.styles_mainTitle {
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
}
.styles_mainTitle svg{
    margin-top: 4px;
    margin-left: 3px;
}
.styles_mainSubTitle {
    font-size: 12px;
    display: block;
}
.styles_viewCart {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.styles_viewCartIcon {
    margin-left: 5px;
}


