.mobile-nav-bottom {
    z-index: 998;
    bottom: 0;
    background-color: var(--primary);
    height: 65px;
    box-shadow: 0 -1px 1px 0 rgba(0,0,0,.1);

}

.mobile-menu {
    position: relative;
    font-size: 0.7rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    text-decoration: none;
    color: var(--mobile-nav-color);
    font-weight: 700;
    font-family: 'Mulish';
    align-items: center;
    text-transform: uppercase;
}

.mobile-nav-bottom .active {
    color: var(--white);
}

.mobile-nav-bottom .mobile-menu i.active {
    color: var(--white);
}

.mobile-nav-bottom .mobile-menu i {
    font-size: 22px;
    text-align: center;
    line-height: 20px;
    display: inline-block;
    color: var(--mobile-nav-color);
    font-weight: 700;
}

.mobile-menu {
    background-color: transparent;
    border: none;
}

// close btn

.mobile-btn-close {
    margin: 15px 20px;
    background-color: transparent;
    border: none;
}

.mobile-btn-close svg {
    width: 20px;
    height: 20px;
}

.mobile-btn-close:hover {
    color: var(--bs-btn-close-color);
    text-decoration: none;
    opacity: var(--bs-btn-close-hover-opacity);
}

.mobile-btn-close:focus {
    outline: 0;
    box-shadow: var(--bs-btn-close-focus-shadow);
    opacity: var(--bs-btn-close-focus-opacity);
}

.mobile-btn-closebtn-close:disabled,
.mobile-btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: var(--bs-btn-close-disabled-opacity);
}

.mobile-btn-close-white {
    filter: var(--bs-btn-close-white-filter);
}
@media only screen and (max-width: 575px) {
.offcanvas-form{
    padding: 20px;
}
.offcanvas-form .login-head .title{
    text-align: start;
}
.offcanvas-form .login-head p{
    text-align: start; 
}
}

.mob_cart_qty {
    position: absolute;
    top: -5px;
    right: -7px;
    color: var(--primary);
    font-size: 8px;
    font-weight: 700;
    background: #fff;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    padding: 0;
    text-align: center;
    line-height: 1rem;
}