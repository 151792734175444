.full_screen_loader_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.6);
    transition: opacity .15s linear;
    z-index : 10000;
    display: flex;
    justify-content: center;
    align-items: center;
}

// DEFAULT LOADER
.default_loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
}

.fixed_loader {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 10000;
    background: #000;
    opacity: 0.3;
    overflow: hidden;
}
.fixed_loader_img {
    z-index: 10001;
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: hidden;
    transform: translate(-50%, -50%);
}