.total_bill {
  color: var(--new-primary);
  font-weight: 700;
}

.step {
  padding: 0px 0px 50px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.step .circle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-stepper {
  position: relative;
  /*   visibility: visible; */
}


/* regular step */
.step .circle {
  background-color: var(--primary);
  // border: 3px dotted #a6a4a4;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  // display: inline-block;
}

.step .line {
  left: 24px;
  position: absolute;
  height: 65px;
  border-left: 3px dotted #a6a4a4;
}

.step.completed .circle {
  visibility: visible;
  background-color: #791313;
  border-color: #791313;
}

.step.completed .circle svg {
  color: #fff;
  font-size: 25px;
}

.step.completed .line {
  border-left: 3px dotted #791313;
  top: 33px;
  height: 90px;
}

.step.active .circle {
  visibility: visible;
  border: 2px dotted #791313;
}

.step.active .circle img {
  width: 30px;
}

.content_completed svg {
  color: #791313;
}

.content_active svg {
  color: rgb(251, 205, 1);
}

.content_in_active {
  color: #a6a4a4;
}

.progress__title {
  font-size: 18px;
  margin: 0px;
  font-weight: 700;
  font-family: 'Mulish', sans-serif;
}

.inactive_order_status {
  color: #787777;
}

.active_order_status {
  color: #791313;
}

.active_icon svg{
  color: #fff;
}
.inactive_icon {
  color: #a6a4a4;
}

.progress__info {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Mulish', sans-serif;
}

.step.empty .circle svg {
  font-size: 25px;
}

.step.active .line {
  top: 50px;
  height: 107px;
}


.step:last-child .line {
  border-left: 3px solid white;
  z-index: -1;
}

.content {
  margin-left: 20px;
}

// ORDER TRACKING 
.order_tracking_container {
  padding: 30px 0px 60px 0px;
  // background-image: url('../../../images/common_black_bg.png');

  @media (max-width:991px) {
    padding: 10px 0px 30px 0px;
  }
   
  .order_track_items {
    padding: 10px;
  }
  .inner_space {
    padding: 1.5rem;

    @media (max-width:991px) {
      padding: 10px;
    }
  }

  .boxshadow {
    box-shadow: 0 1rem 3rem rgba(var(--bs-body-color-rgb), 0.175) !important;
  }

  .card_bottom_space {
    margin-bottom: 0.1rem !important;

    .order_tracks_st {
      padding-left: 0;

    @media (max-width:991px) {
      padding-left: 15px;
    }
    }

    .order_track_gmap {
      padding-right: 0px;
    }
  }

  .order_id_container {
    background-color: #fff;
    display: flex;
    justify-content: space-between;

    @media (max-width:991px) {
        display: block;
    }

    .order_track_id {
      color: #791313;
      font-size: 16px;
      font-weight: 700;
      font-family: 'Mulish', sans-serif;
    }

    .order_placed {
      font-family: 'Mulish', sans-serif;
      @media (max-width:991px) {
        font-size: 12px;
      }
    }

    .order_quantity_amount  {
      font-family: 'Mulish', sans-serif;
      font-size: 14px;
      font-weight: 700;

      @media (max-width:991px) {
        font-size: 13px;
      }

      span {
        &::before {
          content: "\20B9";
        }
      }
    }
    .order_track_status {
      .status_txt {
        color: #791313;
        font-weight: 800;
        font-size: 16px;
        line-height: 25px;
        font-family: 'Mulish', sans-serif;

        @media (max-width:991px) {
          padding-top: 15px;
          font-size: 14px;
        }
      }
    }

  }

  .order_track_status_container {
    background-color: #ffffff;
    padding: 30px 0px 0px 40px;

    @media (max-width:991px) {
      padding: 10px;
      margin: 10px 0px;
    }
  }
}

.google_maps_tracker {
  height: 100%;
  width: 100%;
}

.pr-0 {
  padding-right: 0px;
}

.pl-0 {
  padding-left: 0px;
}

.order_track_shop_details {
  display: flex;
  padding-bottom: 25px;

  .order_track_shopImg {
    width: 100px;
    height: 80px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }

  .order_track_shop {
    margin-left: 15px;

    .title {
      font-size: 16px;
      font-weight: 600;
      font-family: 'Mulish', sans-serif;
    }

    .address {
      font-size: 12px;
      font-weight: 500;
      font-family: 'Mulish', sans-serif;

      &::after {
        display: flex;
        content: "";
        position: absolute;
        margin-top: 6px;
        background: #791313;
        width: 40px;
        height: 2px;
      }
    }


  }
}

.order_track_extra_details {
  margin-bottom: 20px;

  .ex_title {
    font-size: 14px;
    font-weight: 600;
    color: #791313;
    letter-spacing: 0.3px;
    font-family: 'Mulish', sans-serif;
  }

  .ex_content {
    display: flex;
    margin-top: 10px;

    .ex_c_icon {
      width: 30px;
      height: 30px;
    }

    .ex_desc {
      padding-left: 5px;
      font-size: 12px;
      font-weight: 400;
      color: #7b7979;
      font-family: 'Mulish', sans-serif;
      letter-spacing: 0.35px;
    }
  }
}

.order_track_total_container {
  padding: 0px 0px 0px 30px;
  @media (max-width: 991px) {
    padding: 0px;
  }
}
.order_track_items_container {
  padding: 0px 20px 0px 0px;

  @media (max-width: 991px) {
    padding: 0px;
  }
  
  .order_track_item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    flex: 12;

  
    .order_track_inner_item {
      display: flex;
      flex: 9;
    }

    .order_track_item_price {
      flex: 3;
      display: flex;
      justify-content: flex-end;
      font-size: 14px;
      font-weight: 600;
      font-family: 'Mulish', sans-serif;
      line-height: 18px;

      @media (max-width: 991px) {
        align-self: center;
      }
    }
  }

  .it_title {
    font-size: 14px;
    font-weight: 600;
    color: #791313;
    letter-spacing: 0.3px;
    font-family: 'Mulish', sans-serif;
  }

  .order_track_no_items {
    display: flex;

    @media (max-width: 991px) {
      width: 80px;
    }
    .qt {
      font-size: 12px;
      font-weight: 600;
      font-family: 'Mulish', sans-serif;
      color: #726f6f;
    }

    .pr_price::before {
      content: "\20B9";
    }
    .cross {
      font-weight: 400;
      font-family: 'Mulish', sans-serif;
    }
  }
}

.need_help_container {
  margin-top: 20px;
  border-top: 1px dashed #a6a4a4;
  padding: 20px 0px;
  border-radius: 4px;

  .need_help_label {
    padding: 0;
    margin: 0 0 20px;
    font-size: 18px;
    color: #373737;
    font-weight: 400;
    font-family: 'Mulish', sans-serif;
  }

  .reach_out {
    margin: 0 0 5px;
    font-size: 12px;
    color: #56595a;
    font-family: 'Mulish', sans-serif;
  }

  .help_number {
    text-decoration: none;
    font-size: 25px;
    color: #2ebd59;
    display: block;
    font-family: 'Mulish', sans-serif;
  }
  .available {
    font-size: 12px;
    color: #868a86;
    font-family: 'Mulish', sans-serif;
  }
}

.cancel_order_container {
  margin-top: 20px;
  border-top: 1px dashed #a6a4a4;
  padding: 20px 0px;

  .note_cancel {
    margin: 0 0 5px;
    font-size: 14px;
    color: #56595a;
    letter-spacing: 0.3px;
    font-family: 'Mulish', sans-serif;
  }

  .to_cancel_next {
    margin: 0 0 5px;
    font-size: 12px;
    color: #868a86;
    letter-spacing: 0.3px;
    font-family: 'Mulish', sans-serif;

    .cancel_order {
      color: #791313;
      font-weight: 700;
      letter-spacing: 0.4px;
      font-family: 'Mulish', sans-serif;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}