.profile_wrapper {
    -ms-flex-positive: 1;
    flex-grow: 1;
}


.profile_inner_wrapper {
    min-height: 100vh;
    position: relative;
    margin-bottom: 50px;
}

.scrolled {
    background-color: #000;
}

.profile_header {
    padding: 20px 0px;
}

.profile_header_btn {
    float: right;
    position: absolute;
    bottom: 7px;
    right: 0;
}

.profile_btn {
    border: 1px solid hsla(0, 0%, 100%, .6);
    padding: 12px 20px;
    text-transform: uppercase;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.profile_header_name_container {
    font-size: 32px;
    font-weight: 700;

    text-transform: capitalize;
    color: #791313;
}

.profile_header_name_subcontainer {
    margin-top: 2px;
    font-size: 16px;
}

.profile_email {
    margin-left: 15px;
}

.profile_email::before {
    content: ".";
    margin-right: 15px;
}

.profile_content_wrapper {
    background: #fff;
    margin: 0 auto;
    position: relative;
    border: 1px solid rgba(2, 6, 12, 0.15);
}

.profile_inner {
    height: 100%;
    position: absolute;
    top: 0;
    left: 1px;
    opacity: 0;
    z-index: 1;
    will-change: opacity;
    transition: opacity .3s ease;
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

.profile_content_container {
    position: relative;
    margin: 0 auto;
}

.profile_content_inner_container {
    display: -ms-flexbox;
    display: flex;
    position: relative;

}

.profile_sidemenu {
    min-width: 260px;
    background: #f6f6f6 !important;
    position: sticky;
    height: 100vh;
    overscroll-behavior: contain;
    overflow: auto;
    // border-right: 1px solid rgba(2, 6, 12, 0.15);
}

.profile_sidemenu_list {
    padding: 25px 20px;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    position: relative;
}

.sidemenu_active ::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 3px;
    border-radius: 3px;
    background-color: #791313;
}

.profile_edit_pen {
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background-color: #791313;
    line-height: 28px;
    padding: 0px 8px;
    font-size: 14px;
    color: #ffffff;
}

.sidemenu_active {
    background: #791313;
    color: #ffffff;
}

.profile_sidemenu_icon {
    color: #878484;
}

.profile_sidemenu_label {
    margin-left: 15px;
    font-size: 16px;
    color: #000;
    font-weight: 600;
    font-family: 'Mulish', sans-serif;
}

.profile_menu_active {
    color: #fff !important;
}

.profile_item {
    padding: 30px 15px;
    position: relative;
    height: 100vh;
    overflow-y: scroll;
    width: 100%;
    background-color: #ffffff;

}

.profile_item::-webkit-scrollbar {
    display: none;
}

.profile_item_heading {
    font-size: 24px;
    font-weight: 700;
    font-family: 'Mulish', sans-serif;
    color: #000;
}

.past_order_list {
    border: 0.1px solid #e2e2e4;
    padding: 20px;
    margin: 20px 0px;
    border-radius: 2px;
}

.past_order_wrapper {
    padding-bottom: 10px;
    display: flex;
}

.order_image_container {
    background: rgba(40, 44, 63, 0.05);
    width: 100px;
    height: 80px;
    cursor: pointer;
}

.order_history_inner_section {
    padding-left: 15px;
    flex: 1;
    display: flex;
    justify-content: space-between;

    .order_history_shop_details {
        .store_name {
            font-family: 'Mulish', sans-serif;
            font-size: 16px;
            font-weight: 700;
        }

        .store_address_max {
            max-width: 370px;
        }

        .store_address {
            font-family: 'Mulish', sans-serif;
            font-size: 12px;
            font-weight: 500;
        }
    }
}

.order_image_container img {
    height: 100%;
    width: 100%;
    border-radius: 8px;
}

.past_order_details {
    -ms-flex: 1;
    flex: 1;
    position: relative;
}

.order_items_pr_title {
    font-size: 14px;
    font-weight: 500;
    color: #6a6f84;
    display: inline-block;
    cursor: pointer;
    padding-left: 2px;
    font-family: 'Mulish', sans-serif;

    span {
        &::before {
            content: "\20B9";
        }
    }
}

.order_subtitle {
    font-size: 13px;
    color: #686b78;
    text-transform: capitalize;
    margin-top: 3px;
}

.order_id {
    margin: 10px 0px 5px 0px;
    color: #000;
    font-size: 14px;
    text-align: right;
    font-family: 'Mulish', sans-serif;
}

.order_placed_key {
    margin: 10px 0px 5px 0px;
    color: #000;
    font-size: 14px;
    font-family: 'Mulish', sans-serif;
    font-weight: 600;

    span {
        color: #000;
        font-weight: 400;

    }
}

.past_orders_pr_label {
    font-size: 16px;
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
    color: #000;
}

.order_details_btn {
    margin-top: 14px;
    color: #fc8019;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    letter-spacing: -.3px;
    display: inline-block;
}

.oder_delivery_date {
    float: right;
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 18px;
    font-size: 14px;
    color: #686b78;
}

.icon-tickSharp {
    margin-left: 10px;
}

.order_details {
    padding-top: 20px;
    position: relative;
}

.order_description {
    font-size: 15px;
    padding-right: 240px;
    color: #282c3f;
    line-height: 20px;
}

.order_reorder_wrapper {
    display: flex;
    justify-content: flex-end;
}

.order_reorder_btn {
    width: 120px;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    margin-right: 20px;
    line-height: 40px;
    height: 40px;
    color: #fc8019;
    border: 1px solid #fc8019;
    padding: 0 15px;
    margin-top: 20px;
    position: relative;
}

.order_help_btn {
    display: inline-block;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    line-height: 40px;
    height: 40px;
    color: var(--white);
    border: 1px solid #791313;
    background-color: #791313;
    ;
    padding: 0 15px;
    position: relative;
    transition: all 0.5s;
    font-family: 'Mulish', sans-serif;
}

.order_help_btn:hover {
    border: 1px solid #791313;
    color: #791313;
    background-color: var(--white);
    ;
}

.food-item {
    color: #791313;
    font-weight: 600;
}

.addons_name {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    margin: 0px;
}

.order_price {
    margin: 10px 0px 5px 0px;
    color: #000;
    font-weight: 600;
    font-size: 14px;
    font-family: 'Mulish', sans-serif;

    span {
        font-weight: 400;

    }
}

.past_order_status {
    padding-top: 3px;
    font-family: 'Mulish', sans-serif;

    span {
        font-size: 14px;
        font-weight: 700;
    }
}

._1jGfr:before {
    content: "\20B9";
}

.no_order_image {
    width: 100%;
    max-width: 500px;
}

.no_order {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.profile_title {
    font-size: 16px;
    font-weight: 700;
    color: #000;
    text-transform: capitalize;
    font-family: 'Mulish', sans-serif;
}

.address_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    column-gap: 0px;
    row-gap: 20px;
}

.address_box {
    border: 0.1px solid #e2e2e4;
    padding: 20px;
    position: relative;
    color: #3d4152;
    display: flex;
    width: 49%;

    @media screen and (max-width:640px) {
        width: 100%;
    }
}

.load_more_btn {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0px;
}

.address_icon_box {
    padding-right: 20px;
    padding-top: 3px;
    font-size: 20px;
}

.address_heading {
    font-size: 17px;
    font-weight: 600;
    color: #000;
    text-transform: capitalize;
    font-family: 'Mulish', sans-serif;
}

.detailed_address {
    font-size: 13px;
    color: #93959f;
    margin-bottom: 8px;
    font-weight: 300;
    line-height: 18px;
    height: 60px;
    padding-top: 5px;
    overflow: hidden;
    font-family: inherit;
}
.confirmed_address_sym {
    margin-left: 15px;
    background-color: var(--primary);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.detailed_address_landmark {
    font-size: 14px;
    color: #585859;
    line-height: 20px;
    font-weight: 500;
    font-family: 'Mulish', sans-serif;
    margin-top: 5px;
}
.address_btn {
    margin-top: 11px;
}

.address_btn span {
    color: #791313;
    font-weight: 600;
    font-size: 14px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Mulish', sans-serif;
}

@media only screen and (max-width: 991px) {

    .accordion_address_wrapper {
        position: relative;
        overflow: hidden;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        position: relative;
        overflow: hidden;
        border: 0;
        outline: 0;
        background: inherit;
        width: 100%;
    }

    .accordion_address_container {
        width: 100%;
    }

    .accordion_inner_body {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 1rem;
        font-weight: 500;
    }

    .accordion_icon_box {
        margin-right: 16px;
        font-size: 16px;
        width: 16px;
        text-align: center;
    }

    .accordion_body_heading {
        font-size: 1.1rem;
        margin: 0px;
        text-align: center;
    }

    .icon-downArrow {
        font-size: 16px;
        font-weight: 600;

    }

    .past-orders-id {
        font-size: 20px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 0 15px;
        letter-spacing: .8px;
        font-family: 'Mulish', sans-serif;
    }

    .order_card {
        background-color: #ffffff;
    }

    .inner_card_container {
        overflow: hidden;
        position: relative;
        padding: 20px 16px;
    }

    .order_card_header {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .order_card_heading {
        font-size: 1.07rem;
        font-weight: 500;
        font-family: 'Mulish', sans-serif;
    }

    .order_status_container {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .order_status {
        font-size: .86rem;
        color: #3d4152;
        text-transform: capitalize;
        line-height: 11px;
    }


    .tickSharp {
        margin-left: 5px;
    }

    .order_card_subheading {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        color: #93959f;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-top: 4px;
        margin-bottom: 3px;
    }

    .order_subtitle {
        font-size: .86rem;
        font-weight: 400;
        text-transform: capitalize;
    }

    .order_card_price {
        font-size: .93rem;
        margin-right: 8px;
        color: #7e808c;
    }


    .past_order_content {
        border-top: 1px dashed #d4d5d9;
        padding-bottom: 30px;
    }

    .past_order_item {
        margin-top: 20px;
        color: #7e808c;
        font-size: .93rem;
    }

    .past_order_time {
        margin-top: 8px;
        color: #a9abb2;
        font-size: .86rem;
    }

    .past_order_btn {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin-top: 20px;
    }

    .order-card-reorder {
        text-decoration: none;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        border: 1px solid #791313;
        background-color: #ffffff;
        color: #791313;
        line-height: 38px;
        height: 40px;
        font-weight: 600;
        border-radius: 2px;
        width: calc(50% - 16px) !important;
        margin-right: 16px;
    }

    .order-card-reorder:last-child {
        text-decoration: none;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        border: 1px solid #282c3f;
        background-color: #000;
        color: #282c3f;
        line-height: 38px;
        height: 40px;
        font-weight: 600;
        margin-right: 0;
    }

    .logout_wrapper {
        font-size: 1.07rem;
        font-weight: 600;
        position: relative;
        overflow: hidden;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding: 16px;
        background-color: #edecec;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        text-transform: uppercase;
    }

    .logout_wrapper_heading {
        width: 100%;
    }

    .logout_icon {
        font-size: .86rem;
        font-weight: 600;
        color: #93959f;

    }

    .logout_icon::before {
        content: "\f107";
        transition: all 0.5s;
        font-size: 18px;
        font-family: 'Font Awesome 5 Free';
        z-index: 99;
        opacity: 1;
        transition: all 0.5s;
        display: block;
        font-weight: 900;
    }
}

@media only screen and (max-width: 575px) {
    .address_list {
        flex-direction: column;
        margin-top: 0px;

    }

    .address_box {
        border: 0px;
        border-bottom: 1px dashed #7e808c;
        padding: 10px 0px;
    }

    .accordion_address_wrapper {
        position: relative;
        overflow: hidden;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        position: relative;
        overflow: hidden;
        border: 0;
        outline: 0;
        background: inherit;
        width: 100%;
    }

    .accordion_address_container {
        width: 100%;
    }

    .accordion_inner_body {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 1rem;
        font-weight: 500;
    }

    .accordion_icon_box {
        margin-right: 16px;
        font-size: 16px;
        width: 16px;
        text-align: center;
    }

    .accordion_body_heading {
        font-size: 1.1rem;
        margin: 0px;
        text-align: center;
    }

    .icon-downArrow {
        font-size: 16px;
        font-weight: 600;

    }

    .past-orders-id {
        font-size: 20px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 0 15px;
        letter-spacing: .8px;
        font-family: 'Mulish', sans-serif;
    }

    .order_card {
        background-color: #ffffff;
        border: 1px solid rgba(2, 6, 12, 0.15)
    }

    .inner_card_container {
        overflow: hidden;
        position: relative;
        padding: 20px 16px;
    }

    .order_card_header {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .order_card_heading {
        font-size: 1.07rem;
        font-weight: 500;
        font-family: 'Mulish', sans-serif;
    }

    .order_status_container {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .order_status {
        font-size: .86rem;
        color: #3d4152;
        text-transform: capitalize;
        line-height: 11px;
    }

    .tickSharp {
        margin-left: 5px;
    }

    .order_card_subheading {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        color: #93959f;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-top: 4px;
        margin-bottom: 3px;
    }

    .order_subtitle {
        font-size: .86rem;
        font-weight: 400;
        text-transform: capitalize;
    }

    .order_card_price {
        font-size: .93rem;
        margin-right: 8px;
        color: #7e808c;
    }

    .past_order_content {
        border-top: 1px dashed #d4d5d9;
        padding-bottom: 30px;
    }

    .past_order_item {
        margin-top: 20px;
        color: #7e808c;
        font-size: .93rem;
    }

    .past_order_time {
        margin-top: 8px;
        color: #a9abb2;
        font-size: .86rem;
    }

    .past_order_btn {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin-top: 20px;
    }

    .order-card-reorder {
        text-decoration: none;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        border: 1px solid #791313;
        background-color: #ffffff;
        color: #791313;
        line-height: 38px;
        height: 40px;
        font-weight: 600;
        border-radius: 2px;
        width: calc(50% - 16px) !important;
        margin-right: 16px;
    }

    .order-card-reorder:last-child {
        text-decoration: none;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        border: 1px solid #282c3f;
        background-color: #ffffff;
        color: #282c3f;
        line-height: 38px;
        height: 40px;
        font-weight: 600;
        margin-right: 0;
    }

    .logout_wrapper {
        font-size: 1.07rem;
        font-weight: 600;
        position: relative;
        overflow: hidden;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding: 16px;
        background-color: #edecec;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        text-transform: uppercase;
    }

    .logout_wrapper_heading {
        width: 100%;
    }

    .logout_icon {
        font-size: .86rem;
        font-weight: 600;
        color: #93959f;

    }

    .logout_icon::before {
        content: "\f107";
        transition: all 0.5s;
        font-size: 18px;
        font-family: 'Font Awesome 5 Free';
        z-index: 99;
        opacity: 1;
        transition: all 0.5s;
        display: block;
        font-weight: 900;
    }
}

.account_user_details {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 12px 0px;

    .account_user_name {
        font-size: 18px;
        font-weight: 600;
        color: #000;
        text-transform: capitalize;
        font-family: inherit;
    }

    .account_user_secondar_heading {
        color: #7e808c;
        margin-top: 2px;
        font-size: 14px;
        line-height: 1.3;
        font-family: inherit;
    }
}


.profile_img {
    width: 48px;
    height: 48px;
}

.profile_items_container {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ececec;
    margin: 0px 10px;

    .profile_item_icon {
        width: 24px;
        height: 24px;
    }

    .profile_item_subtitle {
        font-size: 14px;
        line-height: 18px;
        padding: 1.25rem 0.75rem;
        font-weight: 500;
        color: #000;
        width: 100%;
    }
}

// MOBILE LAYOUT ORDERS PAGE
.mb_orders_container {
    background: #efefef;

    .mb_past_order_list {
        margin: 10px 0px;
        border: 0.1px solid #e2e2e4;
        padding: 15px 10px;
        border-radius: 7px;
        background-color: #fff;

        .mb_first_row {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .mb_order_id {
                color: #000;
                font-size: 14px;
                font-weight: 600;
                font-family: 'Mulish', sans-serif;
            }
            .mb_order_status {
                color: var(--primary);
                font-size: 12px;
                font-weight: 800;
                border-radius: 5px;
                font-family: 'Mulish', sans-serif;
            }
        }
        

        .mb_past_order_wrapper {
            padding: 15px 0px 10px;
        }

        .mb_order_shop {
            display: flex;

            img {
                width: 100px;
                height: 80px;
            }
        }

        .order_history_shop_details {
            padding-left: 5px;
            .store_name {
                font-family: 'Mulish', sans-serif;
                font-size: 16px;
                font-weight: 700;
            }

            .store_address_max {
                max-width: 370px;
            }

            .store_address {
                font-family: 'Mulish', sans-serif;
                font-size: 12px;
                font-weight: 500;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
            }
        }
        .order_item_product {
            display: flex;
            justify-content: space-between;
            padding: 0.35rem 0px;

            .mb_order_item_pr {
                font-size: 12px;
                font-family: inherit;
                text-transform: capitalize;
                font-weight: 500;
                color: #000;
            }
            .mb_order_item_qty {
                color: #757575;
                font-size: 11px;
                font-weight: 400;
            }
            .mb_order_item_price {
                font-weight: 700;
                font-size: 12px;
                font-family: inherit;
                color: #000;

                &::before {
                    content: "\20B9";
                }
            }
        }
        
    }
}