.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #AEAED5;
}

.form-control,
.wp-block-categories-dropdown select,
.wp-block-archives-dropdown select {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  color: var(--bs-body-color);
  background-color: var(--bs-form-control-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

  .form-control,
  .wp-block-categories-dropdown select,
  .wp-block-archives-dropdown select {
    transition: none;
  }
}

.form-control[type="file"],
.wp-block-categories-dropdown select[type="file"],
.wp-block-archives-dropdown select[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]),
.wp-block-categories-dropdown select[type="file"]:not(:disabled):not([readonly]),
.wp-block-archives-dropdown select[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus,
.wp-block-categories-dropdown select:focus,
.wp-block-archives-dropdown select:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-form-control-bg);
  border-color: #bed3a0;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(125, 166, 64, 0.25);
}

.form-control::-webkit-date-and-time-value,
.wp-block-categories-dropdown select::-webkit-date-and-time-value,
.wp-block-archives-dropdown select::-webkit-date-and-time-value {
  height: 1.6em;
}

.form-control::-webkit-datetime-edit,
.wp-block-categories-dropdown select::-webkit-datetime-edit,
.wp-block-archives-dropdown select::-webkit-datetime-edit {
  display: block;
  padding: 0;
}

.form-control::placeholder,
.wp-block-categories-dropdown select::placeholder,
.wp-block-archives-dropdown select::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}

.form-control:disabled,
.wp-block-categories-dropdown select:disabled,
.wp-block-archives-dropdown select:disabled {
  background-color: var(--bs-form-control-disabled-bg);
  opacity: 1;
  cursor: not-allowed;
}

.form-control::file-selector-button,
.wp-block-categories-dropdown select::file-selector-button,
.wp-block-archives-dropdown select::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

  .form-control::file-selector-button,
  .wp-block-categories-dropdown select::file-selector-button,
  .wp-block-archives-dropdown select::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button,
.wp-block-categories-dropdown select:hover:not(:disabled):not([readonly])::file-selector-button,
.wp-block-archives-dropdown select:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.6;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.82031rem;
  border-radius: 0.75rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1.17188rem;
  border-radius: 1rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.25rem;
}

.form-control-color::-webkit-color-swatch {
  border-radius: 0.25rem;
}

.form-select,
select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  color: var(--bs-body-color);
  background-color: var(--bs-form-control-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {

  .form-select,
  select {
    transition: none;
  }
}

.form-select:focus,
select:focus {
  border-color: #bed3a0;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(125, 166, 64, 0.25);
}

.form-select[multiple],
select[multiple],
.form-select[size]:not([size="1"]),
select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled,
select:disabled {
  background-color: var(--bs-form-control-disabled-bg);
}

.form-select:-moz-focusring,
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.82031rem;
  border-radius: 0.75rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.17188rem;
  border-radius: 1rem;
}


.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-form-control-bg);
  width: 1em;
  height: 1em;
  vertical-align: top;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  print-color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #bed3a0;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(125, 166, 64, 0.25);
}

.form-check-input:checked {
  background-color: #791313;
  border-color: #791313;
}

.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #791313;
  border-color: #791313;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23bed3a0'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}

.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled]+.btn,
.btn-check[disabled]+.wp-block-button__link,
.btn-check:disabled+.btn,
.btn-check:disabled+.wp-block-button__link {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}


.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0rem rgba(125, 166, 64, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0rem rgba(125, 166, 64, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #791313;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #d8e4c6;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #791313;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #d8e4c6;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}

.form-floating::before:not(.form-control:disabled) {
  position: absolute;
  top: var(--bs-border-width);
  left: var(--bs-border-width);
  width: calc(100% - (calc(calc(0.4em + 0.1875rem) + calc(0.8em + 0.375rem))));
  height: 1.875em;
  content: "";
  background-color: var(--bs-form-control-bg);
  border-radius: 0.25rem;
}


.form-floating>label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating>label {
    transition: none;
  }
}

.form-floating>.form-control,
.wp-block-categories-dropdown .form-floating>select,
.wp-block-archives-dropdown .form-floating>select,
.form-floating>.form-control-plaintext {
  padding: 1rem 0.75rem;
}

.form-floating>.form-control::placeholder,
.wp-block-categories-dropdown .form-floating>select::placeholder,
.wp-block-archives-dropdown .form-floating>select::placeholder,
.form-floating>.form-control-plaintext::placeholder {
  color: transparent;
}

.form-floating>.form-control:focus,
.wp-block-categories-dropdown .form-floating>select:focus,
.wp-block-archives-dropdown .form-floating>select:focus,
.form-floating>.form-control:not(:placeholder-shown),
.wp-block-categories-dropdown .form-floating>select:not(:placeholder-shown),
.wp-block-archives-dropdown .form-floating>select:not(:placeholder-shown),
.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:-webkit-autofill,
.wp-block-categories-dropdown .form-floating>select:-webkit-autofill,
.wp-block-archives-dropdown .form-floating>select:-webkit-autofill,
.form-floating>.form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-select,
.form-floating>select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:focus~label,
.wp-block-categories-dropdown .form-floating>select:focus~label,
.wp-block-archives-dropdown .form-floating>select:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.wp-block-categories-dropdown .form-floating>select:not(:placeholder-shown)~label,
.wp-block-archives-dropdown .form-floating>select:not(:placeholder-shown)~label,
.form-floating>.form-control-plaintext~label,
.form-floating>.form-select~label,
.form-floating>select~label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control:-webkit-autofill~label,
.wp-block-categories-dropdown .form-floating>select:-webkit-autofill~label,
.wp-block-archives-dropdown .form-floating>select:-webkit-autofill~label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control-plaintext~label {
  border-width: var(--bs-border-width) 0;
}

.form-floating>.form-control:disabled~label,
.wp-block-categories-dropdown .form-floating>select:disabled~label,
.wp-block-archives-dropdown .form-floating>select:disabled~label {
  color: #6c757d;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
  font-size: 12px;
}

.placeholder.btn::before,
.placeholder.wp-block-button__link::before {
  display: inline-block;
  content: "";
  font-size: 12px;
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}

// .clearfix::after {
//   display: block;
//   clear: both;
//   content: "";
// }

.text-bg-primary {
  color: #000 !important;
  background-color: RGBA(125, 166, 64, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(254, 159, 16, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(19, 194, 77, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(0, 174, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 189, 19, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(255, 0, 3, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(250, 248, 242, var(--bs-bg-opacity, 1)) !important;
}


.link-primary {
  color: #791313 !important;
}

.link-primary:hover,
.link-primary:focus {
  color: #97b866 !important;
}

.link-secondary {
  color: #FE9F10 !important;
}

.link-secondary:hover,
.link-secondary:focus {
  color: #feb240 !important;
}

.link-success {
  color: #13c24d !important;
}

.link-success:hover,
.link-success:focus {
  color: #42ce71 !important;
}

.link-info {
  color: #00aeff !important;
}

.link-info:hover,
.link-info:focus {
  color: #33beff !important;
}

.link-warning {
  color: #FFBD13 !important;
}

.link-warning:hover,
.link-warning:focus {
  color: #ffca42 !important;
}

.link-danger {
  color: #ff0003 !important;
}

.link-danger:hover,
.link-danger:focus {
  color: #ff3335 !important;
}

.link-light {
  color: #faf8f2 !important;
}

.link-light:hover,
.link-light:focus {
  color: #fbf9f5 !important;
}

.link-dark {
  color: #222222 !important;
}

.link-dark:hover,
.link-dark:focus {
  color: #1b1b1b !important;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio>* {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.form-label {
  font-size: 14px;
  font-weight: 600;
  color: #191919;
  margin-bottom: 10px;
  background: transparent;
  border-color: #E1E1F0;
  font-family: "Mulish", sans-serif;
}

.form-group {
  position: relative;
}

.form-group input[type='password'] {
  padding-right: 50px;
}

.without_validation {
  position: absolute;
  top: 38px;
  right: 0px;
  bottom: 0;
  height: 100%;
  border: none;
  height: 100%;
  max-height: 35px;
  background: transparent;
  outline: none;
  cursor: pointer;
}

.without_validation:active {
  background-color: transparent;
}

.without_validation:active {
  background-color: transparent;
}

.validation:active {
  background-color: transparent;
}

.without_validation i {
  color: #89879f;
  text-align: center;
}

.show-pass .fa-eye {
  display: none;
}

.show-pass.active .fa-eye {
  display: inline-block;
}

.show-pass.active .fa-eye-slash {
  display: none;
}

.text-left {
  text-align: left;
}

input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=url],
textarea,
.form-control,
.wp-block-categories-dropdown select,
.wp-block-archives-dropdown select {
  height: 39px;
  border: 1px solid #dddddd;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  transition: all 0.3s ease-in-out;
  background: #fff;
  font-family: 'Mulish', sans-serif;
  border-radius: var(--border-radius-base);
  width: 100%;
}

.mobile_number_input {
  padding: 10px 75px !important;
}

input[type=email] .select,
input[type=number] .select,
input[type=password] .select,
input[type=search] .select,
input[type=tel] .select,
input[type=text] .select,
input[type=url] .select,
textarea .select,
.form-control .select,
.wp-block-categories-dropdown select .select,
.wp-block-archives-dropdown select .select {
  padding: 12px 0;
}

input[type=email] span,
input[type=number] span,
input[type=password] span,
input[type=search] span,
input[type=tel] span,
input[type=text] span,
input[type=url] span,
textarea span,
.form-control span,
.wp-block-categories-dropdown select span,
.wp-block-archives-dropdown select span {
  margin-top: 0;
}

input[type=email]::-webkit-input-placeholder,
input[type=number]::-webkit-input-placeholder,
input[type=password]::-webkit-input-placeholder,
input[type=search]::-webkit-input-placeholder,
input[type=tel]::-webkit-input-placeholder,
input[type=text]::-webkit-input-placeholder,
input[type=url]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder,
.wp-block-categories-dropdown select::-webkit-input-placeholder,
.wp-block-archives-dropdown select::-webkit-input-placeholder {
  color: #666666;
  font-size: 12px;
}

input[type=email]:-ms-input-placeholder,
input[type=number]:-ms-input-placeholder,
input[type=password]:-ms-input-placeholder,
input[type=search]:-ms-input-placeholder,
input[type=tel]:-ms-input-placeholder,
input[type=text]:-ms-input-placeholder,
input[type=url]:-ms-input-placeholder,
textarea:-ms-input-placeholder,
.form-control:-ms-input-placeholder,
.wp-block-categories-dropdown select:-ms-input-placeholder,
.wp-block-archives-dropdown select:-ms-input-placeholder {
  color: #666666;
  font-size: 12px;
}

input[type=email]::placeholder,
input[type=number]::placeholder,
input[type=password]::placeholder,
input[type=search]::placeholder,
input[type=tel]::placeholder,
input[type=text]::placeholder,
input[type=url]::placeholder,
textarea::placeholder,
.form-control::placeholder,
.wp-block-categories-dropdown select::placeholder,
.wp-block-archives-dropdown select::placeholder {
  color: #666666;
  font-size: 12px;
}

input[type=email]:focus,
input[type=email]:active,
input[type=email].active,
input[type=number]:focus,
input[type=number]:active,
input[type=number].active,
input[type=password]:focus,
input[type=password]:active,
input[type=password].active,
input[type=search]:focus,
input[type=search]:active,
input[type=search].active,
input[type=tel]:focus,
input[type=tel]:active,
input[type=tel].active,
input[type=text]:focus,
input[type=text]:active,
input[type=text].active,
input[type=url]:focus,
input[type=url]:active,
input[type=url].active,
textarea:focus,
textarea:active,
textarea.active,
.form-control:focus,
.wp-block-categories-dropdown select:focus,
.wp-block-archives-dropdown select:focus,
.form-control:active,
.wp-block-categories-dropdown select:active,
.wp-block-archives-dropdown select:active,
.form-control.active,
.wp-block-categories-dropdown select.active,
.wp-block-archives-dropdown select.active {
  border-color: #791313;
}


input:-internal-autofill-selected {
  background: white !important;
  background-image: none !important;
  color: -internal-light-dark-color(black, white) !important;
}

.input-group-text {
  margin-bottom: 0px !important;
  border-color: transparent;
  z-index: 10;
  position: absolute;

  img {
    width: 30px;
    border-radius: 3px;
  }

  p {
    margin: 0px;
    padding: 0px 0px 0px 5px;
    font-size: 14px;
    color: #666666;
    font-weight: 400;
    font-family: 'Mulish', sans-serif;
  }
}

.toggle {
  cursor: pointer;
  display: block;
}

.toggle-switch {
  display: inline-block;
  background: #f7f7f7;
  border-radius: 16px;
  width: 45px;
  height: 20px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}

.toggle-switch::before,
.toggle-switch::after {
  content: "";
}

.toggle-switch::before {
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  border-radius: 50%;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 1px;
  left: 3px;
  transition: left 0.25s;
}

.toggle:hover .toggle-switch::before {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
}

.toggle-checkbox:checked+.toggle-switch {
  background: #791313;
}

.toggle-checkbox:checked+.toggle-switch::before {
  left: 26px;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-label {
  margin-left: 15px;
  position: relative;
  top: 2px;
  font-size: 16px;
  font-weight: 400;
}

.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  border: 1px solid #E1E1F0;
  border-radius: 5px;
  color: #AEAED5;
}

.file-upload-wrapper::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  padding: 4px 15px;
  display: block;
  width: calc(100% - 40px);
  pointer-events: none;
  z-index: 20;
  height: calc(1.5em + 1rem + 2px);
  line-height: 30px;
  border-radius: 5px 10px 10px 5px;
  font-weight: 400;
  overflow: hidden;
}

.file-upload-wrapper::before {
  content: 'Upload';
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  height: calc(1.5em + 1rem + 2px);
  background: #791313;
  color: #000;
  font-weight: 400;
  z-index: 25;
  font-size: 14px;
  line-height: 40px;
  padding: 0 15px;
  text-transform: capitalize;
  pointer-events: none;
  border-radius: 0 5px 5px 0;
}

.file-upload-wrapper:hover::before {
  background: #618132;
}

.file-upload-wrapper input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: calc(1.5em + 1rem + 2px);
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

#ui-datepicker-div {
  display: none;
  background: #fafafa;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  margin-top: 0.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.ui-datepicker-calendar thead th {
  padding: 0.25rem 0;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
  color: #666666;
}

.ui-datepicker-calendar tbody td {
  width: 2.5rem;
  text-align: center;
  padding: 0;
}

.ui-datepicker-calendar tbody td a {
  display: block;
  border-radius: 0.25rem;
  line-height: 2rem;
  transition: 0.3s all;
  color: #666666;
  font-size: 0.875rem;
  text-decoration: none;
}

.ui-datepicker-calendar tbody td a:hover {
  background-color: #791313;
  color: #000;
}

.ui-datepicker-calendar tbody td a.ui-state-active {
  background-color: #791313;
  color: #000;
}

.ui-datepicker-header a.ui-corner-all {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  transition: 0.3s all;
}

.ui-datepicker-header a.ui-corner-all:hover {
  background-color: #c5dca3;
  color: #000;
}

.ui-datepicker-header a.ui-datepicker-prev {
  left: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 0.5rem;
  background-position: 50%;
  transform: rotate(180deg);
}

.ui-datepicker-header a.ui-datepicker-next {
  right: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 50%;
}

.ui-datepicker-header a>span {
  display: none;
}

.ui-datepicker-title {
  text-align: center;
  line-height: 2rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding-bottom: 0.25rem;
}

.ui-datepicker-week-col {
  color: #666666;
  font-weight: 400;
  font-size: 0.75rem;
}

label.error {
  color: #ff0003;
  position: absolute;
  bottom: 0;
  margin-bottom: -22px;
  font-size: 12px;
  font-weight: 400;
}

.nice-select {
  display: flex;
  align-items: center;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.nice-select ul {
  width: 100% !important;
}

.nice-select .list {
  background: #222222;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #222222;
  color: #791313;
}

.form-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn),
select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.form-select,
select {
  border: 1px solid transparent;
}

.form-select .dropdown-toggle,
select .dropdown-toggle {
  border: 0 !important;
  padding: 0;
  font-weight: normal;
  text-transform: unset;
  outline: none;
  background: none;
  font-size: 18px;
  line-height: 40px;
}

.form-select .dropdown-toggle:focus,
select .dropdown-toggle:focus,
.form-select .dropdown-toggle:hover,
select .dropdown-toggle:hover {
  outline: 0 !important;
  outline-offset: 0;
  background: none;
}

.form-select .dropdown-toggle::after,
select .dropdown-toggle::after {
  content: none;
}

.form-select.show,
select.show {
  box-shadow: none !important;
  outline: 0px !important;
}

.form-select.show .dropdown-toggle,
select.show .dropdown-toggle {
  box-shadow: none !important;
  outline: 0px !important;
}

.form-select.show.btn-group .dropdown-menu.inner,
select.show.btn-group .dropdown-menu.inner {
  display: block;
}

.form-select .dropdown-menu,
select .dropdown-menu {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  margin-top: -1px;
  padding: 10px 0;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  font-size: 14px;
}

.form-select .dropdown-menu ul li,
select .dropdown-menu ul li {
  padding: 0;
}

.form-select .dropdown-menu ul li a,
select .dropdown-menu ul li a {
  display: block;
  padding: 8px 20px;
  clear: both;
  font-weight: 400;
  color: #333;
  white-space: nowrap;
}

.form-select .dropdown-menu ul li a img,
select .dropdown-menu ul li a img {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  margin-right: 5px;
}

.form-select .dropdown-menu ul li a:hover,
select .dropdown-menu ul li a:hover {
  background-color: #f2f2f4;
}

.form-select .dropdown-menu ul li.selected a,
select .dropdown-menu ul li.selected a {
  background-color: #791313;
  color: #000;
}

.form-select:focus,
select:focus,
.form-select:active,
select:active,
.form-select.active,
select.active {
  border-color: #791313;
}

.custom-file-label {
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  height: 45px;
  border-radius: 30px !important;
  padding: 10px 20px;
  font-size: 15px;
  border-color: transparent;
}

.custom-file-label::after {
  content: none !important;
}

.input-line {
  border-bottom: 1px solid #fff;
  align-items: center;
}

.input-line .form-control,
.input-line .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .input-line select,
.input-line .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .input-line select {
  padding: 0;
  height: 48px !important;
  line-height: 24px;
  padding-bottom: 10px;
  font-size: 18px;
  border: none;
  border-radius: 0;
  color: #000;
  font-weight: 400;
  background-color: transparent;
}

.input-line textarea::placeholder {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #000;
}

.input-line .form-control::placeholder,
.input-line .wp-block-categories-dropdown select::placeholder,
.wp-block-categories-dropdown .input-line select::placeholder,
.input-line .wp-block-archives-dropdown select::placeholder,
.wp-block-archives-dropdown .input-line select::placeholder {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #000;
}

.input-line .default-select {
  background: transparent;
  border: 0;
  border-radius: 0;
  height: 48px !important;
  padding-bottom: 10px;
  background-color: transparent;
  color: #000;
  font-weight: 400;
  background-image: none;
  position: relative;
  font-size: 18px;
  line-height: 24px;
  padding: 0;
}

.input-line .default-select .btn.show,
.input-line .default-select .show.wp-block-button__link {
  color: #000;
}

.input-line .default-select select {
  display: none !important;
}

.input-line .default-select:active,
.input-line .default-select:hover,
.input-line .default-select:focus {
  background-color: transparent;
  border-color: transparent;
}

.input-line .input-group-prepand {
  width: 35px;
  height: 50px;
  line-height: 40px;
  position: absolute;
  left: 0;
}

.input-line .input-group-prepand i {
  font-size: 24px;
  color: #000;
}

.input-line .input-group-prepand+.default-select {
  padding: 0 0 0px 40px;
}

.input-line .input-group-prepand+.form-control,
.input-line .wp-block-categories-dropdown .input-group-prepand+select,
.wp-block-categories-dropdown .input-line .input-group-prepand+select,
.input-line .wp-block-archives-dropdown .input-group-prepand+select,
.wp-block-archives-dropdown .input-line .input-group-prepand+select {
  padding-left: 40px;
}

.input-line.input-black::before,
.input-line.input-black::after {
  content: "";
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 25px;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.input-line.input-black::after {
  width: 100%;
     background-color: #ffffff;;
  opacity: 0.1;
  box-shadow: 10px 10px 20px 0 rgba(0, 7, 66, 0.2);
}

.input-line.input-black::before {
  width: 0;
     background-color: #ffffff;;
  transform: translateX(-50%);
  left: 50%;
}

.input-line.input-black:hover::before,
.input-line.input-black:focus::before,
.input-line.input-black:active::before,
.input-line.input-black.focused::before {
  width: 100%;
}

.input-line.input-black .form-control,
.input-line.input-black .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .input-line.input-black select,
.input-line.input-black .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .input-line.input-black select {
  color: #222;
  border-color: #222222;
}

.input-line.input-black .form-control::placeholder,
.input-line.input-black .wp-block-categories-dropdown select::placeholder,
.wp-block-categories-dropdown .input-line.input-black select::placeholder,
.input-line.input-black .wp-block-archives-dropdown select::placeholder,
.wp-block-archives-dropdown .input-line.input-black select::placeholder {
  color: #222;
}

.input-line.input-black textarea {
  height: 105px !important;
}

.input-line.input-black .default-select {
  color: #000;
  border-bottom: 1px solid #222222 !important;
  border-radius: 0;
}

.input-line.input-black .default-select button {
  color: #222 !important;
  border-bottom: none !important;
}

.input-line.input-black .default-select:option {
  color: #222 !important;
}

.input-line .input-group-prepand+.bootstrap-select .dropdown-toggle {
  padding: 0 0 0 40px;
}

.input-line .bootstrap-select {
  padding: 0 !important;
}

.passowrd_icon {
  font-size: 15px !important;
}