.menu-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-prev-long,
.btn-next-long {
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  text-align: center;

}

.btn-prev-long i,
.btn-next-long i {
  color: #000;
  // font-size: 16px;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  text-align: center;
}

.btn-prev-long::before,
.btn-prev-long::after,
.btn-next-long::before,
.btn-next-long::after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.btn-prev-long::before,
.btn-next-long::before {
  width: 30px;
  border-radius: 50px;
  height: 30px;
  line-height: 30px;
  background-color: var(--primary);
}

.btn-prev-long::after,
.btn-next-long::after {
  width: 40px;
  height: 2px;
     background-color: #ffffff;;
}

.btn-prev-long:not(.swiper-button-disabled) i,
.btn-next-long:not(.swiper-button-disabled) i {
  color: var(--primary);
}

.btn-prev-long.disabled i,
.btn-next-long.disabled i {
  color: #ccc;
}

.btn-prev-long:not(.swiper-button-disabled)::after,
.btn-next-long:not(.swiper-button-disabled)::after {
  z-index: 1;
  width: 10px;
  background-color: var(--primary);
  display: none;
}

.btn-prev-long:not(.swiper-button-disabled)::before,
.btn-next-long:not(.swiper-button-disabled)::before {
  border: 1.5px dashed var(--primary);
  background-color: transparent;
  height: 30px;
  width: 30px;
  line-height: 50px;
}

.btn-prev-long::before {
  left: -7px;
}

.btn-prev-long::after {
  left: 10px;
  z-index: -2;
}

.btn-prev-long:hover:not(.swiper-button-disabled) i {
  margin-left: 4px;
}

.btn-next-long::before {
  right: -8px;
  left: auto;
}

.btn-next-long::after {
  right: 10px;
  left: auto;
  z-index: -2;
}

.btn-next-long:hover:not(.swiper-button-disabled) i {
  margin-right: 4px;
}

.swiper-btn-lr {
  position: relative;
}

.swiper-btn-lr .btn-prev-long,
.swiper-btn-lr .btn-next-long,
.swiper-btn-lr .btn-prev,
.swiper-btn-lr .btn-next {
  position: static;
  transform: translate(0);
  margin: 0px 19px 0;
}

.swiper-btn-lr .btn-prev-long,
.swiper-btn-lr .btn-prev {
  position: static;
  transform: translate(0);
  margin: 0px 19px 0;
}

@media only screen and (max-width: 1480px) {

  .swiper-btn-lr .btn-prev-long,
  .swiper-btn-lr .btn-prev {
    position: static;
    transform: translate(0);
    margin: 0px 19px 0;
  }

  .swiper-btn-lr .btn-next-long,
  .swiper-btn-lr .btn-next {
    position: static;
    transform: translate(0);
    margin: 0px 19px 0;
  }
}

@media only screen and (max-width: 1280px) {

  .swiper-btn-lr .btn-prev-long,
  .swiper-btn-lr .btn-next-long {
    position: static;
    transform: translate(0);
    margin: 0px 19px 0;
  }
}

.swiper-btn-lr .btn-prev-1,
.swiper-btn-lr .btn-next-1 {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  right: 0;
  background-color: rgba(34, 34, 34, 0.1);
  border-radius: var(--border-radius-base);
  height: 80px;
  width: 27px;
  line-height: 80px;
  text-align: center;
  color: #222222;
  font-size: 14px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.swiper-btn-lr .btn-prev-1:hover,
.swiper-btn-lr .btn-next-1:hover {
  background-color: var(--primary);
  color: #000;
}

.swiper-btn-lr .btn-prev-1 {
  left: 0;
  right: auto;
}

.widget-title,
.comments-title,
.comment-reply-title {
  margin-bottom: 25px;
  position: relative;
  font-size: 1.25rem;
  padding-bottom: 12px;
}

@media only screen and (max-width: 1200px) {

  .widget-title,
  .comments-title,
  .comment-reply-title {
    margin-bottom: 20px;
  }
}

.widget-title:after,
.widget-title:before,
.comments-title:after,
.comments-title:before,
.comment-reply-title:after,
.comment-reply-title:before {
  content: '';
  position: absolute;
  bottom: 0;
  height: 6px;
  border-radius: 3px;
}

.widget-title:after,
.comments-title:after,
.comment-reply-title:after {
  left: 12px;
  width: 53px;
  height: 4px;
  background: var(--primary);
}

.widget-title:before,
.comments-title:before,
.comment-reply-title:before {
  left: 0;
  width: 6px;
  border-radius: 30px;
  background: #791313;
}

.widget-title .title,
.comments-title .title,
.comment-reply-title .title {
  position: relative;
  margin-bottom: 0px;
  font-size: 1.25rem;
}

.comments-title,
.comment-reply-title {
  margin-bottom: 30px;
}

.comment-reply-para {
  margin-bottom: 25px;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .comment-reply-para {
    margin-bottom: 30px;
  }
}

.search-bx form {
  margin-bottom: 10px;
}

.search-bx .form-control,
.search-bx .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .search-bx select,
.search-bx .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .search-bx select {
  background-color: #000;
  border: 0;
  border-radius: 6px;
  z-index: 0 !important;
  height: 50px;
  padding: 6px 0px 6px 20px;
}

.search-bx .input-side {
  background-color: var(--primary);
  border-radius: 6px;
  display: flex;
  margin: 0;
  padding: 5px;
  align-items: center;
  width: 100%;
  height: 60px;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.search-bx .input-group-btn .btn,
.search-bx .input-group-btn .wp-block-button__link {
  margin-left: 5px;
  height: 50px;
  width: 50px;
  padding: 0;
  justify-content: center;
  min-width: 50px;
}

.search-bx .input-group-btn .btn i,
.search-bx .input-group-btn .wp-block-button__link i {
  font-size: 21px;
}

.search-bx .input-group-btn .btn:active,
.search-bx .input-group-btn .wp-block-button__link:active {
  background-color: transparent;
  border-color: transparent;
}

.search-bx .input-group-btn .btn:hover,
.search-bx .input-group-btn .wp-block-button__link:hover {
  background-color: var(--primary);
  border-color: transparent;
}

.search-bx.style-1 form .input-group {
  border: 1px solid #ddd;
}

.search-bx.style-1 form .input-side {
  border-radius: 0 !important;
  background-color: transparent;
  display: flex;
  margin: 0;
}

.search-bx.style-1 form .input-group-btn {
  position: absolute;
  right: 3px;
  top: 2px;
}

.search-bx.style-1 form .input-group-btn button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: 0;
  font-size: 15px;
  height: 45px;
  width: 60px;
  border-width: 0;
}

.search-bx.style-1 form .form-control,
.search-bx.style-1 form .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .search-bx.style-1 form select,
.search-bx.style-1 form .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .search-bx.style-1 form select {
  border: 1px solid #efefef;
  border-width: 0;
  font-size: 15px;
  height: 45px;
  padding: 5px 20px;
}

.download-file {
  background-image: var(--gradient);
  color: #000;
  padding: 25px 25px 20px 20px;
  border-radius: 4px;
}

.download-file .title {
  color: #000;
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.download-file ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.download-file ul li a {
  background-color: #000;
  color: #000;
  display: block;
  margin-bottom: 10px;
  border-radius: 4px;
  width: 100%;
  padding: 15px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.download-file ul li a i {
  width: 18px;
  height: 18px;
  position: relative;
}

.download-file ul li a i::after,
.download-file ul li a i::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
}

.download-file ul li a i::before {
  -webkit-animation: download1 2s ease infinite;
  animation: download1 2s ease infinite;
}

.download-file ul li a .text {
  display: inline;
  position: relative;
}

.download-file ul li a:hover .text {
  color: var(--primary);
}

@-webkit-keyframes download1 {

  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes download1 {

  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

.widget_contact {
  position: relative;
  padding: 50px 30px;
  border-radius: 6px;
  background-size: cover;
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.widget_contact::after {
  background: #791313;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  z-index: -1;
  background-size: 200%;
}

.widget_contact img {
  margin-bottom: 20px;
}

.widget_contact h4,
.widget_contact .h4,
.widget_contact .wp-block-search .wp-block-search__label,
.wp-block-search .widget_contact .wp-block-search__label {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 30px;
}

.widget_contact .phone-number {
  position: relative;
  font-size: 24px;
  color: #000;
  font-weight: 500;
  margin-bottom: 11px;
}

.widget_contact .phone-number a {
  color: #000;
}

.widget_contact .email {
  position: relative;
  font-size: 18px;
  color: #000;
  font-weight: 500;
  margin-bottom: 30px;
}

.widget_contact .email a {
  color: #000;
}

.list-2 {
  display: table;
}

.list-2 li {
  width: 50% !important;
  float: left;
}

@media only screen and (max-width: 1200px) {
  .list-2 li {
    width: 100% !important;
  }
}

@media only screen and (max-width: 991px) {
  .list-2 li {
    width: 50% !important;
  }
}

@media only screen and (max-width: 575px) {
  .list-2 li {
    width: 100% !important;
  }
}

.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list,
.widget_categories ul,
.widget_archive ul,
.widget_meta ul,
.widget_pages ul,
.widget_recent_comments ul,
.widget_nav_menu ul,
.widget_recent_entries ul,
.widget_services ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.wp-block-latest-posts li,
.wp-block-categories-list li,
.wp-block-archives-list li,
.widget_categories ul li,
.widget_archive ul li,
.widget_meta ul li,
.widget_pages ul li,
.widget_recent_comments ul li,
.widget_nav_menu ul li,
.widget_recent_entries ul li,
.widget_services ul li {
  padding-bottom: 1.125rem;
  margin-bottom: 0.8125rem;
  position: relative;
  padding: 10px 0 10px 20px;
  margin-bottom: 0;
  line-height: 1.25rem;
}

.wp-block-latest-posts li a,
.wp-block-categories-list li a,
.wp-block-archives-list li a,
.widget_categories ul li a,
.widget_archive ul li a,
.widget_meta ul li a,
.widget_pages ul li a,
.widget_recent_comments ul li a,
.widget_nav_menu ul li a,
.widget_recent_entries ul li a,
.widget_services ul li a {
  color: inherit;
  text-transform: capitalize;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  font-size: 15px;
  font-weight: 500;
}

.wp-block-latest-posts li a::before,
.wp-block-categories-list li a::before,
.wp-block-archives-list li a::before,
.widget_categories ul li a::before,
.widget_archive ul li a::before,
.widget_meta ul li a::before,
.widget_pages ul li a::before,
.widget_recent_comments ul li a::before,
.widget_nav_menu ul li a::before,
.widget_recent_entries ul li a::before,
.widget_services ul li a::before {
  content: "\f0da";
  font-family: "Font Awesome 5 Free";
  background: transparent;
  width: auto;
  height: auto;
  position: absolute;
  left: 0;
  top: 9px;
  font-size: 1rem;
  font-weight: 700;
  color: var(--primary);
}

.wp-block-latest-posts li a:hover,
.wp-block-categories-list li a:hover,
.wp-block-archives-list li a:hover,
.widget_categories ul li a:hover,
.widget_archive ul li a:hover,
.widget_meta ul li a:hover,
.widget_pages ul li a:hover,
.widget_recent_comments ul li a:hover,
.widget_nav_menu ul li a:hover,
.widget_recent_entries ul li a:hover,
.widget_services ul li a:hover {
  color: var(--primary);
}

.wp-block-latest-posts li li,
.wp-block-categories-list li li,
.wp-block-archives-list li li,
.widget_categories ul li li,
.widget_archive ul li li,
.widget_meta ul li li,
.widget_pages ul li li,
.widget_recent_comments ul li li,
.widget_nav_menu ul li li,
.widget_recent_entries ul li li,
.widget_services ul li li {
  border-bottom: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.375rem;
}

.wp-block-latest-posts li li:last-child,
.wp-block-categories-list li li:last-child,
.wp-block-archives-list li li:last-child,
.widget_categories ul li li:last-child,
.widget_archive ul li li:last-child,
.widget_meta ul li li:last-child,
.widget_pages ul li li:last-child,
.widget_recent_comments ul li li:last-child,
.widget_nav_menu ul li li:last-child,
.widget_recent_entries ul li li:last-child,
.widget_services ul li li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.wp-block-latest-posts li li::before,
.wp-block-categories-list li li::before,
.wp-block-archives-list li li::before,
.widget_categories ul li li::before,
.widget_archive ul li li::before,
.widget_meta ul li li::before,
.widget_pages ul li li::before,
.widget_recent_comments ul li li::before,
.widget_nav_menu ul li li::before,
.widget_recent_entries ul li li::before,
.widget_services ul li li::before {
  top: 0.5rem;
  left: 0;
}

.wp-block-latest-posts ul,
.wp-block-categories-list ul,
.wp-block-archives-list ul,
.widget_categories ul ul,
.widget_archive ul ul,
.widget_meta ul ul,
.widget_pages ul ul,
.widget_recent_comments ul ul,
.widget_nav_menu ul ul,
.widget_recent_entries ul ul,
.widget_services ul ul {
  padding-left: 1.125rem;
  margin-top: 0.5rem;
  margin-left: -0.9375rem;
}

.widget_archive li ul,
.wp-block-categories-list li ul,
.wp-block-archives-list li ul,
.wp-block-latest-posts li ul,
.widget_categories li ul {
  margin-bottom: -0.625rem;
}

.widget_archive ul li,
.wp-block-categories-list li,
.wp-block-archives-list li,
.wp-block-latest-posts li,
.widget_categories ul li {
  text-align: right;
  display: table;
  width: 100%;
  padding: 10px 0 10px 20px !important;
  line-height: 1.3 !important;
}

.wp-block-categories-list li::before,
.wp-block-archives-list li::before,
.wp-block-latest-posts li::before {
  content: none !important;
}

.widget_archive li a,
.wp-block-latest-posts li a,
.wp-block-categories-list li a,
.wp-block-archives-list li a,
.widget_categories li a {
  float: left;
  text-transform: capitalize;
  text-align: left;
}

.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list {
  padding: 0 !important;
}

.widget_recent_comments ul {
  margin-top: -0.625rem;
}

.widget_recent_comments ul li {
  padding-left: 1.875rem;
  line-height: 1.625rem;
}

.widget_recent_comments ul li a:hover {
  color: inherit;
}

.widget_recent_comments ul li a::before {
  content: none;
}

.widget_recent_comments ul li::before {
  content: "\f27a";
  font-family: "Font Awesome 5 Free";
  background: transparent;
  width: auto;
  height: auto;
  position: absolute;
  left: 0;
  top: 11px;
  font-size: 1.125rem;
  font-weight: 500;
}

.widget_meta ul li a abbr[title] {
  color: #333;
  border-bottom: none;
}

.widget_calendar th,
.widget_calendar td {
  text-align: center;
  border: 0.0625rem solid var(--rgba-primary-1);
  padding: 0.4em;
}

.widget_calendar th,
.widget_calendar tfoot td {
  border-color: rgba(0, 0, 0, 0.05);
}

.widget_calendar th:last-child,
.widget_calendar tfoot td:last-child {
  border: 0;
}

.widget_calendar tr {
  border-bottom: 0.0625rem solid var(--rgba-primary-1);
}

.widget_calendar tr:nth-child(2n+2) {
  background-color: var(--rgba-primary-05);
}

.widget_calendar table {
  border-collapse: collapse;
  margin: 0;
  width: 100%;
}

.widget_calendar .calendar_wrap th,
.widget_calendar tfoot td a {
  color: #000;
  background: var(--primary);
}

.widget_calendar .calendar_wrap td a {
  font-weight: 600;
  text-transform: uppercase;
  color: inherit;
}

.widget_calendar caption {
  text-align: center;
  font-weight: 600;
  caption-side: top;
  background: var(--primary);
  border: 2px solid #fff;
  border-width: 0 2px;
  color: #000;
  text-transform: uppercase;
  font-size: 0.9375rem;
}

.wp-block-calendar table th {
  font-weight: 400;
  background: var(--primary);
  color: #000;
}

.wp-block-calendar table caption {
  color: #000;
}

footer .widget_calendar th,
footer .widget_calendar td {
  border-color: rgba(255, 255, 255, 0.1);
}

footer .widget_calendar tr {
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
}

footer .widget_calendar tr:nth-child(2n+2) {
  background-color: rgba(255, 255, 255, 0.05);
}

.wp-calendar-nav {
  background: var(--primary);
  color: #000;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.wp-calendar-nav span {
  padding: 0.3125rem 1.25rem;
  width: 44%;
  border-right: 0.0625rem solid rgba(255, 255, 255, 0.2);
}

.wp-calendar-nav span a {
  color: #000;
}

.widget_archive select {
  width: 100%;
  padding: 0.3125rem;
  border: 0.0625rem solid #CCC;
}

.list-2-column ul {
  display: table;
}

.list-2-column ul li {
  width: 50%;
  float: left;
}

.widget_categories select,
.widget_archive select {
  width: 100%;
  border: 0.0625rem solid #CCC;
}

.widget_text select {
  width: 100%;
  border: 0.0625rem solid #CCC;
}

.widget_text select option {
  width: 100%;
}

.widget_text p {
  font-size: 0.9375rem;
  line-height: 1.75rem;
}

.rsswidget img {
  display: inherit;
}

.widget-title .rsswidget {
  display: inline-block;
}

ul a.rsswidget,
ul cite {
  font-weight: 600;
  color: var(--title);
}

ul a.rsswidget {
  font-size: 1.125rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

ul a.rsswidget:hover {
  color: var(--primary);
}

.rssSummary {
  margin: 1.25rem 0;
  line-height: 1.7;
  font-size: 15px;
}

.rss-date {
  display: inline-block;
  margin-top: 0.3125rem;
  color: var(--primary);
  font-weight: 400;
}

.widget_rss ul li {
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 0;
}

.widget_rss ul li:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.widget_gallery ul {
  padding-left: 0;
  display: inline-block;
}

.widget_gallery .gallery-grid-8 li {
  width: 12.5%;
}

@media only screen and (max-width: 767px) {
  .widget_gallery .gallery-grid-8 li {
    width: 25%;
  }
}

.widget_gallery.gallery-grid-2 li {
  width: 50%;
}

.widget_gallery.gallery-grid-4 li {
  width: 25%;
}

.widget_gallery li {
  display: inline-block;
  width: 33.33%;
  float: left;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  padding: 5px;
}

.widget_gallery li img {
  display: inline-block;
  width: 100%;
  border-radius: 5px;
}

.widget_gallery li:nth-child(3n-3) {
  margin-right: 0;
}

.widget_gallery li:hover {
  opacity: 0.7;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.widget_gallery li a {
  display: inline-block;
}

.widget_tag_cloud .tagcloud {
  clear: both;
  display: table;
}

.widget_tag_cloud .tagcloud a {
  position: relative;
  padding: 9px 15px;
  display: inline-block;
  margin: 0 10px 10px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  background: #fff;
  border-radius: 8px !important;
  color: #666666;
  border: 1px solid rgba(34, 34, 34, 0.1);
}

@media only screen and (max-width: 1200px) {
  .widget_tag_cloud .tagcloud a {
    padding: 6px 14px;
    margin: 0 8px 8px 0;
  }
}

.widget_tag_cloud .tagcloud a:hover {
  background: #ECECEC;
  border-color: #ECECEC;
}

.widget_tag_cloud .tagcloud a span {
  display: inline-block;
}

.recent-posts-entry .widget-post-bx .widget-post {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid rgba(34, 34, 34, 0.1);
  border-radius: 10px;
}

.recent-posts-entry .widget-post-bx .widget-post .dz-media {
  height: 80px;
  min-width: 100px;
  width: 100px;
  margin-right: 15px;
  border-radius: var(--border-radius-base);
}

@media only screen and (max-width: 1200px) {
  .recent-posts-entry .widget-post-bx .widget-post .dz-media {
    height: 80px;
    min-width: 80px;
    width: 80px;
  }
}

.recent-posts-entry .widget-post-bx .widget-post .dz-meta {
  margin-bottom: 0px;
}

.recent-posts-entry .widget-post-bx .widget-post .dz-meta ul {
  line-height: 1;
  margin: 0 -10px;
}

.recent-posts-entry .widget-post-bx .widget-post .dz-meta ul li {
  margin: 0 10px;
  color: #666666;
  position: relative;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}

.recent-posts-entry .widget-post-bx .widget-post .dz-meta ul li i {
  color: var(--primary);
  font-size: 14px;
}

@media only screen and (max-width: 1280px) {
  .recent-posts-entry .widget-post-bx .widget-post .dz-meta ul {
    margin: 0 -6px;
  }

  .recent-posts-entry .widget-post-bx .widget-post .dz-meta ul li {
    margin: 0 6px;
  }
}

.recent-posts-entry .widget-post-bx .widget-post .title {
  line-height: 1.3;
  font-family: 'Mulish', sans-serif;
  ;
  margin-bottom: 8px;
  display: block;
  font-size: 16px;
}

@media only screen and (max-width: 1200px) {
  .recent-posts-entry .widget-post-bx .widget-post .title {
    font-size: 16px;
  }
}

.recent-posts-entry .widget-post-bx .widget-post .post-date {
  font-size: 13px;
  font-weight: 500;
  color: #777777;
  line-height: 20px;
  display: inline-block;
}

.recent-posts-entry .widget-post-bx.style-1 .widget-post {
  padding: 0;
  background: transparent;
  border: none;
  align-items: center;
  margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
  .recent-posts-entry .widget-post-bx.style-1 .widget-post {
    margin-bottom: 20px;
  }
}

.recent-posts-entry .widget-post-bx.style-1 .widget-post .dz-media {
  width: 100px;
  min-width: 100px;
  height: 100px;
}

.recent-posts-entry .widget-post-bx.style-1 .widget-post .title {
  font-size: 16px;
  line-height: 24px;
  color: #1F1F1F;
}

.recent-posts-entry .widget-post-bx.style-1 .widget-post .dz-meta ul li {
  color: #1F1F1F;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  margin-right: 25px;
}

.recent-posts-entry .widget-post-bx.style-1 .widget-post .dz-meta ul li.date {
  color: #707070;
  font-weight: 500;
  font-size: 13px;
  line-height: 30px;
}

.recent-posts-entry .widget-post-bx.style-1 .widget-post .dz-meta ul li:last-child {
  margin-right: 0;
}

.recent-posts-entry .widget-post-bx.style-1 .widget-post .dz-meta ul li:not(:last-child)::after {
  content: "";
  height: 6px;
  width: 6px;
  position: absolute;
  right: -20px;
  top: 50%;
  border-radius: 50%;
  background-color: #707070;
  transform: translate(0%, -50%);
}

@media only screen and (max-width: 1280px) {
  .recent-posts-entry .widget-post-bx.style-1 .widget-post .dz-meta ul li {
    margin-right: 20px;
  }

  .recent-posts-entry .widget-post-bx.style-1 .widget-post .dz-meta ul li:not(:last-child)::after {
    right: -15px;
  }
}