.logo-header {
  display: flex;
  align-items: center;

  @media (max-width: 1100px) {
    justify-content: space-between;
    width: 100%;
  }
}

.other_header_title {
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0px;
}

.extra-cell-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  cursor: pointer;



  &:hover {
    .home_icon {
      color: #791313;
    }

    .home_icon_label {
      color: #791313;
    }

  }
}



.header_auth_btn {
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  padding: 0px 30px;
  color: var(--primary-light);
  border: 1px solid var(--primary);
  background: linear-gradient(to right, var(--white) 50%, #791313 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 0.5s ease;
  cursor: pointer;

  &:hover {
    background-position: left bottom;
    border: 1px solid var(--primary);
    color: var(--primary) !important;

    .home_icon_label_R {
      color: var(--primary);
    }
  }
}

.home_icon_label {
  font-size: 16px !important;
  color: var(--white);
  margin: 0px;

  text-transform: capitalize;
  font-family: 'Mulish', sans-serif;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
}

.home_icon_label1 {
  font-size: 16px !important;
  color: #000;
  margin: 0px;

  text-transform: capitalize;
  font-family: 'Mulish', sans-serif;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
}

.home_icon_label_R {
  font-size: 16px !important;
  color: var(--white);
  margin: 0px;
  text-transform: capitalize;
  font-family: 'Mulish', sans-serif;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  line-height: 40px;

}

.site-header {
  position: relative;
  z-index: 1000;
  background: white;
  width: 100%;
  box-shadow: 0 15px 40px -20px rgba(40, 44, 63, .15);

  .main-bar-wraper {
    height: 80px;

    @media (max-width: 1200px) {
      height: 60px;
    }
  }

  .main-bar {
    display: flex;
    align-items: center;
    height: inherit;
    padding: 0px 40px;

    @media (max-width: 1200px) {
      padding: 0px 10px;
    }

    .extra-nav {
      position: relative;
      padding-left: 30px;
      flex: 1;
      display: flex;
      flex-direction: row-reverse;

      @media only screen and (max-width: 1100px) {
        display: none;
      }
    }
  }
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu {
  display: none;
  top: 40px;
  left: 0;
  z-index: 1000;
  min-width: 7rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #dbdbdb;
  border-radius: .25rem;
  transition: all .2s ease-in-out;
}

.dropdown-toggle::after {
  display: none;
}


.site-header .extra-nav .extra-cell>ul {
  display: flex;
  align-items: center;
}

.site-header .extra-nav .extra-cell>ul>li {
  @media screen and (min-width: 1100px) {
    margin-left: 25px;
  }

  @media screen and (min-width: 1200px) {
    margin-left: 30px;
  }

  @media screen and (min-width: 1300px) {
    margin-left: 35px;
  }

  @media screen and (min-width: 1400px) {
    margin-left: 40px;
  }
}

.header_address_container {
  display: none;
  margin-left: 25px;
  cursor: pointer;

  @media (max-width: 1200px) {
    margin-left: 10px;
  }

  .selected_address_type {
    font-weight: 700;
    color: #3d4152;
    position: relative;
  }

  .address_type_label {
    display: block;
    min-width: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 700;
    font-size: 14px;
    color: #000;
    line-height: 24px;
    font-family: 'Mulish', sans-serif;
  }

  .header_address_content {
    display: flex;
  }

  .home_address_label {
    font-size: 13px !important;
    font-weight: 400;
    color: #000;
    margin: 0px;
    text-transform: capitalize;
    font-family: "Mulish", sans-serif;
    cursor: pointer;
    line-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;

    @media screen and (max-width: 360px) {
      font-size: 12px !important;
      max-width: 150px;
    }
  }
}

.home_icon {
  width: 22px;
  height: 22px;
}

.site-header .extra-nav .extra-cell>ul>li .icon {
  padding: 0;
  background: transparent;
  min-width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
}




.navbar-toggler {
  border: 0 solid #efbb20;
  font-size: 16px;
  line-height: 24px;
  margin: 8px 0px 0px 15px;
  padding: 0;
  float: right;
  outline: none !important;
}

.navicon {
  width: 45px;
  height: 45px;
  background: #791313;
  box-shadow: 0 5px 15px -10px #791313;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  border-radius: 6px;
}

.navicon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 1px;
  opacity: 1;
  background: var(--theme-text-color);
  left: 10px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.navicon span:nth-child(1) {
  top: 11px;
  width: 26px;
}

.navicon span:nth-child(2) {
  top: 20px;
  width: 22px;
}

.navicon span:nth-child(3) {
  top: 30px;
  width: 17px;
}

.navicon.open span:nth-child(1) {
  top: 25px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

@media only screen and (max-width: 1280px) {
  .navicon.open span:nth-child(1) {
    top: 22px;
    width: 25px;
  }
}

.navicon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.navicon.open span:nth-child(3) {
  top: 25px;
  width: 26px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

@media only screen and (max-width: 1280px) {
  .navicon.open span:nth-child(3) {
    top: 22px;
    width: 25px;
  }
}

.navicon.style-2 span {
  background: #fff;
}

.header-tb .navicon span {
  background: #fff;
}

.header-nav {
  padding: 0;
}

@media only screen and (max-width: 991px) {
  .header-nav {
    overflow-y: scroll;
    position: fixed;
    width: 60px;
    left: -280px;
    height: 100vh !important;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    top: 0;
    background-color: #000;
    margin: 0;
    z-index: 99;
  }
}

@media only screen and (max-width: 991px) {
  .header-nav.show {
    left: -1px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    margin: 0;
    width: 280px;
  }
}



.header-nav .nav {
  float: right;
  font-size: 0;
  padding: 0;
}

.header-nav .nav>li {
  margin: 0px;
  font-weight: 400;
  position: relative;
}

.header-nav .nav>li.has-mega-menu {
  position: inherit;
}

.header-nav .nav>li.has-mega-menu>a::after {
  content: "\f078";
  font-size: 10px;
  margin-left: 7px;
  margin-top: 0;
  vertical-align: middle;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
}

@media only screen and (max-width: 991px) {
  .header-nav .nav>li.has-mega-menu>a::after {
    content: "\f054";
    background-color: #791313;
    color: #000;
    height: 30px;
    line-height: 30px;
    right: 0;
    text-align: center;
    width: 30px;
    z-index: 3;
    float: right;
    font-size: 12px;
    margin: 0;
    border-radius: 6px;
  }
}

@media only screen and (max-width: 991px) {
  .header-nav .nav>li.has-mega-menu>a::after::before {
    content: "\f054";
  }
}

@media only screen and (max-width: 991px) {
  .header-nav .nav>li {
    border-bottom: 1px solid #eee;
    width: 100%;
  }
}

.header-nav .nav>li.sub-menu-down>a::after {
  content: "\f078";
  font-size: 10px;
  margin-left: 7px;
  margin-top: 0;
  vertical-align: middle;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
}

@media only screen and (max-width: 991px) {
  .header-nav .nav>li.sub-menu-down>a::after {
    content: "\f054";
    background-color: #791313;
    color: #000;
    height: 30px;
    line-height: 30px;
    right: 0;
    text-align: center;
    width: 30px;
    z-index: 3;
    float: right;
    font-size: 12px;
    margin: 0;
    border-radius: 6px;
  }
}

@media only screen and (max-width: 991px) {
  .header-nav .nav>li.sub-menu-down>a::after::before {
    content: "\f054";
  }
}

@media only screen and (max-width: 991px) {
  .header-nav .nav>li.sub-menu-down.open>a::after {
    content: "\f078";
  }
}

.header-nav .nav>li>a {
  color: #071c35;
  font-size: 18px;
  padding: 40px 15px;
  font-family: var(--font-family-base);
  cursor: pointer;
  font-weight: 500;
  display: inline-block;
  position: relative;
  border-width: 0 0 0 1px;
  text-transform: capitalize;
  line-height: 1.1;
}

@media only screen and (max-width: 1200px) {
  .header-nav .nav>li>a {
    font-size: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .header-nav .nav>li>a {
    padding: 8px 0;
    display: block;
    line-height: 30px;
    font-size: 16px;
  }
}

.header-nav .nav>li>a span {
  display: inline-block;
}

.header-nav .nav>li .mega-menu,
.header-nav .nav>li .sub-menu {
  background-color: #000;
  display: block;
  left: 0;
  list-style: none;
  opacity: 0;
  padding: 10px 0;
  position: absolute;
  visibility: hidden;
  width: 220px;
  z-index: 10;
  border-radius: 6px;
  margin-top: 20px;
  box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.1);
  text-align: left;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

@media only screen and (max-width: 991px) {

  .header-nav .nav>li .mega-menu,
  .header-nav .nav>li .sub-menu {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: transparent;
    box-shadow: none;
    margin: 0;
    border-top: 1px solid #eee;
    padding: 10px 0;
    -webkit-transition: none;
    -ms-transition: none;
    transition: none;
  }

  .header-nav .nav>li .mega-menu>li>a i,
  .header-nav .nav>li .sub-menu>li>a i {
    color: #791313 !important;
    height: 30px;
    line-height: 30px;
    right: 0;
    text-align: center;
    width: 30px;
    z-index: 3;
    font-size: 14px !important;
    margin: 0 !important;
    background: var(--rgba-primary-2);
  }
}

.header-nav .nav>li .mega-menu li,
.header-nav .nav>li .sub-menu li {
  position: relative;
}

.header-nav .nav>li .mega-menu li a,
.header-nav .nav>li .sub-menu li a {
  color: #212529;
  display: block;
  font-size: 15px;
  padding: 8px 25px;
  line-height: 1.3;
  text-transform: capitalize;
  position: relative;
  transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  font-weight: 500;
}

@media only screen and (max-width: 991px) {

  .header-nav .nav>li .mega-menu li a,
  .header-nav .nav>li .sub-menu li a {
    padding: 2px 0 1px 16px;
    display: block;
    line-height: 30px;
    position: relative;
  }

  .header-nav .nav>li .mega-menu li a::after,
  .header-nav .nav>li .sub-menu li a::after {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    width: auto;
    height: auto;
    background: 0 0 !important;
    top: 3px;
    color: #262F5A;
    font-size: 12px;
    position: absolute;
    left: 0;
    font-weight: 700;
  }
}

.header-nav .nav>li .mega-menu li:hover>a,
.header-nav .nav>li .sub-menu li:hover>a {
  color: #791313;
}

@media only screen and (max-width: 991px) {

  .header-nav .nav>li .mega-menu li:hover>a,
  .header-nav .nav>li .sub-menu li:hover>a {
    padding: 2px 0 1px 16px;
    background: transparent;
  }
}

.header-nav .nav>li .mega-menu li:hover>a::after,
.header-nav .nav>li .sub-menu li:hover>a::after {
  width: 10px;
}

.header-nav .nav>li .mega-menu li>.sub-menu,
.header-nav .nav>li .sub-menu li>.sub-menu {
  left: 220px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (max-width: 991px) {

  .header-nav .nav>li .mega-menu li>.sub-menu,
  .header-nav .nav>li .sub-menu li>.sub-menu {
    display: none;
  }
}

@media only screen and (max-width: 991px) {

  .header-nav .nav>li .mega-menu .mega-menu,
  .header-nav .nav>li .mega-menu .sub-menu,
  .header-nav .nav>li .sub-menu .mega-menu,
  .header-nav .nav>li .sub-menu .sub-menu {
    border-top: 0;
    padding-left: 20px;
  }

  .header-nav .nav>li .mega-menu .mega-menu li a::after,
  .header-nav .nav>li .mega-menu .sub-menu li a::after,
  .header-nav .nav>li .sub-menu .mega-menu li a::after,
  .header-nav .nav>li .sub-menu .sub-menu li a::after {
    width: 5px;
    content: "";
    height: 5px;
    background: #262F5A !important;
    border-radius: 4px;
    top: 13px;
  }
}

.header-nav .nav>li .sub-menu li>a>i {
  color: inherit;
  display: block;
  float: right;
  font-size: 12px;
  opacity: 1;
  margin-top: 7px;
  border-radius: 6px;
}

.header-nav .nav>li .sub-menu.open>a>i::before {
  content: "\f078";
}

.header-nav .nav>li .sub-menu>li.open>a i::before {
  content: "\f107";
}

.header-nav .nav>li .mega-menu {
  right: 0px;
  width: 100%;
  z-index: 9;
}

@media only screen and (min-width: 991px) {
  .header-nav .nav>li .mega-menu {
    padding: 0;
    display: table;
    border: 1px solid #f8f8f8;
  }
}

.header-nav .nav>li .mega-menu>li {
  display: table-cell;
  padding: 30px 0 20px;
  position: relative;
  vertical-align: top;
  width: 25%;
}

@media only screen and (max-width: 991px) {
  .header-nav .nav>li .mega-menu>li {
    width: 100%;
    padding: 0;
    display: block;
  }
}

.header-nav .nav>li .mega-menu>li>a {
  color: #222222;
  display: block;
  padding: 0 25px;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
}

.header-nav .nav>li .mega-menu>li>a>i {
  font-size: 14px;
  margin-right: 5px;
  text-align: center;
  width: 15px;
}

@media only screen and (max-width: 991px) {
  .header-nav .nav>li .mega-menu>li>a {
    display: none;
  }
}

.header-nav .nav>li .mega-menu>li::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  width: 1px;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .header-nav .nav>li .mega-menu>li::after {
    content: none;
  }
}

.header-nav .nav>li .mega-menu>li:last-child::after {
  display: none;
}

.header-nav .nav>li .mega-menu>li:hover>a {
  color: #222222;
  background-color: transparent;
}

.header-nav .nav>li .mega-menu>li ul {
  margin-top: 15px;
  padding: 0;
}

@media only screen and (max-width: 991px) {
  .header-nav .nav>li .mega-menu>li ul {
    margin-top: 0px;
  }
}

.header-nav .nav>li .mega-menu>li li i {
  font-size: 14px;
  margin-right: 5px;
  text-align: center;
  width: 15px;
}

.header-nav .nav>li.active>a {
  color: #791313;
}

.header-nav .nav>li:hover>.mega-menu,
.header-nav .nav>li:hover>.sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

@media only screen and (max-width: 991px) {

  .header-nav .nav>li:hover>.mega-menu,
  .header-nav .nav>li:hover>.sub-menu {
    -webkit-transition: none;
    -ms-transition: none;
    transition: none;
  }
}

.header-nav .nav>li:hover>.mega-menu li:hover>.sub-menu,
.header-nav .nav>li:hover>.sub-menu li:hover>.sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  top: -1px;
}

.header-nav .nav>li.open .mega-menu,
.header-nav .nav>li.open .sub-menu {
  display: block;
  opacity: 1;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}

.header-nav .nav>li.open .open.menu-item-has-children>a::before {
  transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.header-nav .nav>li.open .open .mega-menu,
.header-nav .nav>li.open .open .sub-menu {
  display: block;
  opacity: 1;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}

@media only screen and (max-width: 1480px) {

  .header-nav .nav>li:nth-last-child(3) .sub-menu .sub-menu,
  .header-nav .nav>li:nth-last-child(2) .sub-menu .sub-menu,
  .header-nav .nav>li:last-child .sub-menu .sub-menu {
    left: -220px;
  }
}

@media only screen and (max-width: 1200px) {
  .header-nav .nav {
    padding: 0;
  }

  .header-nav .nav>li:last-child .sub-menu {
    left: auto;
    right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .header-nav .nav {
    float: none;
    padding: 0 20px;
  }
}

.header-nav .navbar {
  position: unset;
}

.header-nav .dz-social-icon {
  display: none;
  text-align: center;
}

.header-nav .dz-social-icon ul {
  margin: 0 -2px;
}

.header-nav .dz-social-icon li {
  display: inline-block;
  padding: 0 2px;
}

.header-nav .dz-social-icon a {
  width: 35px;
  height: 35px;
  padding: 0;
  color: #791313;
  border: 1px solid lightgrey;
  line-height: 33px;
  text-align: center;
  border-radius: var(--border-radius-base);
  font-size: 15px;
}

.header-nav .dz-social-icon a:hover {
  background-color: #791313;
  color: #000;
  box-shadow: 0px 5px 15px var(--rgba-primary-4);
}

@media only screen and (max-width: 991px) {
  .header-nav .dz-social-icon {
    display: block;
    padding: 30px 10px 20px;
  }
}

.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 15px 40px -20px rgba(40, 44, 63, .15);
}



.is-fixed .main-bar {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  color: #000;
  transition: all 0.5s;
  animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  background: #ffffff;
  width: 100%;
}

.is-fixed .extra-nav .btn-white {
  background-color: #791313;
}

.is-fixed .extra-nav .btn-white i {
  color: #000 !important;
}

.is-fixed .extra-nav .btn-white:hover,
.is-fixed .extra-nav .btn-white:focus,
.is-fixed .extra-nav .btn-white:active {
  background-color: var(--primary-hover) !important;
}

.text-black .nav>li>a {
  color: #222222;
}

.text-black .navbar-toggler span {
  background: #222222;
}


@-moz-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }

  100% {
    margin-top: 0;
  }
}

@-ms-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }

  100% {
    margin-top: 0;
  }
}

@-webkit-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }

  100% {
    margin-top: 0;
  }
}

@keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }

  100% {
    margin-top: 0;
  }
}

.logo-header img {
  max-width: 120px;
  object-fit: contain;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;

  @media screen and (max-width: 350px) {
    max-width: 100px;
  }
}

.toggle-holder {
  width: 50px;
  height: 60px;
  padding: 0 10px;

  @media (min-width: 1101px) {
      display: none;
  }
}

.toggle {
  position: relative;
  transition: transform 0.3s linear;
}

.toggle::before {
  background-color: var(--primary);
  width: 21px;
  top: 20px;
}

.toggle::after {
  background-color: var(--primary);
  width: 30px;
  top: 36px;
}

.toggle::before,
.toggle::after {
  content: "";
  height: 4px;
  left: 0px;
  border-radius: 2px;
  position: absolute;
  transform-origin: 50% 50%;
  transition: all 0.25s;
}

.toggle_on::before {
  transform: rotate3d(0, 0, 1, 45deg) translate3d(0, 0, 0);
  background-color: #000;
  margin-left: 0;
  width: 26px !important;
  top: 25px;
}

.toggle_on::after {
  transform: rotate3d(0, 0, 1, -45deg);
  background-color: var(--primary);
  width: 26px !important;
  top: 25px;
}

.toggle_on .menu-line {
  opacity: 0;
}

.menu-line {
  background-color: #000;
  width: 13px;
  height: 4px;
  left: 0px;
  top: 28px;
  border-radius: 2px;
  position: absolute;
  transition: all 0.25s;
}