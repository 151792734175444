.text-bg-primary {
  color: #000 !important;
  background-color: RGBA(58, 122, 254, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(36, 54, 92, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(16, 202, 147, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(0, 175, 239, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 159, 0, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(242, 87, 103, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(235, 238, 246, var(--bs-bg-opacity, 1)) !important;
}


.link-primary {
  color: #f25521 !important;
}
.link-primary:hover,
.link-primary:focus {
  color: #6195fe !important;
}

.link-secondary {
  color: #24365c !important;
}
.link-secondary:hover,
.link-secondary:focus {
  color: #1d2b4a !important;
}

.link-success {
  color: #10ca93 !important;
}
.link-success:hover,
.link-success:focus {
  color: #40d5a9 !important;
}

.link-info {
  color: #00afef !important;
}
.link-info:hover,
.link-info:focus {
  color: #33bff2 !important;
}

.link-warning {
  color: #ff9f00 !important;
}
.link-warning:hover,
.link-warning:focus {
  color: #ffb233 !important;
}

.link-danger {
  color: #f25767 !important;
}
.link-danger:hover,
.link-danger:focus {
  color: #f57985 !important;
}

.link-light {
  color: #ebeef6 !important;
}
.link-light:hover,
.link-light:focus {
  color: #eff1f8 !important;
}

.link-dark {
  color: #181c32 !important;
}
.link-dark:hover,
.link-dark:focus {
  color: #131628 !important;
}
