.cart-list {
  border: 0;
  right: 0;
  left: auto;
  width: 300px;
  top: 100%;
  transition: none;
  margin-top: 0;
}

.cart-list li {
  padding: 10px 15px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  width: 100%;
}

.cart-list li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.cart-list li .media {
  display: flex;
  align-items: center;
}

.cart-list li .media .media-left {
  width: 80px;
  height: 80px;
  border-radius: 6px;
  overflow: hidden;
}

.cart-list li .media .media-left img {
  width: 100%;
  height: 100%;
}

.cart-list li .media .media-body {
  position: relative;
  padding-left: 10px;
  padding-right: 30px;
  flex: 1;
}

.cart-list li .media .media-body .dz-title {
  color: var(--primary);
}

.cart-list li .media .media-body .dz-price {
  font-size: 16px;
  color: var(--primary);
}

.cart-list li .media .media-body .item-close {
  background-color: var(--bs-danger);
  color: #000;
  display: block;
  font-size: 24px;
  height: 24px;
  line-height: 24px;
  position: absolute;
  right: 0px;
  text-align: center;
  top: 0;
  width: 24px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 400;
}

.cart-list.dropdown-menu {
  width: 300px !important;
  box-shadow: 0 60px 50px 0 rgba(0, 0, 0, 0.1);
}

.anim-logo,
.anim-logo-white {
  position: relative;
  padding-top: 5px;
  display: inline-block;
}

.anim-logo:after,
.anim-logo-white:after {
  content: "";
  animation-duration: 1.8s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: swigo-logo;
  background-image: url(../../../images/pan1.png);
  height: 12px;
  width: 38px;
  position: absolute;
  top: 0px;
  left: 3px;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 767px) {

  .anim-logo:after,
  .anim-logo-white:after {
    top: 10px;
    background-size: 90%;
  }
}

.anim-logo-white:after {
  background-image: url(../../../images/pan2.png);
}

.header-nav .anim-logo:after,
.header-nav .anim-logo-white:after,
.footer-logo .anim-logo:after,
.footer-logo .anim-logo-white:after {
  top: -7px;
}

@media only screen and (max-width: 767px) {

  .header-nav .anim-logo:after,
  .header-nav .anim-logo-white:after,
  .footer-logo .anim-logo:after,
  .footer-logo .anim-logo-white:after {
    top: -5px;
  }
}

@keyframes swigo-logo {

  from,
  45%,
  55%,
  65%,
  75%,
  80%,
  85%,
  to {
    animation-timing-function: cubic-bezier(0.2, 0.6, 0.35, 1);
  }

  0% {
    transform: rotate(0);
  }

  45% {
    transform: translate3d(-4%, -13%, 0) rotate(-13deg);
  }

  55% {
    transform: translate3d(1%, -2%, 0) rotate(6deg);
  }

  65% {
    transform: translate3d(-2%, -4%, 0) rotate(-3deg);
  }

  75% {
    transform: translate3d(2%, 3%, 0) rotate(3deg);
  }

  80% {
    transform: translate3d(-2%, 1%, 0) rotate(-2deg);
  }

  to {
    transform: none;
  }
}



.site-header.style-1 .extra-nav {
  padding-left: 60px;
}

// @media only screen and (max-width: 1200px) {
//   .site-header.style-1 .extra-nav {
//     padding-left: 30px;
//   }
// }

@media only screen and (max-width: 1199) {
  .site-header.style-1 .extra-nav {
    display: none;
  }
}

.site-header.style-1.header-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

@media only screen and (min-width: 1024px) {
  .site-header.style-1 .header-nav .nav>li>a {
    padding: 20px 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.site-header.style-1 .header-nav .nav>li>a.active {
  color: var(--primary);
}

.site-header.style-1 .header-nav .nav>li.active>a,
.site-header.style-1 .header-nav .nav>li:hover>a {
  color: var(--primary);
}

.header-nav .nav>li.active>a {
  color: #791313;
}

.header-adv img {
  padding: 15px;
}

@media only screen and (max-width: 991px) {
  .header-adv {
    display: none;
  }

  .header-adv img {
    display: none;
  }
}

.header-nav .nav>li.active>a {
  color: #791313;
}

.extra-nav .extra-cell form input {
  height: 45px;
  border-radius: 25px !important;
  border-color: var(--primary);
}

.extra-nav .extra-cell form .input-group-addon {
  right: 0;
}

.extra-nav .extra-cell form .input-group-addon .btn,
.extra-nav .extra-cell form .input-group-addon .wp-block-button__link {
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  padding: 0;
  justify-content: center;
  border-radius: 100%;
}

.extra-nav .extra-cell form .input-group-addon .btn i,
.extra-nav .extra-cell form .input-group-addon .wp-block-button__link i {
  display: block;
  font-size: 18px;
}

@media only screen and (max-width: 1280px) {
  .extra-nav .extra-cell form {
    display: none;
  }
}

.menu-btn {
  background: transparent;
  width: 45px;
  height: 45px;
  line-height: 45px;
  box-shadow: none;
  margin-left: 30px;
}

@media only screen and (max-width: 1280px) {
  .menu-btn {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 991px) {
  .menu-btn {
    display: none;
  }
}

.contact-sidebar {
  padding: 30px;
  position: fixed;
  height: 100%;
  width: 300px;
  z-index: 999999999;
  background: #fff;
  top: 0;
  left: -300px;
  overflow-y: auto;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.contact-sidebar.active {
  left: 0;
}

.contact-sidebar .logo-contact {
  margin-bottom: 30px;
  height: 65px;
}

.contact-sidebar .logo-contact a {
  display: table-cell;
  vertical-align: middle;
}

.contact-sidebar .dz-title {
  margin-bottom: 12px;
}

.contact-sidebar .dz-title h4,
.contact-sidebar .dz-title .h4,
.contact-sidebar .dz-title .wp-block-search .wp-block-search__label,
.wp-block-search .contact-sidebar .dz-title .wp-block-search__label {
  font-size: 24px;
}

.contact-sidebar .icon-bx-wraper {
  margin-bottom: 20px;
}

.contact-sidebar .icon-bx-wraper .icon-md {
  padding: 0;
  margin-right: 15px !important;
  background: var(--primary);
  box-shadow: 0px 5px 10px 0 var(--rgba-primary-1);
  color: #000;
  width: 50px;
  height: 50px;
  line-height: 50px !important;
  margin-top: 5px;
  border-radius: var(--border-radius-base);
}

.contact-sidebar .icon-bx-wraper .icon-md i {
  font-size: 28px;
}

.contact-sidebar .icon-bx-wraper .tilte {
  font-family: var(--font-family-title);
  margin-bottom: 5px;
}

.contact-sidebar .icon-bx-wraper p {
  font-size: 14px;
  font-weight: 500;
}

.menu-close {
  width: 0;
  position: fixed;
  height: 100%;
  background: #333;
  top: 0;
  opacity: 0.90;
  left: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 99998;
}

.contact-sidebar.active+.menu-close,
.login-area.active+.menu-close {
  width: 100%;
}