::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none
}

::-webkit-scrollbar-corner {
    background-color: transparent
}

::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid transparent;
    -webkit-border-radius: 7px;
    border-radius: 7px;
    background-color: rgba(0, 0, 0, .15);
}

::-webkit-scrollbar {
    width: 4px;
    background-color: #fff
}

::-webkit-scrollbar-thumb {
    height: 50px;
    background: var(--primary)
}
.section_wrapper {
    padding: 35px 0px 15px;

    @media screen and (max-width: 1024px) {
        padding: 10px 0px 15px;
    }
}
.commitments_wrapper {
    padding: 20px 0px 0px;
}
.key_title {
    font-family: 'Playball',sans-serif;
    font-size: 30px;
    color:  #791313;
}
.section_title {
    font-family: 'Mulish', sans-serif;
    font-size: 22px;
    color: #000;
    font-weight: 800;

    @media screen and (max-width: 350px) {
        font-size: 19px;
    }
}
.color-primary {
    color: var(--primary);
}

.default_ninan_btn {
    width: 100%;
    max-width: 150px;
}
.mobile_inner {
    padding: 100px 0px 0px 0px;
}

.home_banner_img {
    width: 100%;
    max-width: 350px;
    margin-right: calc(11% + 24px);
    margin-top: 0px;
}

.home_banner_heading_container {
    margin-top: 60px;
    height: 145px;
    max-width: 391px;
    margin-left: calc(11% + 11px);
}

.home_banner_svg {
    width: 128px;
    height: 10px;
    margin-top: 0px;
    margin-left: calc(16% + 11px);
}

.swiper-button-lock{
    display: block !important;
}


.swiper-horizontal>.swiper-scrollbar{
    display: none;
}


.horizontal_line {
    border: 1px solid rgb(145, 145, 149);
    margin: 32px;

}

// faeture

.overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: -1;
}

.section-title {
    margin-bottom: 37px;
    position: relative;
}

.section-title .sub-title {
    color: var(--primary);
    font-family: 'lobster';
    font-weight: 400;
    margin-bottom: 10px;
}

@media (max-width: 1440px) {

    .subscribe-area .section-title .title,
    .featured-area .section-title .title {
        font-size: 50px;
        line-height: 60px;
    }
}

.subscribe-area .section-title .title,
.featured-area .section-title .title {
    font-size: 60px;
    line-height: 66px;
}

.section-title .btn {
    margin-top: 22px;
    height: 64px;
    line-height: 64px;
    background: var(--primary)
}

.btn-base {
    color: #000;
    border: 0;
    background: var(--primary)
}

.dz-box,
.dz-info,
.dz-tilte,
.dz-tilte-inner {
    padding: 15px 0px;
}

.dz-title {
    font-family: inherit;
    color: #1A1110;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 1.3;
}

.category_title {
    font-family: 'Mulish', sans-serif;    
    font-size: 18px;
    margin-bottom: 15px;
}



.category_btn {
    width: 100%;
    max-width: 85px !important;
    padding: 5px 6px !important;
    font-size: 12px !important;
}



.otpInputContainer input {
    background: transparent;
    border: 0.5px solid #ccc;
    border-radius: 6px;
    font-size: 14px;
    height: 40px;
    max-width: 40px !important;
    margin-right: 4px;
    width: 100% !important;
}

.otpInputContainer {
    padding: 10px 0px 25px 0px;
}

.otpInputContainer span {
    color: #8e8e8e;
    padding: 0px 15px;
}

.mobile_div {
    position: relative;
}
.otp-label {
    font-family: 'Mulish', sans-serif;
}
.edit-icon {
    position: absolute;
    top: 50px;
    right: 10px;
}

input[type="text" i] {
    padding-block: 1px;
    padding-inline: 2px;
}



.resend_container {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;

    .resend_btn {
        padding: 0px !important;
        font-family: 'Mulish', sans-serif;
    }
}

.is-fixed .home_icon_label {
    color: #000;
}

.is-fixed .home_icon i {
    color: #000;
}

.map_placeholder {
    color: #000;
}

.is-fixed .map_placeholder {
    color: #000;
}

.shopping_bag {
    font-size: 24px !important;
    color: var(--header-text);
}

.is-fixed .shopping_bag {
    color: #000;
}

.header-info {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header_user_info {
    font-size: 16px !important;
    color: var(--theme-text-color);
    margin: 0px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: 'Mulish', sans-serif;
    cursor: pointer;
    text-align: center;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.profile_dropdown {
    box-shadow: 0 60px 50px 0 rgba(0, 0, 0, 0.1);
}

.dropdown-item {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1) !important;
}

.dropdown-item:last-child {
    border-bottom: 1px dashed transparent !important;
}

.dropdown-item svg {
    margin-right: 10px;
    color: var(--primary);
}

.dropdown-item span {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    font-family: 'Mulish', sans-serif;    
    cursor: pointer;
}

.dropdown-item:hover,
.dropdown-item:focus {
    background-color: transparent !important;
    color: var(--primary);
}

.login-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.is-fixed .header_user_info {
    color: #000;
}

.outline_btn {
    color: #791313 !important;
}

.map_btn {
    width: 100%;
    max-width: 150px;
  padding: 10px !important;
}

.save_address_wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
}

.secondary_bg {
    background-color: rgba(125, 166, 64, 0.05);
}

.map_title {
    font-family: "Mulish", sans-serif;  
    color: #232324;
    font-size: 14px;
    font-weight: 700;
}

.map_location_wrapper {
    padding: 10px 0px;
}

.map_location_subtitle {
    font-size: 14px;
    font-weight: 600;
    color: #3f4042;
    font-family: "Mulish", sans-serif;
}

/* CSS */
.modal-scrollbar::-webkit-scrollbar {
    width: 5px;
}

.modal-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 1rem;
    height: 30vh;
}

.modal-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 6px;
}

.modal-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: var(--primary);
}

.modal-scrollbar{
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
.cart-scrollbar::-webkit-scrollbar {
    width: 5px;
}

.cart-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 1rem;
    height: 30vh;
}

.cart-scrollbar::-webkit-scrollbar-thumb {
    background-color: #791313;
    border-radius: 6px;
}

.cart-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #791313;
}

.cart-scrollbar{
    height: 50%;
    overflow-y: auto;
    overflow-x: hidden;

    @media (max-width: 991px) {
        height: 100%;
        overflow-y: hidden;
    }
}
.addOn-scrollbar{
    overflow-y: auto;
    overflow-x: hidden;
}
.addOn-scrollbar::-webkit-scrollbar {
    width: 5px;
}

.addOn-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 1rem;
    height: 30vh;
}

.addOn-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 6px;
}

.addOn-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: var(--primary);
}

// filter


.filter_body {
    height: 50vh;
    padding: 0px !important;
}

.filter_dialogue {
    max-width: 600px !important;
}

.filter_poup_wrapper {
    height: 100%;
    width: 100%;
    position: relative;
}


// .filtre_popup_list::before{
//     content: "";
//     position: absolute;
//     transition: transform 300ms ease-out 0s;
//     top: 0px;
//     // transform: translateY(calc(336px));
//     left: 0px;
//     width: 6px;
//     height: 56px;
//     border-top-right-radius: 8px;
//     border-bottom-right-radius: 8px;
//     background: rgb(241, 87, 0);
// }
.filtre_popup_list .active::before {
    content: "";
    position: absolute;
    transition: transform 300ms ease-out 0s;
    top: 0px;
    // transform: translateY(calc(336px));
    left: 0px;
    width: 6px;
    height: 56px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background: var(--primary);
}

.filter_inner_list {
    width: 100%;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.filter_item_wrapper {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 18px 24px;
    align-self: start;
}

.filter_popup_title {
    -webkit-font-smoothing: antialiased;
    font-family: inherit;
    cursor: pointer;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.3px;
    color: rgba(2, 6, 12, 0.75);
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    overflow-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.filter_popup_content {
    margin-left: auto;
    padding-left: 24px;
    padding-right: 12px;
    height: 100%;
    width: 70%;
    position: relative;
}

.filter_popup_inner_content {
    -webkit-font-smoothing: antialiased;
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.3px;
    color: rgba(2, 6, 12, 0.75);
}

.filter_popup_title_wrapper {
    padding: 16px 0px;
    text-transform: uppercase;
}

.filter_sort {
    -webkit-font-smoothing: antialiased;
    font-family: 'Mulish', sans-serif;
    font-weight: 200;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.3px;
    color: rgba(2, 6, 12, 0.6);
}

.fiter_sort_content {
    display: grid;
    gap: 8px;
}

.low_to_high {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.low_to_high input {
    position: absolute;
    opacity: 1;
    cursor: pointer;
    height: 20px;
}

.low_to_high .custom-checkbox {
    z-index: 0;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    padding: 8px 0px;
    left: 0px;
}

.low_to_high label {
    width: 100%;
    z-index: 1;
    cursor: pointer;
    padding: 8px 0px 8px calc(24px);
}

.cEHLlK {
    display: flex;
    box-shadow: rgba(40, 44, 63, 0.1) 0px -2.63677px 5.27354px;
    width: 100%;
    bottom: 0px;
    position: absolute;
    -webkit-box-align: center;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 16px;
    z-index: 1;
    background: rgb(255, 255, 255);
    gap: 20px;
}

.biiCzD {
    position: relative;
    overflow: hidden;
    background-color: transparent;
    width: 100%;
    transition: transform 0.1s ease-in 0s;
    min-width: 96px;
    padding-left: 16px;
    padding-right: 16px;
    height: 44px;
    border: 1px solid var(--primary);
    border-radius: 6px;
    text-align: center;
}

.hYsHck {
    width: 150px;
    margin: 0px;
    padding: 0px;
}

.fcdMmX {
    border-bottom-width: 0px;
    border-bottom-style: dashed;
    display: inline;
}

.crKGXn {
    -webkit-font-smoothing: antialiased;
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: var(--primary);
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    overflow-wrap: break-word;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.kOMrTR {
    position: relative;
    text-align: center;
}

.gfnbZV {
    position: relative;
    overflow: hidden;
    background-color: #791313;
    width: 100%;
    transition: transform 0.1s ease-in 0s;
    min-width: 96px;
    padding-left: 16px;
    padding-right: 16px;
    height: 44px;
    border: 1px solid #791313;
    border-radius: 6px;
}

.gfnbZV:focus {
    background-color: #791313 !important;
}

.khSxRZ {
    width: 150px;
    margin: auto;
}

.dHuylL {
    -webkit-font-smoothing: antialiased;
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: rgba(255, 255, 255, 0.92);
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    overflow-wrap: break-word;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.eKisRq {
    text-align: center;
    font-size: 18px;
}

.filter_header {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: absolute;
    top: 19px;
    right: 19px;
    height: 27px;
    width: 27px;
    border-radius: 20px;
    color:#ffffff;
    background: #791313;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 6px;
    border: none;
    cursor: pointer;
}

.address_type {
    display: flex;
    align-items: center;
    gap: 2px;
}

.address_type p {
    margin: 0px;
    font-size: 14px;
    color: #000;
}

.address_type {
    cursor: pointer;
}

.get_location_header {
    display: inline-block !important;
}

.delivery_location {
    font-size: 16px;
    font-family: 'Mulish', sans-serif;
    font-weight: 600;
    color: #000;
}

.bg-image1 {
    background-image: url(../images/logout.jpg);
    background-size: cover;
    background-position: center;
}
.logout_main {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .logout_content .logout_popup_subtitle {
        padding: 15px 30px 0px 30px;
    }
}
.logout_popup_title{
    font-family: 'Mulish', sans-serif;
    font-weight: 600;
    color: #000;
    letter-spacing: 1.5px;
    font-size: 35px;
}
.logout_popup_subtitle{
    font-size: 14px;
    text-align: center;
    font-family: 'Mulish', sans-serif;
}
.logout_btn_wrapper {
    display: flex;
    gap: 20px;
    margin: 30px 0px;
    justify-content: center;
    align-items: center;
}

.logout_cancel_btn {
    max-width: 150px;
    background-color: #7e808c !important;
    color: #f1f1f1 !important;
    font-family: 'Mulish', sans-serif !important;
}

.login_logout_btn{
    max-width: 150px;
    font-family: 'Mulish', sans-serif !important;
}

.order_loader_container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px ;
}
.box_1{
    display: none;
}

ul#masonry {
    position: relative;
}

.MenuStickyBottom_container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    pointer-events: none;
}

.loader{
    display: flex;
    justify-content: center;
    align-items: center;
}

.order_success_image{
    width: 100%;
    max-width: 200px;
}
.primary_color{
    color: var(--primary);
    cursor: pointer;
}

.profile_not_logged {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 65px);
}
.no_data_found_image{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 130px);
}

.no_data_found_image img{
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
}
.cart_empty_text{
    margin-top: 24px;
    font-size: 19px;
    font-weight: 700;
    color: #000;
    font-family: inherit;
}
.cart_empty_btn{
    margin-top: 24px; 
    font-family: 'Mulish', sans-serif !important;
}
.cart_empty_subtext{
    margin-top: 8px;
    color: #000;
    font-family: 'Mulish', sans-serif;
}

.no_cart_conatiner{
    text-align: center;
}

.footer_tagline {
    font-family: 'Mulish';
    color: #000;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    max-width: 210px;
}

.footer_tagliner{
    font-size: 16px;
    color: #ffff;
    padding-top: 10px;
    padding-left: 0px;
    padding-right: 10px;
    font-weight: 700;
    font-family: 'Mulish';
}
.premium_sub_content {
    font-size: 16px;
    max-width: 700px;
}