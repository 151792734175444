.main-bnr-one {
  position: relative;
  align-items: center;
  z-index: 1;
  overflow: hidden;
}

.main-bnr-one .banner-inner {
  position: relative;
  z-index: 1;
  background-color: var(--primary);
  height: 930px;
}

.main-bnr-one .banner-inner::after {
  position: absolute;
  z-index: -1;
  content: "";
  height: 100%;
  width: 70%;
  top: 0;
  left: 0;
  background: var(--bg-light);
}

@media only screen and (min-width: 2400px) {
  .main-bnr-one .banner-inner::after {
    width: 65%;
  }
}

.main-bnr-one .banner-inner .banner-content {
  position: relative;
  z-index: 1;
  margin-bottom: 60px;
}

.main-bnr-one .banner-inner .banner-content .sub-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 30px;
  color: #FE9F10;
  transition: all 0.5s ease-out 0.1s;
  transform: translateX(-15px);
  transition-delay: 0.5s;
  opacity: 0;
  display: block;
}

.main-bnr-one .banner-inner .banner-content h1,
.main-bnr-one .banner-inner .banner-content .h1 {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 1.2;
  margin-bottom: 10px;
  transition: all 0.6s ease-out 0.1s;
  transform: translateX(-15px);
  transition-delay: 0.7s;
  opacity: 0;
}

.main-bnr-one .banner-inner .banner-content p {
  font-size: 18px;
  line-height: 27px;
  max-width: 500px;
  transition: all 0.7s ease-out 0.1s;
  transform: translateX(-15px);
  transition-delay: 0.9s;
  opacity: 0;
}

.main-bnr-one .banner-inner .banner-content .banner-btn {
  margin-top: 40px;
  transition: all 0.8s ease-out 0.1s;
  transform: translateX(-15px);
  transition-delay: 1.1s;
  opacity: 0;
}

.main-bnr-one .banner-inner .banner-media {
  position: relative;
  z-index: 1;
  margin-top: 70px;
  transition: all 0.5s ease-out 0.1s;
  transform: translateY(15px);
  transition-delay: 0.5s;
  opacity: 0.5;
}

.main-bnr-one .banner-inner .img1,
.main-bnr-one .banner-inner .img2,
.main-bnr-one .banner-inner .img3 {
  position: absolute;
  z-index: 1;
}

.main-bnr-one .banner-inner .img1 {
  right: 0;
  min-width: 50vw;
  width: 50vw;
  top: 0;
  opacity: 0.25;
  z-index: -1;
}

.main-bnr-one .banner-inner .img2 {
  top: 156px;
  right: 147px;
  opacity: 0;
  transform: rotate(5deg);
  transition: all 0.5s ease-out 0.1s;
  transition-delay: 0.5s;
}

.main-bnr-one .banner-inner .img3 {
  top: 100px;
  left: 50%;
  width: 150px;
  transform: translateX(-50%);
  animation: move1 7s infinite;
}

@media only screen and (max-width: 1680px) {
  .main-bnr-one .banner-inner .img2 {
    right: 45px;
  }
}

@media only screen and (max-width: 1480px) {
  .main-bnr-one .banner-inner {
    height: 850px;
  }

  .main-bnr-one .banner-inner::after {
    width: 75%;
  }

  .main-bnr-one .banner-inner .img2 {
    right: -60px;
  }

  .main-bnr-one .banner-inner .banner-content h1,
  .main-bnr-one .banner-inner .banner-content .h1 {
    font-size: 70px;
    line-height: 85px;
  }

  .main-bnr-one .banner-inner .banner-media {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 1320px) {
  .main-bnr-one .banner-inner .img2 {
    right: -128px;
  }
}

@media only screen and (max-width: 1280px) {
  .main-bnr-one .banner-inner {
    height: 750px;
  }

  .main-bnr-one .banner-inner::after {
    width: 100%;
  }

  .main-bnr-one .banner-inner .banner-content h1,
  .main-bnr-one .banner-inner .banner-content .h1 {
    font-size: 60px;
    line-height: 75px;
  }

  .main-bnr-one .banner-inner .banner-content p {
    font-size: 16px;
    line-height: 24px;
  }

  .main-bnr-one .banner-inner .banner-media img {
    height: 750px !important;
    object-fit: cover;
  }

  .main-bnr-one .banner-inner .img2,
  .main-bnr-one .banner-inner .img3 {
    display: none;
  }

  .main-bnr-one .banner-inner .main-thumb1-area {
    bottom: 95px;
  }
}

@media only screen and (max-width: 991px) {
  .main-bnr-one .banner-inner {
    padding-top: 50px;
    height: 650px;
  }

  .main-bnr-one .banner-inner .banner-content {
    margin-bottom: 0;
  }

  .main-bnr-one .banner-inner .banner-content h1,
  .main-bnr-one .banner-inner .banner-content .h1 {
    font-size: 50px;
    line-height: 65px;
  }

  .main-bnr-one .banner-inner .banner-content .sub-title {
    margin-bottom: 15px;
  }

  .main-bnr-one .banner-inner .banner-content .banner-btn {
    margin-top: 25px;
  }

  .main-bnr-one .banner-inner .banner-media img {
    height: 600px !important;
    object-fit: cover;
  }
}

@media only screen and (max-width: 767px) {
  .main-bnr-one .banner-inner {
    padding-top: 110px;
    height: 800px;
  }

  .main-bnr-one .banner-inner .banner-content {
    margin-bottom: 30px;
  }

  .main-bnr-one .banner-inner .banner-content .sub-title {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .main-bnr-one .banner-inner .banner-media {
    text-align: center;
  }

  .main-bnr-one .banner-inner .banner-media img {
    object-fit: cover;
    margin: auto;
    width: 250px;
    height: 375px !important;
  }
}

@media only screen and (max-width: 575px) {

  .main-bnr-one .banner-inner .banner-content h1,
  .main-bnr-one .banner-inner .banner-content .h1 {
    font-size: 45px;
    line-height: 55px;
  }

  .main-bnr-one .banner-inner .banner-content p {
    font-size: 14px;
  }
}

.main-bnr-one .main-thumb1 {
  width: 612px;
  height: auto;
  overflow: hidden;
}

.main-bnr-one .main-thumb1 .swiper-wrapper {
  height: auto;
}

.main-bnr-one .main-thumb1 .swiper-slide {
  width: 295px !important;
  opacity: 0.5;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
}

.main-bnr-one .main-thumb1 .swiper-slide.swiper-slide-thumb-active {
  opacity: 1;
}

.main-bnr-one .main-thumb1 .food-card {
  display: flex;
  align-items: center;
}

.main-bnr-one .main-thumb1 .food-card .dz-media {
  width: 80px;
  min-width: 80px;
  border-radius: var(--border-radius-base);
}

.main-bnr-one .main-thumb1 .food-card .dz-content {
  margin-left: 15px;
}

.main-bnr-one .main-thumb1 .food-card .dz-content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
  margin-bottom: 0;
}

.main-bnr-one .main-thumb1-area {
  padding: 0 50px;
  position: absolute;
  bottom: 55px;
  z-index: 999;
}

@media only screen and (max-width: 991px) {
  .main-bnr-one .main-thumb1-area {
    display: none;
  }
}

.main-bnr-one .swiper-slide.swiper-slide-active .banner-inner .banner-content .sub-title,
.main-bnr-one .swiper-slide.swiper-slide-active .banner-inner .banner-content .banner-btn,
.main-bnr-one .swiper-slide.swiper-slide-active .banner-inner .banner-content h1,
.main-bnr-one .swiper-slide.swiper-slide-active .banner-inner .banner-content .h1,
.main-bnr-one .swiper-slide.swiper-slide-active .banner-inner .banner-content p {
  transform: translateY(0);
  opacity: 1;
}

.main-bnr-one .swiper-slide.swiper-slide-active .banner-inner .banner-media {
  transform: translateY(0);
  opacity: 1;
}

.main-bnr-one .swiper-slide.swiper-slide-active .banner-inner .img2 {
  opacity: 1;
  transform: rotate(0);
}

.main-bnr-one .slider-pagination {
  position: absolute;
  width: auto;
  left: 50px;
  z-index: 99;
  top: 50%;
  transform: translateY(-50%);
}

.main-bnr-one .slider-pagination .main-button-next,
.main-bnr-one .slider-pagination .main-button-prev {
  position: static;
  align-items: center;
  color: var(--primary);
  z-index: 2;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  font-size: 30px;
  margin: 10px 0;
}

.main-bnr-one .slider-pagination .main-button-next i,
.main-bnr-one .slider-pagination .main-button-prev i {
  font-family: 'feather';
  font-style: normal;
}

@media only screen and (max-width: 991px) {

  .main-bnr-one .slider-pagination .main-button-next,
  .main-bnr-one .slider-pagination .main-button-prev {
    display: none;
  }
}

.main-bnr-one .slider-pagination .swiper-pagination-bullet {
  font-weight: 400;
  font-size: 24px;
  margin: 10px 0;
  text-align: center;
  opacity: 1;
  color: var(--rgba-primary-4);
  font-family: 'Lobster';
  display: block;
  height: 55px;
  width: 55px;
  line-height: 55px;
  border-radius: 0;
  background: transparent;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.main-bnr-one .slider-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  color: var(--primary);
}

@media only screen and (max-width: 1480px) {
  .main-bnr-one .slider-pagination {
    left: 20px;
  }
}

@media only screen and (max-width: 1280px) {
  .main-bnr-one .slider-pagination {
    right: 20px;
    left: auto;
  }
}

@media only screen and (max-width: 991px) {
  .main-bnr-one .slider-pagination {
    right: 10px;
  }

  .main-bnr-one .slider-pagination .swiper-pagination-bullet {
    font-size: 18px;
    height: 40px;
    width: 40px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .main-bnr-one .slider-pagination {
    right: 10px;
    top: auto;
    bottom: 15px;
    transform: translate(0);
  }
}

@keyframes move1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  25% {
    -webkit-transform: translate(-15px, -15px);
    transform: translate(-15px, -15px);
  }

  50% {
    -webkit-transform: translate(-8px, -8px);
    transform: translate(-8px, -8px);
  }

  75% {
    -webkit-transform: translate(-15px, 8px);
    transform: translate(-15px, 8px);
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@media only screen and (min-width: 1281px) {
  [data-layout="boxed"] .main-bnr-one .banner-inner::after {
    width: 80%;
  }

  [data-layout="boxed"] .main-bnr-one .slider-pagination {
    left: auto;
    right: 20px;
  }

  [data-layout="boxed"] .main-bnr-one .slider-pagination .swiper-pagination-bullet {
    color: #000;
  }

  [data-layout="boxed"] .main-bnr-one .slider-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    color: #000;
    opacity: 0.5;
  }

  [data-layout="boxed"] .main-bnr-one .slider-pagination .main-button-next,
  [data-layout="boxed"] .main-bnr-one .slider-pagination .main-button-prev {
    color: #000;
  }
}

.main-bnr-two {
  position: relative;
  align-items: center;
  z-index: 1;
  overflow: hidden;
}

.main-bnr-two .banner-inner {
  position: relative;
  z-index: 1;
  background-color: #000;
}

.main-bnr-two .banner-inner .banner-content {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  margin-right: -100px;
}

.main-bnr-two .banner-inner .banner-content .sub-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}

.main-bnr-two .banner-inner .banner-content h1,
.main-bnr-two .banner-inner .banner-content .h1 {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 1.2;
  margin-bottom: 10px;
}

.main-bnr-two .banner-inner .banner-content p {
  font-size: 18px;
  line-height: 28px;
  max-width: 522px;
}

.main-bnr-two .banner-inner .banner-content .banner-btn {
  margin-top: 42px;
}

@media only screen and (max-width: 1480px) {

  .main-bnr-two .banner-inner .banner-content h1,
  .main-bnr-two .banner-inner .banner-content .h1 {
    font-size: 70px;
  }
}

@media only screen and (max-width: 1280px) {
  .main-bnr-two .banner-inner .banner-content {
    margin-right: 0;
    margin-bottom: 0;
    margin-top: 30px;
  }

  .main-bnr-two .banner-inner .banner-content h1,
  .main-bnr-two .banner-inner .banner-content .h1 {
    font-size: 60px;
  }

  .main-bnr-two .banner-inner .banner-content p {
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 991px) {

  .main-bnr-two .banner-inner .banner-content h1,
  .main-bnr-two .banner-inner .banner-content .h1 {
    font-size: 42px;
  }
}

@media only screen and (max-width: 575px) {

  .main-bnr-two .banner-inner .banner-content h1,
  .main-bnr-two .banner-inner .banner-content .h1 {
    font-size: 36px;
  }

  .main-bnr-two .banner-inner .banner-content p {
    width: 100%;
  }

  .main-bnr-two .banner-inner .banner-content .banner-btn {
    margin-top: 20px;
  }
}

.main-bnr-two .banner-inner .banner-media {
  position: relative;
  min-width: 50vw;
  height: 100%;
  float: left;
}

.main-bnr-two .banner-inner .item-media {
  position: absolute;
  bottom: 50px;
  margin-left: -50px;
  width: 53vw;
}

.main-bnr-two .banner-inner .item-media img:not(.crown) {
  width: 100%;
}

.main-bnr-two .banner-inner .item-bg {
  position: relative;
  width: 50vw;
}

.main-bnr-two .banner-inner .crown {
  position: absolute;
  left: 18%;
  top: -12%;
  width: 80%;
}

.main-bnr-two .banner-inner .offer {
  position: absolute;
  top: -120px;
  right: 120px;
  width: auto !important;
}

.main-bnr-two .banner-inner .primary-box {
  position: relative;
  z-index: 1;
}

.main-bnr-two .banner-inner .primary-box::before,
.main-bnr-two .banner-inner .primary-box::after {
  position: absolute;
  content: "";
  z-index: 1;
  width: 214px;
  height: 214px;
  border-radius: 25px;
  background: var(--primary);
  transform: rotate(30deg);
  top: -127px;
  left: -117px;
}

.main-bnr-two .banner-inner .primary-box::after {
  background: var(--rgba-primary-2);
  top: -103px;
  left: -112px;
}

.main-bnr-two .banner-inner .primary-box.style-1::before,
.main-bnr-two .banner-inner .primary-box.style-1::after {
  width: 160px;
  height: 160px;
  bottom: 70px;
  left: -125px;
  top: auto;
}

.main-bnr-two .banner-inner .primary-box.style-1::after {
  bottom: 38px;
  left: -117px;
}

@media only screen and (max-width: 1680px) {
  .main-bnr-two .banner-inner .offer {
    top: -100px;
    right: 50px;
  }

  .main-bnr-two .banner-inner .item-media {
    bottom: 20px;
    margin-left: -30px;
  }
}

@media only screen and (max-width: 1480px) {
  .main-bnr-two .banner-inner .primary-box::before {
    left: -145px;
  }

  .main-bnr-two .banner-inner .primary-box::after {
    top: -119px;
    left: -140px;
  }

  .main-bnr-two .banner-inner .primary-box.style-1::before {
    left: -138px;
  }

  .main-bnr-two .banner-inner .primary-box.style-1::after {
    bottom: 50px;
    left: -140px;
  }
}

@media only screen and (max-width: 1280px) {

  .main-bnr-two .banner-inner .crown,
  .main-bnr-two .banner-inner .offer {
    display: none;
  }

  .main-bnr-two .banner-inner .primary-box {
    display: none;
  }

  .main-bnr-two .banner-inner .banner-media {
    width: 55vw;
  }

  .main-bnr-two .banner-inner .item-bg {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .main-bnr-two .banner-inner .banner-media {
    width: 60vw;
    transform: translateX(-50px);
  }

  .main-bnr-two .banner-inner .item-media {
    margin-left: 0;
    left: -60px;
    bottom: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .main-bnr-two .banner-inner {
    padding: 90px 0 50px;
    background-color: #791313;
  }

  .main-bnr-two .banner-inner .item-bg {
    display: none;
  }

  .main-bnr-two .banner-inner .banner-media {
    transform: unset;
  }

  .main-bnr-two .banner-inner .item-media {
    position: static;
  }
}

@media only screen and (max-width: 767px) {
  .main-bnr-two .banner-inner {
    padding: 80px 0 30px;
  }

  .main-bnr-two .banner-inner .banner-media {
    width: 100%;
  }

  .main-bnr-two .banner-inner .item-media {
    position: initial;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    width: 100%;
  }

  .main-bnr-two .banner-inner .item-media img {
    width: 100%;
    height: auto;
  }
}

.main-thumb2-area {
  display: flex;
  flex-direction: row;
  gap: 20px;
  position: relative;
  bottom: -130px;
  left: 0;
}

.main-thumb2-area .food-card {
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.main-thumb2-area .food-card .icon {
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  width: 78px;
  min-width: 78px;
  height: 78px;
  line-height: 78px;
  border-radius: var(--border-radius-base);
  background: var(--rgba-primary-1);
  color: var(--primary);
  text-align: center;
}

.main-thumb2-area .food-card .icon i {
  font-size: 45px;
}

.main-thumb2-area .food-card:hover .icon {
  background: var(--primary);
  color: #000;
}

@media only screen and (max-width: 1680px) {
  .main-thumb2-area {
    bottom: -80px;
  }
}

@media only screen and (max-width: 1280px) {
  .main-thumb2-area {
    bottom: -95px;
  }
}

@media only screen and (max-width: 1280px) {
  .main-thumb2-area {
    display: none;
  }
}

@media only screen and (min-width: 1281px) {
  [data-layout="boxed"] .main-bnr-two .banner-inner .item-media {
    width: 48vw;
  }
}

@media only screen and (min-width: 1481px) {
  [data-layout="boxed"] .main-bnr-two .banner-inner .item-media {
    width: 44vw;
  }
}

@media only screen and (min-width: 1681px) {
  [data-layout="boxed"] .main-bnr-two .banner-inner .item-bg {
    width: 45vw;
  }

  [data-layout="boxed"] .main-bnr-two .banner-inner .item-media {
    width: 40vw;
  }

  [data-layout="boxed"] .main-bnr-two .banner-inner .offer {
    top: -150px;
    right: 100px;
  }
}

.main-bnr-three {
  position: relative;
  align-items: center;
  z-index: 1;
  overflow: hidden;
  --slider-left-spacing: 80px;
}

@media only screen and (max-width: 1280px) {
  .main-bnr-three.top-space {
    transform: translateY(95px);
    margin-bottom: 75px;
  }
}

// .main-bnr-three .swiper-wrapper {
//   min-height: 825px;
//   height: calc(100vh - 95px); }
// @media only screen and (max-width: 1280px) {
//   .main-bnr-three .swiper-wrapper {
//     min-height: 600px;
//     height: 600px; } }
// @media only screen and (max-width: 991px) {
//   // .main-bnr-three .swiper-wrapper {
//   //   min-height: 580px;
//   //   height: 580px; } }
.main-bnr-three .swiper-slide {
  height: 100% !important;
}

.main-bnr-three .banner-inner {
  position: relative;
  border-radius: 10px;
  overflow: hidden !important;
}


.main-bnr-three .banner-inner::after {
  opacity: 0;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  left: auto;
  right: 0;
  border-radius: 30px;
}

@media only screen and (max-width: 1280px) {
  .main-bnr-three .banner-inner {
    margin-left: 0;
  }
}

@media only screen and (max-width: 991px) {

  .main-bnr-three .banner-inner::before,
  .main-bnr-three .banner-inner::after {
    width: 100%;
  }
}


.main-bnr-three .banner-inner .banner-content {
  position: relative;
  z-index: 2;
  padding: 20px;
}

.main-bnr-three .banner-inner .banner-content .sub-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  display: block;
  font-family: 'ProximaNovaExtraBold', arial, 'Helvetica Neue', sans-serif;
}

.main-bnr-three .banner-inner .banner-content h1,
.main-bnr-three .banner-inner .banner-content .h1 {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 1.2;
  margin-bottom: 16px;
  color: #000;
}

.main-bnr-three .banner-inner .banner-content .bnr-text {
  font-size: 18px;
  line-height: 27px;
  color: rgba(255, 255, 255, 0.7);
  max-width: 580px;
  font-weight: 300;
}

.main-bnr-three .banner-inner .banner-content .banner-btn {
  margin-top: 40px;
}

.main-bnr-three .banner-inner .banner-content .food-card {
  border: 1px dashed rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  width: 370px;
  position: relative;
}

.main-bnr-three .banner-inner .banner-content .food-card::before {
  content: "";
  position: absolute;
  border-radius: 100%;
  width: 13px;
  height: 13px;
  background: #F3B700;
  z-index: 1;
  bottom: 200px;
  right: -500px;
}

.main-bnr-three .banner-inner .banner-content .food-card .dz-head {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: space-between;
  padding: 15px 25px;
}

.main-bnr-three .banner-inner .banner-content .food-card .dz-head ul {
  display: flex;
}

.main-bnr-three .banner-inner .banner-content .food-card .dz-head ul li i {
  color: #EBBC16;
}

.main-bnr-three .banner-inner .banner-content .food-card .dz-body {
  display: flex;
  justify-content: space-between;
  padding: 16px 25px;
}

.main-bnr-three .banner-inner .banner-content .food-card .dz-body .dz-left .profile-info {
  display: flex;
}

.main-bnr-three .banner-inner .banner-content .food-card .dz-body .dz-left .profile-info .dz-media {
  min-width: 45px;
  width: 45px;
  height: 45px;
  border-radius: 100%;
}

.main-bnr-three .banner-inner .banner-content .food-card .dz-body .dz-left .profile-info .dz-content {
  margin-left: 8px;
}

.main-bnr-three .banner-inner .banner-content .food-card .dz-body .dz-left .profile-info .dz-content .title {
  margin-bottom: 3px;
}

.main-bnr-three .banner-inner .banner-content .food-card .dz-body .dz-left .profile-info .dz-content p {
  color: #C4C4C4;
  font-size: 13px;
  line-height: 20px;
}

.main-bnr-three .banner-inner .banner-content .food-card .dz-body .dz-left .text {
  font-size: 15px;
  margin-bottom: 0;
  color: #DCDCDC;
}

@media only screen and (max-width: 1280px) {
  .main-bnr-three .banner-inner .banner-content .food-card {
    display: none;
  }
}

@media only screen and (max-width: 1480px) {

  .main-bnr-three .banner-inner .banner-content h1,
  .main-bnr-three .banner-inner .banner-content .h1 {
    font-size: 70px;
  }
}

@media only screen and (max-width: 1280px) {
  .main-bnr-three .banner-inner .banner-content {
    margin-bottom: 0;
  }

  .main-bnr-three .banner-inner .banner-content h1,
  .main-bnr-three .banner-inner .banner-content .h1 {
    font-size: 60px;
  }

  .main-bnr-three .banner-inner .banner-content .bnr-text {
    max-width: 500px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 992px) {

  .main-bnr-three .banner-inner .banner-content h1,
  .main-bnr-three .banner-inner .banner-content .h1 {
    font-size: 50px;
  }

  .main-bnr-three .banner-inner .banner-content .bnr-text {
    width: 350px;
  }

  .outline-btn {
    padding: 10px 20px !important;
    font-size: 14px !important;
    width: 100% !important;
    max-width: 109px !important;
  }


  .main-bnr-three .banner-inner .banner-content {
    padding: 0px;
  }

  .main-bnr-three .banner-inner .banner-content .sub-title {
    font-size: 20px;
  }

  .main-bnr-three .banner-inner .banner-content h1,
  .main-bnr-three .banner-inner .banner-content .h1 {
    font-size: 40px;
  }

  .main-bnr-three .banner-inner::before,
  .main-bnr-three .banner-inner::after {
    height: 45vh;
  }

}

@media only screen and (max-width: 767px) {
  .main-bnr-three .banner-inner .banner-content .bnr-text {
    width: 100%;
  }

  .main-bnr-three .banner-inner .banner-content .banner-btn {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 575px) {

  .main-bnr-three .banner-inner .banner-content h1,
  .main-bnr-three .banner-inner .banner-content .h1 {
    font-size: 18px;
  }

  .main-bnr-three .banner-inner .banner-content .bnr-text {
    width: 350px;
  }

  .outline-btn {
    padding: 2px 5px !important;
    font-size: 14px !important;
    width: 100% !important;
    max-width: 109px !important;
  }

  .main-bnr-three .banner-inner .banner-content {
    padding: 0px;
  }

  .main-bnr-three .banner-inner .banner-content .sub-title {
    font-size: 14px;
  }

  .main-bnr-three .banner-inner .banner-content h1,
  .main-bnr-three .banner-inner .banner-content .h1 {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .main-bnr-three .banner-inner::before,
  .main-bnr-three .banner-inner::after {
    height: 22vh;
  }
  .solid_btn{
    padding: 5px 10px !important;
  }
  .solid_btn span{
    font-size: 12px !important;
  }
  .outline_btn{
    padding: 5px 10px !important;
  }
  .outline_btn span{
    font-size: 12px !important;
  }
  .main-bnr-three.top-space{
    margin-bottom: 30px !important;
  }
  .main-bnr-three .banner-inner .banner-content .banner-btn {
    margin-top: 20px;
}


  .main-bnr-three .banner-inner {
    background-size: 100% !important;
    background-repeat: no-repeat;
  }
}

.main-bnr-three .banner-inner .banner-media {
  position: relative;
  z-index: 1;
  height: 100%;
  float: left;
  min-width: 50vw;
  top: -42px;
  left: -37px;
}

.main-bnr-three .banner-inner .banner-media::after {
  content: "";
  position: absolute;
  border-radius: 100%;
}

.main-bnr-three .banner-inner .banner-media::after {
  width: 60vw;
  height: 60vw;
  border: 180px solid var(--primary);
  top: -25vw;
  right: -20vw;
  z-index: -1;
  transform: rotate(-45deg);
}

.main-bnr-three .banner-inner .banner-media img {
  height: 35vh;
  margin-left: 50px;
  text-align: center;
  transform: rotate(-25deg);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

@media only screen and (max-width: 1680px) {
  .main-bnr-three .banner-inner .banner-media {
    left: -100px;
  }

  .main-bnr-three .banner-inner .banner-media::after {
    border: 100px solid var(--primary);
  }
}

@media only screen and (max-width: 1280px) {
  .main-bnr-three .banner-inner .banner-media {
    left: -30px;
  }

  .main-bnr-three .banner-inner .banner-media::after {
    border: 80px solid var(--primary);
  }
}

@media only screen and (max-width: 991px) {
  .main-bnr-three .banner-inner .banner-media {
    left: -30px;
    top: -30px;
  }

  .main-bnr-three .banner-inner .banner-media img {
    width: 60vw;
    min-width: 60vw;
  }

  .main-bnr-three .banner-inner .banner-media::after {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .main-bnr-three .banner-inner .banner-media {
    left: 0;
    width: 100%;
  }

  .main-bnr-three .banner-inner .banner-media img {
    width: 85vw;
    min-width: 85vw;
  }
}

@media only screen and (max-width: 575px) {
  .main-bnr-three .banner-inner .banner-media img {
    width: 90vw;
    min-width: 90vw;
  }
}

.main-bnr-three .banner-inner .target-line {
  position: absolute;
  bottom: 50px;
  right: -100%;
  z-index: 1;
  transform: translateX(25%);
  min-width: 485px;
}

@media only screen and (max-width: 1480px) {
  .main-bnr-three .banner-inner .target-line {
    display: none;
  }
}

.main-bnr-three .banner-inner .leaf {
  position: absolute;
  top: 0;
  left: 48.5%;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .main-bnr-three .banner-inner .leaf {
    display: none;
  }
}

.main-bnr-three .swiper-slide-visible.swiper-slide-active .banner-inner .banner-media img {
  transform: rotate(0deg);
}

.main-bnr-three .swiper-bnr-pagination {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: var(--slider-left-spacing);
  height: 100%;
  position: absolute;
}

@media only screen and (max-width: 575px) {
  .main-bnr-three .swiper-bnr-pagination {
    width: auto;
    right: 0;
  }
}

.main-bnr-three .swiper-bnr-pagination .main-button-next,
.main-bnr-three .swiper-bnr-pagination .main-button-prev {
  color: var(--primary);
  font-size: 30px;
  margin: 10px 0;
}

.main-bnr-three .swiper-bnr-pagination .main-button-next i,
.main-bnr-three .swiper-bnr-pagination .main-button-prev i {
  font-family: 'feather';
  font-style: normal;
}

@media only screen and (max-width: 1280px) {

  .main-bnr-three .swiper-bnr-pagination .main-button-next,
  .main-bnr-three .swiper-bnr-pagination .main-button-prev {
    display: none;
  }
}

.main-bnr-three .main-swiper3-pagination {
  position: static;
  transform: unset !important;
  height: auto !important;
  z-index: 9999;
}

.main-bnr-three .main-swiper3-pagination .swiper-pagination-bullet {
  font-weight: 400;
  font-size: 24px;
  line-height: 55px;
  margin: 10px 0;
  text-align: center;
  opacity: 1;
  color: var(--rgba-primary-4);
  font-family: 'Lobster';
  display: block;
  width: 55px;
  border-radius: 0;
  height: 55px;
  background: transparent;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.main-bnr-three .main-swiper3-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  color: var(--primary);
}

@media only screen and (max-width: 1320px) {
  .main-bnr-three .main-swiper3-pagination {
    left: 0;
  }

  .main-bnr-three .main-swiper3-pagination .swiper-pagination-bullet {
    font-size: 22px;
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
}

@media only screen and (max-width: 1280px) {
  .main-bnr-three .main-swiper3-pagination .swiper-pagination-bullet {
    display: inline-block;
  }
}

@media only screen and (max-width: 575px) {
  .main-bnr-three .main-swiper3-pagination {
    width: 30px;
  }

  .main-bnr-three .main-swiper3-pagination .swiper-pagination-bullet {
    font-size: 16px;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 1281px) {
  [data-layout="boxed"] .main-bnr-three .banner-inner .banner-media img {
    width: 40vw;
    min-width: 40vw;
  }
}

@media only screen and (min-width: 1481px) {
  [data-layout="boxed"] .main-bnr-three .banner-inner .banner-media::after {
    border: 120px solid var(--primary);
    top: -20vw;
    right: -8vw;
    width: 50vw;
    height: 50vw;
  }

  [data-layout="boxed"] .main-bnr-three .banner-inner .banner-media img {
    width: 32vw;
    min-width: 32vw;
  }

  [data-layout="boxed"] .main-bnr-three .banner-inner .banner-media {
    min-width: 50vw;
    top: -40px;
    left: -100px;
  }
}

.move-1 {
  -webkit-animation: move1 5s infinite;
  animation: move1 5s infinite;
}

.move-2 {
  -webkit-animation: move2 5s infinite;
  animation: move2 5s infinite;
}

.move-3 {
  -webkit-animation: move3 5s infinite;
  animation: move3 5s infinite;
}

.move-4 {
  -webkit-animation: move4 5s infinite;
  animation: move4 5s infinite;
}

.move-5 {
  -webkit-animation: move5 5s infinite;
  animation: move5 5s infinite;
}

.move-6 {
  -webkit-animation: move6 5s infinite;
  animation: move6 5s infinite;
}

.move-7 {
  -webkit-animation: move7 5s infinite;
  animation: move7 5s infinite;
}

.move-lr {
  animation: move-lr 3s infinite linear;
}

.rotate-360 {
  animation: rotate-360 30s infinite linear;
}

@keyframes rotate-360 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@-webkit-keyframes move1 {
  0% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px);
  }

  50% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px);
  }

  100% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px);
  }
}

@-webkit-keyframes move2 {
  0% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px);
  }

  50% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px);
  }

  100% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px);
  }
}

@-webkit-keyframes move3 {
  0% {
    transform: translate(0, 0);
  }

  20% {
    transform: translate(5px, 0);
  }

  40% {
    transform: translate(5px, 5px);
  }

  65% {
    transform: translate(0, 5px);
  }

  65% {
    transform: translate(5px, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes move4 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  25% {
    -webkit-transform: translate(10px, 10px);
    transform: translate(10px, 10px);
  }

  50% {
    -webkit-transform: translate(5px, 5px);
    transform: translate(5px, 5px);
  }

  75% {
    -webkit-transform: translate(10px, -5px);
    transform: translate(10px, -5px);
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes move5 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  25% {
    -webkit-transform: translate(-10px, -10px);
    transform: translate(-10px, -10px);
  }

  50% {
    -webkit-transform: translate(-5px, -5px);
    transform: translate(-5px, -5px);
  }

  75% {
    -webkit-transform: translate(-10px, 5px);
    transform: translate(-10px, 5px);
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes move6 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  25% {
    -webkit-transform: translate(15px, 15px);
    transform: translate(15px, 15px);
  }

  50% {
    -webkit-transform: translate(8px, 8px);
    transform: translate(8px, 8px);
  }

  75% {
    -webkit-transform: translate(15px, -8px);
    transform: translate(15px, -8px);
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes move7 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  25% {
    -webkit-transform: translate(-15px, -15px);
    transform: translate(-15px, -15px);
  }

  50% {
    -webkit-transform: translate(-8px, -8px);
    transform: translate(-8px, -8px);
  }

  75% {
    -webkit-transform: translate(-15px, 8px);
    transform: translate(-15px, 8px);
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes move-lr {
  0% {
    transform: rotate(0);
  }

  10% {
    transform: rotate(-15deg);
  }

  20% {
    transform: rotate(20deg);
  }

  30% {
    transform: rotate(-24deg);
  }

  40% {
    transform: rotate(21deg);
  }

  50% {
    transform: rotate(-18deg);
  }

  60% {
    transform: rotate(14deg);
  }

  70% {
    transform: rotate(-11deg);
  }

  80% {
    transform: rotate(7deg);
  }

  90% {
    transform: rotate(-3deg);
  }

  100% {
    transform: rotate(1deg);
  }
}