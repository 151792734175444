// ABOUT US ORIGINAL
.about_special_section {
  background-color: #ffffff;

  .section-head {
    margin-bottom: 40px;
  }

  .section_title {
    color: #000;
  }

  .special_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;

    .special_imgs {
      border-radius: 35px;
    }
    .special_item_lower {
      padding: 30px 10px 20px 10px;

      .sp_lower_title {
        font-size: 30px;
        font-weight: 500;
        font-family: "Mulish", sans-serif;
        color: #000;
        text-align: center;
      }

      .sp_lower_desc {
        font-size: 14px;
        font-weight: 200;
        font-family: "Mulish", sans-serif;
        color: #000;
        text-align: center;
        letter-spacing: 0.3px;
      }
    }
  }
}

// OUR STORY
.real_story {
  margin: 30px 0px;

  .story_img {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    max-width: 100%;
    height: auto;
    width: 100%;
  }
  .story_container {
    .story_title {
      font-size: 36px;
      font-family: "Mulish", sans-serif;
      font-weight: 700;
      color: #000;
      margin: 10px 0px;
    }

    
  }
}
.story_content {
  flex-basis: 0;
  flex-grow: 1;
  font-size: 18px;
  font-weight: 200;
  font-family: "Mulish", sans-serif;
  margin-bottom: 35px;
  color: #000;
}
.branch {
  margin: 20px 0px;
}
.what_makes_us {
  font-size: 42px;
}

.about-media {
  height: 430px;
}

.about-media img {
  height: 100% !important;
  object-fit: cover;
}

@media only screen and (max-width: 991px) {
  .about-media {
    height: 380px;
  }
}

@media only screen and (max-width: 575px) {
  .about-media {
    height: 300px;
  }
}

.dz-box {
  position: relative;
}

.portfolio-slider .swiper-slide {
  width: auto;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.portfolio-wrapper {
  position: relative;
  z-index: 2;
  padding-top: 80px;
  padding-bottom: 50px;
}

.portfolio-wrapper .portfolio-wrapper-inner {
  z-index: 1;
  position: relative;
  overflow: hidden;
  padding-bottom: 125px;
}

.portfolio-wrapper .bg-image {
  margin-bottom: -2px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media only screen and (max-width: 1280px) {
  .portfolio-wrapper .portfolio-wrapper-inner {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-wrapper {
    padding-top: 40px;
    padding-bottom: 0px;
  }

  .portfolio-wrapper .portfolio-wrapper-inner {
    padding-bottom: 50px;
  }
}

.testimonial-pic {
  background: #fff;
  width: 100px;
  height: 100px;
  position: relative;
  display: inline-block;
  border: 5px solid #fff;
}

.testimonial-pic.radius {
  border-radius: 100%;
  -webkit-border-radius: 100%;
}

.testimonial-pic.radius img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  -webkit-border-radius: 100%;
}

.testimonial-pic.shadow {
  -webkit-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
  box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
}

.quote-left,
.quote-right {
  position: relative;
}

.quote-left::before,
.quote-right::before {
  font-family: "Flaticon";
  position: absolute;
  z-index: 10;
  height: 36px;
  width: 36px;
  line-height: 36px;
  background-color: var(--primary);
  color: #000;
  border-radius: 30px;
  text-align: center;
  font-size: 18px;
}

.quote-left::before {
  top: 15px;
  content: "\f115";
  left: 0;
}

.quote-right::before {
  top: 15px;
  content: "\f11e";
  right: 0;
}

.testimonial-text {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  margin: 15px 0;
}

.testimonial-text p:last-child {
  margin: 0;
}

.testimonial-detail {
  padding: 5px;
}

.testimonial-position {
  font-size: 15px;
}

.testimonial-name,
.testimonial-position {
  display: block;
}

.testimonial-name {
  color: #222;
}

.star-rating li {
  display: inline-block;
}

.section-wrapper-1 {
  position: relative;
  z-index: 1;
}

.section-wrapper-1::after {
  content: "";
  position: absolute;
  z-index: -1;
  background-color: rgba(21, 21, 21, 0.7);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(6px);
}

.section-wrapper-2 {
  position: relative;
}

.section-wrapper-2 .bg1,
.section-wrapper-2 .bg2 {
  position: absolute;
}

.section-wrapper-2 .bg1 {
  bottom: 0;
  left: -275px;
}

.section-wrapper-2 .bg2 {
  top: 100px;
  right: 40px;
}

.section-wrapper-2.right .bg1 {
  bottom: 0;
  right: -355px;
  left: auto;
}

@media only screen and (max-width: 1680px) {
  .section-wrapper-2 .bg1 {
    left: -320px;
  }

  .section-wrapper-2 .bg2 {
    top: 90px;
    right: 15px;
  }
}

@media only screen and (max-width: 1480px) {
  .section-wrapper-2 .bg1 {
    display: none;
  }

  .section-wrapper-2 .bg2 {
    top: 28px;
    right: 0px;
  }

  .section-wrapper-2.right .bg1 {
    display: none;
  }
}

@media only screen and (max-width: 1480px) and (max-width: 1280px) {
  .section-wrapper-2 .bg2 {
    display: none;
  }
}

.section-wrapper-3 {
  position: relative;
  z-index: 1;
}

// .section-wrapper-3::before {
//   content: "";
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   background-color: ;
//   z-index: -1; }
// .section-wrapper-3::after {
//   content: "";
//   height: 200px;
//   width: 100%;
//   background-color: #000;
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   z-index: -1; }
.section-wrapper-3 .inner-section-wrapper {
  margin-bottom: 80px;
}

.section-wrapper-3 .bg1,
.section-wrapper-3 .bg2 {
  position: absolute;
  z-index: -1;
}

.section-wrapper-3 .bg2 {
  top: 255px;
  right: 85px;
}

.section-wrapper-3 .bg1 {
  bottom: 300px;
  left: 70px;
}

@media only screen and (max-width: 1680px) {
  .section-wrapper-3 .bg2 {
    top: 120px;
    right: 40px;
  }

  .section-wrapper-3 .bg1 {
    bottom: 280px;
    left: 30px;
  }

  .section-wrapper-3 .inner-section-wrapper {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 1480px) {
  .section-wrapper-3 .bg2 {
    top: 50px;
    right: 30px;
  }

  .section-wrapper-3 .bg1 {
    bottom: 350px;
    left: 10px;
  }
}

@media only screen and (max-width: 1280px) {
  .section-wrapper-3 .inner-section-wrapper {
    margin-bottom: 60px;
  }

  // .section-wrapper-3 .bg1, .section-wrapper-3 .bg2 {
  //   display: none; }
}

@media only screen and (max-width: 767px) {
  .section-wrapper-3 .inner-section-wrapper {
    margin-bottom: 45px;
  }

  .pagination {
    display: none !important;
  }
}

.section-wrapper-4 {
  margin-left: 100px;
  margin-right: 100px;
  background-color: var(--bg-light);
  border-radius: 30px;
}

@media only screen and (max-width: 1480px) {
  .section-wrapper-4 {
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 0;
  }
}

.section-wrapper-5 {
  margin-left: 100px;
  margin-right: 100px;
  z-index: 1;
  position: relative;
  border-radius: 30px;
}

.section-wrapper-5::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(6px);
  background-color: rgba(21, 21, 21, 0.7);
  width: 100%;
  height: 100%;
  z-index: -1;
}

@media only screen and (max-width: 1480px) {
  .section-wrapper-5 {
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 0;
  }
}

.section-wrapper-6 {
  position: relative;
  z-index: 1;
}

.section-wrapper-6::after {
  content: "";
  height: 125px;
  width: 100%;
  background-color: rgba(34, 34, 34, 0.05);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.section-wrapper-6 .inner-section-wrapper {
  margin-bottom: 80px;
}

@media only screen and (max-width: 767px) {
  .section-wrapper-6 .inner-section-wrapper {
    margin-bottom: 45px;
  }
}

@media only screen and (max-width: 575px) {
  .section-wrapper-6 .inner-section-wrapper {
    margin-bottom: 25px;
  }

  .dz-img-box.style-3 .dz-tag {
    border-radius: 0px 0px 10px;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
  }

  .dz-media img,
  .dz-post-media img {
    height: 80px !important;
    width: 80px !important;
    border-radius: 10px;
  }

  .dz-img-box.style-3 {
    width: 90px;
    height: 98px;
  }

  .dz-img-box.style-3 .dz-media {
    height: 98px !important;
  }

  .dz-img-box.style-3 .dz-hover-content {
    display: none !important;
  }

  .dz-title {
    font-size: 14px;
  }

  .dz-content {
    font-size: 10px;
  }
}

.section-wrapper-7 {
  position: relative;
  z-index: 1;
}

.section-wrapper-7::after {
  content: "";
  width: 850px;
  height: 850px;
  background-color: #ffeebb;
  position: absolute;
  top: 110px;
  right: -19%;
  z-index: -2;
  filter: blur(9px);
  border-radius: 100%;
}

.section-wrapper-7 .inner-section-wrapper {
  margin-bottom: 50px;
}

.section-wrapper-7 .bg1 {
  position: absolute;
  top: 85px;
  left: -110px;
}

.section-wrapper-7 .bg2 {
  position: absolute;
  right: -54px;
  top: 30px;
  z-index: -1;
}

@media only screen and (max-width: 1480px) {
  .section-wrapper-7::after {
    width: 700px;
    height: 700px;
    top: 45px;
    right: -22%;
  }

  .section-wrapper-7 .bg1 {
    display: none;
  }

  .section-wrapper-7 .bg2 {
    width: 600px;
  }
}

@media only screen and (max-width: 1280px) {
  .section-wrapper-7::after {
    width: 600px;
    height: 600px;
  }

  .section-wrapper-7 .bg2 {
    width: 500px;
  }
}

@media only screen and (max-width: 1024px) {
  .section-wrapper-7::after {
    display: none;
  }

  .section-wrapper-7 .bg2 {
    top: auto;
    bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .section-wrapper-7 .bg2 {
    display: none;
  }

  .section-wrapper-7 .inner-section-wrapper {
    margin-bottom: 0px;
  }
}

.section-wrapper-8 {
  position: relative;
  background-color: rgba(34, 34, 34, 0.04);
  border-radius: 30px;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 270px;
  margin-bottom: 100px;
  padding-left: 30px;
  padding-right: 30px;
}

.section-wrapper-8 .inner-section-wrapper {
  margin-bottom: 80px;
  margin-top: -215px;
}

@media only screen and (max-width: 1480px) {
  .section-wrapper-8 {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 190px;
    margin-bottom: 70px;
  }

  .section-wrapper-8 .inner-section-wrapper {
    margin-top: -170px;
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .section-wrapper-8 {
    background-color: #000;
    margin: 0;
  }

  .section-wrapper-8 .inner-section-wrapper {
    margin-top: 0;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-wrapper-8 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .section-wrapper-8 .inner-section-wrapper {
    margin-bottom: 45px;
  }
}

// .img_btn_container{
//   position: absolute;
//   // top: 121px;
//   top: 0px;
//   right: 0px;
//   display: flex;
//   justify-content: space-between;
// }
