.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 0.9375rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0rem rgba(125, 166, 64, 0.25);
  --bs-pagination-active-color: #000;
  --bs-pagination-active-bg: #2a3b19;
  --bs-pagination-active-border-color: #2a3b19;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}

.page-link.active,
.active>.page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled,
.disabled>.page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.17188rem;
  --bs-pagination-border-radius: 1rem;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.82031rem;
  --bs-pagination-border-radius: 0.75rem;
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #000;
  --bs-badge-border-radius: 0.25rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}

.badge:empty {
  display: none;
}

.btn .badge,
.wp-block-button__link .badge {
  position: relative;
  top: -1px;
}

.pagination {
  justify-content: center;
}

.pagination .page-item {
  margin: 0 8px;
}

.pagination .page-item .page-link {
  height: 45px;
  min-width: 45px;
  line-height: 45px;
  padding: 0;
  border: 0;
  text-align: center;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
  background-color: #000;
  border-radius: 8px;
}

.pagination .page-item .page-link.current,
.pagination .page-item .page-link.active,
.pagination .page-item .page-link:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #000;
}

.pagination.pagination-md .page-item .page-link {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
}

.pagination.pagination-sm .page-item .page-link {
  padding: 0;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  text-align: center;
}

.pagination.pagination-xs .page-item .page-link {
  padding: 0;
  min-width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  text-align: center;
}

.pagination-primary .page-item .page-link {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: #222222;
}

.pagination-primary .page-item .page-link:hover,
.pagination-primary .page-item .page-link.active {
  color: #000;
  background: var(--primary);
  border-color: var(--primary);
  box-shadow: 0 0.625rem 1.25rem 0rem rgba(255, 193, 180, 0.3);
}

.pagination-danger .page-item .page-link {
  background: rgba(255, 0, 3, 0.1);
  border-color: rgba(255, 0, 3, 0.1);
  color: #ff0003;
}

.pagination-danger .page-item .page-link:hover,
.pagination-danger .page-item .page-link.active {
  background: #ff0003;
  border-color: #ff0003;
  color: #000;
  box-shadow: 0 0.625rem 1.25rem 0rem rgba(255, 0, 3, 0.2);
}

.pagination-info .page-item .page-link {
  background: rgba(0, 174, 255, 0.1);
  border-color: rgba(0, 174, 255, 0.1);
  color: #00aeff;
}

.pagination-info .page-item .page-link:hover,
.pagination-info .page-item .page-link.active {
  background: #00aeff;
  border-color: #00aeff;
  color: #000;
  box-shadow: 0 0.625rem 1.25rem 0rem rgba(0, 174, 255, 0.2);
}

.pagination.style-1 {
  display: flex;
  align-items: center;
}

.pagination.style-1 .main-button-next {
  top: auto !important;
  bottom: 30%;
}

.pagination.style-1 .main-button-next,
.pagination.style-1 .main-button-prev {
  position: absolute;
  top: 30%;
  align-items: center;
  color: var(--primary);
  z-index: 2;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  left: 105px;
  font-size: 30px;
  margin: 10px 0;
}

.pagination.style-1 .main-button-next i,
.pagination.style-1 .main-button-prev i {
  font-family: 'feather';
  font-style: normal;
}

@media only screen and (max-width: 1480px) {

  .pagination.style-1 .main-button-next,
  .pagination.style-1 .main-button-prev {
    left: 23px;
  }
}

@media only screen and (max-width: 1320px) {

  .pagination.style-1 .main-button-next,
  .pagination.style-1 .main-button-prev {
    left: 10px;
  }
}

@media only screen and (max-width: 1280px) {

  .pagination.style-1 .main-button-next,
  .pagination.style-1 .main-button-prev {
    display: none;
  }
}

.pagination.style-1.left-align .main-button-next,
.pagination.style-1.left-align .main-button-prev {
  left: 10px;
  top: 30%;
}

.pagination.style-1.left-align .main-button-next {
  bottom: 30%;
}

.pagination.style-1.bottom-align .main-button-next,
.pagination.style-1.bottom-align .main-button-prev {
  left: 10px;
  bottom: 30%;
}

.pagination.style-1.bottom-align .main-button-next {
  bottom: 30%;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #791313 !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet {
  background: #ccc !important;
  opacity: 1 !important;
}
.swiper-pagination {
  position: relative !important;
  padding-top: 20px;
}