.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas .btn-close {
  margin: 15px 20px;
  color: #000;
}

.fade {
  transition: opacity 0.15s linear;
}

.offcanvas {
  z-index: 999999 !important;
  border: 0;
}

.offcanvas-form .login-head .title {
  font-size: 30px;
}

.offcanvas-form .login-head {
  text-align: center;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.offcanvas .offcanvas-body {
  padding: 0;
  overflow-y: initial;
}

.offcanvas-form {
  background: #ffffff;
  padding: 0px 50px;
}
.offcanvas_back_btn {
  margin: 10px 0px 20px 0px;
  cursor: pointer;
}
.offcanvas.offcanvas-end {
  width: 500px;
  overflow-y: auto;
  transition: all 1s ease;
}

.offcanvas-form input {
  height: 40px !important;
  border-radius: 6px !important;
}



.offcanvas {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #ffffff;
  background-clip: padding-box;
  outline: 0;
}


.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: all 1s ease;
}


.offcanvas.showing,
.offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing,
.offcanvas.hiding,
.offcanvas.show {
  visibility: visible;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}


.offcanvas-title {
  margin-bottom: 0;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}