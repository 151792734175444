.Avatar_Container {
  width: 25px;
  height: 25px;
  border-radius: 0.375rem;
  margin-right: 5px;
}

.Avatar {
  color: #ffffff;
  height: 100%;
  width: 100%;
  border-radius: 10%; 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px; 
  font-weight: 700;
  border-radius: 30px;
  background-color: #791313;
}
.is-fixed .Avatar{
  background-color: #791313;
  color: #ffffff;
}

.avatar_image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.Small_Avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%; // Add this line to apply border-radius
}
