.veritical-line {
  padding: 20px 30px;
  border-top: 1px solid #d7dae3;
  border-right: 1px solid #d7dae3;
  border-bottom: 1px solid #d7dae3;
  position: relative;
}
.veritical-line:before {
  background: #d7dae3;
  bottom: 0;
  content: '';
  height: 100%;
  left: -1px;
  max-height: 40%;
  position: absolute;
  width: 1px;
}

.tab-content-text p {
  color: #181c32;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 25px;
}

.tab-item-list li a {
  background: #fff;
  border-top: 1px solid #d7dae3;
  border-left: 1px solid #d7dae3;
  border-right: 1px solid #d7dae3;
  color: #181c32;
  display: block;
  font-size: 16px;
  padding: 16px;
  text-transform: uppercase;
}
.tab-item-list li a:hover,
.tab-item-list li a:focus {
  background: #fff;
  color: #181c32;
  border-right: 0px;
}

.tab-item-list li:last-child {
  border-bottom: 1px solid #d7dae3;
}

.tab-list li {
  margin-bottom: 7px;
  font-size: 13px;
}
.tab-list li i {
  font-size: 13px;
  margin-right: 14px;
}

.slide-item-list {
  text-align: center;
  margin: 0 -0.3125rem;
  border: 0;
}
.slide-item-list .nav-link {
  border: 0;
  border-radius: 0.375rem;
}
.slide-item-list .nav-link:hover {
  border: 0;
}
.slide-item-list .nav-link.active {
  border: 0;
  border-radius: 0.375rem;
}
.slide-item-list li {
  display: inline-block;
  flex: 0 0 25%;
  width: 25%;
  padding: 0 0.3125rem;
}
.slide-item-list li a {
  display: inline-block;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  background: transparent;
  padding-bottom: 0rem;
}
.slide-item-list li a:hover,
.slide-item-list li a:focus {
  background: transparent;
}
.slide-item-list li a img {
  width: 100%;
  border-radius: 0.375rem !important;
}
