.faq_hero {
    background-image: url("../../../images/footer/footerBackground.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    line-height: 1;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 350px;
    background-attachment: fixed;

    .subheader {
        height: 350px;
        width: 100%;
        display: table;
        text-align: center;

        .subcontent {
            display: table-cell;
            padding: 0 15%;
            vertical-align: middle;

            .subheader_title {
                margin: 0 0 10px 0;
                font-size: 26px;
                font-weight: 300;
                color: #fff;
                font-family: inherit;
            }
        }

    }
}

.faqs {
    padding-top: 60px;
    padding-bottom: 60px;
}

.accordion.accordion-1 .panel {
    box-shadow: 0 1px 5px rgba(40, 40, 40, 0.1);
    border-radius: 1px;
    background-color: #fff;
    margin-bottom: 30px;
    border-bottom-color: rgba(49, 49, 49, 0.1);
    padding: 10px;
    border: 1px solid transparent;
}

.accordion .panel .panel--heading {
    padding: 19px;
    border: 1px dashed #791313;
    cursor: pointer;
}

.accordion .panel .panel--heading p {
    font-family: inherit;
    font-size: 16px;
    color: #282828;
    font-weight: 600;
    text-transform: capitalize;
    position: relative;
    display: block;
    line-height: 27px;
}

.accordion .panel .panel--body {
    padding: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    background-color: #f5f5f5;
    margin-top: 10px;
    transition-timing-function: ease;
    transition-duration: 0.35s;
}

.widget-info {
    background-color: #f6f6f6;
    padding: 50px 36px;
    text-align: center;
    position: relative;
}

.divider--shape-10up {
    width: 124px;
    height: 13px;
    display: none;
    background: url("../../../images/shape-1.png") center no-repeat;
    position: absolute;
    top: -13px;
    left: 50%;
    margin-left: -62px;
}

.divider--shape-10down {
    width: 124px;
    height: 13px;
    display: none;
    background: url("../../../images/shape-2.png") center no-repeat;
    position: absolute;
    bottom: -13px;
    left: 50%;
    margin-left: -62px;
}

.widget-info .info--title {
    color: #000;
    font-family: inherit;
    font-size: 25px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 20px;
    position: relative;
}

.widget-info .info--content {
    color: #9b9b9b;
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    font-style: italic;
    margin-bottom: 23px;
}

.btn--primary {
    background-color: #791313;
    color: #fff;
    border: 1px solid transparent;
}

.btn--rounded {
    border-radius: 50px;
}

// ABOUT 


.about_hero {
    background: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../../images/footer/footerBackground.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    line-height: 1;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 250px;
    z-index: 1;


    .subheader {
        height: 350px;
        width: 100%;
        display: table;
        text-align: center;

        .subcontent {
            display: table-cell;
            padding: 0 15%;
            vertical-align: middle;

            .subheader_title {
                margin: 0 0 10px 0;
                font-size: 26px;
                font-weight: 300;
                color: #fff;
                font-family: inherit;
            }

            .subheader_content {
                margin: 0 0 10px 0;
                font-size: 32px;
                font-weight: 600;
                color: #ffffff;
                font-family: inherit;
            }
        }

    }
}

.about_hero::before {
    content: "";
    background-color: rgba(27, 26, 26, 0.25);
    background-image: linear-gradient(180deg, rgba(27, 26, 26, 0.75) 0%, rgba(27, 26, 26, 0) 34%, rgba(27, 26, 26, 0) 82%, rgba(27, 26, 26, 0) 100%);
    display: inline-block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.about_content_container {
    padding-bottom: 60px;
    padding-top: 60px;
    text-align: center;

    .heading .heading--title {
        font-family: 'Abril Fatface', cursive;
        color: #222222;
        font-size: 36px;
        font-weight: 400;
        line-height: 1;
        margin-bottom: 25px;
    }

    .heading .heading--desc {
        font-family: 'Poppins', sans-serif;
        color: #a5a5a5;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 0;
        padding: 0 40px;
    }

    .banner .banner-panel .banner--img {
        margin-bottom: 28px;
        position: relative;
    }
}

.mb-50 {
    margin-bottom: 50px;
}

.about_content_container .banner-panel .banner--img {
    margin-bottom: 28px;
    position: relative;
}

.about_content_container .banner--img:after {
    position: absolute;
    content: "";
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border: 1px dashed #e2e2e2;
    opacity: 0.45;
    z-index: 1;
}

.our_story_container {
    padding: 60px 0px;

    .panel--heading {
        font-size: 36px;
        text-align: center;
        font-family: 'Ruthie', cursive;
        color: #333333;
        font-weight: 400;
        margin-bottom: 40px;
    }

    .panel--logos {
        padding-left: 0;
        margin-left: -5px;
        list-style: none;
        margin-bottom: 50px;
        text-align: center;
    }

    .panel--logos li {
        padding-right: 15px;
        padding-left: 15px;
    }

    .panel--logos>li {
        display: inline-block;
    }

    .panel--details {
        padding: 0;
        margin-bottom: 0;
    }

    .panel--details.text-right li {
        padding-right: 20px;
        padding-left: 0;
    }

    .text-right {
        text-align: right;
    }

    .text-left {
        text-align: left;
    }

    .panel--details li {
        font-size: 13px;
        line-height: 30px;
        list-style: none;
        position: relative;
        padding-left: 20px;
        color: #9b9b9b;
    }

    .panel--details.text-right li::before {
        right: 0;
    }

    .panel--details li::before {
        content: "•";
        color: #222222;
        font-size: 20px;
        position: absolute;
        left: 0;
    }
}

.mb-30 {
    margin-bottom: 30px;
}

.contact_section {
    padding: 60px 0px;
    background-color: #ffffff;

    .contact--desc {
        margin-bottom: 40px;

        .contact--desc--content {
            font-family: 'Mulish', sans-serif;
            color: #000;
        }
    }

    .contact--info h3 {
        font-size: 17px;
        font-weight: 800;
        line-height: 1;
        margin-bottom: 10px;
        font-family: 'Mulish', sans-serif;
        color: #000;
    }

    .contact--info p {
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
        margin-bottom: 0;
        font-family: 'Mulish', sans-serif;
        color: #000;
    }

    .contact--info p:last-of-type {
        margin-bottom: 6px;
    }

    .contact--info a.link--styled {
        color: #791313;
        font-size: 14px;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        position: relative;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    .contact-form {
        @media screen and (max-width: 768px) {
            margin-top: 50px;
        }
        .form-control {
            font-family: 'Mulish', sans-serif;
            font-size: 13px;
            font-weight: 400;
            line-height: 49px;
            border: 1px solid #dbd9d9;
            background: transparent;
            padding-left: 18px;
            margin-bottom: 30px;
            position: relative;
            -moz-border-radius: 2px;
            -webkit-border-radius: 2px;
            border-radius: 2px;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            height: 49px;
        }

        textarea.form-control {
            height: auto;
        }
    }

    .contact_submit_btn {
        background-color: #791313;
        color: #ffffff;
        border: 2px solid #791313;
        width: 100%;
        font-family: 'Mulish', sans-serif;
        position: relative;
        z-index: 2;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        border-radius: 0;
        padding: 0;
        line-height: 49px;
        height: 51px;
        border-radius: 5px;
        letter-spacing: 0.28px;
    }

    
    .contact--info .social-icons a {
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 10px;
        background-color: #791313;
        color: #791313;
        display: inline-block;
        margin-right: 10px;
    }
}

.follow_Us{
    color: #999999;
    font-weight: 600;
    font-size: 12px;
    margin: 0 5px 0px;
    font-family: 'Mulish', sans-serif;
    line-height: 1.1;
    letter-spacing: 0.01em;
    text-transform: uppercase;
}

.footerIcon{
    color: #ffffff;
    margin: 0px 10px 0px 0px;
    width: 13px;
    height: 13px;
}

.help_sc_Icon{
    color: #ffffff;
    width: 15px;
    height: 15px;
}

// terms and conditions

.terms_nd_conditions {
    max-width: 900px;
    padding: 0 20px;
    margin: 50px auto;

    p {
        line-height: 1.5;
        font-size: 14px;
        margin: 1em 0;
    }

    h2 {
        font-family: inherit;
        font-size: 14px;
        font-weight: 700;
        color: inherit;
        margin-bottom: 8px;
    }
}

.privacy-policy {
    max-width: 900px;
    padding: 0 20px;
    margin: 50px auto;

    p {
        line-height: 1.5;
        font-size: 14px;
        margin: 1em 0;
    }

    h2 {
        font-family: inherit;
        font-size: 14px;
        font-weight: 700;
        color: inherit;
        margin: 8px 0px;
    }
}
.social-icons {
    display: flex;
    align-items: center;
    justify-content: center;
}


// TESTIMONIALS

/* src/TestimonialSlider.css */
.parallax-background {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.testimonial-slider {
    position: relative;
    margin: 50px auto;
}

.testimonial_slider_heading .heading--title {
    font-family: 'Mulish', sans-serif;
    font-size: 50px;
    font-weight: 400;
    margin-bottom: 25px;
    color: #000;
    text-align: center;
    // margin: 70px auto;
    font-style: italic;
}

// .testimonial-slider:before {
//     content: "";
//     background-color: rgba(27, 26, 26, 0.75);
//     display: inline-block;
//     height: 100%;
//     left: 0;
//     position: absolute;
//     top: 0;
//     width: 100%;
//     z-index: -1;
// }

.divider--shape-1up {
    width: 124px;
    height: 13px;
    background: url("../../../images/testimonials/arrow-up.png") center no-repeat;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -62px;
}

.divider--shape-1down {
    width: 124px;
    height: 13px;
    background: url("../../../images/testimonials/arrow-down.png") center no-repeat;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -62px;
}

.testimonial-container {
    padding-bottom: 11px;
    position: relative;

    .testimonial-panel {
        background-color: #fff;
        padding: 10px;
        position: relative;
        text-align: center;

        .testimonial-bordered {
            border: 1px dashed #791313;
            padding: 28px;


            .testimonial--rating {
                margin-bottom: 20px;
            }
        }

        .cont {
            font-size: 14px;
            font-family: inherit;
            font-weight: 400;
            color: #a5a5a5;
            line-height: 25px;
            margin-bottom: 23px;
        }

        .testimonial--img {
            margin: 12px auto;
            background-color: #791313;
            border-radius: 50%;
            width: 40px;
            height: 40px;

            .testimonial--frst-ltr {
                color: #fff;
                font-size: 14px;
                font-family: inherit;
                font-weight: 400;
                margin: 0px;
                line-height: 40px;
            }
        }

        .testimonial--author h4 {
            font-size: 16px;
            line-height: 1.3;
            color: #791313;
            font-weight: 500;
            letter-spacing: 0.35px;
            font-style: italic;
            font-family: inherit;
            margin-bottom: 0;
        }
    }
}


// FRANCHISE

.franchise_reason_container {
    background: #f7f9fe;
    padding: 70px 0;
    overflow: hidden;

    .feelfree {
        text-align: center;
        font-size: 32px;
        font-weight: 300;
        margin-bottom: 20px;
        padding-bottom: 20px;
        position: relative;
        font-family: inherit;
    }

    .feelfree_highlight {
        font-weight: 700;
    }

    .desc {
        color: #000;
        font-size: 18px;
        font-weight: 300;
        font-family: inherit;
        margin-bottom: 1rem;
        text-align: center;
        width: 65%;
        margin: 0 auto;
    }

    .fc_icon {
        position: relative;
        min-width: 90px;
        height: 90px;
    }

    .fc_svg_ic {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 90px;
        height: 90px;
    }

    .oppor-icon-box .fc_icon path {
        fill: #791313;
    }

    .fc_inner_icon {
        z-index: 10000;
        font-size: 26px;
        color: #fff;
    }

    .fc_opportunity_cont {
        margin: 10px 0px;

        .fc_opportunity_content {
            text-align: center;
            font-size: 18px;
            color: #791313;
            font-weight: 400;
            font-family: inherit;
        }
    }
}

.franshise_success {
    background-image: url("../../../images/footer/footerBackground.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: -webkit-box;
    display: flex;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    line-height: 1;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 350px;
    z-index: 1;
    background-attachment: fixed;
}

.franshise_success::before {
    content: "";
    background-color: #000000;
    opacity: 0.5;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    display: inline-block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.franchise_gallery_section {
    padding: 70px 0;
    overflow: hidden;

    .feelfree {
        text-align: center;
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 20px;
        padding-bottom: 20px;
        position: relative;
        font-family: inherit;
    }

    .fran_img_gallery {
        padding-bottom: 70%;
        position: relative;
        border-radius: 4px;
        overflow: hidden;

        .fran_img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
        }
    }


}

.franchise_gain_wrapper {
    text-align: center;

    @media screen and (max-width: 992px) {
        margin-bottom: 15px;
    }

    .franchise_gain_title {
        color: #fff;
        font-size: 22px;
        font-weight: 600;
        font-family: inherit;
    }

    .franchise_gain_counter {
        font-size: 16px;
        font-weight: 300;
        color: #fff;
        font-family: inherit;
    }

    .franchise_gain_con {
        text-align: center;
        font-size: 16px;
        font-weight: 300;
        color: #fff;
        font-family: inherit;
    }
}