
.carousel {
    position: relative; }
  
  .carousel.pointer-event {
    touch-action: pan-y; }
  
  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden; }
    .carousel-inner::after {
      display: block;
      clear: both;
      content: ""; }
  
  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-item {
        transition: none; } }
  
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    display: block; }
  
  .carousel-item-next:not(.carousel-item-start),
  .active.carousel-item-end {
    transform: translateX(100%); }
  
  .carousel-item-prev:not(.carousel-item-end),
  .active.carousel-item-start {
    transform: translateX(-100%); }
  
  .carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none; }
  
  .carousel-fade .carousel-item.active,
  .carousel-fade .carousel-item-next.carousel-item-start,
  .carousel-fade .carousel-item-prev.carousel-item-end {
    z-index: 1;
    opacity: 1; }
  
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s 0.6s; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-fade .active.carousel-item-start,
      .carousel-fade .active.carousel-item-end {
        transition: none; } }
  
  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #000;
    text-align: center;
    background: none;
    border: 0;
    opacity: 0.5;
    transition: opacity 0.15s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-control-prev,
      .carousel-control-next {
        transition: none; } }
    .carousel-control-prev:hover, .carousel-control-prev:focus,
    .carousel-control-next:hover,
    .carousel-control-next:focus {
      color: #000;
      text-decoration: none;
      outline: 0;
      opacity: 0.9; }
  
  .carousel-control-prev {
    left: 0; }
  
  .carousel-control-next {
    right: 0; }
  
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%; }
  
  /* rtl:options: {
    "autoRename": true,
    "stringMap":[ {
      "name"    : "prev-next",
      "search"  : "prev",
      "replace" : "next"
    } ]
  } */
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }
  
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }
  
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none; }
    .carousel-indicators [data-bs-target] {
      box-sizing: content-box;
      flex: 0 1 auto;
      width: 30px;
      height: 3px;
      padding: 0;
      margin-right: 3px;
      margin-left: 3px;
      text-indent: -999px;
      cursor: pointer;
      background-color: #000;
      background-clip: padding-box;
      border: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      opacity: 0.5;
      transition: opacity 0.6s ease; }
      @media (prefers-reduced-motion: reduce) {
        .carousel-indicators [data-bs-target] {
          transition: none; } }
    .carousel-indicators .active {
      opacity: 1; }
  
  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #000;
    text-align: center; }
  
  .carousel-dark .carousel-control-prev-icon,
  .carousel-dark .carousel-control-next-icon {
    filter: invert(1) grayscale(100); }
  
  .carousel-dark .carousel-indicators  {
    background-color: #ffffff; }
  
  .carousel-dark .carousel-caption {
    color: #000; }


.btn-next,
.btn-prev {
  position: static;
  width: 48px;
  height: 48px;
  line-height: 48px;
  border-radius: 0px;
  color: #000;
  background: #791313;
  font-size: 15px;
  z-index: 1;
  text-align: center;
  margin: 0 10px;
  display: inline-block;
  box-shadow: 0px 10px 30px 0px var(--rgba-primary-3);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 10px; }
  .btn-next:hover,
  .btn-prev:hover {
    background: #222222;
    color: #000;
    box-shadow: 0px 10px 30px 0px rgba(34, 34, 34, 0.2); }
  .btn-next::after,
  .btn-prev::after {
    content: none; }
  .btn-next.dark:hover,
  .btn-prev.dark:hover {
    background: #fff;
    color: #000;
    box-shadow: 0px 10px 30px 0px rgba(34, 34, 34, 0.2); }
  .btn-next i,
  .btn-prev i {
    font-size: 20px;
    line-height: inherit; }
  @media only screen and (max-width: 1200px) {
    .btn-next,
    .btn-prev {
      width: 35px;
      height: 35px;
      line-height: 35px; }
      .btn-next i,
      .btn-prev i {
        font-size: 16px; } }
  @media only screen and (max-width: 575px) {
    .btn-next,
    .btn-prev {
      width: 30px;
      height: 30px;
      line-height: 30px;
      margin: 0 2px 2px; }
      .btn-next i,
      .btn-prev i {
        font-size: 15px; } }

.swiper-visible {
  overflow: unset; }
  .swiper-visible .swiper-slide {
    opacity: 0;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
  .swiper-visible .swiper-slide-active,
  .swiper-visible:not(.swiper-single) .swiper-slide-next,
  .swiper-visible:not(.swiper-single) .swiper-slide-visible:not(.swiper-slide-duplicate-prev) {
    opacity: 1; }
  .swiper-visible.swiper-item-4 .swiper-slide-next + .swiper-slide,
  .swiper-visible.swiper-item-4 .swiper-slide-next + .swiper-slide + .swiper-slide {
    opacity: 1; }