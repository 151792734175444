.product-detail .badge {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #666666;
  display: flex;
  align-items: center;
  padding: 2px;
  margin-bottom: 10px;
}

.product-detail .badge svg {
  margin-right: 8px;
}

.product-detail .detail-media {
  border-radius: 10px;
  overflow: hidden;
}

.img-line {
  // width: 50%;
  // height: 1px;
  margin: 0 15px;
  color: #7D7D7D;
  // border-bottom: 1px dashed #7D7D7D;
  display: inline-block;
}

.addon_inner_price {
  width: 40px;
  text-align: end;
}

.product-detail .detail-media img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.product-detail .detail-info {
  position: relative;
}

.category_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product_heading {
  margin: 0px !important;
}

.product-detail .detail-info .dz-head .rating {
  font-size: 14px;
  line-height: 21px;
}

.rating {
  display: flex;
}

.rating span {
  color: var(--primary);
  font-size: 12px;
  margin-left: 2px;
  font-weight: 700;
}

.product-detail .detail-info .dz-head .rating i {
  color: #791313;
}

.product-detail .detail-info .text {
  font-size: 15px;
  margin-top: 20px;
}

.product-detail .detail-info .detail-list {
  display: flex;
  margin: 25px 0px;
}

.product-detail .detail-info .detail-list li {
  margin-right: 45px;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}

.product-detail .detail-info .detail-list li span {
  display: flex;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}


.product-detail .detail-info .add-product {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 30px;
}

.product-detail .detail-info .add-product li {
  padding: 5px;
  width: 50%;
}

.product-detail .detail-info .add-product .mini-modal {
  display: inline-flex;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgba(34, 34, 34, 0.1);
  align-items: center;
}

.product-detail .detail-info .add-product .mini-modal .dz-media {
  width: 45px;
  min-width: 45px;
  height: 45px;
  border-radius: 6px;
}

.product-detail .detail-info .add-product .mini-modal .dz-content {
  margin-left: 15px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.product-detail .detail-info .add-product .mini-modal .dz-content p {
  margin-bottom: 0;
  font-weight: 500;
  color: var(--title);
}

.product-detail .detail-info .modal-btn-group {
  display: flex;
  margin: 0 -7px;
}

.product-detail .detail-info .modal-btn-group li {
  margin: 0 7px;
}

@media only screen and (max-width: 991px) {
  .product-detail .detail-info .modal-btn-group li {
    margin: 0 7px 10px;
  }
}

@media only screen and (max-width: 575px) {
  .product-detail .detail-info .text {
    margin-top: 10px;
  }

  .product-detail .detail-info .detail-list {
    margin: 15px 0;
  }

  .product-detail .detail-info .add-product {
    margin: 0 0 20px;
  }

  .product-detail .detail-info .add-product li {
    padding: 5px 0;
    width: 100%;
  }

  .product-detail .detail-info .modal-btn-group {
    display: block;
  }
}

.modal-content .modal-body.product-detail {
  display: flex;
  padding: 6px;
}

.modal-content .modal-body.product-detail .detail-info {
  padding: 25px 20px 25px 35px;
}

.modal-content .modal-body.product-detail .detail-media {
  width: 365px;
  min-width: 365px;
}

@media only screen and (max-width: 991px) {
  .modal-content .modal-body.product-detail {
    display: block;
  }

  .modal-content .modal-body.product-detail .detail-info {
    padding: 15px;
  }

  .modal-content .modal-body.product-detail .detail-media {
    width: 100%;
    min-width: 100%;
  }

  .modal-content .modal-body.product-detail .detail-media img {
    height: 400px;
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .modal-content .modal-body.product-detail .detail-media img {
    height: 280px;
  }
}

.section-wrapper-1 {
  position: relative;
  z-index: 1;
}

.section-wrapper-1::after {
  content: "";
  position: absolute;
  z-index: -1;
  background-color: rgba(21, 21, 21, 0.7);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(6px);
}

.section-wrapper-2 {
  position: relative;
}

.section-wrapper-2 .bg1,
.section-wrapper-2 .bg2 {
  position: absolute;
}

.section-wrapper-2 .bg1 {
  bottom: 0;
  left: -275px;
}

.section-wrapper-2 .bg2 {
  top: 100px;
  right: 40px;
}

.section-wrapper-2.right .bg1 {
  bottom: 0;
  right: -355px;
  left: auto;
}

@media only screen and (max-width: 1680px) {
  .section-wrapper-2 .bg1 {
    left: -320px;
  }

  .section-wrapper-2 .bg2 {
    top: 90px;
    right: 15px;
  }
}

@media only screen and (max-width: 1480px) {
  .section-wrapper-2 .bg1 {
    display: none;
  }

  .section-wrapper-2 .bg2 {
    top: 28px;
    right: 0px;
  }

  .section-wrapper-2.right .bg1 {
    display: none;
  }
}

@media only screen and (max-width: 1480px) and (max-width: 1280px) {
  .section-wrapper-2 .bg2 {
    display: none;
  }
}

.section-wrapper-3 {
  position: relative;
  z-index: 1;
}

// .section-wrapper-3::before {
//   content: "";
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   background-color: var(--bg-light);
//   z-index: -1; }
.section-wrapper-3::after {
  content: "";
  height: 200px;
  width: 100%;
  background-color: #000;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.section-wrapper-3 .inner-section-wrapper {
  margin-bottom: 80px;
  margin-top: 30px;
}

.section-wrapper-3 .bg1,
.section-wrapper-3 .bg2 {
  position: absolute;
  z-index: -1;
}

.section-wrapper-3 .bg2 {
  top: 255px;
  right: 85px;
}

.section-wrapper-3 .bg1 {
  bottom: 300px;
  left: 70px;
}

@media only screen and (max-width: 1680px) {
  .section-wrapper-3 .bg2 {
    top: 120px;
    right: 40px;
  }

  .section-wrapper-3 .bg1 {
    bottom: 280px;
    left: 30px;
  }

  .section-wrapper-3 .inner-section-wrapper {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 1480px) {
  .section-wrapper-3 .bg2 {
    top: 50px;
    right: 30px;
  }

  .section-wrapper-3 .bg1 {
    bottom: 350px;
    left: 10px;
  }
}

@media only screen and (max-width: 1280px) {
  .section-wrapper-3 .inner-section-wrapper {
    margin-bottom: 60px;
  }

  .section-wrapper-3 .bg1,
  .section-wrapper-3 .bg2 {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .section-wrapper-3 .inner-section-wrapper {
    margin-bottom: 45px;
  }
}

.section-wrapper-4 {
  margin-left: 100px;
  margin-right: 100px;
  background-color: var(--bg-light);
  border-radius: 30px;
}

@media only screen and (max-width: 1480px) {
  .section-wrapper-4 {
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 0;
  }
}

.section-wrapper-5 {
  margin-left: 100px;
  margin-right: 100px;
  z-index: 1;
  position: relative;
  border-radius: 30px;
}

.section-wrapper-5::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(6px);
  background-color: rgba(21, 21, 21, 0.7);
  width: 100%;
  height: 100%;
  z-index: -1;
}

@media only screen and (max-width: 1480px) {
  .section-wrapper-5 {
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 0;
  }
}

.section-wrapper-6 {
  position: relative;
  z-index: 1;
}

.section-wrapper-6::after {
  content: "";
  height: 125px;
  width: 100%;
  background-color: rgba(34, 34, 34, 0.05);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.section-wrapper-6 .inner-section-wrapper {
  margin-bottom: 80px;
}

@media only screen and (max-width: 767px) {
  .section-wrapper-6 .inner-section-wrapper {
    margin-bottom: 45px;
  }
}

@media only screen and (max-width: 575px) {
  .section-wrapper-6 .inner-section-wrapper {
    margin-bottom: 25px;
  }
}

.section-wrapper-7 {
  position: relative;
  z-index: 1;
}

.section-wrapper-7::after {
  content: "";
  width: 850px;
  height: 850px;
  background-color: #FFEEBB;
  position: absolute;
  top: 110px;
  right: -19%;
  z-index: -2;
  filter: blur(9px);
  border-radius: 100%;
}

.section-wrapper-7 .inner-section-wrapper {
  margin-bottom: 50px;
}

.section-wrapper-7 .bg1 {
  position: absolute;
  top: 85px;
  left: -110px;
}

.section-wrapper-7 .bg2 {
  position: absolute;
  right: -54px;
  top: 30px;
  z-index: -1;
}

@media only screen and (max-width: 1480px) {
  .section-wrapper-7::after {
    width: 700px;
    height: 700px;
    top: 45px;
    right: -22%;
  }

  .section-wrapper-7 .bg1 {
    display: none;
  }

  .section-wrapper-7 .bg2 {
    width: 600px;
  }
}

@media only screen and (max-width: 1280px) {
  .section-wrapper-7::after {
    width: 600px;
    height: 600px;
  }

  .section-wrapper-7 .bg2 {
    width: 500px;
  }
}

@media only screen and (max-width: 1024px) {
  .section-wrapper-7::after {
    display: none;
  }

  .section-wrapper-7 .bg2 {
    top: auto;
    bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .section-wrapper-7 .bg2 {
    display: none;
  }

  .section-wrapper-7 .inner-section-wrapper {
    margin-bottom: 0px;
  }
}

.section-wrapper-8 {
  position: relative;
  background-color: rgba(34, 34, 34, 0.04);
  border-radius: 30px;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 270px;
  margin-bottom: 100px;
  padding-left: 30px;
  padding-right: 30px;
}

.section-wrapper-8 .inner-section-wrapper {
  margin-bottom: 80px;
  margin-top: -215px;
}

@media only screen and (max-width: 1480px) {
  .section-wrapper-8 {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 190px;
    margin-bottom: 70px;
  }

  .section-wrapper-8 .inner-section-wrapper {
    margin-top: -170px;
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .section-wrapper-8 {
    background-color: #000;
    margin: 0;
  }

  .section-wrapper-8 .inner-section-wrapper {
    margin-top: 0;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-wrapper-8 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .section-wrapper-8 .inner-section-wrapper {
    margin-bottom: 45px;
  }
}

.coming-wrapper {
  position: relative;
  z-index: 1;
  padding: 25px;
}

.coming-wrapper .bg-img {
  position: absolute;
  top: 20px;
  z-index: -1;
}

.coming-wrapper .dz-social-icon ul {
  margin-right: -20px;
}

.coming-wrapper .dz-social-icon ul li {
  display: inline-block;
  padding: 0 6px;
  margin-right: 20px;
}

.coming-wrapper .dz-social-icon ul li a {
  font-size: 18px;
}

.coming-wrapper .dz-social-icon ul li a i {
  color: #000000;
}

@media only screen and (max-width: 1480px) {
  .coming-wrapper .bg-img {
    top: 25px;
    left: 80px;
  }
}

@media only screen and (max-width: 1280px) {
  .coming-wrapper {
    padding: 45px 25px 25px;
  }

  .coming-wrapper .bg-img {
    top: 10px;
    left: 14px;
  }
}

@media only screen and (max-width: 991px) {
  .coming-wrapper {
    padding: 25px;
  }

  .coming-wrapper .bg-img {
    display: none;
  }

  .coming-wrapper .dz-social-icon ul li a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .coming-wrapper {
    padding: 25px 0 25px;
  }
}

.coming-wrapper .inner-content {
  width: 100%;
  min-height: 650px;
  overflow: hidden;
}

.coming-wrapper .inner-content .coming-head {
  font-size: 32px;
  line-height: 1.3;
  letter-spacing: 0.01em;
  color: #323232;
  margin-bottom: 0;
}

.coming-wrapper .inner-content .coming-para {
  color: #828282;
  letter-spacing: 0.01em;
}

.coming-wrapper .inner-content .coming-media {
  transform: scale(2.5);
  z-index: -1;
  position: relative;
}

@media only screen and (max-width: 1480px) {
  .coming-wrapper .inner-content {
    min-height: 500px;
  }

  .coming-wrapper .inner-content .center-logo {
    margin-bottom: 25px;
  }

  .coming-wrapper .inner-content .coming-media {
    transform: scale(2);
  }
}

@media only screen and (max-width: 991px) {
  .coming-wrapper .inner-content {
    min-height: 600px;
  }

  .coming-wrapper .inner-content .coming-media {
    transform: scale(2.5);
  }
}

@media only screen and (max-width: 767px) {
  .coming-wrapper .inner-content {
    min-height: 500px;
  }

  .coming-wrapper .inner-content .coming-head {
    font-size: 25px;
  }
}

@media only screen and (max-width: 575px) {
  .coming-wrapper .inner-content {
    min-height: 400px;
  }
}

.coming-wrapper .middle-content {
  margin-bottom: 60px;
}

@media only screen and (max-width: 1480px) {
  .coming-wrapper .middle-content {
    margin-bottom: 35px;
  }
}

.coming-wrapper .coming-footer p {
  color: #828282;
  letter-spacing: 0.01em;
  margin-bottom: 0;
}

.style-8 .countdown-timer {
  margin-bottom: 50px;
}

.style-8 .dez-title {
  font-size: 140px;
  line-height: 140px;
  font-family: cookie-regular;
  color: #c90103;
  font-weight: 500;
}

.style-8 .countdown-timer .clock .val {
  color: #000;
  font-family: cookie-regular;
  font-size: 95px;
  line-height: 165px;
  margin-bottom: 5px;
}

.style-8 .countdown-timer .clock .kineticjs-content:after {
  border: 1px solid #d2d2d2;
}

.style-8 .countdown-timer .clock .type-time {
  color: #000;
}

.water-layer3,
.water-layer1 {
  background-size: 95%;
}

.map-iframe iframe {
  width: 100%;
}

.map-iframe.style-1 {
  position: relative;
}

.map-iframe.style-1::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #222;
  mix-blend-mode: color;
  z-index: 0;
  pointer-events: none;
}

.map-iframe.style-1 iframe {
  height: 400px;
  margin-bottom: -10px;
}

@media only screen and (max-width: 991px) {
  .map-iframe.style-1 iframe {
    height: 350px;
  }
}

@media only screen and (max-width: 575px) {
  .map-iframe.style-1 iframe {
    height: 300px;
  }
}

.contact-box {
  padding-right: 100px;
  margin-bottom: 70px;
}

@media only screen and (max-width: 575px) {
  .contact-box {
    padding-right: 0;
    margin-bottom: 30px;
  }
}

.widget_working {
  padding: 30px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  margin-bottom: 0;
}

.widget_working .head {
  margin-bottom: 40px;
}

.widget_working .head .title {
  font-size: 24px;
}

.widget_working .head p {
  font-size: 15px;
  margin-bottom: 0;
  opacity: 0.7;
}

.widget_working ul li {
  color: #000;
  display: flex;
  margin-bottom: 25px;
  padding-left: 40px;
  position: relative;
}

.widget_working ul li p {
  margin-bottom: 0;
}

.widget_working ul li i {
  position: absolute;
  font-size: 30px;
  left: 0;
  top: 5px;
  color: #791313;
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.widget_working ul li:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 991px) {
  .widget_working {
    margin-bottom: 30px !important;
  }
}

@media only screen and (max-width: 575px) {
  .widget_working {
    padding: 15px;
  }
}

.contact-area {
  padding: 50px 50px 20px;
  border-radius: 10px;
  background-color: #222222;
}

.contact-area .contact-head {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1280px) {
  .contact-area {
    padding: 30px 30px 0;
  }
}

@media only screen and (max-width: 575px) {
  .contact-area {
    border-radius: 0px;
  }
}

.under-construction {
  position: relative;
  overflow: hidden;
  min-height: 750px;
  height: 100vh;
}

.under-construction>div {
  z-index: 2;
  position: relative;
}

.under-construction .particles-js-canvas-el {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .under-construction {
    min-height: 600px;
    height: 100vh;
  }
}

@media only screen and (max-width: 575px) {
  .under-construction {
    min-height: 450px;
    height: 100vh;
  }
}

.under-construction .inner-construction .dz-head {
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 15px;
  line-height: 1.2;
}

.under-construction .inner-construction .const-p {
  font-size: 18px;
  color: #828282;
  margin-bottom: 25px;
}

.under-construction .inner-construction .space-right {
  max-width: 350px;
}

.under-construction .inner-construction .space-right .input-group .form-control,
.under-construction .inner-construction .space-right .input-group .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .under-construction .inner-construction .space-right .input-group select,
.under-construction .inner-construction .space-right .input-group .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .under-construction .inner-construction .space-right .input-group select {
  height: 50px;
  margin-bottom: 15px;
}

.under-construction .inner-construction .space-right .input-group button {
  border-radius: 6px !important;
}

@media only screen and (max-width: 1280px) {
  .under-construction .inner-construction .const-p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 991px) {
  .under-construction .inner-construction .dz-head {
    font-size: 34px;
  }

  .under-construction .inner-construction .space-right {
    max-width: 270px;
  }

  .under-construction .inner-construction .const-p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .under-construction .inner-construction .space-right {
    max-width: 100%;
  }
}

.under-construction .construction-media {
  position: relative;
  z-index: 1;
}

.under-construction .construction-media img {
  position: absolute;
  left: 120px;
  top: 0;
  width: 100%;
  min-width: 35vw;
}

.under-construction .construction-media::after {
  position: absolute;
  content: "";
  top: -15vw;
  left: 10vw;
  z-index: -1;
  width: 50vw;
  height: 50vw;
  background: #791313;
  opacity: 0.2;
  border-radius: 100%;
}

@media only screen and (max-width: 1480px) {
  .under-construction .construction-media img {
    left: 0;
    min-width: 830px;
  }

  .under-construction .construction-media::after {
    top: -285px;
    left: 70px;
  }
}

@media only screen and (max-width: 1280px) {
  .under-construction .construction-media img {
    min-width: 730px;
  }

  .under-construction .construction-media::after {
    top: -214px;
    width: 850px;
    left: 40px;
    height: 850px;
  }
}

@media only screen and (max-width: 991px) {
  .under-construction .construction-media img {
    min-width: 550px;
  }

  .under-construction .construction-media::after {
    top: -170px;
    width: 600px;
    left: 34px;
    height: 600px;
  }

  .mobile_view {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .under-construction .construction-media {
    margin-top: 30px;
  }

  .under-construction .construction-media img {
    min-width: 100%;
    position: unset;
  }

  .under-construction .construction-media::after {
    top: 0;
    width: 450px;
    left: 0;
    height: 450px;
  }

  .mobile_view {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .desktop_section {
    display: none;
  }

  .under-construction .construction-media::after {
    display: none;
  }

  .mobile_view {
    display: block;
  }

  .category_inner_wrapper {
    display: none !important;
  }

  .category_wrapper {
    position: relative;
    margin: 24px 16px 16px;
  }

  .category_inner_wrapper {
    display: flex;
  }

  .category-mobile-grid {
    position: relative;
  }

  .category_grid {
    padding: 4px 0px;
  }

  .catergory_inner_grid {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .veg-category {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 4px;
  }

  .category_name {
    word-break: break-word;
  }

  .category_mobile_price {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 4px;
  }

  .category_inner_mobile_price {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-right: 4px;
  }

  .caetgory_span {
    margin-right: 0px;
  }

  .category_caetgory_inner_price {
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.3px;
    color: rgba(2, 6, 12, 0.92);
  }

  .category_caetgory_inner_price> ::before {
    content: "₹";
    margin-right: 0.1rem;
  }

  .caetgory_mobile_rating {
    display: flex;
    margin-top: 12px;
  }

  .caetgory_mobile_rating_wrapper {
    width: 14px;
    height: 14px;
  }

  .caetgory_mobile_rating_conatiner {
    color: rgb(17, 102, 73);
    margin-left: 2px;
  }

  .category_mobile_image {
    margin-left: 12px;
    max-height: 164px;
    min-width: 156px;
  }

  .category_mobile_image_btn {
    width: 156px;
    height: 144px;
    border-radius: 12px;
  }

  .category_mobile_image_btn img {
    width: 100%;
    max-width: 190px;
    border-radius: 10px;
  }

  .styles_itemImage__container {
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 12px;
    object-fit: cover;
    border-radius: 12px;
    width: 100%;
    max-width: 154px;
    height: 144px;
  }

  .home_banner_section {
    display: none;
  }

  .dz-box,
  .dz-info,
  .dz-tilte,
  .dz-tilte-inner {
    padding: 22px 0px;
  }

  .mobile_cart_container {
    position: relative;
    left: 50%;
    bottom: 28px;
    transform: translateX(-50%);
    z-index: 1;
  }


  .mobile_product_wrapper {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
  }

  .mobile_cart_inner_container {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(226, 226, 231);
    height: 40px;
    border-radius: 8px;
    width: 120px;
    position: relative;
    overflow: hidden;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    box-shadow: rgba(40, 44, 63, 0.08) 0px 3px 8px;
  }

  .hFwsQK {
    -webkit-font-smoothing: antialiased;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: rgb(27, 166, 114);
  }

  .dJCRiL {
    transition: none 0s ease 0s;
    height: 100%;
    text-align: center;
    cursor: none;
    pointer-events: none;
    position: absolute;
    width: 100%;
    transform: translateY(0px);
  }

  .jtZFYy {
    padding-right: 16px;
    padding-left: 12px;
    transition: all 100ms ease-in-out 0s;
    transform: translateX(0px);
  }

  .kNelBR {
    appearance: none;
    background: transparent;
    box-shadow: none;
    border: none;
    height: 100%;
    cursor: pointer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-transform: uppercase;
  }

}

.left-container {
  z-index: 1;
  padding-left: 16px;
  padding-right: 12px;
  transition: all 100ms ease-in-out 0s;
  transform: translateX(0px);
}


.minus-button {
  appearance: none;
  background: transparent;
  box-shadow: none;
  border: none;
  height: 100%;
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-transform: uppercase;
}



// .mobile_cart_add_btn_text {
//   position: absolute;
//   inset: 0px;
// }

.iWhCWp {
  transition: all 100ms ease-in-out 0s;
  height: 40px;
  cursor: none;
  pointer-events: none;
  transform: translateY(-40px);
  position: absolute;
  width: 100%;
}

.cart_btn_container {
  -webkit-font-smoothing: antialiased;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--primary);
}

.add-button-label {
  width: 120px;
  position: absolute;
  inset: 0px;
  transition: all 100ms ease-in-out 0s;
  transform: translateY(0px);
  text-align: center;
}

.dxjRpo {
  padding-right: 16px;
  padding-left: 12px;
  transition: all 100ms ease-in-out 0s;
  transform: translateX(64px);
}

.mobile_category_conatiner {
  display: flex;
  justify-content: space-between;
}

.styles_divider__2JelH {
  border-bottom: 0.5px solid #d3d3d3;
  height: 0.5px;
  margin: 20px 0;
}

.caetgory_span> ::before {
  content: "₹";
  margin-right: 0.1rem
}

.MenuStickyBottom_container {
  // bottom: 74px !important;

}


.btn-quantity.style-1 .input-group-btn-vertical {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 2px;
  margin-left: 0 !important;
}



button {
  color: inherit;
  font: inherit;
  margin: 0;
  background: rgba(0, 0, 0, 0);
  border: none;
  border-color: transparent;
  outline: 0;
  padding: 0;
  text-align: left;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.catergory_mobile_wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding-left: 16px;
  -webkit-box-pack: center;
  justify-content: center;
  min-height: 108px;
}

.menu-head {
  margin-bottom: 25px;
}


.menu-head .title {
  font-weight: 400;
  font-size: 35px;
}

.dz-shop-card {
  position: relative;
  overflow: hidden;
}

.dz-shop-card.style-2 .dz-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dz-shop-card.style-2 .dz-content .dz-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.dz-shop-card.style-2 .dz-content .dz-head .header-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}

.dz-shop-card.style-2 .dz-content .dz-head .img-line {
  width: 100%;
  height: 1px;
  margin: 0 15px;
  border-bottom: 1px dashed #7D7D7D;
}

.dz-shop-card.style-2 .dz-content .dz-head .header-price {
  color: var(--primary);
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.dz-shop-card.style-2 .dz-content .dz-body {
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 0;
}

.product_wrapper {
  position: relative;
  // min-height: calc(100vh - 70px);
  // width: 1260px;
  margin: 0 auto;
}

.product_layout {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.grid_filter_sort {
  border-bottom: 0.5px solid #e2e2e7;
  width: 100%;
}

.filter_wrapper {
  width: 100%;
}

.filter_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  column-gap: 10px;
  background-color: #000;
  overflow: auto;
  scrollbar-width: none;
  padding-bottom: 10px;
}

/* Hide the default radio button */
input[type="radio"] {
  display: none;
}

/* Custom styles for the radio button appearance */
// input[type="radio"] + label:before {
//   content: '';
//   display: inline-block;
//   width: 18px;
//   height: 18px;
//   margin-right: 8px;
//   border: 2px solid #000; /* Customize border color */
//   border-radius: 50%; /* Make it round */
//   position: relative;
// }

/* Styles for when radio button is checked */
// input[type="radio"]:checked + label:before {
//      background-color: #ffffff;; /* Customize fill color when checked */
// }

/* Small circle inside the checked radio button */
// input[type="radio"]:checked + label:before::after {
//   content: '';
//   display: block;
//   width: 8px;
//   height: 8px;
//   background-color: #000; /* Customize color of the small circle */
//   border-radius: 50%; /* Make it round */
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// }

.box_1_wrapper {
  white-space: nowrap;
  cursor: pointer;
}

.box_1_inner_container {
  padding: 5px 10px;
  display: inline-grid;
  width: max-content;
  grid-auto-flow: column;
  gap: 8px;
  border-radius: 7px;
  transition: all 100ms ease-in 0s;
}

.box_1_inner_container.active {
  border: 1px solid var(--primary);
  box-shadow: rgba(31, 51, 17, 0.04) 0px 2px 12px;
}

.box_1_svg {
  max-height: 18px;
  line-height: 16px
}

.box_2_svg {
  max-height: 16px;
  line-height: 0px
}

.box_2_svg svg {
  height: 18px;
}

.box_1_label {
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: rgba(2, 6, 12, 0.75);
}

.main_buttonInner {
  width: 96px !important;
  height: 36px !important;
  position: relative;
  min-height: 36px;
}

.main_btn {
  width: 96px;
  min-height: 36px;
  box-shadow: 0 3px 8px #e9e9eb;
  border-radius: 4px;
  display: inline-block;
  height: 30px;
  width: 83px;
  border: 1px solid #d4d5d9;
  color: #60b246;
  font-size: .9rem;
  font-weight: 600;
  line-height: 30px;
  position: relative;
  text-align: center;
  background-color: #000;
}

.desktop_add_btn {
  position: absolute;
  right: -9px;
  top: 0;
  width: 33.33333%;
  display: inline-block;
  opacity: 1;
  transform: translateZ(0);
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 600;
}

.no_product {
  display: flex;
  justify-content: center;
}

.no_addons {
  padding: 100px 0px;
  margin: 0px;
  text-align: center;
}

.add_on_model_title {
  font-size: 12px;
  max-width: 220px;
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  color: #000;
  margin-top: 5px;
  letter-spacing: 0.8px;
  display: inline-block;
}

.addon_sel_price {
  display: inline-block;
  padding-left: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  font-family: 'Mulish', sans-serif;

  &::before {
    content: "\20B9";
    padding-right: 3px;
  }

}

.add_on_header {
  border-bottom: 1px solid #02060c26 !important;
}

.addon_list {
  background: rgb(255, 255, 255);
  border-radius: 16px;
}

.addon_customise_txt {
  color: #02060cbf;
  font-weight: 600;
  font-size: 20px;
  font-family: 'Mulish', sans-serif;
}
.addon_content {
  margin-bottom: 15px;
}
.addon_title {
  font-weight: 700;
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
  color: #000;
  margin-bottom: 5px;
}

.addon_label {
  padding: 7px 0 7px 0px;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  overflow: hidden;
  -ms-flex-align: center;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 40px;
}

.addon_price {
  font-size: 14px;
  text-align: right;
  font-family: 'Mulish';
  color: #000;

  &::before {
    content: "\20B9";
    padding-right: 3px;
  }
}

.style_veg_icon {
  font-size: 10px;
  position: absolute;
  left: 29px;
  top: 12px;
  width: 12px;
  padding-left: 1px;
}

.style_veg_icon svg {
  width: 12px;
}

.addon-modal {
  background-color: #fff;
}

.addon_name {
  margin-left: 5px;
  font-size: 14px;
  text-transform: capitalize;
  min-width: 150px;
  font-family: 'Mulish';
  margin-top: 3px;
  color: #000;
}

.addon_label svg {
  width: 12px;
  margin-top: 0.3em;
}

.addon_footer {
  padding: 10px;
  position: relative;
  background-color:#fff;
  color: #282c3f;
  display: block !important;
}

.addon_inner_footer {
  padding: 6px 16px;
  background-color: var(--primary);
  color: #000;
  position: relative;
  font-weight: 600;
  font-size: 15px;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.addon_total_price {
  font-family: 'Mulish';
  font-size: 18px;
  font-weight: 600;
  color: #fff;

  &::before {
    content: "\20B9";
    padding-right: 3px;
  }
}
.addon_item {
  text-transform: capitalize;
  font-family: 'Mulish';
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.desktop_minus_btn {
  position: absolute;
  left: 10px;
  top: 0;
  width: 33.33333%;
  display: inline-block;
  opacity: 1;
  transform: translateZ(0);
  cursor: pointer;
  font-weight: 600;
  font-size: 1.5rem;
}

.addon_inner_label {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
}

input[type="checkbox"] {
  width: 15px;
  height: 15px;
}

.addons_inner_conatiner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 10px;
}

.addon_item_checkbox {
  border: 1.5px solid #000;
  cursor: pointer;
  
}

.addon_item_checkbox:checked {
  border: 1.5px solid #791313 !important;
}
