.table {
    color: strong;
color: #181c32;
  }
  .table th,
  .table td {
    border-color: #d7dae3;
    padding: 12px 9px;
  }
  [data-theme-version='dark'] .table th,
  [data-theme-version='dark'] .table td {
    border-color: #333a54;
    color: #000;
  }
  .table.table-striped tbody tr:nth-of-type(odd),
  .table.table-hover tr:hover {
    background-color: #f2f2f2;
  }
  [data-theme-version='dark'] .table.table-striped tbody tr:nth-of-type(odd),
  [data-theme-version='dark'] .table.table-hover tr:hover {
    background-color: #181f39;
  }
  .table thead th {
    border-bottom-width: 1px;
    white-space: nowrap;
    text-transform: uppercase;
    color: #181c32;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    border-color: #d7dae3;
  }
  [data-theme-version='dark'] .table thead th {
    border-color: #333a54;
  }
  .table tbody tr td {
    vertical-align: middle;
    // white-space: nowrap;
    // border-color: #d7dae3;
    border: 1px solid #eff2f7;
  }
  .table tbody tr td:last-child{
    white-space: nowrap;
  }
  .custom_table_header{
    text-align: center;
    font-size: 13px;
    color: #000;
    font-weight: 500;
    text-transform: capitalize;
  }
  .custom-table-body{
    text-align: center;
  }
   .table tbody tr td {
    // border-color: #333a54;
  }
  .table:not(.table-bordered) thead th {
    border-top: none;
  }
  .table .thead-primary th {
    background-color: #f25521;
    color: #000;
  }
  .table .thead-info th {
    background-color: #00afef;
    color: #000;
  }
  .table .thead-warning th {
    background-color: #ff9f00;
    color: #000;
  }
  .table .thead-danger th {
    background-color: #f25767;
    color: #000;
  }
  .table .thead-success th {
    background-color: #10ca93;
    color: #000;
  }
  .table.primary-table-bordered {
    border: 1px solid #d7dae3;
  }
  [data-theme-version='dark'] .table.primary-table-bordered {
    border-color: #333a54;
  }
  .table.primary-table-bg-hover thead th {
    background-color: #2169fe;
    color: #000;
    border-bottom: none;
  }
  .table.primary-table-bg-hover tbody tr {
    background-color: #f25521;
    color: #000;
    transition: all 0.1s ease;
  }
  .table.primary-table-bg-hover tbody tr:hover {
    background-color: #538bfe;
  }
  .table.primary-table-bg-hover tbody tr:not(:last-child) td,
  .table.primary-table-bg-hover tbody tr:not(:last-child) th {
    border-bottom: 1px solid #2169fe;
  }
  .table-responsive-tiny {
    min-width: 18.75rem;
  }
  .table-responsive-sm {
    min-width: 30rem !important;
  }
  .table-responsive-md {
    min-width: 36rem !important;
  }
  .table-responsive-lg {
    min-width: 60.9375rem !important;
  }
  .table-responsive-xl {
    min-width: 70.9375rem !important;
  }
  
  .table-primary,
  .table-primary > th,
  .table-primary > td {
    background-color: #ecf2ff;
    color: #f25521;
  }
  [data-theme-version='dark'] .table-primary,
  [data-theme-version='dark'] .table-primary > th,
  [data-theme-version='dark'] .table-primary > td {
    background-color: rgba(58, 122, 254, 0.2);
  }
  
  .table-success,
  .table-success > th,
  .table-success > td {
    background-color: #d3fcf0;
    color: #10ca93;
  }
  [data-theme-version='dark'] .table-success,
  [data-theme-version='dark'] .table-success > th,
  [data-theme-version='dark'] .table-success > td {
    background-color: rgba(16, 202, 147, 0.1);
  }
  
  .table-info,
  .table-info > th,
  .table-info > td {
    background-color: #e0f7ff;
    color: #00afef;
  }
  [data-theme-version='dark'] .table-info,
  [data-theme-version='dark'] .table-info > th,
  [data-theme-version='dark'] .table-info > td {
    background-color: rgba(0, 175, 239, 0.1);
  }
  
  .table-warning,
  .table-warning > th,
  .table-warning > td {
    background-color: #0003e0;
    color: #ff9f00;
  }
  [data-theme-version='dark'] .table-warning,
  [data-theme-version='dark'] .table-warning > th,
  [data-theme-version='dark'] .table-warning > td {
    background-color: rgba(255, 159, 0, 0.1);
  }
  
  .table-danger,
  .table-danger > th,
  .table-danger > td {
    background-color: #fde5e7;
    color: #f25767;
  }
  [data-theme-version='dark'] .table-danger,
  [data-theme-version='dark'] .table-danger > th,
  [data-theme-version='dark'] .table-danger > td {
    background-color: rgba(242, 87, 103, 0.1);
  }
  
  .table-active,
  .table-active > th,
  .table-active > td {
    background-color: #f2f4fa;
  }
  [data-theme-version='dark'] .table-active,
  [data-theme-version='dark'] .table-active > th,
  [data-theme-version='dark'] .table-active > td {
    background-color: #181f39;
  }
  
  .bootgrid-header {
    padding: 0 !important;
    margin: 0;
  }
  @media only screen and (max-width: 575px) {
    .bootgrid-header .actionBar {
      padding: 0;
    }
    .bootgrid-header .search {
      margin: 0 0 10px 0;
    }
  }
  
  table#example {
    padding: 0rem 0 2rem 0;
  }
  
  #example2_wrapper .dataTables_scrollBody {
    max-height: 33.25rem !important;
  }
  
  #employees,
  #custommers {
    padding: 0.5rem 0 1rem 0;
  }
  
  .dataTables_wrapper .dataTables_paginate {
    padding-top: 0.75em;
    padding-bottom: 0.75em;
  }
  
  table.dataTable thead th,
  table.dataTable thead td {
    border-bottom: 0;
    border-top: 0;
  }
  
  table.dataTable tfoot th,
  table.dataTable tfoot td {
    border-top: 0;
  }
  
  table.dataTable tbody tr,
  table.dataTable tbody td {
    background: transparent !important;
  }
  
  table.dataTable thead th {
    color: #181c32;
    font-weight: 600;
  }
  [data-theme-version='dark'] table.dataTable thead th {
    color: #000;
  }
  
  table.dataTable tbody td {
    padding: 12px 10px;
  }
  @media only screen and (max-width: 575px) {
    table.dataTable tbody td {
      padding: 8px 5px;
    }
  }
  
  table.dataTable tr.selected {
    color: #f25521;
  }
  
  table.dataTable tfoot th {
    color: #181c32;
    font-weight: 600;
  }
  [data-theme-version='dark'] table.dataTable tfoot th {
    color: #000;
  }
  
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    border: 0 !important;
    border-radius: 0.2rem;
    padding: 0.25rem 0.7rem;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button:focus,
  .dataTables_wrapper .dataTables_paginate .paginate_button:active,
  .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: #f25521 !important;
    background: #d2e1ff;
    border: 0 !important;
    box-shadow: none;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button.current {
    color: #f25521 !important;
    background: #d2e1ff;
    border: 0 !important;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
  .dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover,
  .dataTables_wrapper .dataTables_paginate .paginate_button.next:hover {
    background: #d2e1ff;
    color: #f25521 !important;
  }
  
  .dataTables_wrapper input[type='search'],
  .dataTables_wrapper input[type='text'],
  .dataTables_wrapper select {
    border: 1px solid #e2e2e2;
    padding: 0.3rem 0.5rem;
    color: #715d5d;
    border-radius: 5px;
  }
  [data-theme-version='dark'] .dataTables_wrapper input[type='search'],
  [data-theme-version='dark'] .dataTables_wrapper input[type='text'],
  [data-theme-version='dark'] .dataTables_wrapper select {
    background: #181f39;
    border-color: #333a54;
    color: #000;
  }
  
  .dataTables_wrapper .dataTables_length {
    margin-bottom: 15px;
  }
  .dataTables_wrapper .dataTables_length .bootstrap-select {
    width: 80px !important;
    margin: 0 5px;
  }
  
  table.dataTable.no-footer {
    border-bottom: 0;
  }
  
  .rounded-lg {
    min-width: 30px;
  }
  
  .dataTables_scroll {
    padding: 1rem 0;
  }
  .dataTables_scrollFoot {
    padding-top: 1rem;
  }
  
  @media (max-width: 991.98px) {
    .dataTables_wrapper .dataTables_length,
    .dataTables_wrapper .dataTables_filter {
      text-align: left;
    }
  }
  
  .dataTables_wrapper .dataTables_info {
    padding-top: 0.755em;
    padding-bottom: 0.755em;
  }
  
  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
    color: #89879f !important;
  }
  
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter,
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_processing,
  .dataTables_wrapper .dataTables_paginate {
    color: #89879f;
  }
  
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    color: #89879f !important;
  }
  
  table.dataTable.row-border tbody th,
  table.dataTable.row-border tbody td,
  table.dataTable.display tbody th,
  table.dataTable.display tbody td {
    border-color: #d7dae3;
  }
  [data-theme-version='dark'] table.dataTable.row-border tbody th,
  [data-theme-version='dark'] table.dataTable.row-border tbody td,
  [data-theme-version='dark'] table.dataTable.display tbody th,
  [data-theme-version='dark'] table.dataTable.display tbody td {
    border-color: #333a54;
  }
  
  .fooicon {
    font-size: 1.25rem;
    color: #181c32;
  }
  
  .jsgrid-table .jsgrid-header-row > .jsgrid-header-cell {
    color: #181c32;
  }
  
  .jsgrid-table > tbody > tr > td {
    padding: 1.2em;
  }
  
  .jsgrid-table .jsgrid-insert-row input,
  .jsgrid-table .jsgrid-insert-row select,
  .jsgrid-table .jsgrid-edit-row input,
  .jsgrid-table .jsgrid-edit-row select {
    border: 1px solid #dddfe1;
  }
  
  .jsgrid .jsgrid-button {
    border: 0 !important;
    margin-left: 10px;
  }
  
  .svg-icons-ov {
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    align-items: center;
    padding: 15px 15px 75px;
    position: relative;
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 30px;
    display: flex;
  }
  .svg-icons-ov .svg-icons-prev {
    margin-right: 15px;
  }
  .svg-icons-ov .svg-icons-prev svg {
    width: 40px;
    height: 40px;
  }
  .svg-icons-ov .svg-icons-prev svg g [fill] {
    fill: #f25521;
  }
  .svg-icons-ov .svg-icon-popup {
    position: absolute;
    bottom: 15px;
    width: 100%;
    display: flex;
    left: 10px;
  }
  .svg-icons-ov .svg-icon-popup .btn {
    padding: 8px;
    background: #ecf2ff;
    margin: 0 5px;
    color: #f25521;
    font-size: 14px !important;
    display: inline-block;
    width: 40%;
    min-width: 40%;
    height: 40px;
  }
  .svg-icons-ov .svg-icon-popup .btn:hover {
    background-color: #f25521;
    color: #000;
  }
  .svg-icons-ov:hover {
    border-color: #f25521;
  }
  
  .svg-area .row {
    margin: 0;
  }
  
  .svg-area .col-md-2 {
    padding: 0;
  }
  
  .svg-area .card-header {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    padding: 0;
    margin-top: 50px;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
  .svg-area .card-header:first-child {
    margin-top: 0;
  }
  