
.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color); }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: var(--bs-border-width);
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }


.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 var(--bs-border-width); }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #e5edd9;
  --bs-table-border-color: #ced5c3;
  --bs-table-striped-bg: #dae1ce;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #ced5c3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d4dbc9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #ffeccf;
  --bs-table-border-color: #e6d4ba;
  --bs-table-striped-bg: #f2e0c5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d4ba;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecdabf;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d0f3db;
  --bs-table-border-color: #bbdbc5;
  --bs-table-striped-bg: #c6e7d0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bbdbc5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c0e1cb;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #ccefff;
  --bs-table-border-color: #b8d7e6;
  --bs-table-striped-bg: #c2e3f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8d7e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdddec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff2d0;
  --bs-table-border-color: #e6dabb;
  --bs-table-striped-bg: #f2e6c6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dabb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece0c0;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #ffcccd;
  --bs-table-border-color: #e6b8b9;
  --bs-table-striped-bg: #f2c2c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6b8b9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecbdbe;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #faf8f2;
  --bs-table-border-color: #e1dfda;
  --bs-table-striped-bg: #eeece6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e1dfda;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e7e5e0;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-dark {
  --bs-table-color: #000;
  --bs-table-bg: #222222;
  --bs-table-border-color: #383838;
  --bs-table-striped-bg: #2d2d2d;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #383838;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #333333;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1169.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1439.98) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6; }

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.17188rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.82031rem; }

  
.table-responsive-sm {
  min-width: 48rem; }

.table-responsive-md {
  min-width: 60rem;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto; }

.table {
  margin-bottom: 0px;
  font-weight: 400; }
  .table tr td,
  .table tr th {
    padding: 15px; }

.table tr th {
  color: #222222;
  font-weight: 500; }

.table-striped thead tr th {
  padding-bottom: 15px !important; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #000; }

.table-striped tfoot {
  border-top: 2px solid #E1E1F0; }
  .table-striped tfoot tr th {
    padding-top: 10px; }

.table-responsive {
  border-radius: var(--border-radius-base); }

.order-detail {
  margin-top: 40px; }
  .order-detail table {
    width: 100% !important;
    margin-bottom: 25px; }
    .order-detail table tr td {
      padding: 6px 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px; }
      .order-detail table tr td.price {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: end; }
    .order-detail table tr.charges {
      border-bottom: 1px dashed rgba(34, 34, 34, 0.2); }
      .order-detail table tr.charges td {
        padding-bottom: 15px; }
    .order-detail table tr.tax {
      border-bottom: 2px solid rgba(34, 34, 34, 0.2); }
      .order-detail table tr.tax td {
        padding: 15px 0; }
    .order-detail table tr.total td {
      padding: 20px 0 0; }
  .order-detail .btn i, .order-detail .wp-block-button__link i {
    vertical-align: middle;
    margin-left: 10px; }

.check-tbl {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  vertical-align: middle; }
  .check-tbl thead th {
    font-size: 16px;
    font-weight: 600;
    padding: 15px 15px;
    color: #000;
    background: #222; }
  .check-tbl tbody td {
    padding: 15px;
    font-weight: 500; }
    .check-tbl tbody td img {
      width: 100px;
      border-radius: 6px; }
  .check-tbl .product-item-close {
    text-align: right; }
    .check-tbl .product-item-close a {
      width: 40px;
      height: 40px;
      border-radius: 6px;
      background: var(--primary);
      display: inline-block;
      color: #000;
      text-align: center;
      line-height: 40px; }