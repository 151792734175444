.icon-bx-xl {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 150px;
  height: 150px;
  line-height: 150px;
}

.icon-bx-xl.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.icon-bx-xl i {
  font-size: 80px;
  vertical-align: middle;
}

.icon-bx-xl img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 80px;
}

.icon-bx-lg {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 120px;
  height: 120px;
  line-height: 120px;
}

.icon-bx-lg.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.icon-bx-lg i {
  font-size: 60px;
  vertical-align: middle;
}

.icon-bx-lg img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 55px;
}

.icon-bx-md {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.icon-bx-md.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.icon-bx-md i {
  font-size: 45px;
  vertical-align: middle;
}

.icon-bx-md img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 40px;
}

.icon-bx-sm {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 67px;
  height: 67px;
  min-width: 67px;
  line-height: 67px;
}

.icon-bx-sm.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.icon-bx-sm i {
  font-size: 35px;
  vertical-align: middle;
}

.icon-bx-sm img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 35px;
}

.icon-bx-xs {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 60px;
  height: 60px;
  line-height: 60px;
}

.icon-bx-xs.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.icon-bx-xs i {
  font-size: 30px;
  vertical-align: middle;
}

.icon-bx-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 20px;
}

.icon-xl {
  display: inline-block;
  text-align: center;
  width: 100px;
  line-height: 1;
}

.icon-xl i {
  vertical-align: middle;
  font-size: 80px;
}

.icon-xl img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 80px;
}

.icon-lg {
  display: inline-block;
  text-align: center;
  line-height: 1;
}

.icon-lg i {
  vertical-align: middle;
  font-size: 60px;
}

@media only screen and (max-width: 767px) {
  .icon-lg i {
    font-size: 50px;
    line-height: 1.2;
  }
}

.icon-lg img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 70px;
}

.icon-md {
  display: inline-block;
  text-align: center;
  line-height: 1;
}

.icon-md i {
  vertical-align: middle;
  font-size: 45px;
}

.icon-md img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 50px;
}

.icon-sm {
  display: inline-block;
  text-align: center;
  width: 40px;
  line-height: 1;
}

.icon-sm i {
  vertical-align: middle;
  font-size: 30px;
}

.icon-sm img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 30px;
}

.icon-xs {
  display: inline-block;
  text-align: center;
  width: 30px;
  line-height: 1;
}

.icon-xs i {
  vertical-align: middle;
  font-size: 20px;
}

.icon-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 20px;
}

.icon-bx-wraper {
  position: relative;
}

.icon-bx-wraper .dz-tilte {
  margin-top: 0;
  font-weight: 600;
}

.icon-bx-wraper .after-titile-line {
  margin-bottom: 10px;
  font-size: 15px;
}

.icon-bx-wraper p:last-child {
  margin: 0;
}

.icon-bx-wraper.center {
  text-align: center;
}

.icon-bx-wraper.left .icon-bx-xl,
.icon-bx-wraper.left .icon-bx-lg,
.icon-bx-wraper.left .icon-bx-md,
.icon-bx-wraper.left .icon-bx-sm,
.icon-bx-wraper.left .icon-bx-xs {
  float: left;
  margin-right: 15px;
}

.icon-bx-wraper.left .icon-xl,
.icon-bx-wraper.left .icon-lg,
.icon-bx-wraper.left .icon-md,
.icon-bx-wraper.left .icon-sm,
.icon-bx-wraper.left .icon-xs {
  line-height: 1;
  float: left;
  margin-right: 10px;
}

.icon-bx-wraper.right {
  text-align: right;
}

.icon-bx-wraper.right .icon-bx-xl,
.icon-bx-wraper.right .icon-bx-lg,
.icon-bx-wraper.right .icon-bx-md,
.icon-bx-wraper.right .icon-bx-sm,
.icon-bx-wraper.right .icon-bx-xs {
  float: right;
  margin-left: 20px;
}

.icon-bx-wraper.right .icon-xl,
.icon-bx-wraper.right .icon-lg,
.icon-bx-wraper.right .icon-md,
.icon-bx-wraper.right .icon-sm,
.icon-bx-wraper.right .icon-xs {
  line-height: 1;
  float: right;
  margin-left: 10px;
}

.icon-bx-wraper.bx-style-1,
.icon-bx-wraper.bx-style-2 {
  border-width: 1px;
  border-style: solid;
  border-color: #ebedf2;
}

.icon-bx-wraper.bx-style-2.center [class*="icon-bx-"] {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-bx-wraper.bx-style-2.center .icon-bx-xl {
  margin-left: -75px;
}

.icon-bx-wraper.bx-style-2.center .icon-bx-lg {
  margin-left: -60px;
}

.icon-bx-wraper.bx-style-2.center .icon-bx-md {
  margin-left: -50px;
}

.icon-bx-wraper.bx-style-2.center .icon-bx-sm {
  margin-left: -40px;
}

.icon-bx-wraper.bx-style-2.center .icon-bx-xs {
  margin-left: -20px;
}

.icon-bx-wraper.bx-style-2.left [class*="icon-bx-"] {
  position: absolute;
  top: auto;
  left: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.icon-bx-wraper.bx-style-2.right [class*="icon-bx-"] {
  position: absolute;
  top: auto;
  right: 0;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

.icon-content {
  overflow: hidden;
}



.bg-white a {
  color: inherit;
}


.icon-cell {
  display: table-cell;
  vertical-align: middle;
}

.dz-box,
.dz-info,
.dz-tilte,
.dz-tilte-inner {
  position: relative;
}

.dz-tilte-inner {
  display: inline-block;
}

.dz-box[class*="border-"],
.dz-info[class*="border-"] {
  border-color: #eee;
}

.dz-info.border-1,
.dz-info.border-2,
.dz-info.border-3,
.dz-info.border-4,
.dz-info.border-5 {
  border-top: none;
}

.border-1,
.border-2,
.border-3,
.border-4,
.border-5 {
  border-style: solid;
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-5 {
  border-width: 5px;
}

.left-border,
.right-border {
  position: relative;
}

.left-border::before,
.right-border::before {
  content: "";
  position: absolute;
  top: 5px;
  width: 1px;
  height: 90%;
  background: #CCC;
}

.right-border::before {
  right: 0;
}

.dz-media,
.dz-post-media {
  position: relative;
  overflow: hidden;
  padding-top: 20px;
}

.dz-media img,
.dz-post-media img {
  width: 100%;
  height: auto;
}

.dz-box-bg {
  overflow: hidden;
  background-size: cover;
}

.dz-box-bg .btn,
.dz-box-bg .wp-block-button__link {
  overflow: unset;
}

.dz-box-bg .glyph-icon {
  font-size: 50px;
}

.dz-box-bg .icon-bx-wraper {
   background-color:#736f6f;
}

.dz-box-bg .text-primary,
.dz-box-bg .icon-content .dz-tilte,
.dz-box-bg .icon-content p,
.dz-box-bg .icon-box-btn .btn,
.dz-box-bg .icon-box-btn .wp-block-button__link {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-box-bg.active .icon-bx-wraper {
  background-color: rgba(0, 0, 0, 0.7);
}

.dz-box-bg.active .text-primary,
.dz-box-bg.active .icon-content .dz-tilte,
.dz-box-bg.active .icon-content p,
.dz-box-bg.active .icon-box-btn .btn,
.dz-box-bg.active .icon-box-btn .wp-block-button__link {
  color: #000;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-box-bg .icon-box-btn .btn,
.dz-box-bg .icon-box-btn .wp-block-button__link {
  background-color: #eeeeee;
  padding: 15px 20px;
  text-transform: uppercase;
  font-size: 15px;
  border-radius: 0;
  color: #1abc9c;
  font-weight: 600;
  margin-bottom: 0;
}


.icon-bx-wraper.style-1 .inner-content {
  position: relative;
  z-index: 1;
}

.icon-bx-wraper.style-1 .inner-content .icon-cell i {
  font-size: 70px;
  color: #791313;
}

.icon-bx-wraper.style-1::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.icon-bx-wraper.style-1:hover,
.icon-bx-wraper.style-1.active {
  transform: scale(1.1);
  border-radius: 10px;
  z-index: 1;
}

.icon-bx-wraper.style-1:hover::after,
.icon-bx-wraper.style-1.active::after {
  background: var(--rgba-primary-9);
}

.icon-bx-wraper.style-1:hover .icon-content .dz-title,
.icon-bx-wraper.style-1.active .icon-content .dz-title {
  color: #000;
}

.icon-bx-wraper.style-1:hover .icon-content p,
.icon-bx-wraper.style-1.active .icon-content p {
  color: #000;
}

@media only screen and (max-width: 991px) {

  .icon-bx-wraper.style-1:hover,
  .icon-bx-wraper.style-1.active {
    transform: scale(1);
  }
}

.icon-wrapper1 {
  position: relative;
  box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
}

.icon-wrapper1::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  border-radius: 10px;
  z-index: -1;
}

@media only screen and (max-width: 991px) {
  .icon-wrapper1 {
    box-shadow: 0px 10px 30px rgba(34, 34, 34, 0.15);
  }
}

.icon-bx-wraper.style-2 {
  background-color: #000;
  overflow: hidden;
  display: flex;
  align-items: center;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.icon-bx-wraper.style-2 .icon-bx {
  background-color: var(--rgba-primary-1);
  width: 100px;
  height: 100px;
  min-width: 100px;
  border-radius: 100%;
  line-height: 100px;
  text-align: center;
  margin-right: 20px;
}

.icon-bx-wraper.style-2 .icon-bx .icon-cell {
  color: #791313;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.icon-bx-wraper.style-2 .icon-bx .icon-cell i {
  font-size: 45px;
}

.icon-bx-wraper.style-2 .icon-content p {
  font-size: 15px;
  font-weight: 300;
  font-family: var(--font-family-title);
}

.icon-bx-wraper.style-2:hover .icon-bx,
.icon-bx-wraper.style-2.active .icon-bx {
  background-color: #791313;
}

.icon-bx-wraper.style-2:hover .icon-bx .icon-cell,
.icon-bx-wraper.style-2.active .icon-bx .icon-cell {
  color: #000;
}

.icon-bx-wraper.style-2:hover .icon-bx .icon-cell i,
.icon-bx-wraper.style-2.active .icon-bx .icon-cell i {
  animation: shake 1s;
}

@media only screen and (max-width: 1280px) {
  .icon-bx-wraper.style-2 .icon-bx {
    width: 80px;
    min-width: 80px;
    height: 80px;
    line-height: 80px;
    margin-right: 15px;
  }

  .icon-bx-wraper.style-2 .icon-bx .icon-cell i {
    font-size: 35px;
  }

  .icon-bx-wraper.style-2 .icon-content .dz-title {
    font-size: 18px;
  }
}

.icon-wrapper2 {
  position: relative;
}

@media only screen and (min-width: 991px) {
  .icon-wrapper2>div:not(:nth-child(3)) {
    border-right: 1px dashed rgba(102, 102, 102, 0.2);
  }

  .icon-wrapper2>div:last-child {
    border-right: none;
  }
}

.icon-bx-wraper.style-3 {
  border-radius: 10px;
  background: rgba(34, 34, 34, 0.05);
  text-align: center;
  padding: 30px 12px;
  border: 2px solid transparent;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.icon-bx-wraper.style-3 .icon-bx {
  width: 95px;
  height: 95px;
  line-height: 95px;
  border-radius: 100%;
  text-align: center;
  background: #791313;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.icon-bx-wraper.style-3 .icon-bx .icon-cell i {
  font-size: 50px;
  line-height: 100px;
  color: #000;
}

.icon-bx-wraper.style-3 .icon-content {
  margin-top: auto;
}

.icon-bx-wraper.style-3 .icon-content p {
  font-size: 14px;
}

.icon-bx-wraper.style-3:hover {
  transform: translateY(-10px);
  border: 2px solid #791313;
  background-color: #000;
  box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
}

.icon-bx-wraper.style-3:hover .icon-bx .icon-cell i {
  animation: shake 1s;
}

@media only screen and (max-width: 1280px) {
  .icon-bx-wraper.style-3 .icon-content .title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 991px) {
  .icon-bx-wraper.style-3 .icon-content .title {
    font-size: 20px;
  }
}

.icon-bx-wraper.style-4 {
  border-radius: 10px;
  text-align: center;
  padding: 20px 12px 30px;
  min-height: 180px;
}

.icon-bx-wraper.style-4 .icon-bx {
  margin-bottom: 20px;
}

.icon-bx-wraper.style-4 .icon-bx .icon-cell {
  width: 65px;
  height: 65px;
  margin: auto;
  min-width: 65px;
  line-height: 65px;
}

.icon-bx-wraper.style-4 .icon-bx .icon-cell i {
  font-size: 60px;
  color: #791313;
}

.icon-bx-wraper.style-4 .icon-content p {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: var(--title);
}

.icon-bx-wraper.style-4:hover .icon-bx .icon-cell i {
  animation: shake 1s;
}

@media only screen and (max-width: 1280px) {
  .icon-bx-wraper.style-4 .icon-content p {
    font-size: 15px;
    line-height: 1.5;
  }
}

.icon-bx-wraper.style-5 {
  position: relative;
  text-align: center;
  margin-bottom: 30px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  padding-top: 60px;
}

.icon-bx-wraper.style-5 .icon-bx {
  width: 115px;
  height: 115px;
  line-height: 115px;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 2;
}

.icon-bx-wraper.style-5 .icon-bx .icon-cell {
  border-radius: 100%;
  box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
  background-color: #000;
  color: #791313;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.icon-bx-wraper.style-5 .icon-bx .icon-cell i {
  font-size: 50px;
  vertical-align: middle;
  line-height: 50px;
}

.icon-bx-wraper.style-5 .icon-content {
  padding: 90px 40px 30px;
  box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
  border-radius: 10px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background-color: #000;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.icon-bx-wraper.style-5 .icon-content .title {
  margin-bottom: 10px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.icon-bx-wraper.style-5 .icon-content p {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.icon-bx-wraper.style-5 .icon-content p:last-child {
  margin-bottom: 0;
}

.icon-bx-wraper.style-5 .icon-content .effect {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  transition: width .4s ease-in-out, height .4s ease-in-out;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}

.icon-bx-wraper.style-5:hover .icon-cell i,
.icon-bx-wraper.style-5.active .icon-cell i {
  animation: shake 1s;
}

.icon-bx-wraper.style-5:hover .icon-content .title,
.icon-bx-wraper.style-5.active .icon-content .title {
  color: #000;
}

.icon-bx-wraper.style-5:hover .icon-content p,
.icon-bx-wraper.style-5.active .icon-content p {
  color: rgba(255, 255, 255, 0.8);
}

.icon-bx-wraper.style-5:hover .icon-content .effect,
.icon-bx-wraper.style-5.active .icon-content .effect {
  width: 300%;
  height: 300%;
}

@media only screen and (max-width: 1280px) {
  .icon-bx-wraper.style-5 {
    padding-top: 45px;
  }

  .icon-bx-wraper.style-5 .icon-bx {
    width: 90px;
    height: 90px;
    line-height: 90px;
  }

  .icon-bx-wraper.style-5 .icon-bx i {
    font-size: 40px;
    line-height: 40px;
  }

  .icon-bx-wraper.style-5 .icon-content {
    padding: 65px 20px 30px;
  }

  .icon-bx-wraper.style-5 .icon-content p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 991px) {
  .icon-bx-wraper.style-5 {
    border-radius: 10px;
    overflow: hidden;
    padding: 30px 20px;
    box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
    position: relative;
    z-index: 1;
  }

  .icon-bx-wraper.style-5 .icon-bx {
    position: initial;
    transform: translateY(0);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    width: 60px;
    height: 60px;
    line-height: 60px;
  }

  .icon-bx-wraper.style-5 .icon-bx .icon-cell {
    box-shadow: none;
    background-color: transparent;
  }

  .icon-bx-wraper.style-5 .icon-bx .icon-cell i {
    font-size: 45px;
    line-height: 45px;
  }

  .icon-bx-wraper.style-5 .icon-content {
    border-radius: 0;
    padding: 0;
    box-shadow: none;
    position: static;
    background-color: transparent;
  }

  .icon-bx-wraper.style-5:hover .icon-bx .icon-cell,
  .icon-bx-wraper.style-5.active .icon-bx .icon-cell {
    color: #000;
  }
}

.dz-team {
  transition: all 0.5s;
}

.dz-team .dz-name a {
  transition: all 0.5s;
}

.dz-team .dz-content {
  transition: all 0.5s;
  position: relative;
}

.dz-team .dz-media img {
  width: 100%;
}

.dz-team .dz-position {
  display: block;
}

.dz-team.style-1 .dz-media {
  position: relative;
  z-index: 1;
}

.dz-team.style-1 .dz-media img {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-team.style-1 .dz-media::before {
  content: "";
  position: absolute;
  width: 200px;
  height: 200px;
  background: #ffffff;
  top: -100px;
  left: -100px;
  opacity: 0.2;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 50%;
  transform: scale(0.5) translateX(-50%);
  z-index: 1;
}

.dz-team.style-1 .dz-content {
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
}

.dz-team.style-1 .dz-content .dz-name {
  margin-bottom: 2px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-team.style-1 .dz-content .dz-position {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #791313;
}

.dz-team.style-1 .team-social li {
  position: relative;
}

.dz-team.style-1 .team-social li a {
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: var(--border-radius-base);
  background: #791313;
  color: #000;
  font-size: 18px;
}

.dz-team.style-1 .team-social li a.share-btn {
  font-size: 22px;
}

.dz-team.style-1 .team-social .sub-team-social {
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 40px;
  border-radius: var(--border-radius-base);
  opacity: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-team.style-1 .team-social .sub-team-social li {
  margin-bottom: 8px;
}

.dz-team.style-1 .team-social .sub-team-social li a {
  color: #000;
  display: inline-block;
  text-align: center;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  margin-bottom: -20px;
}

.dz-team.style-1 .team-social:hover li .sub-team-social {
  opacity: 1;
  bottom: 70px;
}

.dz-team.style-1 .team-social:hover li .sub-team-social li a {
  margin-bottom: 0;
}

.dz-team.style-1:hover .dz-media::before {
  transform: scale(5);
}

.dz-team.style-1:hover .dz-media img {
  transform: scale(1.1);
}