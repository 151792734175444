.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #666666;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.375rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: 0.25rem;
}
@media (min-width: 1201px) {
  .input-group-sm > .form-control,
  .input-group-sm > .form-select,
  .input-group-sm > .input-group-text,
  .input-group-sm > .btn {
    font-size: 0.76562rem;
  }
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-control,
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation
  > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .form-control,
.input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #10ca93;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.76563rem;
  color: #000;
  background-color: rgba(16, 202, 147, 0.9);
  border-radius: 0.375rem;
}
@media (min-width: 1201px) {
  .valid-tooltip {
    font-size: 0.76562rem;
  }
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #10ca93;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2310ca93' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #10ca93;
  box-shadow: 0 0 0 0.25rem rgba(16, 202, 147, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: #10ca93;
}
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size='1'],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size='1'] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2310ca93' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: #10ca93;
  box-shadow: 0 0 0 0.25rem rgba(16, 202, 147, 0.25);
}

.was-validated .form-control-color:valid,
.form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: #10ca93;
}
.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #10ca93;
}
.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(16, 202, 147, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #10ca93;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid,
.input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 12px;
  color: #f25767;
  text-align: left;
  font-family: 'Mulish', sans-serif;
}
.feedback{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 12px;
  color: #f25767;
  text-align: left;
}
.form-group {
  position: relative;
 }
 
 .form-group input[type='password'] {
  padding-right: 50px;
 }
 
.without_validation {
  position: absolute;
  top: 33px;
  right: 0px;
  bottom: 0;
  height: 100%;
  border: none;
  height: 100%;
  max-height: 35px;
  background: transparent;
  outline: none;
  cursor: pointer;
 }
 .without_validation:active{
  background-color: transparent;
 }
 .without_validation:active{
  background-color: transparent;
 }
 .validation:active{
  background-color: transparent;
 }
 .without_validation i{
  color: #89879f;
  text-align: center;
 }
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.76563rem;
  color: #000;
  background-color: rgba(242, 87, 103, 0.9);
  border-radius: 0.375rem;
}
@media (min-width: 1201px) {
  .invalid-tooltip {
    font-size: 0.76562rem;
  }
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}
.validation{
  position: absolute;
  top: 33px;
  bottom: 0;
  height: 100%;
  border: none;
  height: 100%;
  max-height: 35px;
  background: transparent;
  outline: none;
  cursor: pointer;
  right: 12px;
}
.validation:hover{
  background-color: transparent;
  border: none;
}
.validation i{
  color: #89879f;
  text-align: center;
 }
 button:focus{
background-color: transparent;
outline: none;
 }
.was-validated .form-control:invalid,
.form-control.is-invalid {
  // border-color: #f25767;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f25767'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f25767' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.235rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.was-invalid {
  // border-color: #f25767;
  padding-right: calc(1.5em + 0.75rem);
  background-position: right calc(0.375em + 0.235rem) center;
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  // border-color: #f25767;
  // box-shadow: 0 0 0 0.25rem rgba(242, 87, 103, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  // border-color: #f25767;
}
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size='1'],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size='1'] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f25767'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f25767' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: #f25767;
  box-shadow: 0 0 0 0.25rem rgba(242, 87, 103, 0.25);
}

.was-validated .form-control-color:invalid,
.form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: #f25767;
}
.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: #f25767;
}
.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(242, 87, 103, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #f25767;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid,
.input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: '';
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

.placeholder-wave {
  mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}


.label {
  display: inline-block;
  text-align: center;
  font-size: 0.75rem;
  padding: 0.2rem 0.8rem;
}
.label-fixed {
  width: 7.5rem;
  padding: 0.6rem 0;
}
.label-fixed-lg {
  width: 9.5rem;
  padding: 0.6rem 0;
}
.label-big {
  width: 16.8rem;
  font-size: 1.4rem;
  padding: 1.1rem 0;
}
.label-xl {
  width: 10.5rem;
  padding: 1.1rem 0;
  font-size: 1.5rem;
}
.label-lg {
  width: 9.5rem;
  padding: 1.1rem 0;
}
.label-md {
  width: 8.5rem;
  padding: 1.1rem 0;
}
.label-sm {
  width: 7.5rem;
  padding: 1.1rem 0;
}

.label-default {
  background: #adb6c7;
}

.label-primary {
  background: #f25521;
  color: #000;
}

.label-secondary {
  background: #24365c;
  color: #000;
}

.label-info {
  background: #00afef;
  color: #000;
}

.label-success {
  background: #10ca93;
  color: #000;
}

.label-warning {
  background: #ff9f00;
  color: #000;
}

.label-danger {
  background: #f25767;
  color: #000;
}

.label-light {
  background: #ebeef6;
  color: #000;
}

.label-dark {
  background: #181c32;
  color: #000;
}

code {
  word-break: break-word;
  padding: 2px 5px;
  border-radius: 3px;
  background: #fef3f4;
  color: #f25767;
}
[data-theme-version='dark'] code {
  background: rgba(242, 87, 103, 0.1);
}

.heading-labels {
  color: #333333;
}
.heading-labels > * {
  margin-bottom: 0.8rem;
}
.heading-labels h1 .label,
.heading-labels .h1 .label {
  font-size: 18px;
  font-weight: normal;
  padding: 0.4rem 0.9rem;
}
.heading-labels h2 .label,
.heading-labels .h2 .label {
  font-size: 16px;
  font-weight: normal;
  padding: 0.3rem 0.9rem;
}
.heading-labels h3 .label,
.heading-labels .h3 .label {
  font-size: 14px;
  font-weight: normal;
}
.heading-labels h4 .label,
.heading-labels .h4 .label {
  font-size: 12px;
  font-weight: normal;
}
.heading-labels h5 .label,
.heading-labels .h5 .label {
  font-size: 12px;
  font-weight: normal;
}
.heading-labels h6 .label,
.heading-labels .h6 .label {
  font-size: 12px;
  font-weight: normal;
}
// select
.bootstrap-select {
  margin-bottom: 0;
}
.bootstrap-select .btn {
  border: 1px solid #d7dae3 !important;
  background-color: transparent !important;
  padding: 0.532rem 1rem;
  font-weight: 400;
  color: #89879f !important;
}
[data-theme-version='dark'] .bootstrap-select .btn {
  border-color: #333a54 !important;
}
.bootstrap-select .btn:active,
.bootstrap-select .btn:focus,
.bootstrap-select .btn:hover {
  outline: none !important;
  outline-offset: 0;
}
[data-theme-version='dark'] .bootstrap-select .btn:active,
[data-theme-version='dark'] .bootstrap-select .btn:focus,
[data-theme-version='dark'] .bootstrap-select .btn:hover {
  color: #89879f !important;
}
.bootstrap-select .dropdown-menu {
  border-color: #d7dae3 !important;
  box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
}
.bootstrap-select .dropdown-menu .dropdown-item {
  padding: 0.25rem 1rem;
}
[data-theme-version='dark'] .bootstrap-select .dropdown-menu {
  border-color: #d7dae3 !important;
}

.input-group > .bootstrap-select:not(:first-child) .dropdown-toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .bootstrap-select:not(:last-child) .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.show-pass .fa-eye {
  display: none;
  font-size: 12px;
}

.show-pass.active .fa-eye {
  display: inline-block;
}

.show-pass.active .fa-eye-slash {
  display: none;
}

.text-left{
  text-align: left;
}