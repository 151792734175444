.loc_overlay {
    z-index: 99999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 110vw;
    background: rgba(0, 0, 0, .3);

    .loc_left_comp {
        width: 10%;

        .loc_left_ic {
            width: 30px;
        }
    }

    @media (min-width: 640px) {
        .loc_container {
            top: 5.5rem;
            left: 7rem;
            width: fit-content;
            max-width: 26rem;
            padding: 1.5rem 1rem;
        }
        
        .loc_btn_1 {
            margin-right: 1.5rem;
        }
    }
    @media (max-width: 640px) {
        .loc_container {
            top: 4rem;
            padding: 1.5rem 0.7rem;
            max-width: 25rem;
        }
    }

    .loc_container {
        background: #ffffff;
        position: fixed;
        margin-left: .625rem;
        margin-right: .625rem;
        display: flex;
        border-radius: .5rem;

        .loc_right_comp {
            display: flex;
            flex-direction: column;
            width: 90%;

            .loc_nrm {
                font-size: .875rem;
                line-height: 1.125rem;
                font-weight: 400;
                margin-left: 5px;
                letter-spacing: .5px !important;
                font-family: 'Mulish', sans-serif;
            }

            .loc_btn_1 {
                border-radius: .375rem;
                letter-spacing: .1em;
                background-color: #791313;
                color: var(--theme-text-color);
                font-size: .875rem;
                line-height: 1.125rem;
                padding-top: .5rem;
                padding-bottom: .5rem;
                border-width: 1px;
                flex-basis: 50% !important;
                max-width: 10rem;


                .loc_btn_text_1 {
                    letter-spacing: .5px !important;
                    font-weight: 500;
                    font-family: inherit;
                    font-size: .8125rem !important;
                    line-height: 1rem !important;
                    font-family: 'Mulish', sans-serif;
                    color: var(--white);
                }
            }

            .loc_btn_2 {
                border-radius: .375rem;
                letter-spacing: .1em;
                background-color: var(--theme-text-color);
                border:1px solid #791313;
                color: #791313;
                font-size: .875rem;
                line-height: 1.125rem;
                padding-top: .5rem;
                padding-bottom: .5rem;
                flex-basis: 50% !important;


                .loc_btn_text_2 {
                    letter-spacing: .5px !important;
                    font-weight: 500;
                    font-family: inherit;
                    font-size: .8125rem !important;
                    line-height: 1rem !important;
                    color: #791313;
                }
            }
        }


    }
}

.loc_overlay:active .loc_container:not(:active) {
    animation: loc_shake .4s;
}


@keyframes loc_shake {
    10%, 50% {
        transform: translate3d(-4px, 0, 0);
    }
    20%, 40% {
        transform: translate3d(8px, 0, 0);
    }
}