
.user_header{
    display: flex;
    justify-content: flex-end;
    // padding: 0px 20px 10px;
}
.table_wrapper{
    // margin: 20px;
}
.cancel_btn{
    border: 1px solid #74788d;
    background-color: #74788d;
    color: #000;
    margin-right: 5px;
}

