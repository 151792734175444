// banner
@import url("https://fonts.googleapis.com/css2?family=Eater&family=Rubik+Puddles&display=swap");

.common_layout {
  max-width: 80rem;
  margin: auto;
  padding: 0.5rem;
}

.bnr_video_section {
  position: relative;
  height: calc(100vh - 98px);
  min-height: 450px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: column;

  .banner-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    z-index: -2;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &::before {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }

  .banner_content {
    position: relative;
    max-width: 95%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .banner_title {
      color: #ffffff;
      text-align: center;
    }
    .about_sub_banner_cnt {
      font-size: 16px;
      color: #a09999;
    }
    .about_banner_title {
      letter-spacing: 3px;
      font-size: 70px;

      @media  (max-width: 1200px) {
        font-size: 56px;
      }

      @media  (max-width: 768px) {
        font-size: 44px;
      }
      @media (max-width: 480px) {
        font-size: 36px;
      }

      

      .burger {
        color: var(--primary);
        font-family: "Rubik Puddles", serif;
        font-weight: bold;
        font-style: normal;
      }
      
    }

    .bite {
      font-size: 100px;
      font-family: "Mulish", sans-serif;
      font-weight: 800;

      font-style: italic;
      text-transform: capitalize;

      @media (max-width: 1200px) {
        font-size: 55px;
      }
    }

    .big {
      font-size: 80px;
      font-family: "Mulish", sans-serif;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 4px;
    }

    .live_large {
      font-size: 70px;
      font-weight: 700;
      padding-top: 20px;
      text-transform: uppercase;
      font-family: "Noto", sans-serif;
      letter-spacing: 4px;
    }

    .banner_drp_arrow {
      margin: 30px 0px;
      border-radius: 30px;
      max-width: 270px;
      letter-spacing: 0.5px;
      width: 100%;
      padding: 5px 15px;
      color: var(--primary-light);
      background: #791313;
      background-size: 200% 100%;
      background-position: right bottom;
      transition: background-position 0.5s ease;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      .banner_drp_txt {
        padding: 8px 0px 8px 10px;
      }
      .banner_drp_sml_cnt {
        font-size: 12px;
        color: #fff;
        text-align: left;
        line-height: 20px;
      }
      .down_arrow {
        cursor: pointer;
        width: 40px;
        height: 40px;
      }
      .banner_drp_title {
        font-size: 20px;
        color: #fff;
        font-weight: 900;
        font-family: inherit;
        text-transform: uppercase;
      }
    }
  }
}

.rotating {
  animation: rotateArrow 4s linear infinite;
}

@keyframes rotateArrow {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1200px) {
  .bnr_video_section {
    height: 100%;
  }
}
.bnr_secondary_txt {
  font-size: 14px;
  font-family: inherit;
  font-weight: 300;
  letter-spacing: 0.3px;
  color: #fff;
  margin-bottom: 20px;
}
.order_via_ics_container {
  display: flex;
  align-items: center;
}
.ord_via_ics {
  width: 80px;
  border-radius: 7px;
  padding: 5px;
  margin: 10px;
}
.seperator {
  height: 30px;
  width: 1px;
  background-color: #6f6e6e;
}
.bricks-background-video-wrapper {
  background-size: cover;
  height: 100%;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute !important;
  top: 0;
  width: 100%;
  z-index: -1;

  video {
    left: 50%;
    max-width: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.banner_slider {
  margin: 20px 0px 30px 0px;

  @media screen and (max-width: 768px) {
    margin: 20px 0px 0px 0px;
  }
  .swiper {
    border-radius: 10px;
  }

  .home_banner_inner {
    display: flex;
    justify-content: center;

    img {
      cursor: pointer;
      border-radius: 10px;
    }
  }
}

// PREMIUM SELLER

.premium_dishes {
  background-image: url("../../../images/premium_seller_bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.premium_dishes_container {
  width: 100%;
  display: flex;

  .premium_seller_bnr {
    display: flex;
    border-radius: 40px;
    justify-content: center;
    margin: 35px 0px 0px 0px;
    max-width: 100%;

    @media screen and (max-width: 767px) {
      // height: auto;
      // flex-direction: column-reverse;
      // position: static;
      display: flex;
    }

    .premium_dishes_content {
      background-image: url("../../../images/premiumproducts/bg.png");
      background-position: right;
      background-repeat: no-repeat;
      width: 50%;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      background-size: cover;

      @media screen and (max-width: 767px) {
        border-radius: 0 0 20px 20px;
        width: 100%;
      }

      .premium_inner_wrapper {
        height: 100%;

        @media screen and (max-width: 992px) {
          padding: 20px;
        }

        .premium_inner_content {
          border: 1px solid #fff;
          border-radius: 10px;
          height: inherit;
          padding: 0 20px;

          max-width: 700px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          height: 450px;

          .premium_offer {
            font-family: "Mulish", sans-serif;
            font-size: 35px;
            font-weight: 600;
            color: #791313;
            text-align: center;
            text-decoration: underline;
          }

          .premium_pr_title {
            font-family: "Mulish", sans-serif;
            font-size: 20px;
            line-height: 30px;
            font-weight: 600;
            letter-spacing: 1.9px;
            color: var(--white);
            text-align: center;
            max-width: 300px;
          }

          .premium_pr_desc {
            font-size: 16px;
            color: #9a9a9a;
            font-family: "Mulish", sans-serif;
            text-align: center;
            max-width: 300px;
          }

          .premium_pr_price {
            font-family: "Mulish", sans-serif;
            font-size: 28px;
            font-weight: 600;
            text-align: center;
            margin-top: 10px;

            .new_price {
              color: #791313;

              &::before {
                content: "\20B9";
                padding-right: 3px;
              }
            }

            .old_price {
              color: #9a9a9a;
              text-decoration: line-through;
              margin-left: 10px;

              &::before {
                content: "\20B9";
                padding-right: 3px;
              }
            }
          }

          .premium_order_btn {
            background-color: #791313;
            border-radius: 10px;
            height: 40px;
            max-width: 150px;
            line-height: 41px;
            padding: 0px 30px;
            margin-top: 15px;
          }

          .premium_order_btn_text {
            font-size: 18px !important;
            color: var(--theme-text-color);
            margin: 0px;
            letter-spacing: 1px;
            text-transform: capitalize;
            font-family: "Mulish", sans-serif;
            cursor: pointer;
            text-align: center;
          }
        }
      }
    }
  }

  .premium_seller_img_container {
    width: 90%;
    height: 450px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    img {
      max-width: 100%;
      border-radius: 0 20px 20px 0;
      height: 100%;
      object-fit: cover;
      width: 100%;

      @media screen and (max-width: 767px) {
        border-radius: 20px 20px 0 0;
        width: 100%;
      }
    }
  }
}

// CATEGORY - HOME

.section_category {
  background-color: rgb(255, 255, 255);

  .section_title {
    color: #000;
  }

  .category_item {
    flex: 1 1 12.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;

    @media (max-width: 1024px) {
      flex: 1 1 20%;
    }
  }

  .catgory_slider {
    height: 120px;
    border-radius: 5px;
    width: 92%;
    object-fit: cover;

    @media screen and (max-width: 767px) {
      width: 90px;
      height: 70px;
    }
  }

  .category_container {
    padding: 20px 0px;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .category_slider {
    padding-top: 12px;

    .category_name {
      font-size: 17px;
      color: #000;
      font-family: "Mulish", sans-serif;
      font-weight: 600;
      text-align: center;

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }

      @media screen and (max-width: 456px) {
        font-size: 12px;
      }
    }
  }
}

// HOMEPAGE PRODUCTS

.products_hm_container {
  background-color: #f6f6f6;

  .section_title {
    color: #000;
  }

  .home_pr_cat_container {
    padding: 15px 0px 0px;

    .home_pr_category_head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 15px;

      .subtitle {
        font-weight: 600;
        font-size: 20px;
        color: #000;
        font-family: "Mulish", sans-serif;
        margin: 0px;

        @media (max-width: 500px) {
          font-size: 20px;
        }
      }

      .see_all {
        font-size: 18px;
        font-weight: 700;
        font-family: inherit;
        letter-spacing: 0.1px;
        border-bottom: 2px solid #791313;
        cursor: pointer;
        color: #791313;
      }
    }

    .home_category_wth_prs {
      padding: 15px 0px;
    }

    .home_pr_container {
      display: grid;
      column-gap: 0.75rem;
      row-gap: 1rem;
      grid-template-columns: repeat(5, minmax(0, 1fr));

      @media (max-width: 1280px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }

      @media (max-width: 1024px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      @media (max-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      .home_pr_card {
        border-radius: 10px;
        background: #ffffff;
        box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
        text-align: center;
        transition: all 0.2s;
        position: relative;
        height: 100%;
        padding: 25px 20px 40px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @media (max-width: 768px) {
          padding: 25px 10px 25px;
        }

        .home_pr_card_content {
          display: flex;
        }

        .pr-flex-normal {
          padding-top: 15px;
          display: flex;
          width: 100%;
          justify-content: space-around;

          .pr-price-wrap {
            display: flex;
            align-items: center;
          }

          .pr_stp {
            font-size: 15px;
            font-weight: 700;
            font-family: "Mulish", sans-serif;
            letter-spacing: 0.6px;
            text-decoration: line-through;
            color: #000;
          }

          .pr_price {
            font-size: 20px;
            font-weight: 700;
            letter-spacing: 1px;
            font-family: "Mulish", sans-serif;
            margin-left: 7px;
            color: #791313;
          }
        }

        .pr_media {
          cursor: pointer;
          position: relative;
          overflow: hidden;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          width: 40%;
          min-height: 140px;
          max-height: 140px;

          img {
            transition: all 0.2s;
            width: 100%;
            object-fit: cover;
            height: 100%;
            border-radius: 10px;
          }
        }

        .pr-content {
          padding: 0px 15px 15px 15px;
          display: block;
          width: 60%;
          text-decoration: none;

          .pr-title-wrap {
            width: 80%;
            display: flex;
            margin-bottom: 15px;

            @media (max-width: 768px) {
              width: 100%;
            }

            .product_title {
              cursor: pointer;
              font-family: inherit;
              font-size: 20px;
              overflow: hidden;
              text-align: start;
              overflow: hidden;
              display: -webkit-box;
              line-height: 26px;
              color: var(--theme-text-color);
              font-weight: 700;
              -webkit-line-clamp: 2;
              margin-left: 3px;
              -webkit-box-orient: vertical;
            }
          }

          .product_desc_container {
            margin-bottom: 10px;
            height: 40px;

            .product_description {
              font-family: inherit;
              font-size: 11px;
              color: #000;
              font-family: "Mulish", sans-serif;
              text-align: justify;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }

          .veg_nv_ic {
            width: 18px;
            height: 18px;
          }
        }

        .pr_add_cart_btn {
          width: 150px;
          height: 40px;
          border-radius: 10px;
          display: flex;
          justify-content: space-between;
          border: 1px solid #791313;
          letter-spacing: 0.3px;
          font-size: 0.9rem;
          font-weight: 600;
          font-family: inherit;
          position: relative;
          text-align: center;
          background-color: #791313;

          @media (max-width: 768px) {
            width: 100px;
          }

          .pr_add_title {
            text-align: center;
            width: 100%;
            line-height: 40px;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 2px;
            font-family: inherit;
            cursor: pointer;
            color: var(--white);
          }

          .pr_add_inside_btns {
            width: 35%;
            font-size: 16px;
            font-weight: 800;
            line-height: 40px;
            font-family: inherit;
            cursor: pointer;
            color: var(--white);

            @media (max-width: 768px) {
              font-size: 13px;
            }
          }

          .pr_add_qty {
            font-size: 20px;
            font-weight: 700;
            line-height: 40px;
            font-family: inherit;
            color: var(--white);

            @media (max-width: 768px) {
              font-size: 16px;
            }
          }
        }

        // custom

        .dz-meta {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
        }

        .dz-meta ul li.seller {
          position: absolute;
          top: 0px;
          left: 0px;
          z-index: 1;
          background-color: var(--primary);
          color: #000;
          border-radius: 9px 0px 10px;
          font-size: 12px;
          font-weight: 500;
          padding: 4px 10px;
          margin-right: 0;
          display: block;
          -webkit-transition: all 0.8s;
          -ms-transition: all 0.8s;
          transition: all 0.8s;
        }

        .dz-meta ul li.rating {
          position: absolute;
          bottom: 20px;
          right: 20px;
          z-index: 1;
          background-color: #000;
          color: var(--title);
          border-radius: 6px;
          font-size: 14px;
          font-weight: 500;
          padding: 4px 10px;
          margin-right: 0;
          text-transform: uppercase;
          display: block;
          -webkit-transition: all 0.8s;
          -ms-transition: all 0.8s;
          transition: all 0.8s;
          align-items: center;
        }

        .dz-meta ul li.rating i {
          font-size: 12px;
          top: -2px;
          color: #fe9f10;
        }
      }

      .home_pr_card:hover .pr_media img {
        transform: scale(1.1);
      }
    }
  }
}

.customisable {
  content: "customisable";
  font-size: 10px;
  text-align: center;
  font-family: "Mulish", sans-serif;
  padding-top: 2px;
  opacity: 1;
  color: #a49c9c;
  position: absolute;
  bottom: -17px;
}

// COMMITMENTS

.commitments_content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .commitments_img_wrapper {
    width: 120px;
    height: 110px;
    align-items: flex-end;
    display: flex;

    @media (max-width: 480px) {
      width: 80px;
      height: 80px;
    }
  }

  .commitment_title {
    color: #791313;
    font-size: 40px;
    font-family: "Mulish", sans-serif;
    margin: 20px 0px 15px 0px;
    letter-spacing: 1px;
  }

  .commitment_desc {
    color: #000;
    font-size: 18px;
    font-family: "Mulish", sans-serif;
    text-align: center;
    margin-top: 20px;
    font-weight: 600;

    @media (max-width: 480px) {
      font-size: 14px;
    }
  }
}

// INSTA HIGHLIGHTS
.instagram_highlight_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000;

  .insta_carousel {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .insta_carousel_track {
    display: flex;
    flex-direction: row;
    animation: scroll 30s linear infinite;
    flex-wrap: wrap;
  }

  .insta_carousel_track img {
    flex-shrink: 0;
    height: 100%;
  }

  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(-100%);
    }
  }
}

/* src/ImageCollage.css */
.collage-container {
  width: 100%;
  height: 300px;
  /* Fixed height for the container */
  overflow: hidden;
  /* Hide any overflow content */
  position: relative;
}

.collage {
  display: flex;
  flex-wrap: wrap;
  /* Allow wrapping */
  position: absolute;
  top: 0;
  left: 0;
  animation: scroll 20s linear infinite;
}

.collage-item {
  flex: 0 0 auto;
  margin: 5px;
  /* Space between images */
}

.collage-item img {
  display: block;
  max-height: 300px;
  /* Ensure images do not exceed the container height */
  max-width: 100%;
  height: auto;
  /* Maintain aspect ratio */
  object-fit: cover;
  /* Ensure the image covers the container while maintaining aspect ratio */
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

/***offer area***/
.wrap_1 {
  background-color: rgb(206, 40, 41);
}

.wrap_2 {
  background-color: #292929;
}

.wrap_3 {
  background-color: #ffeecc;
}

.single_category {
  cursor: pointer;
}

.caetgory_name {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  font-family: "Mulish", sans-serif;
  cursor: pointer;
}

.single-offer-wrap {
  background-size: 100% 100%;
  border-radius: 12px;
  position: relative;
  // padding: 44px 49px 50px;
  margin-bottom: 25px;
  z-index: 1;
  overflow: hidden;
}

.single-offer-wrap .bg-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}

.single-offer-wrap .bg-img-2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
}

.single-offer-wrap .animated-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  -webkit-animation: 20s linear 0s infinite normal none running left-loop;
  animation: 20s linear 0s infinite normal none running left-loop;
}

.single-offer-wrap .animated-img-2 {
  -webkit-animation: 20s linear 0s infinite normal none running left-loop-2;
  animation: 20s linear 0s infinite none normal running left-loop-2;
}

.single-offer-wrap .overlay-gradient {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    90.1deg,
    #ffeecc 38.57%,
    rgba(255, 238, 204, 0) 66.47%
  );
}

.single-offer-wrap:hover .bg-img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.single-offer-wrap .wrap-details {
  width: 49%;
  position: relative;
}

.single-offer-wrap .wrap-details h2 {
  font-size: 68px;
  font-weight: 700;
  color: #000;
  font-family: "Quattrocento", serif;
  line-height: 72px;
  margin-bottom: 8px;
}

.single-offer-wrap .wrap-details h3 {
  color: #000;
}

.single-offer-wrap .wrap-details h3 span {
  color: var(--main-color);
}

.single-offer-wrap .wrap-details h3.text-heading {
  color: var(--heading-color);
}

.single-offer-wrap .wrap-details h5 {
  color: #000;
  font-size: 20px;
}

.single-offer-wrap .wrap-details .btn {
  height: 48px;
  line-height: 48px;
  margin-top: 17px;
  padding: 0 20px;
}

.single-offer-wrap .offer-sticker {
  display: inline-block;
  margin: -27px 0 -20px 195px;
}

/***single-item-wrap***/
.single-item-wrap {
  position: relative;
  background-color: #000;
  border-radius: 12px;
  padding: 22px 29px 24px;
  margin-bottom: 60px;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}

.single-item-wrap:hover {
  border-radius: 12px 12px 0 0;
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.09);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.09);
}

.single-item-wrap:hover .thumb img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.single-item-wrap:hover .btn-area {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(0, 100%);
  transform: translate(0, 100%);
}

.single-item-wrap .thumb {
  text-align: center;
}

.single-item-wrap .thumb img {
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}

.single-item-wrap .thumb a {
  position: absolute;
  top: 18px;
  right: 27px;
  font-size: 24px;
}

.single-item-wrap .thumb a:hover i:before {
  content: "";
}

.single-item-wrap .wrap-details {
  margin-top: 22px;
}

.single-item-wrap .wrap-details h5 {
  font-weight: 600;
  font-size: 22px;
}

.single-item-wrap .wrap-details .wrap-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.single-item-wrap .wrap-details .wrap-footer .rating {
  font-size: 16px;
  color: rgba(27, 27, 27, 0.7);
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.single-item-wrap .wrap-details .wrap-footer .rating .rating-inner {
  margin: 0 10px;
}

.single-item-wrap .wrap-details .wrap-footer .price {
  margin-left: auto;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
}

.single-item-wrap .btn-area {
  background-color: #000;
  border-radius: 0 0 12px 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  -webkit-transform: translate(0px, 80%);
  transform: translate(0px, 80%);
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  -webkit-box-shadow: 0 30px 30px rgba(0, 0, 0, 0.12);
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.12);
}

.single-item-wrap .btn-area .btn {
  border-radius: 30px 30px 0 0;
  padding: 0 90px;
  height: 64px;
  line-height: 64px;
}

.single-item-wrap.style-2 {
  padding: 25px 29px 30px;
  margin-bottom: 30px;
}

.single-item-wrap.style-2:hover {
  border-radius: 12px;
}

.single-item-wrap.style-2 .media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.single-item-wrap.style-2 .media .thumb {
  margin-right: 30px;
}

.single-item-wrap.style-2 .media .wrap-details {
  margin-top: 0;
}

.single-item-wrap.style-2 .media .wrap-details .wrap-footer .price {
  margin-left: 0;
}

.single-item-wrap.style-2 .media .wrap-details .btn {
  margin-left: auto;
  font-size: 16px;
  font-weight: 600;
  height: 42px;
  line-height: 42px;
  border-radius: 6px;
  padding: 0 32px;
}

/****animation-css-frame****/
@-webkit-keyframes left-loop {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes left-loop {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@-webkit-keyframes left-loop-2 {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes left-loop-2 {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

//   category

@media only screen and (max-width: 1480px) {
  .section-wrapper-2 .bg1 {
    display: none;
  }
}

@media only screen and (max-width: 1680px) {
  .section-wrapper-2 .bg1 {
    left: -320px;
  }
}

.section-wrapper-2 .bg1 {
  bottom: 0;
  left: -275px;
}

.section-wrapper-2 .bg1,
.section-wrapper-2 .bg2 {
  position: absolute;
}

.dz-move-down {
  animation: dzMoveDown 7s infinite;
}

@media only screen and (max-width: 1480px) {
  .section-wrapper-2 .bg1 {
    display: none;
  }
}

@media only screen and (max-width: 1680px) {
  .section-wrapper-2 .bg1 {
    left: -320px;
  }
}

.section-wrapper-2 .bg1 {
  bottom: 0;
  left: -275px;
}

.section-wrapper-2 .bg1,
.section-wrapper-2 .bg2 {
  position: absolute;
}

.dz-move-down {
  animation: dzMoveDown 7s infinite;
}

@media only screen and (max-width: 1480px) {
  .section-wrapper-2 .bg2 {
    top: 28px;
    right: 0px;
  }
}

@media only screen and (max-width: 1680px) {
  .section-wrapper-2 .bg2 {
    top: 90px;
    right: 15px;
  }
}

.section-wrapper-2 .bg2 {
  top: 50px;
  right: 40px;
}

.section-wrapper-2 .bg1,
.section-wrapper-2 .bg2 {
  position: absolute;
}

// top seller
@media (max-width: 1440px) {
  .single-offer-wrap {
    padding: 34px 39px 40px;
  }
}

.single-offer-wrap {
  background-size: 100% 100%;
  border-radius: 12px;
  position: relative;
  padding: 44px 49px 50px;
  margin-bottom: 25px;
  z-index: 1;
  overflow: hidden;
}

.combo_section {
  display: grid;
  transition: all 0.05s ease 0s;
  cursor: pointer;
  gap: 12px;
  grid-auto-flow: row;
  justify-content: stretch;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px;
  width: 100%;
  max-width: 273px;
  height: auto;
}

.slider-container {
  width: 100%;
  /* Set the width as needed */
  overflow: hidden;
  /* Hide overflowing images */
  background-color: rgb(255, 255, 255);
}

.slider {
  display: flex;
  /* Arrange images in a row */
  // transition: scroll-behavior 0.10s; /* Smooth scrolling effect */
  animation: scroll 40s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 3));
  }
}

.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

.instaImg {
  width: 280px;
  height: 200px;
  margin: 10px 16px;
  border-radius: 10px;
}

.InstHe1 {
  text-align: center;
  font-size: 24px;
  color: #000;
  margin-top: 50px;
  font-family: "Playball", sans-serif;
}

.InstHe2 {
  text-align: center;
  color: #000;
  font-size: 40px;
  font-weight: 800;
  margin-top: 20px;
  margin-bottom: 50px;
}

.insta-btn {
  border: 1px solid #791313;
  color: #ffffff;
  width: 250px;
  height: 50px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;

  padding-left: 3px;
  border-radius: 4px;
  background-color: #791313;
  font-family: "Mulish", sans-serif;
}

.btnB {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.offcanvas-body {
  background-color: #ffffff;
}

.instaLogo {
  width: 22px;
  height: 22px;
  margin-left: 5px;
  margin-bottom: 3px;
}

.burger_primary {
  color: var(--primary);
}