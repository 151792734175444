.lh-sm {
  h6{
   font-size: 14px;
}
p{
  font-size: 13px;
}
}
.map_placeholder{
  font-size: 20px;
  margin-left: 20px;
}
.top-search-bar{
  display: flex;
}

.login-head{
  position: relative;
  padding: 30px 0px 20px 0px;
  display: flex;
  align-items: center;
  z-index: 1;

}
.login_title {
  font-size: 28px;
  font-weight: 800;
  font-family: 'Mulish', sans-serif;
  text-transform: uppercase;
  color: #000;
  text-align: left;
}
.login_image {
  position: absolute;
  top: 26px;
  left: 98px;
  z-index: -1;
  width: 100%;
  max-width: 80px;
}
.register_image{
  position: absolute;
  top: 26px;
  right: 0px;
  z-index: -1;
  width: 100%;
  max-width: 80px;
}
.otp_image{
  position: absolute;
  top: 26px;
  right: 0px;
  z-index: -1;
  width: 100%;
  max-width: 80px;
}
.registre_image{
  position: absolute;
  top: 31px;
  right: 0px;
  z-index: -1;
}

.register-subtitle{
  width: 200px;
  text-align: justify;
  font-size: 12px;
  font-family: 'Mulish', sans-serif;
}
.isNew span{
  color: var(--primary);
  font-weight: 500;
}

@media only screen and (max-width: 575px) {
  .login-head{
    padding-top: 0px;
  }
  .login_image{
    display: none;
  }
  .login_title{
    margin-top: 0px;
  }
  .offcanvas-form .login-head p{
    display: none;
  }
  .otp_image{
    display: none;
  }
  
}